import { React, Component } from 'react';
class PurchasedCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            subscriptionDetails: null,
        };
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (prevState.init) {
            return {
                subscriptionDetails: nextProps.subscriptionTeam.subscriptionInfo,
            }
        }
        return null
    }
    render() {
        let available = 0
        let {disabled} = this.props
        if (this.props && this.props.subscriptionInfo) {
            available = this.props.subscriptionInfo.SeatsPurchased - this.props.subscriptionInfo.UsedSeats;
        }
        return (
            <div className="alphatub-card">
               
            </div>
        )
    }
}

export default PurchasedCard