export const login = {
    ADMIN_LOGIN: 'ADMIN_LOGIN',
}
export const loaders = {
    ASSIGNMENT_TABLE_LIST_FETCHING: 'ASSIGNMENT_TABLE_LIST_FETCHING',
    ASSIGNMENT_DATES_FETCHING: 'ASSIGNMENT_DATES_FETCHING',
    IS_AUTH_FETCHING: 'IS_AUTH_FETCHING',
    IS_APP_FETCHING: "IS_APP_FETCHING",
    INNER_APP_FETCHING: "INNER_APP_FETCHING",
    IS_TUB_GALLERY_FETCHING: "IS_TUB_GALLERY_FETCHING",
    INNER_POPUP_LOADING: 'INNER_POPUP_LOADING',
    IS_ASSIGNMENT_LOADING: 'IS_ASSIGNMENT_LOADING',
    SESSION_LIST_FETCHING: 'SESSION_LIST_FETCHING',
    SESSION_DATES_FETCHING: 'SESSION_DATES_FETCHING',
    IS_FETCHING_GALLERY_LIST: 'IS_FETCHING_GALLERY_LIST',
    CREATED_SESSION_LIST_COUNT_FETCHING: 'CREATED_SESSION_LIST_COUNT_FETCHING',
    SESSION_ASSIGNED_DATES_FETCHING: 'SESSION_ASSIGNED_DATES_FETCHING'
}

export const main = {
    SESSION_ASSIGNED_DATES: "SESSION_ASSIGNED_DATES",
    COPYOF_TRACK_ASSIGNMENT_TABLE_LIST: 'COPYOF_TRACK_ASSIGNMENT_TABLE_LIST',
    TRACK_ASSIGNMENT_TABLE_LIST: 'TRACK_ASSIGNMENT_TABLE_LIST',
    ASSIGNMENT_TABLE_LIST: 'ASSIGNMENT_TABLE_LIST',
    ASSIGNMENT_CALENDAR_DATES_LIST: 'ASSIGNMENT_CALENDAR_DATES_LIST',
    STUDENT_ANALYTICS_GRAPHS_LIST: 'STUDENT_ANALYTICS_GRAPHS_LIST',
    RESET_GRAPHS: 'RESET_GRAPHS',
    CREATE_DEFAULT_ASSIGNED_LIST: 'CREATE_DEFAULT_ASSIGNED_LIST',
    ASSIGNMENT_QUERY: 'ASSIGNMENT_QUERY',
    CREATE_ASSIGNED_LIST: 'CREATE_ASSIGNED_LIST',
    ASSIGNED_LIST: "ASSIGNED_LIST",
    CHANGE_ASSIGNMENTS_DROPDOWN_LIST: "CHANGE_ASSIGNMENTS_DROPDOWN_LIST",
    ASSIGNMENTS_DROPDOWN_LIST: 'ASSIGNMENTS_DROPDOWN_LIST',
    CURRICULUM_AND_READING_PROGRAM: 'CURRICULUM_AND_READING_PROGRAM',
    READING_PROGRAM_LISTS: "READING_PROGRAM_LISTS",
    UPDATE_ASSIGNMENT_GALLERY_LIST: "UPDATE_ASSIGNMENT_GALLERY_LIST",
    ASSIGNMENT_GALLERY_LIST: 'ASSIGNMENT_GALLERY_LIST',
    CLASS_PROGRAMS_LIST: 'CLASS_PROGRAMS_LIST',
    ATTENDANCE_DETAIL: 'ATTENDANCE_DETAIL',
    CLASS_CURRICULUM_LIST: 'CLASS_CURRICULUM_LIST',
    ATTENDANCE_LIST: "ATTENDANCE_LIST",
    TOGGLE_REVIEW_TUB_CLASS: 'TOGGLE_REVIEW_TUB_CLASS',
    COLLAGE_STUDENTS_LIST: 'COLLAGE_STUDENTS_LIST',
    SET_GAME_START_TIME: 'SET_GAME_START_TIME',
    COUNTRY_LIST: "COUNTRY_LIST",
    STATES_LIST: "STATES_LIST",
    CITIES_LIST: 'CITIES_LIST',
    LOGOUT: "LOGOUT",
    IS_SELECTED_ACTIVITY_TAB: "IS_SELECTED_ACTIVITY_TAB",
    DISABLE_LAUNCH_TUBCLASS: "DISABLE_LAUNCH_TUBCLASS",
    SELECTED_LEFT_SIDE_BAR: "SELECTED_LEFT_SIDE_BAR",
    TUB_ACTIVITY_SUGGESTION_LIST: "TUB_ACTIVITY_SUGGESTION_LIST",
    RESET_PERSIST: "RESET_PERSIST",
    ACTIVITY_LIST_QUERY_PAYLOAD: "ACTIVITY_LIST_QUERY_PAYLOAD",
    INVOICES_LIST: "INVOICES_LIST",
    SELECTED_ACTIVITY: 'SELECTED_ACTIVITY',
    IS_SELECTED_BACK_BTN: "IS_SELECTED_BACK_BTN",
    USER_SELECTED_GAME: "USER_SELECTED_GAME",
    ANALYTICS_GRAPHS_LIST: "ANALYTICS_GRAPHS_LIST",
    IS_SESSION_PRESENT: 'IS_SESSION_PRESENT',
    CURRENT_GROUP_PLAY_GROUP: "CURRENT_GROUP_PLAY_GROUP",
    CURRENT_GAME_QUESTION: "CURRENT_GAME_QUESTION",
    QUESTION_MAPPED_WITH_RANDOM_PLAYERS: "QUESTION_MAPPED_WITH_RANDOM_PLAYERS",
    MISSING_THE_LETTER_QUESTIONS: "MISSING_THE_LETTER_QUESTIONS",
    UPDATE_AVAILABLE_SEATS: "UPDATE_AVAILABLE_SEATS",
    UPDATE_PRIVACY_POLICY_ACCEPTED: "UPDATE_PRIVACY_POLICY_ACCEPTED",
    MATCH_THE_GOOD_WORD_QUESTIONS: "MATCH_THE_GOOD_WORD_QUESTIONS",
    MATCH_THE_OBJECT_QUESTIONS: "MATCH_THE_OBJECT_QUESTIONS",
    SELECTED_PLAYERS_LIST: "SELECTED_PLAYERS_LIST",
    GAME_SETTINGS_DATA: "GAME_SETTINGS_DATA",
    SELECTED_MODE: 'SELECTED_MODE',
    SELECTED_ACTIVITY_ID: "SELECTED_ACTIVITY_ID",
    GAME_ACTIVITY_DETAIL: "GAME_ACTIVITY_DETAIL",
    GAME_ACTIVITY_LIST: "GAME_ACTIVITY_LIST",
    CREATED_SESSION_LIST: "CREATED_SESSION_LIST",
    SELECTED_TUB_CLASS_TABS: "SELECTED_TUB_CLASS_TABS",
    GAME_TYPES_AND_MODES: "GAME_TYPES_AND_MODES",
    SELECTED_GAME: "SELECTED_GAME",
    PLAYERS_LIST: "PLAYERS_LIST",
    TUB_INBOX_LIST: "TUB_INBOX_LIST",
    SESSION_LIST: "SESSION_LIST",
    TUB_ACTIVITY_LIST: "TUB_ACTIVITY_LIST",
    SESSION_DISABLE_DATES_LIST: "SESSION_DISABLE_DATES_LIST",
    RIGHT_SIDEBAR_STUDENTS_LIST: "RIGHT_SIDEBAR_STUDENTS_LIST",
    SELECTED_CLASS: "SELECTED_CLASS",
    TUB_GALLERY_LIST: "TUB_GALLERY_LIST",
    USER_CLASS_DROPDOWN_LIST: "USER_CLASS_DROPDOWN_LIST",
    GET_SUBSCRIPTION_TEAM_DATA: "GET_SUBSCRIPTION_TEAM_DATA",
    MANAGE_SEAT_ALLOCATION_LIST: "MANAGE_SEAT_ALLOCATION_LIST",
    ROLES_DROPDOWN_LIST: 'ROLES_DROPDOWN_LIST',
    TABLEROLES_DROPDOWN_LIST: "TABLEROLES_DROPDOWN_LIST",
    CLASS_DROPDOWN_LIST: "CLASS_DROPDOWN_LIST",
    CLASS_TABLE_LIST: "CLASS_TABLE_LIST",
    INVITATION_INFO: "INVITATION_INFO",
    PROFILE_INFO: "PROFILE_INFO",
    SUBSCRIPTION_INFO: "SUBSCRIPTION_INFO",
    SAVE_PROFILE: "SAVE_PROFILE",
    UPDATE_PROFILE_PIC: "UPDATE_PROFILE_PIC",
    OLD_USER_GALLERY_DATA: "OLD_USER_GALLERY_DATA",
    NEW_PASSWORD_REQUIRED: 'NEW_PASSWORD_REQUIRED',
}