import React from "react";
import ReactApexChart from "react-apexcharts";

const HorizontalBarchart = (props) => {
  const series = props.series;
  console.log('SERIES --->>>', series);
  const options = {
    chart: {
      type: "bar",
      stacked: true,
      horizontal: true,
    },
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    xaxis: {
      categories: props.y_axis,
    },
    legend: {
      position: "top",
      horizontalAlign: "left",
    },
    colors: props.colors,
  };
  return (
    <ReactApexChart options={options} series={series} type="bar" height="700" />
  );
};
export default HorizontalBarchart;
