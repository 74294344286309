import React from "react";
import Select from "react-select";
import { FormGroup, Label } from "reactstrap";

const ReactSelect = (props) => {
  return (
    <FormGroup>
      {props.label != undefined && <Label>{props.label}</Label>}
      <Select
        onChange={(val) => props.onChange(props.name, val)}
        options={props.options}
        defaultValue={props.value}
        // value={props.value}
        styles={props.styles}
        classNamePrefix={`m-0`}
        isDisabled={props.disabled}
        components={{
          DropdownIndicator: () => null,
          IndicatorSeparator: () => null,
        }}
        // defaultValue={"All"}
        // className={props.className}
        isMulti={props.isMulti}
        menuPosition={"fixed"}
        menuShouldBlockScroll={true}
        isClearable={props.isClearable}
        placeholder={props.placeholder}
      />
      {props.error != undefined && (
        <strong className="text-danger small">
          {props.error ? props.error[0] : ""}
        </strong>
      )}
    </FormGroup>
  );
};

ReactSelect.defaultProps = {
  onChange: () => {},
  options: [],
  value: "",
  classNamePrefix: "form-group",
  isMulti: false,
};
export default ReactSelect;
