import { React,Component } from 'react';
import { connect } from 'react-redux'
import moment from 'moment'
import * as tubInboxAction from '../../../../actions/tubInbox'
import { bindActionCreators } from "redux";
import { ShowMore } from '../../../../components/tubClass/showMore';
import { timeSince } from '../../../../helpers';
import Wrapper from '../../../../Hoc/wrapper';

class InboxClass extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rejectedList: []
        };
    }
    static getDerivedStateFromProps(nextProps, prevState) {
        return {
            rejectedList: nextProps.tubinbox.tubInboxList
        }
    }
    render() {
        let state = this.state
        return (
            <div className="col-12 p-0">
                <div className="row flex-wrap mt-1">
                    {state.rejectedList?.length > 0
                        ?
                            state.rejectedList && state.rejectedList.map((data, i) => (
                                <div className="col-md-6 mt-4" key={i}>
                                    <div className="tub-bg-type-1 deleted_inbox">
                                        <div className="relative-pos">
                                            <div className={`tub-session-card ${(data.TubObjectType === 'Sheet') && 'addFilter p-1'}`}>
                                                <div className="row flex-wrap no-gutters">
                                                    <div className="col-12">
                                                        <img
                                                            src={data?.TubObjectId?.ObjectImage?.original}
                                                            className={`${(data.TubObjectType === 'Card') && 'card_img'}`}
                                                            alt="alphatub"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row align-items-center no-gutters inbox-content-spnImg">
                                                <img
                                                    src={
                                                        data.StudentId != undefined && data.StudentId.profilePic != undefined && data.StudentId.profilePic.original != undefined &&
                                                        data.StudentId.profilePic.original !== '' ? data.StudentId.profilePic.original : require("../../../../assets/images/noImage.png")
                                                    }
                                                    
                                                    className="whbr-60"
                                                    alt="alphatub"
                                                />
                                                <p className="object_student_name pl-2">{data?.StudentName}</p>
                                            </div>
                                        </div>
                                        <div className="row no-gutters mt-2 justify-content-between align-items-center">
                                            <div className="col-4">
                                                <ShowMore 
                                                    i = {i}
                                                    data = {data}
                                                />
                                            </div>
                                            <div className="col-5 d-flex justify-content-center">
                                                <p className="alphatub-card-h2">{data?.TubObjectName}</p>
                                            </div>
                                            <div className="col-3 d-flex justify-content-end">
                                                <p className="f-14 tub-day">{timeSince(data.updatedAt, false)}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        :
                        <div className="row no-gutters align-items-center center-content w-100 h-400px">
                            <p className='tub-content-4 text-center'>No Disabled Content</p>
                        </div>
                    }
                </div>
            </div>
        )
    }
}

const mapStateToProps = ({ tubinbox, loader }) => {
    return {
        tubinbox,
        loader
    };
};

let mapDispatchToProps = (dispatch) => {
    return {
        tubInboxAction: bindActionCreators(tubInboxAction, dispatch),
        dispatch
    }
}

const  Disabled = Wrapper(connect(mapStateToProps, mapDispatchToProps)(InboxClass))

export { Disabled }