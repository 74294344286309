import { React, Component } from 'react';
import { connect } from 'react-redux'
import ButtonType1 from '../../../../components/inputs/ButtonType1';
import FormInput from '../../../../components/inputs/FormInput';
import errorMsgs from "../../../../utils/errorMsgs";
import toast from "../../../../utils/Toast";
import { API } from 'aws-amplify';
import ReactSpeedometer from "react-d3-speedometer"
import SplineArea from '../../../../components/charts/apex/SplineArea';
import { ENVIRONMENT } from '../../../../common/constants';
import { bindActionCreators } from "redux";
import PieChart from '../../../../components/charts/apex/PieChart';
import { ALL_CONTENTS_FILTERS, ANALYTICS_DATE_FILTER, FILTER_OPTIONS, GAMES_OPTIONS } from '../../../../helpers/variables'
import { ST_LEARGNING_CURVE, ST_ACTIVE_LIST, ST_TOP_SCORE_CONTENT, ST_AVG_SCORE_CONTENT, ST_MOST_PLAYED_CONTENT, ST_RECENTLY_PLAYED_CONTENT, ST_TIME_SPENT_ON_GAMES, staticAnalyticsRows, ST_ENGAGEMENT_METER_COLUMNS } from '../../../../helpers/tableDataMapper'
import { CustomBootstrapTable } from '../../../../components/Table/CustomBootstrapTable';
import { AppLoader } from '../../../../components/loader/appLoader';
import _ from 'lodash';
import { accessHeaders, history } from '../../../../helpers';
import { ChartBaseComponent } from '../../../../components/charts/chartBaseComponent';
import HorizontalBarchart from '../../../../components/charts/apex/horizonatalBarchart';
import Wrapper from '../../../../Hoc/wrapper';
import { Card, CardBody } from 'reactstrap';
import Skeleton from 'react-loading-skeleton';
import { CustomSelectFilter } from 'components/filters/customSelectFilter';
import moment from 'moment';
import ReactSelect from 'react-select';
import { DateRangeFilterPopup } from 'components/tubClass/dateRangeFilterPopup';
import * as analyticsAction from 'actions/analytics';
import Apaexlinecolumn from 'components/charts/apex/apaexlinecolumn';

const OverallPerformanceStages = [
    { name: "Low", bgColor: "#00adef66" },
    { name: "Medium", bgColor: "#00adef99" },
    { name: "Average", bgColor: "#00adefcc" },
    { name: "High", bgColor: "#00ADEF" },
]
class StudentsClass extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tab: 1,
            contentOptions: ALL_CONTENTS_FILTERS,
            gamesOptions: GAMES_OPTIONS,
            filterOptions: ANALYTICS_DATE_FILTER,
            selectedPlayerDetail: null,
            isScroll: false,
            showFilterDatePopup: false,
            selectedDateRange: null,
            noOfDays: "30",
            selectedFilter: ANALYTICS_DATE_FILTER[0],
        };
    }
    async componentDidMount() {
        let params = this.props.match.params
        let studentId = params?.id
        this.setStickyTabs()
        this.getAllGraphs('30')
        // this.props.analyticsAction.fetchStudentGraphData(8, 'voacabluaryReportByStudent', '30', null, 1, studentId)
        // this.props.analyticsAction.fetchStudentGraphData(9, 'voacabluaryReportByStudent', '365', null, 1, studentId)
    }
    getAllGraphs = (noOfDays) => {
        let params = this.props.match.params
        let studentId = params?.id
        this.props.analyticsAction.fetchStudentGraphData(0, 'studentCurriculumProgress', noOfDays, null, null, studentId)
        this.props.analyticsAction.fetchStudentGraphData(1, 'studentPerformance', noOfDays, null, null, studentId)
        this.props.analyticsAction.fetchStudentGraphData(2, 'studentLearningProgressForHomeSchoolOverall', noOfDays, null, 1, studentId)
        this.props.analyticsAction.fetchStudentGraphData(3, 'studentParentalContentCreated', noOfDays, null, 1, studentId)
        this.props.analyticsAction.fetchStudentGraphData(4, 'studentParentalGamePlayed', noOfDays, null, 1, studentId)
        this.props.analyticsAction.fetchStudentGraphData(5, 'topWordsLearnedByStudent', noOfDays, null, 1, studentId)
        this.props.analyticsAction.fetchStudentGraphData(6, 'topTopicsLearnedByStudent', noOfDays, null, 1, studentId)
        this.props.analyticsAction.fetchStudentGraphData(7, 'directorvoacabluaryReportByStudent', noOfDays, null, 1, studentId)
    }

    redirect = (tab) => {
        this.setState({
            tab: tab
        })
    }
    unselectSelectedValue = (type) => {
        if (type == "full") {
            this.setState({
                noOfDays: ''
            }, this.getAllGraphs(''))
        }
    }

    onChangeDateFilter = (name, value) => {
        if (value?.value == 'customDates') {
            this.setState({
                selectedDateRange: null,
                showFilterDatePopup: true,
                selectedFilter: value,
            })
        } else {
            this.setState({
                noOfDays: value?.value,
                selectedDateRange: null,
                selectedFilter: value,
            }, this.getAllGraphs(value?.value))
        }

    }
    setStickyTabs = () => {
        let isScroll = false
        window.addEventListener('scroll', () => {
            if (window.scrollY > 10) {
                isScroll = true;
            } else isScroll = false
            this.setState({
                isScroll: isScroll
            });
        });
    }

    triggerPopup = (val, data) => {
        if (val === "showFilterDatePopup") {
            this.unselectSelectedValue('filterByDate')
        }
        this.setState({
            [val]: !this.state[val]
        })
    }

    onChangeFilterDate = (ranges) => {
        let startDate = moment(ranges.startDate)
        let endDate = moment(ranges.endDate)
        let diff = moment.duration(endDate.diff(startDate)).asDays()
        console.log('diff', diff)
        this.setState({
            noOfDays: diff,
            selectedDateRange: ranges,
            showFilterDatePopup: false
        }, this.getAllGraphs(diff))
    }
    goBack = () => {
        this.props.history.push('/dashboard/analytics/studentreport')
    }
    render() {
        let state = this.state
        let { analytics, match, tubGallery } = this.props
        let studentId = match.params?.id
        let getstudentDetail
        if (tubGallery.rightSidebarStudentsList && tubGallery.rightSidebarStudentsList?.length > 0) {
            getstudentDetail = tubGallery.rightSidebarStudentsList.find(st => st._id == studentId)
        }
        return (
            <div className="row ">
                <div className="col-12 pt-2">
                    <div className={`analytics_ovl_cl_main justify-space-between ${state.isScroll ? 'sticky_bx_shadow' : ''}`}>
                        <div className="col-9 d-flex align-items-center">
                            <img
                                src={require('assets/images/left-arrow.svg').default}
                                className="back-btn"
                                onClick={() => this.goBack()}
                                alt="alphatub"
                            />
                            {/* <div className='ml-2'>
                                <h5>{getstudentDetail?.firstName} 's Report</h5>
                            </div> */}
                        </div>
                        <div className="col-md-2 col-5 px-3">
                            <div className="custom-select-filter">
                                <ReactSelect
                                    classNamePrefix={`m-0`}
                                    name={'filterByDate'}
                                    options={state.filterOptions}
                                    placeholder={'Select Filter'}
                                    value={state.selectedFilter}
                                    onChange={(val) => this.onChangeDateFilter('filterByDate', val)}
                                    isClearable={false}
                                />
                            </div>
                            {state.selectedDateRange != null &&
                                <p className='selected_date_range_filter'>({state.selectedDateRange?.startDate} - {state.selectedDateRange.endDate})</p>
                            }
                        </div>
                    </div>
                    {/* <div
                        className="analytics_students mt-2 cursor-pointer"
                    onClick={() => this.renderStudentIndReport(tb,1)}
                    >
                        <img 
                                    src={tb.profilePic.original ? tb.profilePic.original : noImage}
                                    alt="alphatub"
                                />
                                
                    </div> */}
                    <div>
                        <div className='row no-gutters mt-3 report'>
                            <div className='col-12'>
                                <div className='row no-gutters justify-space-around bg_white br-5'>
                                    <div className='col-6'>
                                        <div className='report_type'>
                                            <p>Curriculum Completion</p>
                                        </div>
                                        <div className='mt-55'>
                                            <PieChart
                                                x_axis={analytics.studentsGrpahsList[0].graphData.x_axis}
                                                y_axis={analytics.studentsGrpahsList[0].graphData.y_axis}
                                                colors={['#00ADEF', '#ED7D2B']}
                                                showLegend={true}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-6'>
                                        <div className='report_type'>
                                            <p>Overall Performance</p>
                                            <h6 className='mt-2'>Overall score in all content</h6>
                                        </div>
                                        <div className="mt-3 main_speedometer">
                                            <ReactSpeedometer
                                                value={analytics.studentsGrpahsList[1].graphData?.percentage}
                                                minValue={0}
                                                maxValue={100}
                                                segments={4}
                                                // width={170}
                                                fluidWidth={true}
                                                maxSegmentLabels={0}
                                                // needleHeightRatio={0.75}
                                                needleColor={"#F05A28"}
                                                segmentColors={["#00adef66", "#00adef99", "#00adefcc", "#00ADEF"]}
                                                valueTextFontSize={'12px'}
                                                // valueTextFontWeight={'300'}
                                                textColor={"rgb(240, 90, 40)"}
                                            />
                                        </div>
                                        <div className="ovl_performance_stages pb-3">
                                            {OverallPerformanceStages.map(st => (
                                                <div style={{ backgroundColor: st.bgColor }} >
                                                    {st.name}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Learning Progress */}
                        <div className='row no-gutters justify-content-center mt-4 br-5'>
                            <div className='col-12'>
                                <ChartBaseComponent
                                    loading={analytics.studentsGrpahsList[2].loading}
                                    title={'Learning Progress'}
                                    report_number={''}
                                    children={
                                        <SplineArea
                                            x_axis={analytics.studentsGrpahsList[2].graphData?.x_axis}
                                            y_axis={analytics.studentsGrpahsList[2].graphData?.y_axis}
                                            y_axis_title={'Average Score'}
                                            x_axis_title="Date"
                                            showToolBar={true}
                                            colors={['#F14624']}
                                            offsetY={parseInt(state.noOfDays) >= 30 ? 135 : 90}
                                        />
                                    }
                                />
                            </div>
                        </div>

                        {/* Parental Engagement */}
                        <div className='row no-gutters mt-4 br-5'>
                            <div className='col-12 report'>
                                <Card>
                                    <CardBody>
                                        {analytics.studentsGrpahsList[3].loading ?
                                            <Skeleton
                                                style={{ marginTop: 15 }}
                                                height="350px"
                                                width="100%"
                                            />
                                            :
                                            <div className='row no-gutters justify-space-between'>
                                                <div className='col-5'>
                                                    <div className='row no-gutters'>
                                                        <div className='col-12'>
                                                            <div className='report_heading'>
                                                                <p>{'Parental Engagement'}</p>
                                                            </div>
                                                            <div className='main_graph mt-2'>
                                                                <p className='horizontal_chrt_title'>Content Created by Parents by This Week</p>
                                                                <HorizontalBarchart
                                                                    series = {[
                                                                        {
                                                                            name: 'Content Created',
                                                                            data: analytics.studentsGrpahsList[3].graphData?.x_axis,
                                                                        }
                                                                    ]}
                                                                    y_axis={analytics.studentsGrpahsList[3].graphData?.y_axis}
                                                                    colors={['#00e396', '#00ADEF', '#ff4560', '#775dd0']}
                                                                    name={'Content Created'}
                                                                    y_axis_title=""
                                                                    x_axis_title="No Of Contents Created"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-6'>
                                                    <div className='row no-gutters'>
                                                        <div className='col-12'>
                                                            <div className='report_heading'>
                                                                <p><sup className='report_super_txt'>{''}</sup>{''}</p>
                                                            </div>
                                                            <div className='main_graph'>
                                                                <p className='horizontal_chrt_title'>Games played by Parents by This Week</p>
                                                                <HorizontalBarchart
                                                                    series = {[
                                                                        {
                                                                            name: 'Games Played',
                                                                            data: analytics.studentsGrpahsList[4].graphData?.x_axis,
                                                                        }
                                                                    ]}
                                                                    y_axis={analytics.studentsGrpahsList[4].graphData?.y_axis}
                                                                    colors={['#00ADEF', '#00e396', '#ff4560', '#775dd0']}
                                                                    showToolBar={false}
                                                                    x_axis_title="No Of Games Played"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </CardBody>
                                </Card>
                            </div>
                        </div>
                        {/* Words & topic list */}
                        <div className='row no-gutters mt-4 br-5'>
                            <div className='col-12 report'>
                                <Card>
                                    <CardBody>
                                        {analytics.studentsGrpahsList[5].loading ?
                                            <Skeleton
                                                style={{ marginTop: 15 }}
                                                height="350px"
                                                width="100%"
                                            />
                                            :
                                            <div className='row no-gutters justify-space-between'>
                                                <div className='col-5'>
                                                    <div className='row no-gutters'>
                                                        <div className='col-12'>
                                                            <div className='report_heading'>
                                                                <p>{'Word List Report'}</p>
                                                            </div>
                                                            <div className='main_graph mt-2'>
                                                                <p className='horizontal_chrt_title'>Top 5 Words</p>
                                                                <Apaexlinecolumn
                                                                    horizontal = {true}
                                                                    name = {'Exposures'}
                                                                    y_axis = {analytics.studentsGrpahsList[5].graphData?.x_axis}

                                                                    name_2 = {'Correct Answers'}
                                                                    y_axis_2 = {analytics.studentsGrpahsList[5].graphData?.x_axis_1}

                                                                    x_axis_title="No Of Words"
                                                                    offsetY = {0}
                                                                    x_axis = {analytics.studentsGrpahsList[5].graphData?.y_axis}

                                                                    colors={['#00ADEF', '#ED7D2B']}

                                                                    dataEnabledOffsetY = {0}
                                                                    dataEnabledOffsetX = {15}

                                                                    showToolBar={false}
                                                                    y_axis_title=""

                                                                    horizontalAlign = {'center'}
                                                                    legendPosition = {'bottom'}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-6'>
                                                    <div className='row no-gutters'>
                                                        <div className='col-12'>
                                                            <div className='report_heading'>
                                                                <p>{'Topic List Report'}</p>
                                                            </div>
                                                            <div className='main_graph'>
                                                                <p className='horizontal_chrt_title'>Top 5 Topics</p>
                                                                <Apaexlinecolumn
                                                                    horizontal = {true}
                                                                    name = {'Exposures'}
                                                                    y_axis = {analytics.studentsGrpahsList[6].graphData?.x_axis}

                                                                    name_2 = {'Correct Answers'}
                                                                    y_axis_2 = {analytics.studentsGrpahsList[6].graphData?.x_axis_1}

                                                                    x_axis_title="No Of Topics"
                                                                    offsetY = {0}
                                                                    x_axis = {analytics.studentsGrpahsList[6].graphData?.y_axis}

                                                                    colors={['#00ADEF', '#ED7D2B']}

                                                                    dataEnabledOffsetY = {0}
                                                                    dataEnabledOffsetX = {15}

                                                                    showToolBar={false}
                                                                    y_axis_title=""

                                                                    horizontalAlign = {'center'}
                                                                    legendPosition = {'bottom'}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </CardBody>
                                </Card>
                            </div>
                        </div>
                        {/* Words & topic list */}
                        <div className='row no-gutters mt-4 br-5'>
                            <div className='col-12 report'>
                                <Card>
                                    <CardBody>
                                        {analytics.studentsGrpahsList[7].loading ?
                                            <Skeleton
                                                style={{ marginTop: 15 }}
                                                height="350px"
                                                width="100%"
                                            />
                                            :
                                            <div className='row no-gutters justify-space-between'>
                                                <div className='col-6'>
                                                    <div className='row no-gutters'>
                                                        <div className='col-12'>
                                                            <div className='report_heading'>
                                                                <p>{'Vocabulary Report'}</p>
                                                            </div>
                                                            <div className='main_graph mt-2'>
                                                                <p className='horizontal_chrt_title'></p>
                                                                <HorizontalBarchart
                                                                    series = {[
                                                                        {
                                                                            name: 'Games Played',
                                                                            data: analytics.studentsGrpahsList[7].graphData?.x_axis,
                                                                        }
                                                                    ]}
                                                                    y_axis={analytics.studentsGrpahsList[7].graphData?.y_axis}
                                                                    colors={['#00ADEF', '#00e396', '#ff4560', '#775dd0']}
                                                                    showToolBar={false}
                                                                    x_axis_title="No Of Words"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </CardBody>
                                </Card>
                            </div>
                        </div>
                    </div>

                    {/* Filter date popup*/}
                    <DateRangeFilterPopup
                        showFilterDatePopup={state.showFilterDatePopup}
                        triggerPopup={this.triggerPopup}
                        onChangeFilterDate={this.onChangeFilterDate}
                    />
                </div>

            </div>
        )
    }
}

const mapStateToProps = ({ myProfile, analytics, loader, tubGallery, dataToPersist }) => {
    return {
        myProfile,
        loader,
        tubGallery,
        dataToPersist,
        analytics
    };
};

let mapDispatchToProps = (dispatch) => {
    return {
        analyticsAction: bindActionCreators(analyticsAction, dispatch),
        dispatch
    }
}

const StudentReport = Wrapper(connect(mapStateToProps, mapDispatchToProps)(StudentsClass))

export { StudentReport }


{/* <div className="row no-gutters mt-2">
                                        <div className="col-12">
                                            <div className='st_rp_bg_1'>
                                                <div className='row no-gutters'>
                                                    <div className='col-4'>
                                                        <div className='rp_img_cont'>
                                                            <img src={require('../../../../assets/images/st_rp_2.svg').default} />
                                                        </div>
                                                    </div>
                                                    <div className='col-4'>
                                                        <div className='rp_inner_cont'>
                                                            <h2 className='st_rp_txt1'>Student Report</h2>
                                                            <p className='st_rp_txt2'>01 - 07 April 2022</p>
                                                        </div>
                                                    </div>
                                                    <div className='col-3'>
                                                        <div className='rp_right_cont'>
                                                            <p className='st_rp_txt2'>Student Name: {state.selectedPlayerDetail?.firstName} </p>
                                                            <p className='st_rp_txt2'>Teacher Name: {state.selectedPlayerDetail?.teacherName} </p>
                                                            <p className='st_rp_txt2'>Class: {dataToPersist?.selectedClass?.label}</p>
                                                            <p className='st_rp_txt2'>School: {state.selectedPlayerDetail?.schoolName}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}

{/* <div className="row">
                                    <div className="col-md-4">
                                        <div className="mt-1 ov_l_1">
                                            <span className='ov_p_1'>Last Activity: </span>
                                            <div>
                                                <p className='ov_p_2 mt-1'>20th march 2021, 2:20 PM</p>
                                            </div>
                                        </div>
                                        <div className="mt-1 ov_l_1">
                                            <span className='ov_p_1'>Recent Activity: </span>
                                            <div>
                                                <p className='ov_p_2 mt-1'>TUB Sheet: Animal</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-8'>
                                        <div className='row'>
                                            <div className='col-3'>
                                                <div className='ov_l_meter'>
                                                    <div className='text-center'>
                                                        <h2 className='ov_l_meter_head'>Content Played</h2>
                                                    </div>
                                                    <div className='position_relative'>
                                                        <img src={require('../../../../assets/images/tub_analy_1.svg').default} alt="alphatub"/>
                                                        <p className='ovl_meter_count gauge_meter_1'>23</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-3'>
                                                <div className='ov_l_meter'>
                                                    <div className='text-center'>
                                                        <h2 className='ov_l_meter_head'>Topics Interacted</h2>
                                                    </div>
                                                    <div className='position_relative'>
                                                        <img src={require('../../../../assets/images/tub_analy_2.svg').default} alt="alphatub"/>
                                                        <p className='ovl_meter_count gauge_meter_2'>13</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-3'>
                                                <div className='ov_l_meter'>
                                                    <div className='text-center'>
                                                        <h2 className='ov_l_meter_head'>Words Learnt</h2>
                                                    </div>
                                                    <div className='position_relative'>
                                                        <img src={require('../../../../assets/images/tub_analy_3.svg').default} alt="alphatub"/>
                                                        <p className='ovl_meter_count gauge_meter_3'>123</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-3'>
                                                <div className='text-center ov_l_meter'>
                                                    <p className='ov_l_txt2'>Parent Report for Emily</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
{/* Engagement Meter */ }
{/* <div className="row no-gutters mt-3">
                                    <div className='col-12'>
                                        <div className='report row no-gutters'>
                                            {analytics.studentsGrpahsList[0].loading ?
                                                    <AppLoader 
                                                        className="w-100"
                                                    />
                                                :
                                                <>
                                                    <div className='col-4'>
                                                        <div className='report_heading'>
                                                            <p>Engagement Meter</p>
                                                        </div>
                                                        <div className='main_graph'>
                                                            <div className='report_type'>
                                                                <p>Top 5 Users Engagement</p>
                                                            </div>
                                                            <div >
                                                                <BarChart
                                                                    width={350}
                                                                    height={275}
                                                                    data={analytics.studentsGrpahsList[0].graphData}
                                                                    margin={{
                                                                        top: 20,
                                                                        right: 30,
                                                                        left: 20,
                                                                        bottom: 5,
                                                                    }}
                                                                    >
                                                                    <CartesianGrid strokeDasharray="1 1" />
                                                                    <XAxis dataKey="fname" />
                                                                    <YAxis />
                                                                    <Bar dataKey="playdataCount" stackId="a" fill="#82ca9d" />
                                                                </BarChart>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-8'>
                                                        <div className='graph_filters_main'>
                                                            <div className="col-4 px-3">
                                                                <FormInput 
                                                                    type="select"
                                                                    label=""
                                                                    name="contents"
                                                                    options={state.contentOptions}
                                                                    placeholder={'All Content'}
                                                                    unselectSelectedValue={() => this.fetchGraphData(0, 'engagementMeter', null, {'contentFilter': ''})}
                                                                    selectType = {1}
                                                                    onChange={(name, value) => this.fetchGraphData(0, 'engagementMeter', null, {'contentFilter': value})}
                                                                    value={analytics.studentsGrpahsList[0].contentFilter}
                                                                    showDownArrow={true}
                                                                    groupclassName='m-0'
                                                                    className='analytics_filter'
                                                                />
                                                            </div>
                                                            <div className="col-4 px-3">
                                                                <FormInput 
                                                                    type="select"
                                                                    label=""
                                                                    name="games"
                                                                    options={state.gamesOptions}
                                                                    placeholder={'All Games'}
                                                                    unselectSelectedValue={() => this.fetchGraphData(0, 'engagementMeter', null, {'gameFilter': ''})}
                                                                    selectType = {1}
                                                                    onChange={(name, value) => this.fetchGraphData(0, 'engagementMeter', null, {'gameFilter': value})}
                                                                    value={analytics.studentsGrpahsList[0].gameFilter}
                                                                    showDownArrow={true}
                                                                    groupclassName='m-0'
                                                                    className='analytics_filter'
                                                                />
                                                            </div>
                                                            <div className='col-1 text-center'>
                                                                <img src={require('../../../../assets/images/info_circle.svg').default} alt="alphatub"/>
                                                            </div>
                                                        </div>
                                                        <div className='analytics_content_list'>
                                                            <div className='report_type'>
                                                                <p>List of Active Words</p>
                                                            </div>
                                                            <div className="col-12">
                                                                <CustomBootstrapTable
                                                                    rows={analytics.studentsGrpahsList[0].graphData}
                                                                    columns={ST_ENGAGEMENT_METER_COLUMNS}
                                                                    isLoading={false}
                                                                    keyField="id"
                                                                    tableClass="custom-row-class"
                                                                    tableMainClass="analytics_table"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div> */}
{/* Learning Progress */ }
{/* <div className="row no-gutters mt-3">
                                    <div className='col-12'>
                                        <div className='report row no-gutters'>
                                            {analytics.studentsGrpahsList[1].loading ?
                                                    <AppLoader 
                                                        className="w-100"
                                                    />
                                                :
                                                <>
                                                    <div className='col-12'>
                                                        <div className='row no-gutters'>
                                                            <div className='col-4'>
                                                                <div className='report_heading'>
                                                                    <p>Learning Progress</p>
                                                                </div>
                                                            </div>
                                                            <div className='col-8'>
                                                                <div className='graph_filters_main'>
                                                                    <div className="col-4 px-3">
                                                                        <FormInput 
                                                                            type="select"
                                                                            label=""
                                                                            name="contents"
                                                                            options={state.contentOptions}
                                                                            placeholder={'All Content'}
                                                                            unselectSelectedValue={() => this.fetchGraphData(1, 'learningProgress', null, {'contentFilter': ''})}
                                                                            selectType = {1}
                                                                            onChange={(name, value) => this.fetchGraphData(1, 'learningProgress', null, {'contentFilter': value})}
                                                                            value={analytics.studentsGrpahsList[1].contentFilter}
                                                                            showDownArrow={true}
                                                                            groupclassName='m-0'
                                                                            className='analytics_filter'
                                                                        />
                                                                    </div>
                                                                    <div className="col-4 px-3">
                                                                        <FormInput 
                                                                            type="select"
                                                                            label=""
                                                                            name="games"
                                                                            options={state.gamesOptions}
                                                                            placeholder={'All Games'}
                                                                            unselectSelectedValue={() => this.fetchGraphData(1, 'learningProgress', null, {'gameFilter': ''})}
                                                                            selectType = {1}
                                                                            onChange={(name, value) => this.fetchGraphData(1, 'learningProgress', null, {'gameFilter': value})}
                                                                            value={analytics.studentsGrpahsList[1].gameFilter}
                                                                            showDownArrow={true}
                                                                            groupclassName='m-0'
                                                                            className='analytics_filter'
                                                                        />
                                                                    </div>
                                                                    <div className='col-1 text-center'>
                                                                        <img src={require('../../../../assets/images/info_circle.svg').default} alt="alphatub"/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-12'>
                                                        <div className='row no-gutters'>
                                                            <div className='col-6'>
                                                                <div className='main_graph'>
                                                                    <div className='report_type'>
                                                                        <p>Learning Curve in Last 7 Days</p>
                                                                    </div>
                                                                    <div >
                                                                        <AreaChart
                                                                            width={550}
                                                                            height={275}
                                                                            data={analytics.studentsGrpahsList[1].graphData}
                                                                            margin={{
                                                                                top: 10,
                                                                                right: 30,
                                                                                left: 0,
                                                                                bottom: 0,
                                                                            }}
                                                                            >
                                                                            <CartesianGrid strokeDasharray="3 3" />
                                                                            <XAxis dataKey="name" />
                                                                            <YAxis />
                                                                            <Tooltip />
                                                                            <Area type="linear" dataKey="uv" stroke="#8884d8" fill="#8884d8" />
                                                                        </AreaChart>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='col-6'>
                                                                <div className='analytics_content_list'>
                                                                    <div className='report_type'>
                                                                        <p>Learning Curve List View</p>
                                                                    </div>
                                                                    <div className="col-12">
                                                                        <CustomBootstrapTable
                                                                            rows={analytics.studentsGrpahsList[1].graphData}
                                                                            columns={ST_LEARGNING_CURVE}
                                                                            isLoading={false}
                                                                            keyField="id"
                                                                            tableClass="custom-row-class"
                                                                            tableMainClass="analytics_table"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div> */}
{/* Most Frequently Answered Word */ }
{/* <div className="row no-gutters mt-3">
                                    <div className='col-12'>
                                        <div className='report row no-gutters'>
                                            {analytics.studentsGrpahsList[2].loading ?
                                                    <AppLoader 
                                                        className="w-100"
                                                    />
                                                :
                                                <>
                                                    <div className='col-4'>
                                                        <div className='report_heading'>
                                                            <p>Most Frequently Answered Word</p>
                                                        </div>
                                                        <div className='main_graph'>
                                                            <div className='report_type'>
                                                                <p>Top 5 Active Words</p>
                                                            </div>
                                                            <div >
                                                                <PieChart width={275} height={275} className="chart">
                                                                    <Pie
                                                                        dataKey="value"
                                                                        nameKey = "name"
                                                                        isAnimationActive={false}
                                                                        data={analytics.studentsGrpahsList[2].graphData}
                                                                        labelLine={false}
                                                                        cx={120}
                                                                        cy={120}
                                                                        outerRadius={80}
                                                                        fill="#8884d8"
                                                                        label
                                                                    >
                                                                        {analytics.studentsGrpahsList[2].graphData.map((entry, index) => (
                                                                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} stroke={COLORS[index % COLORS.length]} />
                                                                        ))}
                                                                    </Pie>
                                                                </PieChart>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-8'>
                                                        <div className='graph_filters_main'>
                                                            <div className="col-4 px-3">
                                                                <FormInput 
                                                                    type="select"
                                                                    label=""
                                                                    name="contents"
                                                                    options={state.contentOptions}
                                                                    placeholder={'All Content'}
                                                                    unselectSelectedValue={() => this.fetchGraphData(2, 'mostFreqAnsWord', null, {'contentFilter': ''})}
                                                                    selectType = {1}
                                                                    onChange={(name, value) => this.fetchGraphData(2, 'mostFreqAnsWord', null, {'contentFilter': value})}
                                                                    value={analytics.studentsGrpahsList[2].contentFilter}
                                                                    showDownArrow={true}
                                                                    groupclassName='m-0'
                                                                    className='analytics_filter'
                                                                />
                                                            </div>
                                                            <div className="col-4 px-3">
                                                                <FormInput 
                                                                    type="select"
                                                                    label=""
                                                                    name="games"
                                                                    options={state.gamesOptions}
                                                                    placeholder={'All Games'}
                                                                    unselectSelectedValue={() => this.fetchGraphData(2, 'mostFreqAnsWord', null, {'gameFilter': ''})}
                                                                    selectType = {1}
                                                                    onChange={(name, value) => this.fetchGraphData(2, 'mostFreqAnsWord', null, {'gameFilter': value})}
                                                                    value={analytics.studentsGrpahsList[2].gameFilter}
                                                                    showDownArrow={true}
                                                                    groupclassName='m-0'
                                                                    className='analytics_filter'
                                                                />
                                                            </div>
                                                            <div className='col-1 text-center'>
                                                                <img src={require('../../../../assets/images/info_circle.svg').default} alt="alphatub"/>
                                                            </div>
                                                        </div>
                                                        <div className='analytics_content_list'>
                                                            <div className='report_type'>
                                                                <p>List of Active Words</p>
                                                            </div>
                                                            <div className="col-12">
                                                                <CustomBootstrapTable
                                                                    rows={analytics.studentsGrpahsList[2].graphData}
                                                                    columns={ST_ACTIVE_LIST}
                                                                    isLoading={false}
                                                                    keyField="id"
                                                                    tableClass="custom-row-class"
                                                                    tableMainClass="analytics_table"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div> */}
{/* Active Tags List */ }
{/* <div className="row no-gutters mt-3">
                                    <div className='col-12'>
                                        <div className='report row no-gutters'>
                                            {analytics.studentsGrpahsList[3].loading ?
                                                    <AppLoader 
                                                        className="w-100"
                                                    />
                                                :
                                                <>
                                                    <div className='col-4'>
                                                        <div className='report_heading'>
                                                            <p>Active Tags List</p>
                                                        </div>
                                                        <div className='main_graph'>
                                                            <div className='report_type'>
                                                                <p>Top 5 Active Tags</p>
                                                            </div>
                                                            <div >
                                                                <PieChart width={275} height={275} className="chart">
                                                                    <Pie
                                                                        dataKey="value"
                                                                        nameKey = "name"
                                                                        isAnimationActive={false}
                                                                        data={analytics.studentsGrpahsList[3].graphData}
                                                                        labelLine={false}
                                                                        cx={120}
                                                                        cy={120}
                                                                        outerRadius={80}
                                                                        fill="#8884d8"
                                                                        label
                                                                    >
                                                                        {analytics.studentsGrpahsList[3].graphData.map((entry, index) => (
                                                                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} stroke={COLORS[index % COLORS.length]} />
                                                                        ))}
                                                                    </Pie>
                                                                </PieChart>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-8'>
                                                        <div className='graph_filters_main'>
                                                            <div className="col-4 px-3">
                                                                <FormInput 
                                                                    type="select"
                                                                    label=""
                                                                    name="contents"
                                                                    options={state.contentOptions}
                                                                    placeholder={'All Content'}
                                                                    unselectSelectedValue={() => this.fetchGraphData(3, 'activeTagsList', null, {'contentFilter': ''})}
                                                                    selectType = {1}
                                                                    onChange={(name, value) => this.fetchGraphData(3, 'activeTagsList', null, {'contentFilter': value})}
                                                                    value={analytics.studentsGrpahsList[3].contentFilter}
                                                                    showDownArrow={true}
                                                                    groupclassName='m-0'
                                                                    className='analytics_filter'
                                                                />
                                                            </div>
                                                            <div className="col-4 px-3">
                                                                <FormInput 
                                                                    type="select"
                                                                    label=""
                                                                    name="games"
                                                                    options={state.gamesOptions}
                                                                    placeholder={'All Games'}
                                                                    unselectSelectedValue={() => this.fetchGraphData(3, 'activeTagsList', null, {'gameFilter': ''})}
                                                                    selectType = {1}
                                                                    onChange={(name, value) => this.fetchGraphData(3, 'activeTagsList', null, {'gameFilter': value})}
                                                                    value={analytics.studentsGrpahsList[3].gameFilter}
                                                                    showDownArrow={true}
                                                                    groupclassName='m-0'
                                                                    className='analytics_filter'
                                                                />
                                                            </div>
                                                            <div className='col-1 text-center'>
                                                                <img src={require('../../../../assets/images/info_circle.svg').default} alt="alphatub"/>
                                                            </div>
                                                        </div>
                                                        <div className='analytics_content_list'>
                                                            <div className='report_type'>
                                                                <p>List of Active Tags</p>
                                                            </div>
                                                            <div className="col-12">
                                                                <CustomBootstrapTable
                                                                    rows={analytics.studentsGrpahsList[3].graphData}
                                                                    columns={ST_ACTIVE_LIST}
                                                                    isLoading={false}
                                                                    keyField="id"
                                                                    tableClass="custom-row-class"
                                                                    tableMainClass="analytics_table"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div> */}
{/* Top Scored Content */ }
{/* <div className="row no-gutters mt-3">
                                    <div className='col-12'>
                                        <div className='report row no-gutters'>
                                            {analytics.studentsGrpahsList[4].loading ?
                                                    <AppLoader 
                                                        className="w-100"
                                                    />
                                                :
                                                <>
                                                    <div className='col-4'>
                                                        <div className='report_heading'>
                                                            <p>Top Scored Content</p>
                                                        </div>
                                                        <div className='main_graph'>
                                                            <div className='report_type'>
                                                                <p>Top 5 Scored Content</p>
                                                            </div>
                                                            <div >
                                                                <BarChart
                                                                    width={350}
                                                                    height={275}
                                                                    data={analytics.studentsGrpahsList[4].graphData}
                                                                    margin={{
                                                                        top: 20,
                                                                        right: 30,
                                                                        left: 20,
                                                                        bottom: 5,
                                                                    }}
                                                                    >
                                                                    <CartesianGrid strokeDasharray="1 1" />
                                                                    <XAxis dataKey="fname" />
                                                                    <YAxis />
                                                                    <Bar dataKey="playdataCount" stackId="a" fill="#82ca9d" />
                                                                </BarChart>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-8'>
                                                        <div className='graph_filters_main'>
                                                            <div className="col-4 px-3">
                                                                <FormInput 
                                                                    type="select"
                                                                    label=""
                                                                    name="contents"
                                                                    options={state.contentOptions}
                                                                    placeholder={'All Content'}
                                                                    unselectSelectedValue={() => this.fetchGraphData(4, 'topScoredContent', null, {'contentFilter': ''})}
                                                                    selectType = {1}
                                                                    onChange={(name, value) => this.fetchGraphData(4, 'topScoredContent', null, {'contentFilter': value})}
                                                                    value={analytics.studentsGrpahsList[4].contentFilter}
                                                                    showDownArrow={true}
                                                                    groupclassName='m-0'
                                                                    className='analytics_filter'
                                                                />
                                                            </div>
                                                            <div className="col-4 px-3">
                                                                <FormInput 
                                                                    type="select"
                                                                    label=""
                                                                    name="games"
                                                                    options={state.gamesOptions}
                                                                    placeholder={'All Games'}
                                                                    unselectSelectedValue={() => this.fetchGraphData(4, 'topScoredContent', null, {'gameFilter': ''})}
                                                                    selectType = {1}
                                                                    onChange={(name, value) => this.fetchGraphData(4, 'topScoredContent', null, {'gameFilter': value})}
                                                                    value={analytics.studentsGrpahsList[4].gameFilter}
                                                                    showDownArrow={true}
                                                                    groupclassName='m-0'
                                                                    className='analytics_filter'
                                                                />
                                                            </div>
                                                            <div className='col-1 text-center'>
                                                                <img src={require('../../../../assets/images/info_circle.svg').default} alt="alphatub"/>
                                                            </div>
                                                        </div>
                                                        <div className='analytics_content_list'>
                                                            <div className='report_type'>
                                                                <p>List of Top Scored Content</p>
                                                            </div>
                                                            <div className="col-12">
                                                                <CustomBootstrapTable
                                                                    rows={analytics.studentsGrpahsList[4].graphData}
                                                                    columns={ST_TOP_SCORE_CONTENT}
                                                                    isLoading={false}
                                                                    keyField="id"
                                                                    tableClass="custom-row-class"
                                                                    tableMainClass="analytics_table"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div> */}
{/* Average Scored Content */ }
{/* <div className="row no-gutters mt-3">
                                    <div className='col-12'>
                                        <div className='report row no-gutters'>
                                            {analytics.studentsGrpahsList[5].loading ?
                                                    <AppLoader 
                                                        className="w-100"
                                                    />
                                                :
                                                <>
                                                    <div className='col-4'>
                                                        <div className='report_heading'>
                                                            <p>Average Scored Content</p>
                                                        </div>
                                                        <div className='main_graph'>
                                                            <div className='report_type'>
                                                                <p>Top 5 Highest Average Score</p>
                                                            </div>
                                                            <div >
                                                                <BarChart
                                                                    width={350}
                                                                    height={275}
                                                                    data={analytics.studentsGrpahsList[5].graphData}
                                                                    margin={{
                                                                        top: 20,
                                                                        right: 30,
                                                                        left: 20,
                                                                        bottom: 5,
                                                                    }}
                                                                    >
                                                                    <CartesianGrid strokeDasharray="1 1" />
                                                                    <XAxis dataKey="fname" />
                                                                    <YAxis />
                                                                    <Bar dataKey="playdataCount" stackId="a" fill="#82ca9d" />
                                                                </BarChart>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-8'>
                                                        <div className='graph_filters_main'>
                                                            <div className="col-4 px-3">
                                                                <FormInput 
                                                                    type="select"
                                                                    label=""
                                                                    name="contents"
                                                                    options={state.contentOptions}
                                                                    placeholder={'All Content'}
                                                                    unselectSelectedValue={() => this.fetchGraphData(5, 'avgScoredContent', null, {'contentFilter': ''})}
                                                                    selectType = {1}
                                                                    onChange={(name, value) => this.fetchGraphData(5, 'avgScoredContent', null, {'contentFilter': value})}
                                                                    value={analytics.studentsGrpahsList[5].contentFilter}
                                                                    showDownArrow={true}
                                                                    groupclassName='m-0'
                                                                    className='analytics_filter'
                                                                />
                                                            </div>
                                                            <div className="col-4 px-3">
                                                                <FormInput 
                                                                    type="select"
                                                                    label=""
                                                                    name="games"
                                                                    options={state.gamesOptions}
                                                                    placeholder={'All Games'}
                                                                    unselectSelectedValue={() => this.fetchGraphData(5, 'avgScoredContent', null, {'gameFilter': ''})}
                                                                    selectType = {1}
                                                                    onChange={(name, value) => this.fetchGraphData(5, 'avgScoredContent', null, {'gameFilter': value})}
                                                                    value={analytics.studentsGrpahsList[5].gameFilter}
                                                                    showDownArrow={true}
                                                                    groupclassName='m-0'
                                                                    className='analytics_filter'
                                                                />
                                                            </div>
                                                            <div className='col-1 text-center'>
                                                                <img src={require('../../../../assets/images/info_circle.svg').default} alt="alphatub"/>
                                                            </div>
                                                        </div>
                                                        <div className='analytics_content_list'>
                                                            <div className='report_type'>
                                                                <p>List of Content With Average Score</p>
                                                            </div>
                                                            <div className="col-12">
                                                                <CustomBootstrapTable
                                                                    rows={analytics.studentsGrpahsList[5].graphData}
                                                                    columns={ST_AVG_SCORE_CONTENT}
                                                                    isLoading={false}
                                                                    keyField="id"
                                                                    tableClass="custom-row-class"
                                                                    tableMainClass="analytics_table"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div> */}
{/* Most Played Content */ }
{/* <div className="row no-gutters mt-3">
                                    <div className='col-12'>
                                        <div className='report row no-gutters'>
                                            {analytics.studentsGrpahsList[6].loading ?
                                                    <AppLoader 
                                                        className="w-100"
                                                    />
                                                :
                                                <>
                                                    <div className='col-4'>
                                                        <div className='report_heading'>
                                                            <p>Most Played Content</p>
                                                        </div>
                                                        <div className='main_graph'>
                                                            <div className='report_type'>
                                                                <p>Top 5 Most Played Content</p>
                                                            </div>
                                                            <div >
                                                                <BarChart
                                                                    width={350}
                                                                    height={275}
                                                                    data={analytics.studentsGrpahsList[0].graphData}
                                                                    margin={{
                                                                        top: 20,
                                                                        right: 30,
                                                                        left: 20,
                                                                        bottom: 5,
                                                                    }}
                                                                    >
                                                                    <CartesianGrid strokeDasharray="1 1" />
                                                                    <XAxis dataKey="fname" />
                                                                    <YAxis />
                                                                    <Bar dataKey="playdataCount" stackId="a" fill="#82ca9d" />
                                                                </BarChart>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-8'>
                                                        <div className='graph_filters_main'>
                                                            <div className="col-4 px-3">
                                                                <FormInput 
                                                                    type="select"
                                                                    label=""
                                                                    name="contents"
                                                                    options={state.contentOptions}
                                                                    placeholder={'All Content'}
                                                                    unselectSelectedValue={() => this.fetchGraphData(6, 'mostPlayedContent', null, {'contentFilter': ''})}
                                                                    selectType = {1}
                                                                    onChange={(name, value) => this.fetchGraphData(6, 'mostPlayedContent', null, {'contentFilter': value})}
                                                                    value={analytics.studentsGrpahsList[6].contentFilter}
                                                                    showDownArrow={true}
                                                                    groupclassName='m-0'
                                                                    className='analytics_filter'
                                                                />
                                                            </div>
                                                            <div className="col-4 px-3">
                                                                <FormInput 
                                                                    type="select"
                                                                    label=""
                                                                    name="games"
                                                                    options={state.gamesOptions}
                                                                    placeholder={'All Games'}
                                                                    unselectSelectedValue={() => this.fetchGraphData(6, 'mostPlayedContent', null, {'gameFilter': ''})}
                                                                    selectType = {1}
                                                                    onChange={(name, value) => this.fetchGraphData(6, 'mostPlayedContent', null, {'gameFilter': value})}
                                                                    value={analytics.studentsGrpahsList[6].gameFilter}
                                                                    showDownArrow={true}
                                                                    groupclassName='m-0'
                                                                    className='analytics_filter'
                                                                />
                                                            </div>
                                                            <div className='col-1 text-center'>
                                                                <img src={require('../../../../assets/images/info_circle.svg').default} alt="alphatub"/>
                                                            </div>
                                                        </div>
                                                        <div className='analytics_content_list'>
                                                            <div className='report_type'>
                                                                <p>List of Most Played Content</p>
                                                            </div>
                                                            <div className="col-12">
                                                                <CustomBootstrapTable
                                                                    rows={analytics.studentsGrpahsList[6].graphData}
                                                                    columns={ST_MOST_PLAYED_CONTENT}
                                                                    isLoading={false}
                                                                    keyField="id"
                                                                    tableClass="custom-row-class"
                                                                    tableMainClass="analytics_table"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div> */}
{/* Recently Played Content */ }
{/* <div className="row no-gutters mt-3">
                                    <div className='col-12'>
                                        <div className='report row no-gutters'>
                                            {analytics.studentsGrpahsList[7].loading ?
                                                    <AppLoader 
                                                        className="w-100"
                                                    />
                                                :
                                                <>
                                                    <div className='col-12'>
                                                        <div className='row no-gutters'>
                                                            <div className='col-4'>
                                                                <div className='report_heading'>
                                                                    <p>Recently Played Content</p>
                                                                </div>
                                                            </div>
                                                            <div className='col-8'>
                                                                <div className='graph_filters_main'>
                                                                    <div className="col-4 px-3">
                                                                        <FormInput 
                                                                            type="select"
                                                                            label=""
                                                                            name="contents"
                                                                            options={state.contentOptions}
                                                                            placeholder={'All Content'}
                                                                            unselectSelectedValue={() => this.fetchGraphData(7, 'recentPlayedContent', null, {'contentFilter': ''})}
                                                                            selectType = {1}
                                                                            onChange={(name, value) => this.fetchGraphData(7, 'recentPlayedContent', null, {'contentFilter': value})}
                                                                            value={analytics.studentsGrpahsList[7].contentFilter}
                                                                            showDownArrow={true}
                                                                            groupclassName='m-0'
                                                                            className='analytics_filter'
                                                                        />
                                                                    </div>
                                                                    <div className="col-4 px-3">
                                                                        <FormInput 
                                                                            type="select"
                                                                            label=""
                                                                            name="games"
                                                                            options={state.gamesOptions}
                                                                            placeholder={'All Games'}
                                                                            unselectSelectedValue={() => this.fetchGraphData(7, 'recentPlayedContent', null, {'gameFilter': ''})}
                                                                            selectType = {1}
                                                                            onChange={(name, value) => this.fetchGraphData(7, 'recentPlayedContent', null, {'gameFilter': value})}
                                                                            value={analytics.studentsGrpahsList[7].gameFilter}
                                                                            showDownArrow={true}
                                                                            groupclassName='m-0'
                                                                            className='analytics_filter'
                                                                        />
                                                                    </div>
                                                                    <div className='col-1 text-center'>
                                                                        <img src={require('../../../../assets/images/info_circle.svg').default} alt="alphatub"/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-12'>
                                                        <div className='analytics_content_list'>
                                                            <div className='report_type'>
                                                                <p>List of Recently Played Content</p>
                                                            </div>
                                                            <div className="col-12">
                                                                <CustomBootstrapTable
                                                                    rows={analytics.studentsGrpahsList[7].graphData}
                                                                    columns={ST_RECENTLY_PLAYED_CONTENT}
                                                                    isLoading={false}
                                                                    keyField="id"
                                                                    tableClass="custom-row-class"
                                                                    tableMainClass="analytics_table"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div> */}
{/* Time spent in games */ }
{/* <div className="row no-gutters mt-3">
                                    <div className='col-12'>
                                        <div className='report row no-gutters'>
                                            {analytics.studentsGrpahsList[8].loading ?
                                                    <AppLoader 
                                                        className="w-100"
                                                    />
                                                :
                                                <>
                                                <div className='col-12'>
                                                        <div className='row no-gutters'>
                                                            <div className='col-4'>
                                                                <div className='report_heading'>
                                                                    <p>Time Spent In Games</p>
                                                                </div>
                                                            </div>
                                                            <div className='col-8'>
                                                                <div className='graph_filters_main'>
                                                                    <div className="col-4 px-3">
                                                                        <FormInput 
                                                                            type="select"
                                                                            label=""
                                                                            name="contents"
                                                                            options={state.contentOptions}
                                                                            placeholder={'All Content'}
                                                                            unselectSelectedValue={() => this.fetchGraphData(8, 'timeSpentGames', null, {'contentFilter': ''})}
                                                                            selectType = {1}
                                                                            onChange={(name, value) => this.fetchGraphData(8, 'timeSpentGames', null, {'contentFilter': value})}
                                                                            value={analytics.studentsGrpahsList[8].contentFilter}
                                                                            showDownArrow={true}
                                                                            groupclassName='m-0'
                                                                            className='analytics_filter'
                                                                        />
                                                                    </div>
                                                                    <div className="col-4 px-3">
                                                                        <FormInput 
                                                                            type="select"
                                                                            label=""
                                                                            name="games"
                                                                            options={state.gamesOptions}
                                                                            placeholder={'All Games'}
                                                                            unselectSelectedValue={() => this.fetchGraphData(8, 'timeSpentGames', null, {'gameFilter': ''})}
                                                                            selectType = {1}
                                                                            onChange={(name, value) => this.fetchGraphData(8, 'timeSpentGames', null, {'gameFilter': value})}
                                                                            value={analytics.studentsGrpahsList[8].gameFilter}
                                                                            showDownArrow={true}
                                                                            groupclassName='m-0'
                                                                            className='analytics_filter'
                                                                        />
                                                                    </div>
                                                                    <div className='col-1 text-center'>
                                                                        <img src={require('../../../../assets/images/info_circle.svg').default} alt="alphatub" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-12'>
                                                        <div className='row no-gutters'>
                                                            <div className='col-6'>
                                                                <div className='main_graph'>
                                                                    <div className='report_type'>
                                                                        <p>Overall Time Spent In Games</p>
                                                                    </div>
                                                                    <div >
                                                                        <ScatterChart
                                                                            width={500}
                                                                            height={275}
                                                                            margin={{
                                                                                top: 20,
                                                                                right: 20,
                                                                                bottom: 20,
                                                                                left: 20,
                                                                            }}
                                                                            >
                                                                            <CartesianGrid />
                                                                            <XAxis type="number" dataKey="x" name="stature" unit="cm" />
                                                                            <YAxis type="number" dataKey="y" name="weight" unit="kg" />
                                                                            <ZAxis type="number" range={[100]} />
                                                                            <Tooltip cursor={{ strokeDasharray: '3 3' }} />
                                                                            <Legend />
                                                                            <Scatter name="A school" data={data01} fill="#8884d8" line shape="cross" />
                                                                            <Scatter name="B school" data={data02} fill="#82ca9d" line shape="diamond" />
                                                                        </ScatterChart>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='col-6'>
                                                                <div className='analytics_content_list'>
                                                                    <div className='report_type'>
                                                                        <p>Learning Curve List View</p>
                                                                    </div>
                                                                    <div className="col-12">
                                                                        <CustomBootstrapTable
                                                                            rows={analytics.studentsGrpahsList[8].graphData}
                                                                            columns={ST_TIME_SPENT_ON_GAMES}
                                                                            isLoading={false}
                                                                            keyField="id"
                                                                            tableClass="custom-row-class"
                                                                            tableMainClass="analytics_table"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div> */}