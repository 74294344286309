import { React, Component } from 'react';
import { connect } from 'react-redux'
import ButtonType1 from '../../../../components/inputs/ButtonType1';
import * as tubgallery from '../../../../actions/tubgallery';
import * as profileAction from '../../../../actions/myProfile';
import * as sessionAction from '../../../../actions/session';
import { bindActionCreators } from "redux";
import moment from 'moment';
import { FILTER_OPTIONS, GALLERY_SORT_OPTIONS } from '../../../../helpers/variables';
import { differenceInCalendarDays } from 'date-fns';
import SearchSuggestion from '../../../../components/dropdowns/searchSuggestion';
import { CreateSessionDatePopup } from '../../../../components/tubClass/createSessionDatePopup';
import { ContentOriginalImage } from '../../../../components/tubClass/contentOriginalImage';
import { ContentStudentDetail } from '../../../../components/tubClass/contentStudentDetail';
import { ContentDetail } from '../../../../components/tubClass/contentDetail';
import { ContentSelectionImagesSlider } from '../../../../components/tubClass/contentSelectionImagesSlider';
import ScrollToTopButton from '../../../../components/ScrollToTopButton';
import { DateRangeFilterPopup } from '../../../../components/tubClass/dateRangeFilterPopup';
import { CustomSelectFilter } from '../../../../components/filters/customSelectFilter';
import { AlphabetsFilter } from '../../../../components/tubClass/alphabetsFilter';
import _ from 'lodash';
import { HideContent, HideOrUnhideContent, UnhideContent } from '../../../../components/tubClass/hideOrUnhideContent';
import { InboxPendingCard } from '../../../../components/tubClass/inboxPendingCard';
import { API } from 'aws-amplify';
import { ENVIRONMENT } from '../../../../common/constants';
import { accessHeaders, ALPHABETS } from '../../../../helpers';
import toast from '../../../../helpers/Toast';
import { ShowOnlyAssignment } from '../../../../components/tubClass/showOnlyAssignment';
import Wrapper from '../../../../Hoc/wrapper';
import { AppLoader } from '../../../../components/loader/appLoader';

var days = 15
class GalleryClass extends Component {
    defaultQueryPayload =  {
        search: '',
        startDate: '',
        endDate: '',
        sort: '',
        tubObjectType: '',
        classId: '',
        schoolId: '',
        studentId: ''
    }
    constructor(props) {
        super(props);
        this.state = {
            sortOptions: GALLERY_SORT_OPTIONS,
            filterOptions: FILTER_OPTIONS,
            typeOfSheetOption: [
                {
                    label: "TUB Sheet",
                    value: "Sheet",
                    type: 3
                },
                {
                    label: "TUB Cards",
                    value: "Card",
                    type: 3
                },
                {
                    label: "Assignments",
                    value: "Assignment",
                    type: 2,
                },
                {
                    label: "Images",
                    value: "Images",
                    type: 1
                },
                {
                    label: "Hidden Images",
                    value: "HiddenImages",
                    type: 2
                }
            ],
            queryPayload: {
                search: '',
                type: null,
                startDate: null,
                endDate: null,
                sort: null,
                tubObjectType: null,
                classId: null,
                schoolId: null,
                studentId: null,
                filterByDate: null
            },
            showDatePopup: false,
            maxDate: new Date(Date.now() + days*24*60*60*1000),
            minDate: new Date(),
            contentSelectedDate: new Date(),
            selectedObject: null,
            workingDaysOptions :  [
                {
                    label: "S",
                    value: "Sun",
                    checked: false,
                    index: 0
                },
                {
                    label: "M",
                    value: "Mon",
                    checked: false,
                    index: 1
                },
                {
                    label: "T",
                    value: "Tue",
                    checked: false,
                    index: 2
                },
                {
                    label: "W",
                    value: "Wed",
                    checked: false,
                    index: 3
                },
                {
                    label: "T",
                    value: "Thu",
                    checked: false,
                    index: 4
                },
                {
                    label: "F",
                    value: "Fri",
                    checked: false,
                    index: 5
                },
                {
                    label: "S",
                    value: "Sat",
                    checked: false,
                    index: 6
                },
            
            ],
            showSuggessionPopup: false,
            showImagesSlider: false,
            objectImagesList: [],
            loading: false,
            tab: 1,
            selectedTubObject: null,
            selectedLetterIndexOnEdit: null
        };
    }
    toggleImagesPopup = (data) => {
        this.setState({
            showImagesSlider: true,
            objectImagesList: data.alphatub_content_result.data
        })
    }
    getTubGalleryList = async () => {
        let {queryPayload} = this.state
        let { dataToPersist } = this.props
        let selectedClass = sessionStorage.getItem('selectedClass')
        queryPayload.schoolId = sessionStorage.getItem('schoolId')
        queryPayload.classId =  selectedClass != null ? selectedClass : dataToPersist?.selectedClass?.value
        await this.props.galleryAction.getGalleryList(queryPayload)
        
    }
    getGalleryTypeTwoList = async () => {
        let {queryPayload} = this.state
        let { dataToPersist } = this.props
        let selectedClass = sessionStorage.getItem('selectedClass')
        queryPayload.schoolId = sessionStorage.getItem('schoolId')
        queryPayload.classId =  selectedClass != null ? selectedClass : dataToPersist?.selectedClass?.value
        await this.props.galleryAction.getGalleryTypeTwoObjectList(queryPayload)
        
    }
    onChange = (name, value, type = null) => {
        let { queryPayload, filterOptions } = this.state
        if(name === "filterByDate" && !_.isEmpty(value)){
            let getdates = filterOptions.find(op => op.value === value)
            if(getdates && getdates.id === 7){
                queryPayload["filterByDate"] = value
                this.setState({
                    showFilterDatePopup: true,
                    queryPayload
                })
            } else {
                if(getdates){
                    queryPayload["filterByDate"] = value
                    queryPayload["startDate"] = getdates.startDate
                    queryPayload["endDate"] = getdates.endDate
                }
            }
        } else if (name === "filterByDate" && value == undefined) {
            queryPayload["startDate"] = null
            queryPayload["endDate"] = null
            queryPayload["filterByDate"] = ''
        } else {
            queryPayload[name] = value
        }

        if(name == "tubObjectType" && value == undefined){
            queryPayload["type"] = ''
        } else if(name == "tubObjectType" &&  value != null){
            queryPayload["type"] =  type
        } else {
            queryPayload["type"] = queryPayload['type']
        }

        if(name === 'sort' && ( value == '7' || value == '8') && queryPayload["tubObjectType"] == 'Images'){
            queryPayload["search"] = 'A'
            this.setState({
                selectedLetterIndexOnEdit: 0
            })
        } 
        queryPayload['onlyAssignment'] = false
        this.setState({
            queryPayload
        }, this.checkForApiTrigger)

    }
    checkForApiTrigger = () => {
        let { queryPayload } = this.state
        if((queryPayload['tubObjectType'] == 'HiddenImages' || queryPayload['tubObjectType'] == 'Images') || (queryPayload['tubObjectType'] == 'Assignment' && queryPayload['type'] == 1)){
            this.getGalleryTypeTwoList()
        } else {
            this.getTubGalleryList()
        }
    }
    addToCreateSession = async (contentSelectedDate) => {
        let { selectedObject } = this.state
        let time = contentSelectedDate.toLocaleTimeString('it-IT')
        try {
            let payload = {
                Class_Id: selectedObject.ClassID,
                School_Id: selectedObject.SchoolID,
                Session_Type: selectedObject.TubObjectType,
                TubObjectId: selectedObject.TubObjectId,
                studentId: selectedObject.StudentId,
                Session_Date: moment(contentSelectedDate).format(`YYYY-MM-DD[T${time}.000Z]`)
            }
            let response = await this.props.galleryAction.createSession(payload)
            if(response != undefined){
                await this.props.sessionAction.checkLauchTubEnabled()
                this.setState({
                    showDatePopup: false,
                })
            }
           
        } catch(error) {
            console.log('error', error)
        }
    }
    triggerPopup = (val, data) => {
        if(val === "showFilterDatePopup"){
            this.unselectSelectedValue('filterByDate')
        }
        this.setState({
            [val]: !this.state[val],
            selectedObject: data
        })
    }
    onChangeDate = (value) => {
        this.setState({
          contentSelectedDate: value
        })
    }
    onChangeFilterDate = (ranges) => {
        let { queryPayload } = this.state
        queryPayload = {
            ...queryPayload,
            ...ranges
        }
        // queryPayload["filterByDate"] = `Custom Dates ${ranges}` 
        this.setState({
          queryPayload,
          showFilterDatePopup: false
        }, this.getTubGalleryList)
    }
    isSameDay = (disDate, date) => {
        return differenceInCalendarDays(disDate, date) === 0;
    }
    tileDisabled = ({ date, view }) => {
        let {  session } = this.props
        let { workingDaysOptions } = this.state
        let getDisableDates = [],filteredDays
        if (session.sessionDisableDatesList?.length > 0) {
            getDisableDates = session.sessionDisableDatesList.find(dDate => this.isSameDay(dDate, date))
        }
        if(session.schoolAcademicYear != null){
            let filteredIndexes = workingDaysOptions.filter(fl => !session.schoolAcademicYear.days.includes(fl.value)).map(f => f.index)
            if (filteredIndexes?.length > 0) {
                filteredDays = filteredIndexes.includes(date.getDay());
            } 
        }
        if(session.sessionDisableDatesList?.length > 0){
            return filteredDays || getDisableDates
        } else {
            return filteredDays
        }
    }
    onSuggestionSelect = (value) => {
        let { queryPayload } = this.state
        queryPayload['search'] = value
        this.setState({ queryPayload}, this.getTubGalleryList)
    }
    unselectSelectedValue = (type) => {
        let { queryPayload } = this.state
        if(type == "filterByDate"){
            queryPayload["startDate"] = ''
            queryPayload['endDate'] = ''
            queryPayload['filterByDate'] = ''
        } else {
            queryPayload[type] = ''
        }
        this.setState({ queryPayload}, this.getTubGalleryList)
    }
    onApplyTypeTwoObjectFilter = (name, value, type) => {
        let { queryPayload } = this.state
        queryPayload[name] = value
        queryPayload['type'] = type
        this.setState({ queryPayload }, this.getGalleryTypeTwoList)
    }
    onLetterSelection = (letter) => {
        let { queryPayload } = this.state
        queryPayload['search'] = letter
        let getIndex = ALPHABETS.findIndex(fl => fl.alphabet == letter)
        this.setState({ queryPayload , selectedLetterIndexOnEdit: getIndex}, this.getGalleryTypeTwoList)
    }
    unHideImage = async (data) => {
        let query = {
            schoolID : data.SchoolID,
            classID : data.ClassID,
            contentId: data.TubObjectId,
            imageId: data.originalImageId,
            isHidden: false
        }
        await this.props.sessionAction.onHideContent(query)
        this.getGalleryTypeTwoList()

    }
    onNavigate = (tab, data) => {
        this.setState({
            tab: tab,
            selectedTubObject: data
        })
    }

    deleteTubObject = async (data) => {
        let payload = {}
        const username = localStorage.getItem('userid');
        payload.TubObjectStatus = "Deleted"
        payload.TubObjectSubmittedBy = username
        let url = `tubinbox/deleteTubInboxData/${data._id}`;
        let myInit = {
            headers: accessHeaders() ,
            body: payload
        }
        const response = await API.post(ENVIRONMENT, url, myInit);
        if(response.status === 200){
            toast(response.message, 'success')
            this.setState({
                tab: 1
            })
            this.getTubGalleryList()
        }
    }

    hideImages = async (data) => {
        let query = {
            schoolID : data.SchoolID,
            classID : data.ClassID,
            contentId: data.TubObjectId,
            imageId: data.originalImageId,
            isHidden: true
        }
        await this.props.sessionAction.onHideContent(query)
        this.getGalleryTypeTwoList()
    }
    
    showOnlyAssignment = (payload) => {
        let {queryPayload} = this.state
        queryPayload['onlyAssignment'] = payload
        queryPayload['type'] = queryPayload.type == 1 ? 1 : 2
        this.setState({queryPayload}, this.checkForApiTrigger)

    }
    render() {
        let { tubGallery, session, loader } = this.props
        let { tab, objectImagesList,selectedTubObject,typeOfSheetOption,selectedLetterIndexOnEdit,filterOptions,showFilterDatePopup, sortOptions, queryPayload, showDatePopup,showImagesSlider } = this.state
        return (
            <>
                {tab == 1 ?
                    <div className="col-12 p-0">
                        {(queryPayload["tubObjectType"] == 'Images' || queryPayload["tubObjectType"] == 'HiddenImages' ) &&
                            <div className='mb-2'>
                                <ShowOnlyAssignment 
                                    showOnlyAssignment = {this.showOnlyAssignment}
                                />
                            </div>
                        }
                        <div className="bg-white py-2-half row no-gutters align-items-center">
                            <div className="col-3 px-3">
                                <CustomSelectFilter 
                                    name = "tubObjectType"
                                    options = {typeOfSheetOption}
                                    placeholder = {'Select TUB Types'}
                                    isClearable = {false}
                                    onApplyFilter = {(name, value, type) => this.onChange(name, value, type)}
                                    onApplyTypeTwoFilter = {(name, value, type) => this.onApplyTypeTwoObjectFilter(name, value, type)}
                                />
                            </div>
                            <div className="col-2 px-3">
                                <CustomSelectFilter 
                                    name="sort"
                                    options={sortOptions}
                                    placeholder={'Sort'}
                                    isClearable = {false}
                                    onApplyFilter={(name, value) => this.onChange(name, value)}
                                />
                            </div>
                            <div className="col-2 px-3">
                                <CustomSelectFilter 
                                    name="filterByDate"
                                    options={filterOptions}
                                    placeholder={'Select Filter'}
                                    isClearable = {false}
                                    onApplyFilter={(...params) => this.onChange(...params)}
                                />
                            </div>
                            {queryPayload.tubObjectType == "Assignment" &&
                                <div className={`col-2 px-3`}>
                                    <CustomSelectFilter 
                                        name="assignmentId"
                                        options={this.props.curriculum.assignmentDropdownList}
                                        placeholder={'Assignment #'}
                                        isClearable = {false}
                                        onApplyFilter={(...params) => this.onChange(...params)}
                                    />
                                </div>
                            }
                            <div className="col-3 ml-auto px-3 relative_position">
                                <SearchSuggestion 
                                    onSuggestionSelect={this.onSuggestionSelect}
                                    getTubGalleryList={this.getTubGalleryList}
                                />
                            </div> 
                        </div> 
                        {queryPayload["type"] == 1 && (queryPayload["sort"] == 7 || queryPayload["sort"] == 8) &&
                            <>
                                <AlphabetsFilter 
                                    onChange={(name, alphabet) => this.onLetterSelection(alphabet)}
                                    selectedLetterIndexOnEdit={selectedLetterIndexOnEdit}
                                />
                            </>
                        }
                        {loader.isTubGalleryFetching ? 
                            <AppLoader />
                            :
                            <>
                            { tubGallery.tubGalleryList && tubGallery.tubGalleryList?.length > 0 
                                ?
                                <div className="row flex-wrap mt-1">
                                    {(queryPayload.tubObjectType == 'Images' || queryPayload.tubObjectType == "HiddenImages" || ( queryPayload.tubObjectType == "Assignment" && queryPayload.type == 1)) ? 
                                        <>
                                            {
                                                tubGallery.tubGalleryList && tubGallery.tubGalleryList.map((data, i) => (
                                                    <div className="col-lg-6 col-xl-4 col-md-6 col-sm-12 col-12 mt-3" key={i}>
                                                        <div className="tub-bg-type-1 p-2">
                                                                <div className="row no-gutters justify-content-between align-items-center px-2 tub-inner-heading pt-10">
                                                                    {
                                                                        queryPayload?.tubObjectType == 'HiddenImages' ?
                                                                            <div className="col-1 ml-auto mb-2 hide_container">
                                                                                <UnhideContent 
                                                                                    unHideImages = {() => this.unHideImage(data)}
                                                                                />
                                                                            </div>
                                                                            :
                                                                        ( queryPayload?.tubObjectType == 'Images' || queryPayload?.tubObjectType == 'Assignment')?
                                                                            <div className="col-1 ml-auto mb-2 hide_container">
                                                                                <HideContent
                                                                                    hideImages = {() => this.hideImages(data)}
                                                                                />
                                                                            </div>
                                                                        :
                                                                        <div className="col-3 ml-auto mb-2 h-24-lh-12">
                                                                            {queryPayload.type != 1 &&
                                                                                <ButtonType1
                                                                                    btnName="Add"
                                                                                    className='btn-bg1 buttonType1'
                                                                                    onClick={() => this.triggerPopup("showDatePopup", data)}
                                                                                />
                                                                            }
                                                                        </div>
                                                                    }
                                                                </div>
                                                            <div className="relative-pos">
                                                                <div className={`tub-session-card ${(data.TubObjectType === 'Sheet') ? 'addFilter p-1' : ''}`}>
                                                                    <div className="row flex-wrap no-gutters">
                                                                        <div className="col-12">
                                                                            <ContentOriginalImage 
                                                                                src={data.alphabetImage?.original}
                                                                                className={`w-100`}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row align-items-center no-gutters inbox-content-spnImg">
                                                                    <ContentStudentDetail
                                                                        src={
                                                                            data.profilePic != undefined && data.profilePic.original != undefined &&
                                                                            data.profilePic.original !== '' ? data.profilePic.original : require("../../../../assets/images/noImage.png")
                                                                        }
                                                                        className="whbr-50"
                                                                        studentName={data?.firstName}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="row no-gutters mt-2 justify-content-between">
                                                                <ContentDetail 
                                                                    ObjectName={data?.imageTitle}
                                                                    createdAt={moment(data.createdAt).format('DD MMM YYYY')}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </>
                                        :
                                        <>
                                            { tubGallery.tubGalleryList.map((data, i) => (
                                                <div className="col-lg-6 col-xl-4 col-md-6 col-sm-12 col-12 mt-3" key={i}>
                                                    <div className="tub-bg-type-1 p-2">
                                                        <div className="row no-gutters justify-content-between align-items-center px-2 tub-inner-heading pt-10">
                                                            <div className="col-3 ml-auto mb-2">
                                                                <ButtonType1
                                                                    btnName="Add"
                                                                    className='btn-bg1 h-24-lh-12 buttonType1'
                                                                    onClick={() => this.triggerPopup('showDatePopup', data)}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="relative-pos">
                                                            <div className={`tub-session-card ${(data.TubObjectType === 'Sheet') && 'addFilter p-1'}`}>
                                                                <div className="row flex-wrap no-gutters">
                                                                    <div className="col-12" onClick={() => this.onNavigate(2, data)}>
                                                                        <ContentOriginalImage 
                                                                            src={data.alphatub_content_result?.ObjectImage?.original}
                                                                            className={`${(data.TubObjectType === 'Card' || data.TubObjectType === 'Assignment' ) && 'card_img'}`}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row align-items-center no-gutters inbox-content-spnImg">
                                                                <ContentStudentDetail
                                                                    src={
                                                                        data.StudentId != undefined && data.StudentId.profilePic.original != undefined &&
                                                                        data.StudentId.profilePic.original !== '' ? data.StudentId.profilePic.original : require("../../../../assets/images/noImage.png")
                                                                    }
                                                                    className="whbr-50"
                                                                    studentName={data?.StudentId?.firstName}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="row no-gutters mt-2 justify-content-between">
                                                            <ContentDetail 
                                                                ObjectName={data?.alphatub_content_result?.ObjectName}
                                                                createdAt={moment(data.createdAt).format('DD MMM YYYY')}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                )
                                            )}
                                        </>
                                    }
                                </div>

                                :

                                <div className="row no-gutters align-items-center center-content w-100 h-400px">
                                    <p className='tub-content-4 text-center'>No Data Found</p>
                                </div>
                            }

                            </>
                        }
                        <ScrollToTopButton />
                    </div>
                    :
                tab == 2 ?
                    <div className="col-12 p-0">
                        <div className="bg-white py-2-half row no-gutters align-items-center">
                            <img src={require('../../../../assets/images/left-arrow.svg').default} className="back-btn ml-3" onClick={() => this.onNavigate(1)} alt="alphatub"/>
                            <p className="global_color privacy-content ml-3 cursor-pointer" onClick={() => this.onNavigate(1)}>Back To TUB Gallery</p>
                        </div>
                        <div>
                            <InboxPendingCard 
                                tubInboxList = {selectedTubObject}
                                triggerPopup = {this.triggerPopup}
                                updateTubObject = {this.updateTubObject}
                                type={2}
                                addToCreateSession={this.addToCreateSession}
                                deleteTubObject={this.deleteTubObject}
                                {...this.state}
                                {...this.props}
                            />
                        </div>
                    </div>
                    :
                    <></>
                }
                <ContentSelectionImagesSlider 
                    showImagesSlider={showImagesSlider}
                    objectImagesList={objectImagesList}
                    triggerPopup={this.triggerPopup}
                />
                {/* create session date popup */}
                <CreateSessionDatePopup 
                    showDatePopup={showDatePopup}
                    triggerPopup={this.triggerPopup}
                    addToCreateSession={this.addToCreateSession}
                    session={session}
                />
                {/* Filter date popup*/}
                <DateRangeFilterPopup 
                    showFilterDatePopup={showFilterDatePopup}
                    triggerPopup={this.triggerPopup}
                    onChangeFilterDate={this.onChangeFilterDate}
                />
            </>
        )
    }
}

const mapStateToProps = ({ tubGallery, myProfile, loader, session,dataToPersist,curriculum }) => {
    return {
        tubGallery,
        myProfile,
        loader,
        session,
        dataToPersist,
        curriculum
    };
};

let mapDispatchToProps = (dispatch) => {
    return {
        galleryAction: bindActionCreators(tubgallery, dispatch),
        profileAction: bindActionCreators(profileAction, dispatch),
        sessionAction: bindActionCreators(sessionAction, dispatch)
    }
}

const Tubgallery = Wrapper(connect(mapStateToProps, mapDispatchToProps)(GalleryClass))

export { Tubgallery }