import { Modal, ModalBody } from 'reactstrap';
import {Carousel} from "react-responsive-carousel";
import RightArrow from '../../assets/images/rightarrow.svg'
import LeftArrow from '../../assets/images/leftarrow.svg'
import ButtonType1 from '../inputs/ButtonType1';
import { RejectedPendingObject } from './rejectedPendingObject';
import { useState } from 'react';

export const ContentSelectionImagesSlider = ({showImagesSlider, objectImagesList, triggerPopup}) => {
    const [currentImageToShow, setCurrentImageToShow] = useState(0)

    const  clickNext = () => {
        if((objectImagesList?.length - 1) === currentImageToShow){
            setCurrentImageToShow(0)
        } else {
            setCurrentImageToShow(currentImageToShow + 1)
        }
        
    }
    
    const clickPrev = () => {
        if(currentImageToShow === 0){
            setCurrentImageToShow(objectImagesList?.length - 1)
        } else {
            setCurrentImageToShow(currentImageToShow - 1)

        }
    }

    return (
        <Modal
            isOpen={showImagesSlider}
            toggle={() => triggerPopup('showImagesSlider', null)}
            className="pop-up max-800 inbox_image_popup"
        >
            <ModalBody>
                <div className='inbox_Left' onClick={() => clickPrev()}>
                    <img 
                        src={LeftArrow}
                    />
                </div>
                <Carousel
                    showStatus={false}
                    showIndicators={false}
                    showThumbs={false}
                    emulateTouch={true}
                    showArrows={false}
                    selectedItem={currentImageToShow}
                    className="reject_popup_slider"
                >
                {objectImagesList && objectImagesList.map(item => (
                    <div>
                        <div className='position_relative'>
                            <img
                                src={item.alphabetImage.original}
                                className="br-10"
                                alt="alphatub"
                            />
                        </div>
                        <div className="row no-gutters align-items-center mt-1">
                            <div className="col-8">
                                <p className="tubInbox_pp_nm">{item.imageTitle}</p>
                                <p className="tubInbox_pp_tg">#{item.tags[0]}</p>
                            </div>
                        </div>
                    </div>
                ))}
                </Carousel>
                <div className='inbox_Right' onClick={() => clickNext()}>
                    <img 
                        src={RightArrow}
                    />
                </div>
            </ModalBody>
        </Modal>
    )
}