import React from 'react'
import "./TubPage.css"

const TubPage = () => {
  return (
    <div className="container-fluid">
      <div className="coming-soon w-50">
        <p className="coming-soon-title mb-4">TUB Page is Coming Soon</p>
        <h3>
          TUB Page is a community page where teachers can share their TUB Sheet
          and TUB Cards with other teachers..... <br/><br/><br/> Remember, it takes a village to
          raise a child...
        </h3>
      </div>
    </div>
  )
}

export {TubPage}
