import { React, useEffect, useState } from 'react';
import { CustomBootstrapTable } from '../../../../components/Table/CustomBootstrapTable';
import { ManageClassProgramColumns } from '../../../../helpers/tableDataMapper'

const ClassesList = (props) => {
    const [data] = useState(props.data)

   
    const redirect = async (data, tab) => {
        props.redirect(data,tab)
    }
    
    const getTableColumns = () => {
        let action = {
            text: "Action",
            dataField: "action",
            headerClasses: "columnHeader text-center",
            formatter: (cellContent, row, rowIndex) => {
                return (
                    <div className="class_tooltip position_relative cursor-pointer text-center">
                        <img
                            src={require('../../../../assets/images/edit.png')}
                            onClick={() => redirect(row, 2)}
                            alt="alphatub"
                        />
                    </div>
                )
            }
        }
        return [...ManageClassProgramColumns,action]

    }

    return (
        <>
            <div className="row no-gutters mt-3">
                <div className="col-9">
                    <h3 className="login-text-login text-left">Manage Class Programs</h3>
                </div>
            </div>
            <div className="row no-gutters mt-3 bg-white">
                <div className="col-12">
                    <CustomBootstrapTable 
                        rows = {data}
                        columns = {getTableColumns()}
                        isLoading={props.loader.isTableDetailsFetching}
                        keyField = "_id"
                        tableClass="manageseats_table"
                        tableMainClass="manageSeatsTable"
                    />
                </div>
            </div>
        </>
    )
}


export {ClassesList}