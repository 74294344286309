import { useEffect, useState } from "react";
import { ALPHABETS } from "../../helpers";

export const AlphabetsFilter = ({onChange, selectedLetterIndexOnEdit}) => {
    const [alphabets] = useState(ALPHABETS)
    const [selectedLetter, setSelectedLetter] = useState(selectedLetterIndexOnEdit != null ? selectedLetterIndexOnEdit: null)

    const onSelection = (alphabet, index) => {
        // if(selectedLetterIndexOnEdit == null){
            setSelectedLetter(index)
        // }
        onChange('letter', alphabet)
    }
    return (
        <>
            <div className="row no-gutters align-items-center justify-between mt-2 mb-2">
                {alphabets.map((obj, i) => (
                    <p 
                        key={i}
                        className={selectedLetter === i ? 'highlighted_letter global_color rejectAlphabet cursor-pointer ' : 'rejectAlphabet cursor-pointer'} 
                        onClick={() => onSelection(obj.alphabet, i)}
                    >
                        {obj.alphabet}
                    </p>
                ))}
            </div>
        </>
    )
}