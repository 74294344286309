import ButtonType1 from 'components/inputs/ButtonType1';
import { ContentDetail } from 'components/tubClass/contentDetail';
import { React } from 'react';

const totalNoOfObjects = 9

export const ReadingProgramView = ({ btnName, setReadingPr, readingPr, index }) => {
    const getData = (data) => {
        const elements = []
        const noOfItems = data?.length
        const len = totalNoOfObjects - noOfItems
        const incs = len === 0 ? noOfItems : noOfItems + len
        for (let i = 0; i < incs; i++) {
            elements.push(
                <div className='reading_program_images_container'>
                    <div
                        className={`content_inner ${i % 2 === 0 ? 'even-color' : 'odd-color'}`}
                    >
                        <img src={data[i]?.alphabetImage?.original} className="" />
                    </div>
                </div>
            )
        }

        return elements
    }
    return (
        <div className="tub-bg-type-1 p-2">
            {btnName !== null ?
                <div className="row no-gutters justify-content-between align-items-center px-2 tub-inner-heading pt-10">
                    <div className="ml-auto mb-2">
                        {readingPr?.isSessionCreated ?
                            <ButtonType1
                                btnName={"Added"}
                                className='btn-bg1 h-24-lh-12 buttonType1'
                                onClick={() => null}
                            />
                            :
                            <ButtonType1
                                btnName={btnName}
                                className='btn-bg1 h-24-lh-12 buttonType1'
                                onClick={() => setReadingPr(readingPr, index)}
                            />
                        }
                    </div>
                </div>
                :
                <></>
            }
            <div className='row gap-1 no-gutters mt-3 align-items-center'>
                {getData(readingPr?.data)}
            </div>
            <div className="row no-gutters mt-2 justify-content-between">
                <ContentDetail
                    ObjectName={readingPr?.title}
                    createdAt={null}
                />
            </div>
        </div>
    )
}
