import React, { Component } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { isEmptyOrUndefine, passwordMatchCondition } from 'utils/common.utils';
import { connect } from 'react-redux';
import * as auth from 'actions/auth';
import { bindActionCreators } from "redux";
import PhoneInput from 'react-phone-input-2';
import "assets/css/main.scss";
import errorMsgs from "utils/errorMsgs";
import toast from "utils/Toast";
import FormInput from "components/inputs/FormInput";
import Header from "components/Header/header";
import AuthBg from 'assets/images/loginpageimage.png';
import { checkIsPrivacyAccepted } from "helpers";

class SignUpClass extends Component {
  constructor(props) {
    super(props);
    this.state = {
      basicDetail: {
        username: null,
        password: null,
        firstname: null,
        lastname: null,
        gender: null,
        Tel_Country_Code: null,
        phone_number: null,
        Email: null
      },
      errors: {
        username: null,
        password: null,
        firstname: null,
        lastname: null,
        gender: null,
        phone_number: null,
        Email: null
      },
      genderOptions: [
        {
          label: "Male",
          value: "Male"
        },
        {
          label: "Female",
          value: "Female"
        },
        {
          label: "Others",
          value: "Others"
        }
      ],
      usernameErrorMessage: "",
      passwordErrorMessage: "",
      emptyBtnClicks: 0,
      emailValidBtnClicks: 0,
      captchaBtnClicks: 0,
      passwordCondBtnClicks: 0,
      invalidUser: 0,
      isPasswordVisible: false,
      disableButton: false,
      isAccepted: false,
      captchaValue: null,
      isPrivacyAcceptedAlready: false
    };
    this._reCaptchaRef = React.createRef();
  }

  componentDidMount() {
    const isPrivacyAccepted = checkIsPrivacyAccepted()
    if(isPrivacyAccepted){
      this.setState({isPrivacyAcceptedAlready: true, isAccepted: true})
    }
  }

  handleChange = (name, value) => {
    let { basicDetail, errors } = this.state
    basicDetail[name] = value
    errors[name] = undefined
    this.setState({ basicDetail: basicDetail, errors: errors });
  }
  checkRequiredFields = () => {
    let { basicDetail, emptyBtnClicks, passwordCondBtnClicks, captchaBtnClicks, captchaValue } = this.state;
    if (!this.state.isAccepted) {
      toast("Please accept the terms and condition.", "error");
      return false;
    }
    if (isEmptyOrUndefine(basicDetail["gender"]) || isEmptyOrUndefine(basicDetail["phone_number"]) || isEmptyOrUndefine(basicDetail["firstname"]) || isEmptyOrUndefine(basicDetail["lastname"]) || isEmptyOrUndefine(basicDetail["password"])) {
      let newBtnClicks = emptyBtnClicks === 4 ? 1 : emptyBtnClicks + 1;
      this.setState({
        emptyBtnClicks: newBtnClicks
      }, () => this.showToast(newBtnClicks, 1));
      return false;

    } else if (captchaValue === null) {
      let newBtnClicks = captchaBtnClicks === 4 ? 1 : captchaBtnClicks + 1;
      this.setState({
        captchaBtnClicks: newBtnClicks
      }, () => this.showToast(newBtnClicks, 3));
      return false

    } else if (!passwordMatchCondition(basicDetail["password"])) {
      let newBtnClicks = passwordCondBtnClicks === 4 ? 1 : passwordCondBtnClicks + 1;
      this.setState({
        passwordCondBtnClicks: newBtnClicks
      }, () => this.showToast(newBtnClicks, 4));

      return false;
    }
    return true;
  }
  showToast = (btnClicks, type) => {
    let errMsg = "";
    if (type === 1) {
      switch (btnClicks) {
        case 1:
          errMsg = errorMsgs.TCW001[0];
          break;
        case 2:
          errMsg = errorMsgs.TCW001[1];
          break;
        case 3:
          errMsg = errorMsgs.TCW001[2];
          break;
        case 4:
          errMsg = errorMsgs.TCW001[3];
          break;
        default:
          errMsg = errorMsgs.TCW001[0];
          break;
      }
    } else if (type === 2) {
      switch (btnClicks) {
        case 1:
          errMsg = errorMsgs.TCW031[0];
          break;
        case 2:
          errMsg = errorMsgs.TCW031[1];
          break;
        case 3:
          errMsg = errorMsgs.TCW031[2];
          break;
        case 4:
          errMsg = errorMsgs.TCW031[3];
          break;
        default:
          errMsg = errorMsgs.TCW031[0];
          break;
      }
    } else if (type === 3) {
      switch (btnClicks) {
        case 1:
          errMsg = errorMsgs.TCW034[0];
          break;
        case 2:
          errMsg = errorMsgs.TCW034[1];
          break;
        case 3:
          errMsg = errorMsgs.TCW034[2];
          break;
        case 4:
          errMsg = errorMsgs.TCW034[3];
          break;
        default:
          errMsg = errorMsgs.TCW034[0];
          break;
      }
    } else if (type === 4) {
      switch (btnClicks) {
        case 1:
          errMsg = errorMsgs.TCW032[0];
          break;
        case 2:
          errMsg = errorMsgs.TCW032[1];
          break;
        case 3:
          errMsg = errorMsgs.TCW032[2];
          break;
        case 4:
          errMsg = errorMsgs.TCW032[3];
          break;
        default:
          errMsg = errorMsgs.TCW032[0];
          break;
      }
    }
    toast(errMsg, "error")
  }
  authenticate = () => {
    let { basicDetail, errors } = this.state;
    this.props.register(basicDetail, errors);
  }

  onCaptchaChange = () => {
    let value = this._reCaptchaRef.current.getValue()
    this.setState({ captchaValue: value });
  };

  onChange = (name, value, id) => {
    let { basicDetail, errors } = this.state;
    basicDetail[name] = value;
    errors[name] = undefined;
    this.setState({ basicDetail: basicDetail, errors: errors });
  }

  getQueryVariable = (variable) => {
    var query = window.location.search.substring(1);
    console.log(query)//"app=article&act=news_content&aid=160990"
    var vars = query.split("&");
    console.log(vars) //[ 'app=article', 'act=news_content', 'aid=160990' ]
    for (var i = 0; i < vars?.length; i++) {
      var pair = vars[i].split("=");
      console.log(pair)//[ 'app', 'article' ][ 'act', 'news_content' ][ 'aid', '160990' ] 
      if (pair[0] === variable) { return pair[1]; }
    }
    return (false);
  }

  authenticateUser = () => {
    let { invalidUser } = this.state
    try {
      if (this.checkRequiredFields()) {
        let invalidUsers = invalidUser < 4 ? invalidUser + 1 : 4;
        this.setState({ invalidUser: invalidUsers }, () => this.authenticate());
      }
    } catch (error) {
      console.log('error signing in', error);
    }
  }

  togglePassword = () => {
    this.setState({
      isPasswordVisible: !this.state.isPasswordVisible
    })
  }
  handleCheckbox = () => {
    const isPrivacyAccepted = checkIsPrivacyAccepted()
    if(!isPrivacyAccepted){
        localStorage.setItem("isPrivacyPolicyAccepted", "true")

        this.setState({
          isAccepted: true,
          isPrivacyAcceptedAlready: true
        });
    }
   
  }
  onExpired = () => {
    // let value = this._reCaptchaRef.current.getValue()
    this.setState({ captchaValue: null });
  }
  changeMobileNumber = (value, data) => {
    let {basicDetail } = this.state
    basicDetail["Tel_Country_Code"] = data.dialCode
    basicDetail["phone_number"] = value
    this.setState({basicDetail})
  }
  render() {
    let { isPasswordVisible, isAccepted, basicDetail, errors, genderOptions,isPrivacyAcceptedAlready } = this.state
    return (
      <>
        <Header />
        <div className="maincontainer auth-background">
          <div className="container-fluid">
            <div className="row no-gutter justify-content-center">
              <div className="col-md-7 d-none d-lg-flex auth-bg">
                <div className="">
                  <img src={AuthBg} className="auth-bgImage" alt="alphatub"/>
                </div>
              </div>
              <div className="col-md-5">
                <div className="h-100 d-flex align-items-left py-4">
                  <div className="col-lg-12 col-xl-12 mx-auto">
                    <h3 className="login-text-login">Create An Account</h3>
                    <p className="light-text mb-4 text-center" style={{margin: '0px 70px'}}>
                      You have been Invited to the {this.props.invitation && this.props.invitation.invitationDetails && this.props.invitation.invitationDetails.SchoolName} Team, Enter the following details
                      to get started
                    </p>
                    <div className="row no-gutters">
                      <div className="col-md-12">
                        <FormInput
                          label="Email ID"
                          type="text"
                          name="Email"
                          className="mb-2"
                          labelClassName="mt-login"
                          value={basicDetail["Email"]}
                          onChange={this.handleChange}
                        />
                      </div>
                      {/* <div className="col-md-1">
                        <div className="tickdiv">
                          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-check-lg" viewBox="0 0 16 16" style= {{ color:'#00AFEE'}}>
                            <path d="M13.485 1.431a1.473 1.473 0 0 1 2.104 2.062l-7.84 9.801a1.473 1.473 0 0 1-2.12.04L.431 8.138a1.473 1.473 0 0 1 2.084-2.083l4.111 4.112 6.82-8.69a.486.486 0 0 1 .04-.045z" />
                          </svg>
                        </div>
                      </div> */}
                    </div>
                    <div className="mb-2">
                    </div>
                    <div className="row mb-3">
                      <div className="col-md-6">
                        <FormInput
                          id="firstname"
                          name="firstname"
                          label="First Name"
                          type="text"
                          className="m-0"
                          labelClassName="mt-login"
                          value={basicDetail["firstname"]}
                          onChange={this.handleChange}
                        />
                      </div>
                      <div className="col-md-6">
                        <FormInput
                          id="lastname"
                          name="lastname"
                          label="Last Name"
                          type="text"
                          className="m-0"
                          labelClassName="mt-login"
                          value={basicDetail["lastname"]}
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                    <div className="mb-3">
                      <div className="password-input">
                        <FormInput
                          name="password"
                          label="Create Password"
                          className="m-0"
                          labelClassName="mt-login"
                          value={basicDetail['password']}
                          isPasswordVisible={isPasswordVisible}
                          type="password"
                          togglePassword={this.togglePassword}
                          onChange={this.handleChange}
                        />
                      </div>
                      <p className='passwordPolicyContent'>Please create a password with minimum of 6 alpha numeric characters.Which will contains atleast one capital letter, one small letter, one special symbol and one numeric digit</p>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="password-input">
                          <FormInput
                            type="select"
                            label="Gender"
                            name="gender"
                            placeholder='Select Gender'
                            labelClassName="mt-login"
                            options={genderOptions}
                            onChange={(...params) => this.onChange(...params, null)}
                            value={basicDetail["gender"]}
                            error={errors["gender"]}
                            showDownArrow={true}
                          />
                        </div>
                      </div>
                      <div className="col-md-6 mt-1">
                        <label className='mt-login' style={{ marginBottom: '3px'}}>Contact Number</label>
                        <div className="mb-3">
                          <PhoneInput
                            country={'in'}
                            name="phone_number"
                            countryCodeEditable={false}
                            value={this.state.basicDetail.phone_number}
                            onChange={(value, data) => this.changeMobileNumber(value, data)}
                            placeholder=''
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row mb-3">
                      <div style={{ "paddingLeft": "3%" }}>
                        <ReCAPTCHA
                          sitekey={'6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI'}
                          ref={this._reCaptchaRef}
                          onExpired={() => this.onExpired()}
                          onChange={() => this.onCaptchaChange()}
                        />
                      </div>
                    </div>
                    <div className="d-flex mb-3 no-gutter mt-10 align-items-center">
                      <input
                        type="checkbox"
                        id="check1"
                        name="isSetRememberPass"
                        className="remember-checkbox"
                        disabled = {isPrivacyAcceptedAlready}
                        value={isAccepted}
                        checked={isAccepted}
                        onChange={() => this.handleCheckbox()}
                      />
                      <p className="checkboxLabel cursor-pointer" >
                        I agree to the 
                        <span 
                          className='terms-bluetext'
                          onClick = {() => this.props.history.push('/privacy')}
                        > Terms of Service </span> 
                        and 
                        <span 
                          className='terms-bluetext'
                          onClick = {() => this.props.history.push('/privacy')}
                        > Privacy Policy </span> 
                      </p>
                    </div>
                    <div className="proceed-button mtb-10">
                      <button type="button" className="btn btn-primary" onClick={this.authenticateUser}>Create Account</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </>
    );
  }
}


let mapDispatchToProps = (dispatch) => {
  return bindActionCreators(auth, dispatch)
}
const mapStateToProps = ({ invitation, loader }) => {
  return {
    invitation,
    loader
  };
};

export const SignUp = connect(mapStateToProps, mapDispatchToProps)(SignUpClass)

