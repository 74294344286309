import { React, useEffect, useState } from 'react';
import * as curriculumAction from 'actions/curriculum';
import { bindActionCreators } from "redux";
import { connect } from 'react-redux'
import _ from 'lodash';
import { ReadingProgramContent } from 'components/ReadingPrograms';
import moment from 'moment';
import { InnerAppLoader } from 'components/loader/appLoader';

const ReadingProgramFunction = (props) => {
    const [loading, setLoading] = useState(false)

    const getApis = async () => {
        setLoading(true)
        await props.curriculumAction.getCurriculumAndProgramName()
        await props.curriculumAction.getReadingProgramsList(null)
        let dataToPersist = props.dataToPersist
        let selectedClass = sessionStorage.getItem('selectedClass')
        let sessionDatesqueryPayload = {
            schoolId: sessionStorage.getItem('schoolId'),
            month: parseInt(moment(new Date()).format("M")),
            classId: selectedClass ? selectedClass : dataToPersist.selectedClass?.value,
        }
        await props.curriculumAction.getAssingmentCalendarDatesByMonth(sessionDatesqueryPayload)
        setLoading(false)
    }

    useEffect(() => {
        getApis()
    }, [])

    return (
        <>
            {loading ?
                <InnerAppLoader />
                :
                <ReadingProgramContent
                    {...props}
                    btnName="Make Assignment"
                    showAssignmentPopup={true}
                />
            }
        </>

    )
}

const mapStateToProps = ({ tubGallery, myProfile, loader, session, curriculum, dataToPersist }) => {
    return {
        tubGallery,
        myProfile,
        loader,
        session,
        curriculum,
        dataToPersist
    };
};

let mapDispatchToProps = (dispatch) => {
    return {
        curriculumAction: bindActionCreators(curriculumAction, dispatch),
        dispatch
    }
}

const ReadingProgram = connect(mapStateToProps, mapDispatchToProps)(ReadingProgramFunction)

export { ReadingProgram }