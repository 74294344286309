import { AppLoader } from "../loader/appLoader";
import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import './CustomBootstrapTable.scss';

const hiddenRows = [1, 4];

export const CustomBootstrapTable = ({
  pagination,
  totalItems,
  page,
  setPage,
  keyField,
  rows,
  columns,
  sort,
  isLoading,
  selectRows,
  onRowClicked,
  isRowClickEnabled,
  tableClass,
  tableMainClass,
}) => {
  const rowEvents = () => {
    if (isRowClickEnabled) {
      return {
        onClick: (e, row, rowIndex) => {
          console.log({ e, row, rowIndex });
          onRowClicked(row);
        },
      };
    }
  };

  const rowStyle = (row, rowIndex) => {
    const style = {};
    if (row.status === 1) {
      style.backgroundColor = "#c8e6c9";
    }
    return style;
  };

  const emptyDataMessage = () => {
    return <p className="table_empty_text">No Data Found</p>;
  };

  const sizePerPageRenderer = ({
    options,
    onSizePerPageChange,
    currSizePerPage,
  }) => {
    return (<>
      <span className="size-per-page-title">Size per page</span>
      <select value={currSizePerPage} onChange={(event) => onSizePerPageChange(event?.target?.value)}>
        {options.map(option => <option key={Math.random()}>{option.page}</option>)}
      </select>
    </>)
  }

  const pageButtonRenderer = ({
    page,
    active,
    disable,
    title,
    onPageChange,
  }) => {
    const handleClick = (e) => {
      e.preventDefault();
      onPageChange(page);
      // pagination(page,10)
      // setPage(page);
    };
    const activeStyle = {
      backgroundColor: active ? "black" : "gray",
      color: active ? "white" : "black",
      border: "1px solid grey",
      padding: "5px 12px",
      margin: "0 1px",
      fontSize: "medium",
    };

    if (typeof page === "string") {
      activeStyle.backgroundColor = "white";
      activeStyle.color = "black";
      activeStyle.border = "1px solid grey";
      activeStyle.padding = "5px 12px";
      // activeStyle.margin = '0 1px';
      activeStyle.fontSize = "medium";
    }
    return (
      <>
      <li className="page-item">
        <a href="#" onClick={handleClick} style={activeStyle}>
          {page}
        </a>
      </li>
     </>
    );
  };

  const options = {
    pageButtonRenderer,
    sizePerPageRenderer,
    sizePerPage: 10,
    pageStartIndex: 1,
    prePageText: 'Prev',
    nextPageText: 'Next',
    totalSize: rows.length,
    sizePerPageList: [
      { text: "10", value: 10 },
      { text: "30", value: 30 },
      { text: "50", value: 50 },
      { text: "100", value: 100 },
    ],
  };
  return (
    <>
      {isLoading ? (
        <AppLoader />
      ) : (
        <BootstrapTable
          bootstrap4
          keyField="id"
          data={rows}
          columns={columns}
          defaultSorted={sort}
          hiddenRows={hiddenRows}
          selectRow={selectRows}
          classes="table table-bordered table-striped table-hover"
          rowEvents={rowEvents()}
          loading={true}
          striped
          hover
          caption={"Size per page"}
          noDataIndication={emptyDataMessage}
          rowStyle={rowStyle}
          rowClasses={tableClass}
          wrapperClasses={`bootstrap-table  ${tableMainClass}`}
          pagination={paginationFactory(options)}
        />
      )}
    </>
  );
};
