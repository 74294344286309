const routers = [
  {
    component: "StaticLayout",
    path: "/",
    auth: false,
    exact: false,
    redirect: "/login",
    childrens: [
      {
        component: "Login",
        path: "login",
        auth: false,
        exact: true,
      },
      {
        component: "ForgotPassword",
        path: "forgotpassword",
        auth: false,
        exact: true,
      },
      {
        component: "ResetPassword",
        path: "resetpassword",
        auth: false,
        exact: true,
      },
      {
        component: "VerifyCode",
        path: "verifycode",
        auth: false,
        exact: true,
      },
      {
        component: "AcceptInvitation",
        path: "accept/invitation/:id",
        auth: false,
        exact: true,
      },
      {
        component: "SignUp",
        path: "signup",
        auth: false,
        exact: false,
      },
      {
        component: "Privacy",
        path: "privacy",
        auth: true,
        exact: true,
      },
      {
        component: "Sample",
        path: "gallery/:id",
        auth: false,
        exact: false,
      },
      {
        component: "PaymentSuccess",
        path: "payment/success",
        auth: false,
        exact: false,
      },
      {
        component: "PaymentCancel",
        path: "payment/cancel",
        auth: false,
        exact: false,
      },
    ],
  },
  {
    component: "AdminLayout",
    path: "/dashboard",
    auth: true,
    exact: false,
    // redirect: '/dashboard',
    childrens: [
      {
        component: 'Hierarchy',
        path: '/hierarchy',
        auth: true,
        exact: true,
      },
      {
        component: 'TubPage',
        path: '/tubpage',
        auth: true,
        exact: true,
      },
      {
        component: "Profile",
        path: "/settings/profile",
        auth: true,
        exact: true,
      },
      {
        component: "Classes",
        path: "/settings/class",
        auth: true,
        exact: true,
      },
        {
        component: "Schools",
        path: "/settings/schools",
        auth: true,
        exact: true,
      },
      // {
      //   component: "School",
      //   path: "/settings/class",
      //   auth: true,
      //   exact: true,
      // },
      {
        component: "Subscription",
        path: "/settings/subscription",
        auth: true,
        exact: true,
      },
      {
        component: "Curriculum",
        path: "/settings/curriculum",
        auth: true,
        exact: true,
      },
      {
        component: "ReadingProgram",
        path: "/tubclass/readingprogram",
        auth: true,
        exact: true,
      },
      {
        component: "Assignment",
        path: "/tubclass/assignment",
        auth: true,
        exact: true,
      },
      {
        component: "TrackAssignment",
        path: "/tubclass/trackassignment",
        auth: true,
        exact: true,
      },
      {
        component: "Pending",
        path: "/tubclass/tubinbox/pending",
        auth: true,
        exact: true,
      },
      {
        component: "Approved",
        path: "/tubclass/tubinbox/approved",
        auth: true,
        exact: true,
      },
      {
        component: "Rejected",
        path: "/tubclass/tubinbox/rejected",
        auth: true,
        exact: true,
      },
      {
        component: "Disabled",
        path: "/tubclass/tubinbox/disabled",
        auth: true,
        exact: true,
      },
      {
        component: "Deleted",
        path: "/tubclass/tubinbox/deleted",
        auth: true,
        exact: true,
      },
      {
        component: "Tubgallery",
        path: "/tubclass/tubgallery",
        auth: true,
        exact: true,
      },
      {
        component: "Session",
        path: "/tubclass/session",
        auth: true,
        exact: true,
      },
      // {
      //     component: 'TubActivities',
      //     path: '/tubclass/session/tubactivity',
      //     auth: true,
      //     exact: true
      // },
      {
        component: "ClassPresentationHome",
        path: "/class/presentation/home",
        auth: true,
        exact: true,
      },
      {
        component: "Overall",
        path: "/analytics/overall",
        auth: true,
        exact: true,
      },
      {
        component: "SelectStudentsInReport",
        path: "/analytics/studentreport",
        auth: true,
        exact: true,
      },
      {
        component: "StudentReport",
        path: "/analytics/studentreport/students/:id",
        auth: true,
        exact: true,
      },
      {
        component: "AdminAddGalleryTemplate",
        path: "/admin/add/gallery",
        auth: true,
        exact: true,
      },
    ],
  },
  {
    component: "ClassPresentationLayout",
    path: "/class/presentation",
    auth: true,
    exact: false,
    childrens: [
      {
        component: "ClassPresentationHome",
        path: "/home",
        auth: true,
        exact: true,
      },

      {
        component: "TubObjectDetailView",
        path: "/detail/view",
        auth: true,
        exact: true,
      },
      {
        component: "ClassAttendance",
        path: "/attendance",
        auth: true,
        exact: true,
      },
      {
        component: "ClassCollage",
        path: "/collage",
        auth: true,
        exact: true,
      },
      {
        component: "ExploreView",
        path: "/explore",
        auth: true,
        exact: true,
      },
      {
        component: "SelectGame",
        path: "/select/game",
        auth: true,
        exact: true,
      },
      {
        component: "SelectIndividualPlayers",
        path: "/select/individual/players",
        auth: true,
        exact: true,
      },
      {
        component: "SelectRandomPlayers",
        path: "/select/random/players",
        auth: true,
        exact: true,
      },
      {
        component: "SelectGroup",
        path: "/select/group/players",
        auth: true,
        exact: true,
      },
      {
        component: "MatchTheObject",
        path: "/play/matchtheobject",
        auth: true,
        exact: true,
      },
      {
        component: "MissingLetter",
        path: "/play/missingletter",
        auth: true,
        exact: true,
      },
      {
        component: "WatsTheGoodWord",
        path: "/play/matchthegoodword",
        auth: true,
        exact: true,
      },
      {
        component: "ETub",
        path: "/play/etub",
        auth: true,
        exact: true,
      },
    ],
  },
];

export default routers;
