import { React, useState } from 'react';
import toast from "utils/Toast";
import { accessHeaders, formatDate, history } from 'helpers';
import { CommonDatePopup } from 'components/tubClass/commonDatePopup';
import _ from 'lodash';
import { API } from 'aws-amplify';
import { ENVIRONMENT } from 'common/constants';
import moment from 'moment'
import ButtonType1 from 'components/inputs/ButtonType1';
import { ContentDetail } from 'components/tubClass/contentDetail';
import FormInput from 'components/inputs/FormInput';
import { CreateModel } from 'components/Model';
import Calendar from 'react-calendar';
import { differenceInCalendarDays } from 'date-fns';
import { ReadingProgramView } from './readingProgramView';

export const ReadingProgramContent = (props) => {
    const [programData, setProgram] = useState(null)
    const [search, onChangeSearch] = useState(null)
    const [showDatePopup, triggerPopup] = useState(false)
    const [contentSelectedDate, setContentSelectedDate] = useState(new Date())
    const [isConfirmBtnDisabled, setIsBtnDisabled] = useState(false)
    const [workingDaysOptions] = useState([
        {
            label: "S",
            value: "Sun",
            checked: false,
            index: 0
        },
        {
            label: "M",
            value: "Mon",
            checked: false,
            index: 1
        },
        {
            label: "T",
            value: "Tue",
            checked: false,
            index: 2
        },
        {
            label: "W",
            value: "Wed",
            checked: false,
            index: 3
        },
        {
            label: "T",
            value: "Thu",
            checked: false,
            index: 4
        },
        {
            label: "F",
            value: "Fri",
            checked: false,
            index: 5
        },
        {
            label: "S",
            value: "Sat",
            checked: false,
            index: 6
        },
    
    ])

    const assignProgram = async (parsedDate, prData, _id = null) => {
        let dataToPersist = props.dataToPersist
        let selectedClass = sessionStorage.getItem('selectedClass')
        let payload = {
            reading_Program_Id: prData._id,
            program_Choice: prData.data,
            assignment_Name: prData.title,
            "program_Hierarachy": prData.reading_Program_Hierarachy,
            "assignment_Number": 1,
            createdBy: prData.createdBy,
            createdBy: localStorage.getItem('username'),
            classId: selectedClass != null ? selectedClass : dataToPersist.selectedClass?.value,
            schoolId: sessionStorage.getItem('schoolId'),
            start_Date: moment(parsedDate).format('YYYY-MM-DDT[00:00:00.000Z]'),
            teacher_Choice: prData.teacher_Choice,
        }
        if(_id != null){
            payload["_id"] = _id
        }
        try {
            let url = "assignment/v3.7/createUpdateAssignment";
            let myInit = {
                headers: accessHeaders(),
                body: payload
            }
            const response = await API.post(ENVIRONMENT, url, myInit);
            if (response.status === 200) {
                triggerPopup(false)
                history.push(`/dashboard/tubclass/assignment?date=${formatDate(payload.start_Date)}`)
                toast('Program assigned successfully', 'success')
            } else {
                toast(response?.message, 'error')
            }
        } catch (error) {
            console.log("error", error);
            toast("Alert! Content Already Assigned For This Date.", 'error')
        }

    }

    const setReadingPr = async (item, index) => {
        const searchParams = new URLSearchParams(props.location.search);
        const date = searchParams.get('date');
        const _id = searchParams.get('_id');
        if(date){
            await assignProgram(date, item, _id)
        } else {
            if (!props.showAssignmentPopup) {
                props.addToCreateSession(item, index)
            }
            triggerPopup(true)
            setProgram(item)
        }
    }

    const handleSearch = (value) => {
        onChangeSearch(value)
        props.curriculumAction.getReadingProgramsList(value)
    }

    const onChangeDate = (value) => {
        if(moment(value).format('YYYY-MM-DD') < moment().format('YYYY-MM-DD') ){
            setIsBtnDisabled(true)
        } else setIsBtnDisabled(false)
        setContentSelectedDate(value)
    }

    const isSameDay = (disDate, date) => {
        return differenceInCalendarDays(disDate, date) === 0;
    }
    const tileDisabled = ({ date, view }) => {
        let { curriculum } = props
        let getDisableDates = [], filteredDays
        if (curriculum.assignmentCalendarData.sessionDisableDatesList?.length > 0) {
            getDisableDates = curriculum.assignmentCalendarData.sessionDisableDatesList.find(dDate => isSameDay(dDate, date))
        }
        if (curriculum.assignmentCalendarData.schoolAcademicYear != null) {
            let filteredIndexes = workingDaysOptions.filter(fl => !curriculum.assignmentCalendarData.academicYear?.days.includes(fl.value)).map(f => f.index)
            if (filteredIndexes?.length > 0) {
                filteredDays = filteredIndexes.includes(date.getDay());
            }
        }
        if (curriculum.assignmentCalendarData.sessionDisableDatesList?.length > 0) {
            return filteredDays || getDisableDates
        } else {
            return filteredDays
        }
    }

    const tileContent = ({ date, view }) => {
        let { curriculum } = props
        if (view === 'month') {
            let getContent = curriculum.assignmentCalendarData.assignmentDates.find(dDate => isSameDay(dDate, date))
            if (getContent) {
                return 'assignment_present_date';
            }

            let getUnAssignedContent = curriculum.assignmentCalendarData.unAssignmentDates.find(dDate => isSameDay(dDate, date))
            if (getUnAssignedContent) {
                return 'program_date';
            }

        }
    }

    const onChangeMonth = async (value) => {
        let dataToPersist = props.dataToPersist
        let selectedClass = sessionStorage.getItem('selectedClass')

        let sessionDatesqueryPayload = {
            schoolId: sessionStorage.getItem('schoolId'),
            month: moment(value.activeStartDate).format("MM").toString(),
            classId: selectedClass ? selectedClass : dataToPersist.selectedClass?.value,
        }
        setContentSelectedDate(value.activeStartDate) 
        getAssignmentCalendarData(sessionDatesqueryPayload)

    }

    const getAssignmentCalendarData = (query) => {
        props.curriculumAction.getAssingmentCalendarDatesByMonth(query)
    }
    return (
        <>
            <div className="col-12 p-0 mb-3">
                <div className="bg-white py-2-half row no-gutters align-items-center">
                    <div className="col-3 px-3 relative_position">
                        <FormInput
                            id="search"
                            name="search"
                            type="search"
                            placeholder="search..."
                            onChange={(name, value) => handleSearch(value)}
                            value={search}
                            className="m-0"
                            groupclassName='search-type-1'
                        />
                    </div>
                </div>
            </div>
            {props.curriculum.readingProgramList && props.curriculum.readingProgramList?.length > 0 &&
                <div className='col-12 bg_white p-3'>
                    <div className='row'>
                        {props.curriculum.readingProgramList.map((rp, i) => {
                            return (
                                <div className="col-lg-6 col-xl-4 col-md-6 col-sm-12 col-12 mt-3" key={i}>
                                    <ReadingProgramView 
                                        btnName={props.btnName}
                                        readingPr = {rp}
                                        setReadingPr = {setReadingPr}
                                    />
                                </div>
                            )
                        })}
                </div>

                </div>
            }
            {/* Assign Assessment date popup */}
            {props.showAssignmentPopup &&
                <CreateModel
                    isOpen={showDatePopup}
                    toggle={() => triggerPopup(false)}
                    title={"Assign to a date"}
                >
                    <div className="row pt-3 pb-5">
                        <div className="col-12 session-calendar gallery-cal">
                            <Calendar
                                onChange={(value) => onChangeDate(value)}
                                onActiveStartDateChange={(value) => onChangeMonth(value)}
                                value={contentSelectedDate}
                                selectRange={false}
                                tileClassName={tileContent}
                                tileDisabled = {tileDisabled}
                                className="assignment_calendar"
                            />
                        </div>
                    </div>
                    <div className="row align-items-center mt-3 content-end">
                        <div className="col-4">
                            <ButtonType1
                                btnName="Close"
                                className='popup-close-btn'
                                onClick={() => triggerPopup(false)}
                            />
                        </div>
                        <div className="col-4">
                            <ButtonType1
                                btnName="Confirm"
                                className='bg-tub c-white buttonType1'
                                disabled = {isConfirmBtnDisabled}
                                onClick={() => assignProgram(contentSelectedDate, programData)}
                            />
                        </div>
                    </div>
                </CreateModel>
            }
        </>
    )
}