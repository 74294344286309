import { history } from 'helpers';
import Wrapper from 'Hoc/wrapper';
import { React } from 'react';
import { connect } from 'react-redux'
import noImage from 'assets/images/noImage.png'
import _ from 'lodash';

const SelectStudentsInReportFunc = ({dataToPersist, tubGallery}) => {

    const redirct = (student) => {
        history.push({
            pathname: `/dashboard/analytics/studentreport/students/${student._id}`
        })
    }

    return (
        <div className='sr_ir_main mh_100vh'>
            <div>
                <h1 className='SR_TX_1 text-capitalize'>{dataToPersist?.selectedClass?.label}</h1>
            </div>
            <div className='sr_students_list mt-3'>
                {
                    tubGallery.rightSidebarStudentsList && tubGallery.rightSidebarStudentsList?.length > 0
                        ?
                        tubGallery.rightSidebarStudentsList.map(tb => (
                            <div className="analytics_students mt-2 cursor-pointer" onClick={() => redirct(tb)}>
                                <img
                                    src={!_.isEmpty(tb.profilePic.original) ? tb.profilePic.original : noImage}
                                    alt="alphatub"
                                />
                                <h5>{tb.firstName ? tb.firstName : '-'}</h5>
                            </div>
                        ))
                        :
                        <div className="row no-gutters align-items-center center-content w-100 h-400px">
                            <p className='tub-content-4 text-center'>No Students Found</p>
                        </div>
                }
            </div>
        </div>
    )

}

const mapStateToProps = ({ tubGallery ,dataToPersist}) => {
    return {
        tubGallery,
        dataToPersist
    };
};

let mapDispatchToProps = (dispatch) => {
    return {
        dispatch
    }
}

const SelectStudentsInReport = Wrapper(connect(mapStateToProps, mapDispatchToProps)(SelectStudentsInReportFunc))

export { SelectStudentsInReport }