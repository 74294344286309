import { loaders, main } from '../service/actionType';
import { API } from 'aws-amplify';
import toast from '../helpers/Toast';
import { ENVIRONMENT } from '../common/constants';
import { accessHeaders } from '../helpers';


export const getGalleryList = (queryPayload) => async (dispatch, getState) => {
    try {
        dispatch({ type: loaders.IS_TUB_GALLERY_FETCHING, payload: true })
        let url = "gallery/list";
        let myInit = {
            headers: accessHeaders(),
            queryStringParameters: {
                ...queryPayload
            }
        }
        const response = await API.get(ENVIRONMENT, url, myInit);
        if(response.status === 200){
            dispatch({ type: main.TUB_GALLERY_LIST, payload: response.data })
            dispatch({ type: loaders.IS_TUB_GALLERY_FETCHING, payload: false })
        }
    } catch (error) {
        console.log("error", error);
        dispatch({ type: loaders.IS_TUB_GALLERY_FETCHING, payload: false })
        toast(error.message, 'error');
    }

}

export const getGalleryTypeTwoObjectList = (queryPayload) => async (dispatch, getState) => {
    try {
        dispatch({ type: loaders.IS_TUB_GALLERY_FETCHING, payload: true })
        let url = "contentselection/createsession/v3.6/gallery/list";
        let myInit = {
            headers: accessHeaders(),
            queryStringParameters: {
                ...queryPayload
            }
        }
        const response = await API.get(ENVIRONMENT, url, myInit);
        if(response.status === 200){
            dispatch({ type: main.TUB_GALLERY_LIST, payload: response.data })
            dispatch({ type: loaders.IS_TUB_GALLERY_FETCHING, payload: false })
        }
    } catch (error) {
        console.log("error", error);
        dispatch({ type: loaders.IS_TUB_GALLERY_FETCHING, payload: false })
        toast(error.message, 'error');
    }

}
export const getStudentsList = () => async (dispatch, getState) => {
    try {
        let { dataToPersist } = getState()
        let selectedClass = sessionStorage.getItem('selectedClass')
        let url = "director/getAdmins";
        let myInit = {
            headers: accessHeaders(),
            queryStringParameters: {
                // classId:selectedClass != null ? selectedClass :  dataToPersist.selectedClass?.value,
                userName: localStorage.getItem("username")
            }
        }
        const response = await API.get(ENVIRONMENT, url, myInit);
        if(response.status === 200){
            dispatch({ type: main.RIGHT_SIDEBAR_STUDENTS_LIST, payload: response.data })
        } else {
            dispatch({ type: main.RIGHT_SIDEBAR_STUDENTS_LIST, payload: [] })
        }
    } catch (error) {
        console.log("error", error);
        // toast(error.message, 'error');
        dispatch({ type: main.RIGHT_SIDEBAR_STUDENTS_LIST, payload: [] })
    }

}

export const changeClassInRedx = (classDetail) => async (dispatch, getState) => {
    sessionStorage.setItem('selectedClass',classDetail.value)
    dispatch({ type: main.SELECTED_CLASS, payload: classDetail})
}


export const createSession = (data) => async (dispatch, getState) => {
    try {
        // dispatch({ type: loaders.IS_APP_FETCHING, payload: true })
        let url = "contentselection/createsession/create";
        let myInit = {
            headers: accessHeaders(),
            body: data
        }
        const response = await API.post(ENVIRONMENT, url, myInit);
        if(response.status === 200){
            toast(response?.message, 'success')
            // dispatch({ type: loaders.IS_APP_FETCHING, payload: false })
            return true
        } else {
            toast(response?.message, 'error');
            // dispatch({ type: loaders.IS_APP_FETCHING, payload: false })
            return false
        }
    } catch (error) {
        console.log("error", error);
        toast('This Collection has already been added to selected date', 'error');
        dispatch({ type: loaders.IS_APP_FETCHING, payload: false })
    }

}
 