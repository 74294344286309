import { React, Component } from "react";
import { connect } from "react-redux";
import * as gameAction from "../../../../../../actions/game";
import { bindActionCreators } from "redux";
// import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { GamePlayers } from "../gamePlayersList";
import { CreateModel } from "../../../../../../components/Model/createModel";
import ButtonType1 from "../../../../../../components/inputs/ButtonType1";
import { history } from "../../../../../../helpers";
import { main } from "../../../../../../service/actionType";
import RightArrow from "../../../../../../assets/images/rightarrow.svg";
import LeftArrow from "../../../../../../assets/images/leftarrow.svg";
import toast from "../../../../../../helpers/Toast";
import ClassPresentationWrapper from "../../../../../../Hoc/wrapper";
import { ArrowLeft, ArrowRight } from "components/tubClass/arrows";
class ETubClass extends Component {
  constructor(props) {
    super(props);
    this.state = {
      playersList: [],
      selectedItem: 0,
      init: true,
    };
  }

  selectedOption = async (obj, option) => {
    let { game } = this.props;
    let lenghtOfQuestions = game.gameTypeOneQuestions?.length;
    await this.props.gameAction.updateSelectedOption(obj, option);
    let questionsIndex = game.gameTypeOneQuestions.findIndex(
      (op) => op.constantId === obj.constantId
    );
    if (lenghtOfQuestions === questionsIndex + 1) {
      this.storeGameResults();
    } else {
      setTimeout(() => {
        this.setState({
          selectedItem: this.state.selectedItem + 1,
        });
      }, 1500);
    }
  };
  storeGameResults = async () => {
    await this.props.gameAction.storeGameResults("gameTypeOneQuestions");
    await this.props.gameAction.storeGameModes("play");
  };

  updateCurrentSlide = async (index) => {
    await this.props.gameAction.changeToNextQuestion(index);
  };
  quitGame = () => {
    this.props.dispatch({ type: main.IS_SELECTED_BACK_BTN, payload: false });
    history.push("/class/presentation/home");
    this.props.dispatch({
      type: main.MATCH_THE_GOOD_WORD_QUESTIONS,
      payload: [],
    });
    this.props.dispatch({ type: main.PLAYERS_LIST, payload: [] });
    this.props.dispatch({ type: main.SELECTED_PLAYERS_LIST, payload: null });
  };
  closeGame = () => {
    this.props.dispatch({ type: main.IS_SELECTED_BACK_BTN, payload: false });
  };

  clickNext = () => {
    this.setState({
      selectedItem: this.state.selectedItem + 1,
    });
  };
  clickPrev = () => {
    this.setState({
      selectedItem: this.state.selectedItem - 1,
    });
  };

  clickNextItem = (item) => {
    console.log("item", item);
  };
  render() {
    let state = this.state;
    let { game } = this.props;
    let selectedMode = sessionStorage.getItem("selectedMode");
    let getCurrentPlayer;
    if (game.currentQuestion != null) {
      getCurrentPlayer =
        game.questionMappedWithRandomPlayers &&
        game.questionMappedWithRandomPlayers.find(
          (qp) => qp.questionId === game.currentQuestion.questionId
        );
    }
    return (
      <div>
        {" "}
        <>
          <div className="row mt-5">
            <div className="col-12">
              <div className="mb-5">
                <Carousel
                  showStatus={false}
                  emulateTouch={false}
                  showThumbs={true}
                  showIndicators={false}
                  useKeyboardArrows={false}
                  selectedItem={state.selectedItem}
                  onChange={this.updateCurrentSlide}
                  showArrows={false}
                  onSwipeForward={() => this.clickNextItem(state.selectedItem)}
                >
                  {game.gameTypeOneQuestions &&
                    game.gameTypeOneQuestions.map((dt, i) => (
                      <>
                        {i != 0 && (
                          <ArrowLeft
                            className={"game_option_arrow"}
                            prev={this.clickPrev}
                          />
                        )}
                        <div className="row align-items-center justify-content-center">
                          <div className="col-11">
                            <div className="row">
                              <div className="col-12">
                                <p className="clp-game-head  text-center">
                                  {dt.alphabetName}
                                </p>
                              </div>
                            </div>
                            <div className="row mt-5">
                              {dt.options &&
                                dt.options.map((op, i) => (
                                  <>
                                    {dt.isOptionSelected ? (
                                      op.isSelected && op.isCorrectAnswer ? (
                                        //show right mark
                                        <div className="col-4">
                                          <div className="cursor-pointer">
                                            <div className="optionItem1">
                                              <img
                                                src={op.optionImage}
                                                className="inheritHeight br-10"
                                                alt="alphatub"
                                              />
                                            </div>
                                            <div className="wrong-answer-pos">
                                              <img
                                                src={require("../../../../../../assets/images/selectedPlayer.png")}
                                                className="wrong-answer-img"
                                                alt="alphatub"
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      ) : op.isSelected &&
                                        op.isCorrectAnswer === false ? (
                                        // show wrong mark
                                        <div className="col-4">
                                          <div className="cursor-pointer relative-pos">
                                            <div className="optionItem1">
                                              <img
                                                src={
                                                  op.optionImage !== ""
                                                    ? op.optionImage
                                                    : require("../../../../../../assets/images/fullImage.png")
                                                }
                                                className="inheritHeight br-10"
                                                alt="alphatub"
                                              />
                                            </div>
                                            <div className="wrong-answer-pos">
                                              <img
                                                src={require("../../../../../../assets/images/wronganswer.png")}
                                                className="wrong-answer-img"
                                                alt="alphatub"
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      ) : (
                                        // blur
                                        <div className="col-4">
                                          <div className="cursor-pointer">
                                            <div className="optionItem1">
                                              <img
                                                src={
                                                  op.optionImage !== ""
                                                    ? op.optionImage
                                                    : require("../../../../../../assets/images/fullImage.png")
                                                }
                                                className="inheritHeight mo-ind-wr-answer br-10"
                                                alt="alphatub"
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      )
                                    ) : (
                                      <div className="col-4">
                                        <div
                                          className="cursor-pointer"
                                          onClick={() =>
                                            this.selectedOption(dt, op)
                                          }
                                        >
                                          <div className="optionItem1">
                                            <img
                                              src={
                                                op.optionImage !== ""
                                                  ? op.optionImage
                                                  : require("../../../../../../assets/images/fullImage.png")
                                              }
                                              className="inheritHeight br-10"
                                              alt="alphatub"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </>
                                ))}
                            </div>
                          </div>
                        </div>
                        {dt.isOptionSelected && (
                          <ArrowRight
                            className={"game_option_arrow"}
                            next={this.clickNext}
                          />
                        )}
                      </>
                    ))}
                </Carousel>
              </div>
            </div>
          </div>
          <GamePlayers
            selectedMode={selectedMode}
            {...this.props}
            currentPlayer={getCurrentPlayer}
          />
          {game.isSelectedGameBackBtn && (
            <CreateModel
              isOpen={game.isSelectedGameBackBtn}
              toggle={() => this.closeGame()}
              title={""}
            >
              <div className="alphatub-card-border"></div>
              <div className="row pt-3 pb-5">
                <div className="col-12">
                  <p className="f-16px alphatub-card-normal-p">
                    Are you sure, you want to quit the game ?
                  </p>
                </div>
              </div>
              <div className="alphatub-card-border"></div>
              <div className="row align-items-center mt-3 content-end">
                <div className="col-4">
                  <ButtonType1
                    btnName="Close"
                    className="popup-close-btn"
                    onClick={() => this.closeGame()}
                  />
                </div>
                <div className="col-4">
                  <ButtonType1
                    btnName="Confirm"
                    className="bg-tub c-white buttonType1 "
                    onClick={() => this.quitGame()}
                  />
                </div>
              </div>
            </CreateModel>
          )}
        </>
      </div>
    );
  }
}
const mapStateToProps = ({ game }) => {
  return {
    game,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    gameAction: bindActionCreators(gameAction, dispatch),
    dispatch,
  };
};

export const ETub = ClassPresentationWrapper(
  connect(mapStateToProps, mapDispatchToProps)(ETubClass)
);
