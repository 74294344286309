import { useEffect, useState } from "react"
import { CreateModel } from "../Model/createModel"
import Calender from 'react-calendar';
import ButtonType1 from "../inputs/ButtonType1";
import { differenceInCalendarDays } from 'date-fns';

var days = 15
export const CommonDatePopup = ({showDatePopup,triggerPopup,onClick, session, title, mindate}) => {
    const [minDate, setMinDate] = useState()
    const [maxDate, setMaxDate] = useState()
    const [workingDaysOptions] = useState([
        {
            label: "S",
            value: "Sun",
            checked: false,
            index: 0
        },
        {
            label: "M",
            value: "Mon",
            checked: false,
            index: 1
        },
        {
            label: "T",
            value: "Tue",
            checked: false,
            index: 2
        },
        {
            label: "W",
            value: "Wed",
            checked: false,
            index: 3
        },
        {
            label: "T",
            value: "Thu",
            checked: false,
            index: 4
        },
        {
            label: "F",
            value: "Fri",
            checked: false,
            index: 5
        },
        {
            label: "S",
            value: "Sat",
            checked: false,
            index: 6
        },
    
    ])
    const [contentSelectedDate, setContentSelectedDate] = useState(new Date())

    useEffect(() => {
        setMinDate(mindate != undefined ? new Date(mindate) : new Date())
    }, [mindate])
    
    const isSameDay = (disDate, date) => {
        return differenceInCalendarDays(disDate, date) === 0;
    }
    const tileDisabled = ({ date, view }) => {
        let getDisableDates = [],filteredDays
        if (session.sessionDisableDatesList?.length > 0) {
            getDisableDates = session.sessionDisableDatesList.find(dDate => isSameDay(dDate, date))
        }
        if(session.schoolAcademicYear != null){
            let filteredIndexes = workingDaysOptions.filter(fl => !session.schoolAcademicYear.days.includes(fl.value)).map(f => f.index)
            if (filteredIndexes?.length > 0) {
                filteredDays = filteredIndexes.includes(date.getDay());
            } 
        }
        if(session.sessionDisableDatesList?.length > 0){
            return filteredDays || getDisableDates
        } else {
            return filteredDays
        }
    }
    const onChangeDate = (value) => {
        setContentSelectedDate(value)
    }
    return (
        <CreateModel
            isOpen={showDatePopup}
            toggle={() => triggerPopup(false)}
            title={title}
        >
            <div className="row pt-3 pb-5">
                <div className="col-12 session-calendar gallery-cal">
                    <Calender
                        onChange={(value) => onChangeDate(value)}
                        value={contentSelectedDate}
                        selectRange={false}
                        minDate={minDate}
                        maxDate={maxDate}
                        tileDisabled={tileDisabled}
                    />
                </div>
            </div>
            <div className="row align-items-center mt-3 content-end">
                <div className="col-4">
                    <ButtonType1
                        btnName="Close"
                        className='popup-close-btn'
                        onClick={() => triggerPopup(false)}
                    />
                </div>
                <div className="col-4">
                    <ButtonType1
                        btnName="Confirm"
                        className='bg-tub c-white buttonType1 '
                        onClick={() => onClick(contentSelectedDate)}
                    />
                </div>
            </div>
        </CreateModel>
    )
}