import { React, Component } from 'react';
import { connect } from 'react-redux'
import * as gameAction from '../../../../../actions/game';
import { bindActionCreators } from "redux";
import { getGamePath, history } from '../../../../../helpers/index'
import { main } from '../../../../../service/actionType';
import toast from '../../../../../helpers/Toast';
import ClassPresentationWrapper from '../../../../../Hoc/wrapper'

class SelectGameClass extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            screen: 1
        };
    }

    redirectToMode = (type) => {
        let isReviewTubClass = sessionStorage.getItem('reviewTubClass')
        if(isReviewTubClass == 'true'){
            let res = this.checkForGameType(type)
            if(res){
                this.selectedAllPlay(false)
            }
        } else {
            let res = this.checkForGameType(type)
            if(res){
                this.setState({
                    screen: 2
                })
            }
        }

    }
    checkForGameType = (type) => {
        let selectedObject = sessionStorage.getItem('selectedObject')
        if(selectedObject === 'Word' && (type == "2" || type == "1" || type == "3")){
            toast('This game cannot be played with word collection', 'error')
            return false
        } else if(selectedObject === 'Letter' && type === '2'){
            toast('This game cannot be played with Letter collection', 'error')
            return false
        } else {
            if(type == '1'){
                this.props.gameAction.selectedGame(1)
                this.props.dispatch({type: main.USER_SELECTED_GAME, payload: { gameName: "Match The Object"}})
            } else if(type == '2'){
                this.props.gameAction.selectedGame(2)
                this.props.dispatch({type: main.USER_SELECTED_GAME, payload: { gameName: "Find The First Letter"}})
            } else if(type === "3"){
                this.props.gameAction.selectedGame(3)
                this.props.dispatch({type: main.USER_SELECTED_GAME, payload: { gameName: "What's The Good Word"}})
            }
        return true
        }
    }
    selectedAllPlay = async (isAttendanceSelected) => {
        await this.props.gameAction.selectedMode(4)
        this.props.dispatch({type: main.USER_SELECTED_GAME , payload: { gameMode: "All Play"}})
        if(isAttendanceSelected){
            await this.props.gameAction.getPlayersList()
        } else {
            await this.props.gameAction.getAllPlayersList()
        }
        let selectedGame = sessionStorage.getItem('selectedGame')
        let path = getGamePath(parseInt(selectedGame))
        await this.props.gameAction.storeGameSettings(path, null)
    }
    redirectToPlayerSelection = (type) => {
        let path = ''
        if(type === 'All Play'){
            this.selectedAllPlay(true)
        } else if(type === "Individual Play"){
            this.props.gameAction.selectedMode(1)
            this.props.dispatch({type: main.USER_SELECTED_GAME , payload: { gameMode: "Individual Play"}})
            path = '/class/presentation/select/individual/players'
        } else if(type === "Random Play"){
            this.props.gameAction.selectedMode(2)
            this.props.dispatch({type: main.USER_SELECTED_GAME, payload: { gameMode: "Random Play"}})
            path = '/class/presentation/select/random/players'
        } else if(type === "Group Play"){
            this.props.gameAction.selectedMode(3)
            this.props.dispatch({type: main.USER_SELECTED_GAME, payload: { gameMode: "Group Play"}})
            path = '/class/presentation/select/group/players'
        }
        history.push({
            pathname: path
        })
    }

    render() {
        let { game } = this.props
        let {screen} = this.state
        return (
            <>
            {screen === 1 ?
                <>
                    <div className='d-flex align-items-center'>
                        <div className='container'>
                            <div className="row">
                                <div className="col-12">
                                    <div className="mt-2">
                                        <p className="clp_heading1  text-center">Select a Game</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-5">
                                <div className="col-12">
                                    <div className="pt-3 pb-4 plr-35 row">
                                        {game.game.gameType.map((gm,i) => (
                                            <div className="col-4" key={i}>
                                                <div className='cursor-pointer' onClick={() => this.redirectToMode(gm.gameId)}>
                                                    <div className="relative-pos">
                                                        <img
                                                            src={
                                                                gm.typeName
                                                            }
                                                            className="w-100 inheritHeight"
                                                            alt="alphatub"
                                                        />
                                                    </div>
                                                    <div className="mt-2">
                                                        <p className="clp-gameTypes  text-center">{gm.typeId}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='disclaimer_container'>
                    <p>Disclaimer: All TUB Games are based on personalized data. Choosing identical content for gameplay can lead to reduced game experience.</p>                              
                    </div>
                </>
                :
                <>
                    <div class="d-flex align-items-center">
                        <div className='container'>
                            <div className="row">
                                <div className="col-12">
                                    <div className="mt-2">
                                        <p className="clp_heading1  text-center">Select a Mode</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-5">
                                <div className="col-12">
                                    <div className="pt-3 pb-4 row">
                                        {game.game.gameMode.map((gm,i) => (
                                            <div className="col-3" key={i}>
                                                <div className='game_mode_container' onClick={() => this.redirectToPlayerSelection(gm.typeId)}>
                                                    <p className="clp-gameMode  text-center">{gm.typeId}</p>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
        }
            </>
        )
    }
}

const mapStateToProps = ({ game }) => {
    return {
        game
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        gameAction : bindActionCreators(gameAction, dispatch),
        dispatch
    }
};

export const SelectGame = ClassPresentationWrapper(connect(mapStateToProps, mapDispatchToProps)(SelectGameClass))

