import { React, Component } from 'react';
import Calendar from './calender';
import moment from 'moment'
import PurchasedCard from '../../../../components/subscriptionPlan/purchasedCard';

class ClassLeftComponent extends Component {
    constructor(props){
        super(props)
        this.state = { 
        };
    }
   
    render() {
        let { 
            academicYear,
            isStudentPage,
            isClassPage, 
            disabledDates, 
            calendarCompleteList, 
            isShowToggler, 
            selectedDateRange, 
            toggleDate, 
            selectedMonth, 
            disabled, 
            subscriptionInfo,
            selectedClassDetail,
            teacherCalendarDates,
            studentCalendar,
            showBack
        } = this.props
        return (
            <div className="col-md-3 ">
                { showBack &&
                    <div className="col-md-12 mb-3 mt-1">
                        {/* <div className="row no-gutters">
                            <img src={require('../../../../assets/images/left-arrow.svg').default} className="back-btn" onClick={() => this.props.onNavigate(1, null)}  alt="alphatub"/>
                            <p className="global_color privacy-content cursor-pointer ml-3" onClick={() => this.props.onNavigate(1, null)} >Back To Classes</p>
                        </div> */}
                    </div>
                }
                <div className="class_left_container">
                    {isClassPage &&
                        <>
                            <div className="alphatub-card">
                                <div className="py-3 px-2">
                                    <div className="row no-gutters space-between">
                                        <h6 className="alphatub-card-h6">School Academic Year</h6>
                                        {/* <p 
                                            className={disabled ? "disabledText" : "alphatub-card-normal-link"}
                                            onClick={disabled ? () => this.props.triggerPopup("showAcademicYearPopup", true) : null}
                                        >
                                            Edit
                                        </p> */}
                                    </div>
                                    <div className="mt-3">
                                        <div className="row no-gutters space-between">
                                            <h6 className="alp_card_p">{academicYear?.start_Date == null ? '-' : moment(academicYear?.start_Date).format("YYYY-MM-DD")}</h6>
                                            <h6 className="alp_card_p">To</h6>
                                            <h6 className="alp_card_p">{academicYear?.end_Date == null ? '-' : moment(academicYear?.end_Date).format("YYYY-MM-DD")}</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-3">
                                <div className="mb-3">
                                    <h6 className="alphatub-card-h6">School Calender</h6>
                                </div>
                                <Calendar 
                                    triggerPopup={this.props.triggerPopup}
                                    disabled={disabled}
                                    isStudentPage={false}
                                    isShowToggler={isShowToggler}
                                    toggleDate={toggleDate}
                                    selectedMonth={selectedMonth}
                                    selectedDateRange={selectedDateRange}
                                    disabledDates={disabledDates}
                                    onChangeDate={(value) => this.props.onChangeDate(value, 1)}
                                    schoolAcademicYear={academicYear}
                                    calendarCompleteList={calendarCompleteList}
                                    saveCalendar={this.props.saveCalendar}
                                    tileDisabled={this.props.tileDisabled}
                                    changetheMonth={(value) => this.props.changeMonth(value, 1)}
                                    onChangeReason={(...params) => this.props.onChangeReason(...params, 1)}
                                />
                            </div>
                            {!disabled &&
                                <div className="mt-3">
                                    <PurchasedCard disabled={disabled} subscriptionInfo={subscriptionInfo} triggerPopup={this.props.triggerPopup}/>
                                </div>
                            }
                        </>
                    }
                    {/* {isStudentPage &&
                        <>
                            <div className="alphatub-card">
                                <div className="alphatub-card-top-spacing">
                                    <div className="row no-gutters space-between">
                                        <div className="col-4">
                                            <h1>{selectedClassDetail?.class_Name } ({selectedClassDetail?.section_Name})</h1>
                                        </div>
                                        <div className="col-4">
                                            <div className="row no-gutters align-items-center content-end">
                                                <div className={`${selectedClassDetail?.status === "Active" ? "roundBlue" : "roundRed" } mr-1`}></div>
                                                <p>{selectedClassDetail?.status}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="alphatub-card-border"></div>
                                    <div className="row no-gutters space-between mt-2">
                                        <p className="alphatub-card-normal-p">Reading Program </p>
                                        <p className="alphatub-card-bold-p">{selectedClassDetail?.reading_Program_Id?.program_Name}</p>
                                    </div>
                                    <div className="row no-gutters space-between mt-2">
                                        <p className="alphatub-card-normal-p">Allocated Seats </p>
                                        <p className="alphatub-card-bold-p">{selectedClassDetail?.allocated_Seats}</p>
                                    </div>
                                    <div className="row no-gutters space-between mt-2">
                                        <p className="alphatub-card-normal-p">Class Teacher</p>
                                        <p className="alphatub-card-bold-p">{selectedClassDetail?.teachers && selectedClassDetail?.teachers[0]?.label}</p>
                                    </div>
                                    <div className="row no-gutters space-between mt-2">
                                        <p className="alphatub-card-normal-p">Substitute Teacher</p>
                                        <p className="alphatub-card-bold-p">{selectedClassDetail?.substituteTeachers && selectedClassDetail?.substituteTeachers[0]?.label}</p>
                                    </div>
                                    <div className="row no-gutters space-between mt-2">
                                        <p className="alphatub-card-normal-p">Volunteer</p>
                                        <p className="alphatub-card-bold-p">---------</p>
                                    </div>
                                    <div className="row no-gutters space-between mt-2">
                                        <p className="alphatub-card-normal-p">Trainee</p>
                                        <p className="alphatub-card-bold-p">{selectedClassDetail?.trainees != null && selectedClassDetail?.trainees ? selectedClassDetail?.trainees[0]?.label : "---------"}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-3">
                                <div className="mb-3">
                                    <h6 className="alphatub-card-h6">Manage {selectedClassDetail.class_Name} Class Calendar</h6>
                                </div>
                                <Calendar 
                                    disabled={disabled}
                                    isStudentPage={true}
                                    isShowToggler={studentCalendar.isShowToggler}
                                    toggleDate={studentCalendar.studentToggleDate}
                                    selectedDateRange={studentCalendar.selectedStudentDateRange}
                                    disabledDates={disabledDates}
                                    teacherCalendarDates={teacherCalendarDates}
                                    schoolAcademicYear={academicYear}
                                    completeTeacherCalendarList={studentCalendar.completeTeacherCalendarList}
                                    saveTeacherCalendar={this.props.saveTeacherCalendar}
                                    studentCalendarTileDisabled={this.props.studentCalendarTileDisabled}
                                    changetheMonth={(value) => this.props.changeMonth(value, 2)}
                                    onChangeDate={(value) => this.props.onChangeDate(value, 2)}
                                    onChangeReason={(...params) => this.props.onChangeReason(...params, 2)}
                                />
                            </div>
                        </>
                    } */}

                    
                    
                </div>
            </div>
        )
    }
}


// const ClassLeftComponent = connect(null, null)(classLeftComponentClass)

export default ClassLeftComponent 