import React, { useEffect, useState } from 'react';
import { accessHeaders } from '../../helpers';
import { API } from 'aws-amplify';
import { ENVIRONMENT } from '../../common/constants';
import FormInput from '../../components/inputs/FormInput';

let templateData = [
    {
      "stencilImage": {
        "original": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_7434680891132.png",
        "thumbnail": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_7434680891132.png"
      },
    "alphabetName": "A"
    },
    {
      "stencilImage": {
        "original": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_6194119907633.png",
        "thumbnail": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_6194119907633.png"
      },
       "alphabetName": "B"
    },
    {
      "stencilImage": {
        "original": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_7999382794595.png",
        "thumbnail": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_7999382794595.png"
      },
       "alphabetName": "C"
    },
    {
      "stencilImage": {
        "original": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_2355419504753.png",
        "thumbnail": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_2355419504753.png"
      },
       "alphabetName": "D"
    },
    {
      "stencilImage": {
        "original": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_2839176645093.png",
        "thumbnail": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_2839176645093.png"
      },
       "alphabetName": "E"
    },
    {
      "stencilImage": {
        "original": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_9531919999261.png",
        "thumbnail": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_9531919999261.png"
      },
      
      "alphabetName": "F"
    },
    {
      "stencilImage": {
        "original": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_6664649112192.png",
        "thumbnail": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_6664649112192.png"
      },
  
      "alphabetName": "G"
    },
    {
      "stencilImage": {
        "original": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_1609776497592.png",
        "thumbnail": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_1609776497592.png"
      },
  
      "alphabetName": "H"
    },
    {
      "stencilImage": {
        "original": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_1779615999440.png",
        "thumbnail": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_1779615999440.png"
      },
       "alphabetName": "I"
    },
    {
      "stencilImage": {
        "original": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_7293351637223.png",
        "thumbnail": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_7293351637223.png"
      },
       "alphabetName": "J"
    },
    {
      "stencilImage": {
        "original": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_5316365070587.png",
        "thumbnail": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_5316365070587.png"
      },
       "alphabetName": "K"
    },
    {
      "stencilImage": {
        "original": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_4988269700428.png",
        "thumbnail": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_4988269700428.png"
      },
       "alphabetName": "L"
    },
    {
      "stencilImage": {
        "original": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_7767693220186.png",
        "thumbnail": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_7767693220186.png"
      },
       "alphabetName": "M"
    },
    {
      "stencilImage": {
        "original": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_4484263990806.png",
        "thumbnail": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_4484263990806.png"
      },
  
      "alphabetName": "N"
    },
    {
      "stencilImage": {
        "original": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_3687316189967.png",
        "thumbnail": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_3687316189967.png"
      },
  
      "alphabetName": "O"
    },
    {
      "stencilImage": {
        "original": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_281985584616.png",
        "thumbnail": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_281985584616.png"
      },
       "alphabetName": "P"
    },
    {
      "stencilImage": {
        "original": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_5991491925056.png",
        "thumbnail": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_5991491925056.png"
      },
       "alphabetName": "Q"
    },
    {
      "stencilImage": {
        "original": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_725857606841.png",
        "thumbnail": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_725857606841.png"
      },
       "alphabetName": "R"
    },
    {
      "stencilImage": {
        "original": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_1762037198112.png",
        "thumbnail": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_1762037198112.png"
      },
       "alphabetName": "S"
    },
    {
      "stencilImage": {
        "original": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_3772998262112.png",
        "thumbnail": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_3772998262112.png"
      },
       "alphabetName": "T"
    },
    {
      "stencilImage": {
        "original": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_8868602215171.png",
        "thumbnail": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_8868602215171.png"
      },
  
      "alphabetName": "U"
    },
    {
      "stencilImage": {
        "original": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_9194360141960.png",
        "thumbnail": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_9194360141960.png"
      },
  
      "alphabetName": "V"
    },
    {
      "stencilImage": {
        "original": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_6786152969940.png",
        "thumbnail": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_6786152969940.png"
      },
       "alphabetName": "W"
    },
    {
      "stencilImage": {
        "original": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_2958395729464.png",
        "thumbnail": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_2958395729464.png"
      },
       "alphabetName": "X"
    },
    {
      "stencilImage": {
        "original": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_3976354170542.png",
        "thumbnail": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_3976354170542.png"
      },
       "alphabetName": "Y"
    },
    {
      "stencilImage": {
        "original": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_562850494695.png",
        "thumbnail": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_562850494695.png"
      },
       "alphabetName": "Z"
    }
]

let defaultObj = {
    "languageId": "61d6d22f50e72435d4833026",
            tags: [],
            title: '',
            imageName: '',
            originalImage: {
                original: '',
                thumbnail: ''
            },
            stencilImage: {
                original: '',
                thumbnail: ''
            },
            UserName: '',
            imageOf: 'iconic_gallery_images',
            word_Category: 'createdBy_Alphatub',
            word_Classification: [],
            description: ''
}

export const AdminAddGalleryTemplate = () => {
    const [data, setData] = useState({
        "languageId": "61d6d22f50e72435d4833026",
        tags: [],
        title: null,
        imageName: null,
        originalImage: {
            original: '',
            thumbnail: ''
        },
        stencilImage: {
            original: '',
            thumbnail: ''
        },
        UserName: '',
        imageOf: 'iconic_gallery_images',
        word_Category: 'createdBy_Alphatub',
        word_Classification: [],
        description: ''
    })
    const [alphabetsDropdown] = useState([
        {
            label:'A',
            value: 'A'
        },
        {
            label:'B',
            value: 'B'
        },
        {
            label:'C',
            value: 'C'
        },
        {
            label:'D',
            value: 'D'
        },
        {
            label:'E',
            value: 'E'
        },
        {
            label:'F',
            value: 'F'
        },
        {
            label:'G',
            value: 'G'
        },
        {
            label:'H',
            value: 'H'
        },
        {
            label:'I',
            value: 'I'
        },
        {
            label:'J',
            value: 'J'
        },
        {
            label:'K',
            value: 'K'
        },
        {
            label:'L',
            value: 'L'
        },
        {
            label:'M',
            value: 'M'
        },
        {
            label:'N',
            value: 'N'
        },
        {
            label:'O',
            value: 'O'
        },
        {
            label:'P',
            value: 'P'
        },
        {
            label:'Q',
            value: 'Q'
        },
        {
            label:'R',
            value: 'R'
        },
        {
            label:'S',
            value: 'S'
        },
        {
            label:'T',
            value: 'T'
        },
        {
            label:'U',
            value: 'U'
        },
        {
            label:'V',
            value: 'V'
        },
        {
            label:'W',
            value: 'W'
        },
        {
            label:'X',
            value: 'X'
        },
        {
            label:'Y',
            value: 'Y'
        },
        {
            label:'Z',
            value: 'Z'
        }
    ])
    

    const replacePhoto = async (file) => {
        let formData = new FormData()
        formData.append('image', file)
        try  { 
            let url = "user/uploadImage";
            const myInit = {
                body: formData,
                headers: accessHeaders()
            };
            const response = await API.post(ENVIRONMENT, url, myInit)
            if (response.status === 200 && response.message !== "Invalid Image.") {
                setData({
                    ...data,
                    originalImage: {
                        original: response.data?.originalImage?.original,
                        thumbnail: response.data?.originalImage?.original
                    }
                })
            }
         } catch(error) {
            console.log('error', error)
        }
    }
    const checkForStencilImage = (name, value) => {
        if(name == 'imageName'){
            let getStencil = templateData.find(np => np.alphabetName == value)
            if(getStencil){
                setData({...data, "stencilImage": {...data['stencilImage'], original: getStencil?.original, thumbnail: getStencil?.thumbnail}})
            }
        }
    }
    const onChange = async (name, value) => {
        let profile = {...data}
        profile[name] = value
        setData({...data, ...profile})
    }

    const saveProfile = async () => {
        let payload = {
            ...data,
            UserName: localStorage.getItem('username'),
            tags: data.tags && data.tags.split(','),
            word_Classification: data.word_Classification && data.word_Classification.split(',')
        }
        let getStencil = templateData.find(np => np.alphabetName == data.imageName)
        if(getStencil){
            payload["stencilImage"]  =  {original: getStencil?.stencilImage.original, thumbnail: getStencil?.stencilImage.thumbnail}
        }
        let url = 'admin_panel/v3.7/curriculum/addEditGallery';
        const myInit = {
            body: payload,
            headers: accessHeaders()
        };
        console.log('payload',payload)
        const response = await API.post(ENVIRONMENT, url, myInit)
        console.log('response',response)
        setData({...data,...defaultObj})
    }
    return (
        <div className='container mt-4'>
            <div className="row">
                <div className="col-md-6">
                    <FormInput
                        type="select"
                        label="Alphabet"
                        name="imageName"
                        options={alphabetsDropdown}
                        onChange={(...params) => onChange(...params, null)}
                        value={data?.imageName}
                        disabled={false}
                    />
                </div>
                <div className="col-md-6">
                    <FormInput
                        type="text"
                        label="Title"
                        name="title"
                        placeholder={'Title'}
                        onChange={(...params) => onChange(...params, null)}
                        value={data?.title}
                        disabled={false}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <FormInput
                        type="text"
                        label="Tags"
                        name="tags"
                        onChange={(...params) => onChange(...params, null)}
                        value={data?.tags}
                        disabled={false}
                        spantext={'comma seperated values'}
                    />
                </div>
                <div className="col-md-6">
                    <FormInput
                        type="text"
                        label="Description"
                        name="description"
                        placeholder={'Description'}
                        onChange={(...params) => onChange(...params, null)}
                        value={data?.description}
                        disabled={false}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <FormInput
                        type="text"
                        label="Word Classification"
                        name="word_Classification"
                        onChange={(...params) => onChange(...params, null)}
                        value={data?.word_Classification}
                        disabled={false}
                        spantext={'comma seperated values'}
                    />
                </div>
            </div>
            <div className='row'>
                <div className='col-md-6'>
                    {data.originalImage.original != '' &&
                        <div>
                            <img src={data?.originalImage?.original} style={{width: '200px', height:'200px'}}/>
                        </div>
                    }
                    <div className='col-md-4'>
                        <label for="file" className="btn btn-primary small-button">Upload Photo</label>
                        <input 
                        type="file" 
                        id="file" 
                        onChange={({target}) => replacePhoto(target.files[0])}
                        style={{display: 'none'}}
                        />
                    </div>
                </div>
            </div>
            <div className='mt-5'>
                <div className="row no-gutters">
                    <div className="col-md-12 text-right">
                        <button className="btn btn-primary w-200px" onClick={() => saveProfile()}> Save</button>
                    </div>
                </div>
            </div>
        </div>
    )
}