import React, { useEffect, useState } from "react";
import { API } from "aws-amplify";
import { ENVIRONMENT } from "common/constants";
import { accessHeaders } from "../../helpers";
import { Modal, ModalBody, Button, ModalHeader, Table } from "reactstrap";
import moment from "moment";
import "./HierarchyModal.scss";

const HierarchyModal = ({
  isOpen,
  currentUser = {},
  onModalClose,
  getUserLevels,
}) => {
  const [unassignedDirectors, setUnassignedDirectors] = useState([]);
  const [currentUserDetails, setCurrentUserDetails] = useState(currentUser);
  const [parentUserId, setParentUserId] = useState();
  const [schoolList, setSchoolList] = useState();
  const [replaceUserId, setReplaceUserId] = useState(false);
  const currentUserId = localStorage.getItem("userid");
  const toggle = () => onModalClose(!onModalClose);
  const getUnassignedDirectors = async () => {
    try {
      const { data } = await API.get(
        ENVIRONMENT,
        "director/user-levels/child-users/unassigned",
        {
          headers: accessHeaders(),
        }
      );
      setUnassignedDirectors(data);
    } catch (error) {
      console.error("Error fetching unassigned directors:", error);
    }
  };
  const viewBySchool = async () => {
    try {
      const { data } = await API.get(
        ENVIRONMENT,
        "director/school-directors/:schoolId",
        {
          headers: accessHeaders(),
        }
      );
      setSchoolList(data);
      console.log(schoolList,"ggggggg");// console
    } catch (error) {
      console.error("Error fetching unassigned directors:", error);
    }
  };

  const assignCurrentUserId = async (childUserId) => {
    try {
      await API.patch(
        ENVIRONMENT,
        `director/user-levels/${parentUserId}/replace `,
        {
          body: {
            newUserId: childUserId,
          },
          headers: accessHeaders(),
        }
      );
      getUnassignedDirectors();
      getUserLevels(currentUserId); // Refresh user levels
    } catch (error) {
      console.error("Error assigning user ID:", error);
    }
  };

  const CurrentUserId = async (childUserId) => {
    try {
      await API.post(ENVIRONMENT, "director/user-levels", {
        body: {
          parentUserId: currentUserDetails?.userDetails?._id,
          childUserIds: [childUserId],
        },
        headers: accessHeaders(),
      });
      getUnassignedDirectors();
      getUserLevels(currentUserId); // Refresh user levels
    } catch (error) {
      console.error("Error assigning user ID:", error);
    }
  };

  const deactivateCurrentUser = async (userId) => {
    try {
      await API.patch(
        ENVIRONMENT,
        `director/user-levels/${userId}/deactivate`,
        {
          headers: accessHeaders(),
        }
      );
      getUnassignedDirectors();
      getUserLevels(currentUserId); // Refresh user levels
      setCurrentUserDetails((prevState) => ({ ...prevState, isDeleted: true })); // Update currentUserDetails
    } catch (error) {
      console.error("Error deactivating user:", error);
    }
  };

  const fetchActivate = async (userId) => {
    try {
      await API.patch(ENVIRONMENT, `director/user-levels/${userId}/restore`, {
        headers: accessHeaders(),
      });
      getUnassignedDirectors();
      getUserLevels(currentUserId); // Refresh user levels
      setCurrentUserDetails((prevState) => ({
        ...prevState,
        isDeleted: false,
      })); // Update currentUserDetails
    } catch (error) {
      console.error("Error activating user:", error);
    }
  };
  useEffect(() => {
    setCurrentUserDetails(currentUser);
  }, [currentUser]);

  useEffect(() => {
    getUnassignedDirectors();
  }, []);

  return (
    <React.Fragment>
      <Modal className={`pop-up`} isOpen={isOpen} style={{ minWidth: "70%" }}>
        <ModalHeader toggle={toggle}>Director Details</ModalHeader>
        <ModalBody style={{ padding: "10px" }}>
          <Table className="unassigned-users">
            <thead>
              <tr>
                <th style={{ width: "20%" }}>Name</th>
                <th style={{ width: "20%" }}>Role</th>
                <th style={{ width: "20%" }}>Created At</th>
                <th style={{ width: "20%" }}>Email</th>
                <th style={{ width: "20%" }}>School</th>
                <th style={{ width: "20%" }}>Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr
                className={`unassigned-user-values ${
                  currentUserDetails?.isDeleted ? "text-danger" : ""
                }`}
              >
                <td>
                  {currentUserDetails?.userDetails?.First_name}{" "}
                  {currentUserDetails?.userDetails?.Last_name}
                </td>
                <td>{currentUserDetails?.userDetails?.RoleName}</td>
                <td>
                  {moment(currentUserDetails?.userDetails?.createdAt).format(
                    "LL"
                  )}
                </td>
                <td>{currentUserDetails?.userDetails?.Email}</td>
                <td>
                  {currentUserDetails?.userDetails?.teams.map((team, index) => (
                    <span key={index}>
                      {index + 1}. {team?.schools[0]?.SchoolName}.
                      <br />
                    </span>
                  ))}
                </td>

                <td>
                  <div>
                    {currentUserDetails?.isDeleted ? (
                      <>
                        <Button
                          className="w-200px btn btn-primary mb-1"
                          onClick={() => {
                            fetchActivate(currentUserDetails?.userDetails?._id);
                          }}
                        >
                          Active
                        </Button>
                        <Button
                          className="w-200px btn btn-primary"
                          onClick={() => {
                            setParentUserId(
                              currentUserDetails?.userDetails?._id
                            );
                            setReplaceUserId(true);
                          }}
                        >
                          Replace
                        </Button>
                      </>
                    ) : (
                      <Button
                        className="w-200px btn btn-primary"
                        onClick={() => {
                          deactivateCurrentUser(
                            currentUserDetails?.userDetails?._id
                          );
                        }}
                      >
                        Deactivate
                      </Button>
                    )}
                  </div>
                </td>
              </tr>
            </tbody>
          </Table>
          <h1 className="my=3 text-center">List of Unassigned Directors</h1>
          <Table className="unassigned-users">
            <thead>
              <tr className="">
                <th style={{ width: "20%" }}>Name</th>
                <th style={{ width: "20%" }}>Role</th>
                <th style={{ width: "20%" }}>Created At</th>
                <th style={{ width: "20%" }}>Email</th>
                <th style={{ width: "20%" }}>School Name</th>
                <th style={{ width: "20%" }}>Actions</th>
              </tr>
            </thead>
            <tbody>
              {" "}
              {Array.isArray(unassignedDirectors) &&
              unassignedDirectors.length ? (
                unassignedDirectors.map((unassignedDirector, index) => (
                  <tr
                    className="unassigned-user-values"
                    key={unassignedDirector._id}
                  >
                    {/* <td>{index + 1}</td> */}
                    <td>
                      {unassignedDirector.First_name}{" "}
                      {unassignedDirector.Last_name}
                    </td>
                    <td>{unassignedDirector.RoleName}</td>
                    <td>{moment(unassignedDirector.createdAt).format("LL")}</td>
                    <td>{unassignedDirector.Email}</td>
                    {/* <td>{unassignedDirector?.teams[0]?.schools[0]?.SchoolName}</td> */}
                    <td>
                      {unassignedDirector?.teams.map((team, index) => (
                        <span key={index}>
                          {index + 1}. {team?.schools[0]?.SchoolName}.
                          <br />
                        </span>
                      ))}
                    </td>
                    <td>
                      <div className="table-actions">
                        {replaceUserId === false ? (
                          <button
                            className="w-200px btn btn-primary"
                            onClick={() =>
                              CurrentUserId(unassignedDirector._id)
                            }
                          >
                            Add
                          </button>
                        ) : (
                          <button
                            className="w-200px btn btn-primary"
                            onClick={() =>
                              assignCurrentUserId(unassignedDirector._id)
                            }
                          >
                            Replace With
                          </button>
                        )}
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="12" className="text-center py-4">
                    No data available
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export { HierarchyModal };
