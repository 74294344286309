import React, { useState, useRef } from 'react'
import ReactCrop, {
  centerCrop,
  makeAspectCrop,
  Crop,
  PixelCrop,
} from 'react-image-crop'
import { canvasPreview } from './canvasPreview'
import { useDebounceEffect } from './useDebounceEffect'
import { CreateModel } from '../Model/createModel';
import 'react-image-crop/dist/ReactCrop.css'
import ButtonType1 from '../inputs/ButtonType1';

// This is to demonstate how to make and center a % aspect crop
// which is a bit trickier so we use some helper functions.
function centerAspectCrop(mediaWidth,mediaHeight,aspect) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        width: 25,
      },
      aspect,
      mediaWidth,
      mediaHeight,
    ),
    mediaWidth,
    mediaHeight,
  )
}

export default function ImageCrop(props) {
  const [imgSrc, setImgSrc] = useState('')
  const previewCanvasRef = useRef(null)
  const imgRef = useRef(null)
  const [showCropPopup, toggleCropPopup] = useState(false);
  const [crop, setCrop] = useState()
  const [completedCrop, setCompletedCrop] = useState(false)
  const [scale, setScale] = useState(1)
  const [rotate, setRotate] = useState(0)
  const [aspect, setAspect] = useState(1)
  const [loading, setLoading] = useState(false)

  const onSelectFile = (e) => {
    setLoading(true)
    if (e.target.files && e.target.files?.length > 0) {
      const reader = new FileReader()
      reader.addEventListener('load', () => {
          setImgSrc(reader.result.toString() || '')
          toggleCropPopup(true)
          setCompletedCrop(true)
        }
      )
      reader.readAsDataURL(e.target.files[0])
      setLoading(false)
    }
  }

  function onImageLoad(e) {
    if (aspect) {
      const { width, height } = e.currentTarget
      setCrop(centerAspectCrop(width, height, aspect))
    }
  }

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
          rotate,
        )
      }
    },
    100,
    [completedCrop, scale, rotate],
  )

  // function handleToggleAspectClick() {
  //   if (aspect) {
  //     setAspect(undefined)
  //   } else if (imgRef.current) {
  //     const { width, height } = imgRef.current
  //     setAspect(16 / 9)
  //     setCrop(centerAspectCrop(width, height, 16 / 9))
  //   }
  // }
  function dataURLtoBlob(dataurl) {
    var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), n = bstr?.length, u8arr = new Uint8Array(n);
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], {type:mime});
  }
  const saveProfilePic = async () => {
    var canvas1 = document.getElementById("canvasId"); 
    var dataImg = canvas1.toDataURL("image/png");
    var blob = dataURLtoBlob(dataImg)
    let fileType = blob.type.split('/')[1]
    const myFile = new File([blob], `image.${fileType}`, {
      type: blob.type,
    });
    let response = await props.saveProfileImage(myFile)
    if(response){
      setImgSrc('')
      setCompletedCrop(false)
    }
    toggleCropPopup(false)
  }
  const removePhoto = () => {
    setImgSrc('')
    setCompletedCrop(false)
    toggleCropPopup(false)
    props.removePhoto()
  }
  const closePopup = () => {
    setImgSrc('')
    setCompletedCrop(false)
    toggleCropPopup(false)
  }
  return (
    <>
    <div className="text-center profile_image_container">
        {completedCrop ?
          <canvas
            id="canvasId"
            ref={previewCanvasRef}
            style={{
              // border: '1px solid black',
              // objectFit: 'contain',
              borderRadius: '50%',
              width: "150px",
              height: "150px"
            }}
          />
        :
          <img
              className="avatar"
              src={props.profileDetails.isObjectUrlImage
                  ? props.profileDetails.Profile_img.original :
                  require('../../assets/images/noImage.png')} 
              alt="alphatub"
          />
        }
        
    </div>
    <div className="col-md-12 mt-3">
        <div className="row space-evenly no-gutters">
            {props.profileDetails.isObjectUrlImage ?
                <>
                    <div>
                        <label for="file" className="btn btn-primary small-button">Replace Photo</label>
                        <input 
                          type="file" 
                          id="file" 
                          onChange={(e) => onSelectFile(e)} 
                          style={{display: 'none'}}
                          onClick={(event)=> { 
                            event.target.value = null
                          }}
                        />
                    </div>
                    <div>
                        <button onClick={() => removePhoto()} className="btn btn-primary small-button">Remove Photo</button>
                    </div>
                </>
              :
                <div>
                    <label for="file" className="btn btn-primary small-button">Upload Photo</label>
                    {!loading &&
                    <input 
                      type="file" 
                      id="file" 
                      onChange={(e) => onSelectFile(e)} 
                      onClick={(event)=> { 
                        event.target.value = null
                      }}
                      style={{display: 'none'}}
                    />
                }
                </div>
            }
        </div>
    </div>
      
      <CreateModel
          isOpen={showCropPopup}
          toggle={() => closePopup()}
          title={"Crop Profile Photo"}
          className="mx-900 profile_popup image_crop_popup"
      >
        {imgSrc && (
          <ReactCrop
            crop={crop}
            circularCrop={true}
            onChange={(_, percentCrop) => setCrop(percentCrop)}
            onComplete={(c) => setCompletedCrop(c)}
            aspect={aspect}
          >
            <img
              ref={imgRef}
              alt="Crop me"
              src={imgSrc}
              style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}
              onLoad={onImageLoad}
            />
          </ReactCrop>
        )}
        <div className="row align-items-center mt-3 content-end w-100">
            <div className="w_130px">
                <ButtonType1
                    btnName="Close"
                    className='popup-close-btn'
                    onClick={() => closePopup()}
                />
            </div>
            <div className="w_130px ml-2">
                <ButtonType1
                    btnName="Confirm"
                    className='buttonType1'
                    onClick={() => saveProfilePic()}
                />
            </div>
        </div>
        <div>
          
        </div>
      </CreateModel>
    </>
  )
}
