import { React, Component } from "react";
import ReactSelect from "../../../../components/inputs/ReactSelect";
import ButtonType2 from "../../../../components/inputs/ButtonType2";
import { CustomBootstrapTable } from "../../../../components/Table/CustomBootstrapTable";
import { ClassTableColumns } from "../../../../helpers/tableDataMapper";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import toast from "../../../../helpers/Toast";

const ClassTable = (props) => {
  let {
    loader,
    classes,
    classFilterDropdown,
    classQueryPayload,
    disabled,
    onChangeStatusFilter,
    triggerClassPopup,
    selectedClassDetail,
    pagination,
  } = props;

  const exportData = () => {
    let { classes } = props;
    if (
      classes &&
      classes !== undefined &&
      classes.classTableList?.items &&
      classes.classTableList?.items !== undefined &&
      classes.classTableList?.items?.length > 0
    ) {
      let classdata = classes.classTableList.items;
      let newArray = [];
      classdata.map((data) => {
        newArray.push({
          class_Name: data.class_Name,
          teachers:
            data.teachers && data.teachers.map((dt) => dt.label).join(","),
          allocated_Seats: data.allocated_Seats,
          Program: data.reading_Program_Id?.program_Name,
          // section_Name: data.section_Name,

          // substituteTeachers:
          //     data.substituteTeachers &&
          //     data.substituteTeachers.map((dt) => dt.label).join(","),
          // trainees:
          //     data.trainees != null
          //         ? data.trainees && data.trainees.map((dt) => dt.label).join(",")
          //         : null,
        });
      });

      const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const fileName = "Class details";
      const fileExtension = ".xlsx";
      const ws = XLSX.utils.json_to_sheet(newArray);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);
    } else {
      toast("Please create class to export", "error");
    }
  };

  const getTableColumns = (disabled) => {
    let action = {
      text: "Action",
      dataField: "action",
      headerClasses: "columnHeader",
      formatter: (cellContent, row, rowIndex) => {
        return (
          <div className="">
            <div className="ml-2">
              <div className="class_tooltip position_relative">
                <img
                  src={require("../../../../assets/images/rightAction.png")}
                  onClick={() => props?.onNavigate(3, row)}
                  className="action-btn "
                  alt="alphatub"
                />
                <span class="tooltiptext">Enter</span>
              </div>
            </div>
          </div>
        );
      },
    };
    return [...ClassTableColumns, action];
  };

  console.log(classes, "classes");
  return (
    <div className="col-md-9 classTable">
      <div className="row no-gutters">
        <div className="col-10">
          <h3 className="login-text-login text-left">Classes</h3>
        </div>

        <div className="col-2 justify-end">
          <div className="row no-gutters">
            <img
              src={require("../../../../assets/images/left-arrow.svg").default}
              className="back-btn"
              onClick={() => props.onNavigate(1, null)}
              alt="alphatub"
            />
            <p
              className="global_color privacy-content cursor-pointer ml-3"
              onClick={() => props.onNavigate(1, null)}
            >
              Back To Schools
            </p>
          </div>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-lg-3 col-6 col-xl-3">
          <div className="row no-gutters">
            <div className="col-12 custom-reselect1">
              <ReactSelect
                name="status"
                options={classFilterDropdown}
                isClearable={false}
                onChange={(...params) => onChangeStatusFilter(...params, 1)}
                value={classQueryPayload["status"]}
              />
            </div>
          </div>
        </div>
        {!disabled && (
          <div className="col-xl-9 col-lg-9 col-12">
            <div className="row no-gutters justify-end">
              <div className="col-12 col-lg-12 col-xl-10">
                <div className="row justify-end">
                  <div className="col-4">
                    <ButtonType2
                      btnName="Export"
                      className="buttonType1"
                      onClick={() => exportData()}
                      disabled={disabled}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="row no-gutters">
        <div className="col-12">
          <CustomBootstrapTable
            pagination={pagination}
            rows={classes?.classTableList}
            columns={getTableColumns(disabled)}
            isLoading={loader?.isTableDetailsFetching}
            keyField="shopId"
            tableClass="custom-row-class"
            tableMainClass="custom_class_table"
          />
        </div>
      </div>
    </div>
  );
};

export default ClassTable;
