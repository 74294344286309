import { loaders, main } from '../service/actionType';
import { API } from 'aws-amplify';
import toast from '../helpers/Toast';
import { ENVIRONMENT } from '../common/constants';
import { accessHeaders } from '../helpers';
import _ from 'lodash';
import moment from 'moment'

export const getSessionAssignedDatesList = (queryPayload) => async (dispatch, getState) => {
    try {
        dispatch({ type: loaders.SESSION_ASSIGNED_DATES_FETCHING, payload: true })
        let url = "contentselection/session/v3.9/getSessionDataByMonth";
        let myInit = {
            headers: accessHeaders(),
            queryStringParameters: queryPayload
        }
        const response = await API.get(ENVIRONMENT, url, myInit);
        if(response.status === 200){
            let sessionPastDates = [], sessionFutureDates = []
            
            if(response.data.SessionPastData?.length > 0){
                response.data.SessionPastData.map(dt => {
                    sessionPastDates.push(new Date(dt.Session_Date))
                })
            }

            if(response.data.SessionFutureData?.length > 0){
                response.data.SessionFutureData.map(dt => {
                    sessionFutureDates.push(new Date(dt.Session_Date))
                })
            }

            dispatch({ 
                type: main.SESSION_ASSIGNED_DATES, 
                payload: {
                    sessionPastDates: sessionPastDates, 
                    sessionFutureDates: sessionFutureDates,
                } 
            })
        }
        dispatch({ type: loaders.SESSION_ASSIGNED_DATES_FETCHING, payload: false })
    } catch (error) {
        console.log("error", error);
        toast(error.message, 'error');
        dispatch({ type: loaders.SESSION_ASSIGNED_DATES_FETCHING, payload: false })
    }

}

export const getSessionDatesList = (queryPayload) => async (dispatch, getState) => {
    try {
        dispatch({ type: loaders.IS_APP_FETCHING, payload: true })
        let url = "contentselection/createsession/dates/list";
        let myInit = {
            headers: accessHeaders(),
            queryStringParameters: {
                ...queryPayload,
                userName: localStorage.getItem("username")
            }
        }
        const response = await API.get(ENVIRONMENT, url, myInit);
        if(response.status === 200){
            let formattedList = [],filteredDays
            if(response.data.calendarDatesList?.length > 0){
                response.data.calendarDatesList.map(dt => dt.dates.map(date => {
                    if (date.isHoliday) {
                        formattedList.push(new Date(date.holiday_Dates))
                    }
                }))
            }
            dispatch({ type: main.SESSION_DISABLE_DATES_LIST, payload: {sessionDisableDatesList: formattedList, academicYear: response.data.schoolAcademicYear} })
            
        }
        dispatch({ type: loaders.IS_APP_FETCHING, payload: false })
    } catch (error) {
        console.log("error", error);
        toast(error.message, 'error');
        dispatch({ type: loaders.IS_APP_FETCHING, payload: false })
    }

}

export const getStudentsList = () => async (dispatch, getState) => {
    try {
        dispatch({ type: loaders.IS_APP_FETCHING, payload: true })
        let { dataToPersist } = getState()
        let selectedClass = sessionStorage.getItem('selectedClass')
        let url = "director/getAdmins";
        let myInit = {
            headers: accessHeaders(),
            queryStringParameters: {
                // classId: selectedClass ? selectedClass :  dataToPersist.selectedClass?.value,
                userName: localStorage.getItem("username")
            }
        }
        const response = await API.get(ENVIRONMENT, url, myInit);
        if(response.status === 200){
            dispatch({ type: main.RIGHT_SIDEBAR_STUDENTS_LIST, payload: response.data })
        } else dispatch({ type: main.RIGHT_SIDEBAR_STUDENTS_LIST, payload: [] })
        dispatch({ type: loaders.IS_APP_FETCHING, payload: false })
    } catch (error) {
        console.log("error", error);
        // toast(error.message, 'error');
        dispatch({ type: loaders.IS_APP_FETCHING, payload: false })
    }

}

export const deleteSelectedTubObject = (data) => async (dispatch, getState) => {
    try {
        dispatch({ type: loaders.IS_APP_FETCHING, payload: true })
        let url = "contentselection/createsession/delete";
        let myInit = {
            headers: accessHeaders(),
            body: data,
            queryStringParameters: {
                userName: localStorage.getItem("username")
            }
        }
        const response = await API.post(ENVIRONMENT, url, myInit);
        if(response.status === 200){
            toast(response?.message, 'success')
            dispatch({ type: loaders.IS_APP_FETCHING, payload: false })
            return true
        } else {
            toast(response?.message, 'error');
            dispatch({ type: loaders.IS_APP_FETCHING, payload: false })
            return false
        }
    } catch (error) {
        console.log("error", error);
        // toast(error.message, 'error');
        dispatch({ type: loaders.IS_APP_FETCHING, payload: false })
    }

}


export const getTodayAndUpcomingSessionList = (queryPayload) => async (dispatch, getState) => {
    try {
        dispatch({ type: loaders.INNER_APP_FETCHING, payload: true })
        let url = "contentselection/session/list";
        let myInit = {
            headers: accessHeaders(),
            queryStringParameters: {
                ...queryPayload,
                userName: localStorage.getItem("username")
            }
        }
        const response = await API.get(ENVIRONMENT, url, myInit);
        if(response.status === 200){
            dispatch({ type: main.SESSION_LIST, payload: response.data })
        }
        dispatch({ type: loaders.INNER_APP_FETCHING, payload: false })
    } catch (error) {
        console.log("error", error);
        toast(error.message, 'error');
        dispatch({ type: loaders.INNER_APP_FETCHING, payload: false })
    }

}


export const selectedTubClassTab = (tab) => async (dispatch, getState) => {
    dispatch({type: main.SELECTED_TUB_CLASS_TABS, payload:tab })
}

export const selectedLeftSidebar = (tab) => async (dispatch, getState) => {
    dispatch({type: main.SELECTED_LEFT_SIDE_BAR, payload:tab })
    dispatch({type: main.SELECTED_TUB_CLASS_TABS, payload: 1 })
}
export const getCreatedSessionList = (date) => async (dispatch, getState) => {
    try {
        let {dataToPersist} = getState()
        dispatch({ type: loaders.INNER_APP_FETCHING, payload: true })
        let url = "contentselection/createsession/list";
        let selectedClass = sessionStorage.getItem('selectedClass')
        let myInit = {
            headers: accessHeaders(),
            queryStringParameters: {
                schoolId : sessionStorage.getItem('schoolId'),
                classId: selectedClass ? selectedClass : dataToPersist.selectedClass.value,
                date: date,
                userName: localStorage.getItem("username")
            },
        }
        const response = await API.get(ENVIRONMENT, url, myInit)
        if (response.status === 200) {
            let result = response.data.result
            dispatch({ type: main.CREATED_SESSION_LIST, payload: result})
            if(result?.length > 0 && !(moment(date).format('YYYY-MM-DD') > moment(new Date()).format('YYYY-MM-DD'))){
                dispatch({ type: main.IS_SESSION_PRESENT, payload: true})
            } else {
                dispatch({ type: main.IS_SESSION_PRESENT, payload: false})
            }
        } else {
            dispatch({ type: main.CREATED_SESSION_LIST, payload: []})
            dispatch({ type: main.IS_SESSION_PRESENT, payload: false})
        }
        dispatch({ type: loaders.INNER_APP_FETCHING, payload: false })
    } catch (error) {
        dispatch({ type: loaders.INNER_APP_FETCHING, payload: false })
    }
}

export const getSelectedActivityList = () => async (dispatch, getState) => {
    let { selectedActivity, activityListQueryPayload } = getState().session
    console.log('selectedActivity',selectedActivity)
    let {dataToPersist} = getState()
    let selectedClass = sessionStorage.getItem('selectedClass')
    let newqueryPayload = {
        ...activityListQueryPayload,
        schoolId : sessionStorage.getItem('schoolId'),
        classId : selectedClass ? selectedClass : dataToPersist.selectedClass.value,
        tubObjectType: selectedActivity.key != null ? selectedActivity.key : null,
        type: selectedActivity.type != null ? selectedActivity.type : null,
        activityType: selectedActivity != null ? selectedActivity.id  === 3 ? 5 : selectedActivity.id : null
    }
    try {
        dispatch({ type: loaders.INNER_APP_FETCHING, payload: true })
        let url = "contentselection/createsession/activity/list";
        let myInit = {
            headers: accessHeaders(),
            queryStringParameters: {
                ...newqueryPayload,
                userName: localStorage.getItem("username")
            }
        }
        const response = await API.get(ENVIRONMENT, url, myInit);
        if(response.status === 200){
            dispatch({type: main.TUB_ACTIVITY_LIST, payload: response.data})
        } else {
            dispatch({type: main.TUB_ACTIVITY_LIST, payload: []})
        }
        dispatch({ type: loaders.INNER_APP_FETCHING, payload: false })
    } catch (error) {
        console.log("error", error);
        dispatch({ type: loaders.INNER_APP_FETCHING, payload: false })
    }
}


export const getTubActivitySuggestionList = () => async (dispatch, getState) => {
    let { selectedActivity, activityListQueryPayload } = getState().session
    let {dataToPersist} = getState()
    let selectedClass = sessionStorage.getItem('selectedClass')
    let newqueryPayload = {
        ...activityListQueryPayload,
        schoolId : sessionStorage.getItem('schoolId'),
        classId : selectedClass ? selectedClass : dataToPersist.selectedClass.value,
        // tubObjectType: selectedActivity.key != null ? selectedActivity.key : null,
        // type: selectedActivity.type != null ? selectedActivity.type : null,
        activityType: selectedActivity != null ? selectedActivity.id  === 5 ? 1 : 2 : null
    }
    try {
        dispatch({ type: loaders.INNER_APP_FETCHING, payload: true })
        let url = "contentselection/createsession/wordsAndTags/list";
        let myInit = {
            headers: accessHeaders(),
            queryStringParameters: {
                ...newqueryPayload,
                userName: localStorage.getItem("username")
            }
        }
        const response = await API.get(ENVIRONMENT, url, myInit);
        if(response.status === 200){
            dispatch({type: main.TUB_ACTIVITY_SUGGESTION_LIST, payload: response.data})
        } else {
            dispatch({type: main.TUB_ACTIVITY_SUGGESTION_LIST, payload: []})
        }
        dispatch({ type: loaders.INNER_APP_FETCHING, payload: false })
    } catch (error) {
        console.log("error", error);
        dispatch({ type: loaders.INNER_APP_FETCHING, payload: false })
    }
}



export const checkLauchTubEnabled = () => async (dispatch, getState) => {
    let selectedClass = sessionStorage.getItem('selectedClass')
    let schoolId = sessionStorage.getItem('schoolId')
    let { dataToPersist } = getState()
    let classId = selectedClass ? selectedClass : dataToPersist?.selectedClass?.value
    const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

    if(!_.isEmpty(schoolId) && !_.isEmpty(classId)) {
        try {
            let url = "common/check/launchtub";
            let myInit = {
                headers: accessHeaders(),
                body: {
                    schoolId :schoolId,
                    classId : classId,
                    timezone: timeZone
                }
            }
            const response = await API.post(ENVIRONMENT, url, myInit);
            if(response.status === 200){
                dispatch({ type: main.IS_SESSION_PRESENT, payload: response.data})
            } else {
                dispatch({ type: main.IS_SESSION_PRESENT, payload: response.data})
            }
        } catch (error) {
            console.log("error", error);
        }
    }
}



export const onHideContent = (queryPayload) => async (dispatch, getState) => {
        let userid = localStorage.getItem('userid')
        let username = localStorage.getItem('username')
        try {
            let url = "gallery/v3.6/hideUnHideImage";
            let myInit = {
                headers: accessHeaders(),
                body: {
                    ...queryPayload,
                    createdByUserId: userid,
                    createdByUserName: username
                }
            }
            const response = await API.post(ENVIRONMENT, url, myInit);
            if(response.status === 200){
                // dispatch({ type: main.IS_SESSION_PRESENT, payload: response.data})
            } else {
                // dispatch({ type: main.IS_SESSION_PRESENT, payload: response.data})
            }
        } catch (error) {
            console.log("error", error);
        }
}


export const toggleReviewTubClass = (payload) => async (dispatch, getState) => {
    sessionStorage.setItem('reviewTubClass', payload)
    dispatch({ type: main.TOGGLE_REVIEW_TUB_CLASS, payload: payload})
}

