import { React, Component } from 'react';
import { connect } from 'react-redux'
import * as gameAction from '../../../../actions/game';
import { bindActionCreators } from "redux";
import ClassPresentationWrapper from '../../../../Hoc/wrapper'
import ButtonType1 from "../../../../components/inputs/ButtonType1"
import Gallery from 'react-photo-gallery'
// import { ReactPhotoCollage } from "react-photo-collage";
import { getLayoutOfCollage } from '../../../../helpers';

const setting = {
    width: "900px",
    height: ["250px", '250px'],
    // layout: [4,2],
    showNumOfRemainingPhotos: false,
    photos: []
  };

const ClassCollageView = ({game}) => {
    setting["photos"] = game?.collageStudentsList
    setting['layout'] = getLayoutOfCollage(game?.collageStudentsList)
    return (
        <div className='clpContainer container mb-5'>
            {game?.collageStudentsList && game?.collageStudentsList?.length  > 0 ?
                <div className="row ">
                    <div className="col-12">
                        <div className="mt-2">
                            <p className="clp_heading1 text-center">Class Collage</p>
                        </div>
                    </div>
                    <div className="col-12 mt-5 ">
                        {/* <Gallery photos={game?.collageStudentsList}/> */}
                        {/* <div className='d-flex justify-content-center collage_container'>
                            <ReactPhotoCollage
                                {
                                    ...setting
                                } 
                            />
                        </div> */}
                    </div>
                </div>
                :
                <div className='text-center ptb-100'>
                    <p className={`class_atd_player`}>No Students Found</p>
                </div>
            }
        </div>
    )
}

const mapStateToProps = ({ tubGallery, myProfile, loader, game }) => {
    return {
        tubGallery,
        myProfile,
        loader,
        game
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        gameAction : bindActionCreators(gameAction, dispatch)
    }
};

export const ClassCollage = ClassPresentationWrapper(connect(mapStateToProps, mapDispatchToProps)(ClassCollageView))