import { React, Component } from 'react';
import { connect } from 'react-redux'
import ButtonType1 from '../../../../components/inputs/ButtonType1';
import moment from 'moment'
import * as tubInboxAction from '../../../../actions/tubInbox'
import { bindActionCreators } from "redux";
import { API } from 'aws-amplify';
import { ENVIRONMENT } from '../../../../common/constants';
import { loaders } from '../../../../service/actionType';
import toast from '../../../../helpers/Toast';
import { ShowMore } from '../../../../components/tubClass/showMore';
import { accessHeaders, timeSince } from '../../../../helpers';
import Wrapper from '../../../../Hoc/wrapper';

class InboxClass extends Component {
    constructor(props) {
        super(props);
        this.state = {
            approvedList: [],
        };
    }
    static getDerivedStateFromProps(nextProps, prevState) {
        return {
            approvedList: nextProps.tubinbox.tubInboxList
        }
    }
    
    
    deleteTubObject = async (data) => {
        let payload = {}
        const username = localStorage.getItem('userid');
        payload.TubObjectStatus = "Deleted"
        payload.TubObjectSubmittedBy = username
        // this.props.dispatch({ type: loaders.IS_APP_FETCHING, payload: true })
        let url = `tubinbox/deleteTubInboxData/${data._id}`;
        let myInit = {
            headers: accessHeaders() ,
            body: payload
        }
        const response = await API.post(ENVIRONMENT, url, myInit);
        if(response.status === 200){
            toast(response.message, 'success')
            let dataToPersist = this.props.dataToPersist
            let selectedClass = sessionStorage.getItem('selectedClass')
            let queryPayload = {
                SchoolID : sessionStorage.getItem('schoolId'),
                ClassID: selectedClass ? selectedClass : dataToPersist.selectedClass?.value,
                TubObjectStatus: 'Approved'
            }
            await this.props.tubInboxAction.getTubInboxList(queryPayload)
            // this.props.dispatch({ type: loaders.IS_APP_FETCHING, payload: false })
            
        } else {
            // this.props.dispatch({ type: loaders.IS_APP_FETCHING, payload: false })
        }
    }
    render() {
        let state = this.state
        return (
            <div className="col-12 p-0">
                <div className="row flex-wrap mt-1">
                    { state.approvedList?.length > 0
                        ?
                        state.approvedList.map((data, i) => (
                            <div className="col-md-6 mt-4" key={i}>
                                <div className="tub-bg-type-1 p-3">
                                    <div className="row no-gutters justify-flex-end align-items-center px-2 tub-inner-heading pt-15">
                                        <div className="col-3 pb-10">
                                            <div className="row align-items-center justify-flex-end no-gutters">
                                                <div className="col-3 pl-3">
                                                    <img
                                                        src={
                                                            require("../../../../assets/images/deleteSvg.svg")
                                                            .default
                                                        }
                                                        className="h-23"
                                                        onClick={() => this.deleteTubObject(data)}
                                                        alt="alphatub"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="relative-pos">
                                        <div className={`tub-session-card ${(data.TubObjectType === 'Sheet') && 'addFilter p-1'}`}>
                                            <div className="row flex-wrap no-gutters">
                                                <div className="col-12">
                                                    <img
                                                        src={data.TubObjectId?.ObjectImage?.original}
                                                        className={`${(data.TubObjectType === 'Card') && 'card_img'}`}
                                                        alt="alphatub"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row align-items-center no-gutters inbox-content-spnImg">
                                            <img
                                                src={
                                                    data.StudentId != undefined && data.StudentId.profilePic != undefined && data.StudentId.profilePic.original != undefined &&
                                                    data.StudentId.profilePic.original !== '' ? data.StudentId.profilePic.original : require("../../../../assets/images/noImage.png")
                                                }
                                                className="whbr-60"
                                                alt="alphatub"
                                            />
                                            <p className="object_student_name pl-2">{data?.StudentName}</p>
                                        </div>
                                    </div>
                                    <div className="row no-gutters mt-2 justify-content-between align-items-center">
                                        <div className="col-4">
                                           <ShowMore 
                                                i = {i}
                                                data = {data}
                                           />
                                        </div>
                                        <div className="col-5 d-flex justify-content-center">
                                            <p className="alphatub-card-h2">{data?.TubObjectName}</p>
                                        </div>
                                        <div className="col-3 d-flex justify-content-end">
                                            <p className="f-14 tub-day">{timeSince(data.updatedAt, false)}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                    :
                        <div className="row no-gutters align-items-center center-content w-100 h-400px">
                            <p className='tub-content-4 text-center'>No Approved Content</p>
                        </div>
                    }          
                </div>
            </div>
        )
    }
}

const mapStateToProps = ({ tubinbox, loader }) => {
    return {
        tubinbox,
        loader
    };
};

let mapDispatchToProps = (dispatch) => {
    return {
        tubInboxAction: bindActionCreators(tubInboxAction, dispatch),
        dispatch
    }
}

const  Approved = Wrapper(connect(mapStateToProps, mapDispatchToProps)(InboxClass))

export { Approved }