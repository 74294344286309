import { React } from "react";

const GamePlayers = (props) => {
  return (
    <div className="row no-gutters justify-content-center w-100 selected_game_players">
      {props.selectedMode == 1
        ? props.game.playersList &&
          props.game.playersList.map((pl) => (
            <div
              className={
                pl.isSelected
                  ? `highlight-ind-student cursor-pointer p-1 text-center game_player`
                  : `cursor-pointer p-1 text-center game_player`
              }
              // onClick={() => this.selectedObject()}
            >
              <div className="">
                <img
                  src={
                    pl.profilePic != undefined &&
                    pl.profilePic.original != undefined &&
                    pl.profilePic.original != ""
                      ? pl.profilePic.original
                      : require("../../../../../../assets/images/noImage.png")
                  }
                  className={
                    pl.isSelected
                      ? `selected-ind-student-img inheritHeight`
                      : "w-40-brHalf inheritHeight"
                  }
                />
              </div>
              <div className="mt-2">
                <p
                  className={
                    pl.isSelected
                      ? `selected-game-student-names text-center`
                      : `game-student-names text-center`
                  }
                >
                  {pl.label ? pl.label.substring(0, 5) : "-"}
                </p>
              </div>
            </div>
          ))
        : props.selectedMode == 2
        ? props.game.selectedPlayersList != null &&
          props.game.selectedPlayersList.student_list &&
          props.game.selectedPlayersList.student_list.map((pl) => (
            <div
              className={
                pl._id == props.currentPlayer?.playerId
                  ? `highlight-ind-student cursor-pointer p-1 text-center game_player`
                  : `cursor-pointer p-1 text-center game_player`
              }
              // onClick={() => this.selectedObject()}
            >
              <div className="">
                <img
                  src={
                    pl.profilePic != undefined &&
                    pl.profilePic.original != undefined &&
                    pl.profilePic.original != ""
                      ? pl.profilePic.original
                      : require("../../../../../../assets/images/noImage.png")
                  }
                  className={
                    pl._id == props.currentPlayer?.playerId
                      ? `selected-ind-student-img inheritHeight`
                      : "w-40-brHalf inheritHeight"
                  }
                />
              </div>
              <div className="mt-2">
                <p
                  className={
                    pl._id == props.currentPlayer?.playerId
                      ? `selected-game-student-names text-center`
                      : `game-student-names text-center`
                  }
                >
                  {pl.label ? pl.label : "-"}
                </p>
              </div>
            </div>
          ))
        : props.selectedMode == 4
        ? props.game.selectedPlayersList != null &&
          props.game.selectedPlayersList.student_list &&
          props.game.selectedPlayersList.student_list.map((pl) => (
            <div
              className={
                pl._id == props.currentPlayer?.playerId
                  ? `cursor-pointer p-1 text-center game_player`
                  : `cursor-pointer p-1 text-center game_player`
              }
              // onClick={() => this.selectedObject()}
            >
              <div>
                <img
                  src={
                    pl.profilePic != undefined &&
                    pl.profilePic.original != undefined &&
                    pl.profilePic.original != ""
                      ? pl.profilePic.original
                      : require("../../../../../../assets/images/noImage.png")
                  }
                  className={"w-40-brHalf inheritHeight"}
                />
              </div>
              <div className="mt-2">
                <p className={`game-student-names text-center`}>
                  {pl.label ? pl.label : "-"}
                </p>
              </div>
            </div>
          ))
        : props.game.selectedPlayersList != null &&
          props.game.selectedPlayersList.group_details.map((dt, i) => (
            <div className="column-direction">
              <div
                className={`m-2 p-1 row no-gutters ${
                  i == props.game.currentGroupPlayGroupIndex
                    ? "selected-group-box "
                    : "unselected-group-box"
                }`}
              >
                {dt.data &&
                  dt.data.map((pl) => (
                    <div>
                      <div className="cursor-pointer w-75px text-center">
                        {pl.isAdded ? (
                          <img
                            src={
                              pl.playerImage != ""
                                ? pl.playerImage
                                : require("../../../../../../assets/images/noImage.png")
                            }
                            className="whbr-70"
                          />
                        ) : (
                          <img
                            src={require("../../../../../../assets/images/noImage.png")}
                            className="whbr-70"
                          />
                        )}
                      </div>
                    </div>
                  ))}
              </div>
              <div className="mt-2">
                <p className="clpSelectedPlayer text-center">{dt.groupName}</p>
              </div>
            </div>
          ))}
    </div>
  );
};

export { GamePlayers };
