import React, { useState, useEffect } from "react";
import { API } from "aws-amplify";
import { FaCaretDown, FaCaretRight } from "react-icons/fa";
import { accessHeaders } from "../../helpers";
import { ENVIRONMENT } from "common/constants";
import { Button } from "react-bootstrap";
import { HierarchyModal } from "./HierarchyModal";
import moment from "moment";
import "./Hierarchy.scss";
import { Table } from "reactstrap";

const Hierarchy = () => {
  const [unassignedDirectors, setUnassignedDirectors] = useState([]);
  const [userLevels, setUserLevels] = useState({});
  const [childUser, setChildUser] = useState(null);
  const [hierarchyModalEnabled, setHierarchyModalEnabled] = useState(false);

  const currentUserId = localStorage.getItem("userid");

  const RecursiveTable = ({
    children = [],
    padding = 0,
    colSpan = 1,
    depth = 0,
  }) => {
    const [childrenItems, setChildItems] = useState(children);

    const toggleViewChild = (index) => {
      childrenItems[index].viewChildren = !childrenItems[index].viewChildren;
      setChildItems(() => [...childrenItems]);
    };

    const getFontColor = () => {
      const colors = [
        "#FFFFFF",
        "C6C6C6",
        "#edebeb",
        "#E2E2E2",
        "#D7D7D7",
        "#c7c7c7",
        "#b8b7b7",
      ];
      return colors[depth % colors.length];
    };

    return (
      <>
        {childrenItems.map((child, index) => (
          <React.Fragment key={child.id}>
            <tr
              className={child?.isDeleted === true ? "text-danger" : ""}
              style={{ backgroundColor: getFontColor() }}
            >
              <td
                style={{
                  paddingLeft: `${padding}px`,
                }}
                onClick={() => {
                  toggleViewChild(index);
                }}
              >
                {index + 1}
                <span>
                  {childrenItems[index]?.children?.length > 0 && (
                    <>
                      {childrenItems[index].viewChildren ? (
                        <FaCaretDown />
                      ) : (
                        <FaCaretRight />
                      )}
                    </>
                  )}
                </span>
              </td>
              <td>
                {child?.userDetails?.First_name} {child?.userDetails?.Last_name}
              </td>
              <td>{child?.userDetails?.RoleName}</td>
              <td>{moment(child?.userDetails?.createdAt).format("LL")}</td>
              <td>{child?.userDetails?.Email}</td>
              <td>
                {child?.userDetails?.teams.map((team, index) => (
                  <span key={index}>
                    {index + 1}. {team?.schools[0]?.SchoolName}.
                    <br />
                  </span>
                ))}
              </td>{" "}
              <td>
                <Button
                  onClick={() => {
                    setChildUser(() => {
                      return {
                        ...child,
                      };
                    });
                    setHierarchyModalEnabled(true);
                  }}
                >
                  View
                </Button>
              </td>
            </tr>

            {Boolean(childrenItems[index].viewChildren) && (
              <RecursiveTable
                children={childrenItems[index].children}
                padding={padding + 10}
                depth={depth + 1}
                colSpan={6}
              />
            )}
          </React.Fragment>
        ))}
      </>
    );
  };

  const getUnassignedDirectors = async () => {
    try {
      const { data } = await API.get(
        ENVIRONMENT,
        "director/user-levels/child-users/unassigned",
        {
          headers: accessHeaders(),
        }
      );
      setUnassignedDirectors(() => [...data]);
    } catch (error) {
      console.error("Error fetching unassigned directors:", error);
    }
  };

  const assignCurrentUserId = async (childUserId) => {
    try {
      await API.post(ENVIRONMENT, "director/user-levels", {
        body: {
          parentUserId: currentUserId,
          childUserIds: [childUserId],
        },
        headers: accessHeaders(),
      });
      getUnassignedDirectors();
    } catch (error) {
      console.error("Error assigning user ID:", error);
    }
  };

  const getUserLevels = async (userId) => {
    try {
      const {
        data: { userLevels },
      } = await API.get(ENVIRONMENT, `director/user-levels/${userId}`, {
        headers: accessHeaders(),
      });
      setUserLevels(userLevels);
    } catch (error) {
      console.error("Error fetching user levels:", error);
    }
  };

  useEffect(() => {
    getUnassignedDirectors();
    getUserLevels(currentUserId);
  }, []);

  return (
    <>
      <div className="d-flex justify-content-center mb-3 mt-1">
        <h1>Hierarchy</h1>
      </div>
      <Table className="user-hierarchy">
        <thead className="unassigned-user-keys">
          <tr>
            <th>S no.</th>
            <th>Name</th>
            <th>Role</th>
            <th>Date</th>
            <th>Email</th>
            <th>School</th>
            <th>Action</th>
          </tr>
        </thead>
        {userLevels && (
          <RecursiveTable children={userLevels.children} depth={0} />
        )}
      </Table>
      <div className="d-flex justify-content-center mb-3 mt-5">
        <h1>List of Unassigned Users</h1>
      </div>
      <Table className="unassigned-users">
        <thead className="unassigned-user-keys">
          <tr>
            <th>S no.</th>
            <th>Name</th>
            <th>Role</th>
            <th>Date</th>
            <th>Email</th>
            <th>School Name</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {Array.isArray(unassignedDirectors) && unassignedDirectors.length ? (
            unassignedDirectors.map((unassignedDirector, index) => (
              <tr
                className="unassigned-user-values"
                key={unassignedDirector._id}
              >
                <td>{index + 1}</td>
                <td>
                  {unassignedDirector.First_name} {unassignedDirector.Last_name}
                </td>
                <td>{unassignedDirector.RoleName}</td>
                <td>{moment(unassignedDirector.createdAt).format("LL")}</td>
                <td>{unassignedDirector.Email}</td>
                <td>
                  {unassignedDirector?.teams.map((team, index) => (
                    <span key={index}>
                      {index + 1}. {team?.schools[0]?.SchoolName}.
                      <br />
                    </span>
                  ))}
                </td>
                <td>
                  <div className="table-actions">
                    <button
                      className="w-200px btn btn-primary"
                      onClick={() =>
                        assignCurrentUserId(unassignedDirector._id)
                      }
                    >
                      Add
                    </button>
                  </div>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="12" className="text-center py-4">
                No data available
              </td>
            </tr>
          )}
        </tbody>
      </Table>
      <HierarchyModal
        isOpen={hierarchyModalEnabled}
        currentUser={childUser}
        getUserLevels={getUserLevels}
        onModalClose={() => {
          setHierarchyModalEnabled(false);
        }}
      />
    </>
  );
};

export { Hierarchy };
