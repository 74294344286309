import { React, Component } from "react";
import { connect } from "react-redux";
import * as gameAction from "../../../../actions/game";
import { bindActionCreators } from "redux";
import _ from 'lodash';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import ClassPresentationWrapper from "Hoc/wrapper";
import { ArrowLeft, ArrowRight } from "components/tubClass/arrows";

class ExploreViewClass extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedItem: 0,
      studentDetail: {
        firstName: null,
        profilePic: {
          original: null
        }
      }
    };
  }

  selectedObject = (i) => {
    this.setState({
      selectedItem: i,
    });
  };

  renderCustomThumbs = () => {
    let { game } = this.props;
    const thumbList = game.activityDetail.data.map((dt, i) => (
      <div className="p-1">
        <div className="cursor-pointer w-50px" onClick={() => this.selectedObject(i)}>
          <div className="">
            <img
              src={
                dt.alphabetImage.original
                  ? dt.alphabetImage.original
                  : require("../../../../assets/images/tubobject1.png")
              }
              className="wh-50 br-10 inheritHeight"
              alt="alpha"
            />
          </div>
          <div className="mt-2">
            <p className="explore_imageTitle text-center">{dt.alphabetName}</p>
          </div>
        </div>
      </div>
    ));

    return thumbList;
  };

  next = () => {
    let { game } = this.props
    let gameList = game.activityDetail.data
    let lengthOfGameData = gameList?.length
    if ((lengthOfGameData - 1) === this.state.selectedItem) {
      this.setState({
        selectedItem: 0
      })
    } else {
      this.setState({
        selectedItem: this.state.selectedItem + 1
      })
    }

  }
  prev = () => {
    this.setState({
      selectedItem: this.state.selectedItem - 1
    })
  }
  render() {
    let state = this.state;
    let { game } = this.props;
    return (
      <>
        {game.activityDetail != null &&
          <div className="row">
            <div className="col-12">
              <div className="mb-5">
                <Carousel
                  showStatus={false}
                  emulateTouch={false}
                  showIndicators={false}
                  useKeyboardArrows={true}
                  selectedItem={state.selectedItem}
                  showThumbs={true}
                  transitionTime={0}
                  className="explore_carousal"
                  renderThumbs={this.renderCustomThumbs}
                  renderArrowNext={(onClickHandler, hasNext, label) => (
                    <ArrowRight
                      next={this.next}
                    />
                  )}
                  renderArrowPrev={(onClickHandler, hasPrev, label) => (
                    hasPrev && (
                      <ArrowLeft
                        prev={this.prev}
                      />
                    )
                  )}
                >
                  {game.activityDetail.data &&
                    game.activityDetail.data.map((dt) => (
                      <div className="row">
                        <div className="col-4">
                          <div className="p-2 cursor-pointer column-direction d-flex align-items-center justify-content-center h-100 w-80 mlr-auto">
                            <div className="explore_alp_container">
                              <div className="explore_alp_bg">
                                <h1 className="explore-alphabet">
                                  {dt.alphabetName}
                                </h1>
                              </div>
                            </div>
                            <div className="mt-4">
                              <h4 className="clp-fulltext">
                                {dt.imageTitle != undefined ? dt.imageTitle : ''}
                              </h4>
                            </div>
                            <div className="mt-3 pt-2 clp-white-br w-100">
                              <p className="clp-tags">
                                {
                                  dt.tags &&
                                  dt.tags.map(t => " #" + t)
                                }
                              </p>
                            </div>
                          </div>
                        </div>
                        <div
                          className={`col-4 ${game.activityDetail.ObjectType !== 'Reading_Program' ? 'pt-50' : ''}`}
                        >
                          <div className={`p-2 cursor-pointer ${game.activityDetail.ObjectType === 'Reading_Program' ? 'bg_white br-10' : ''}`}>
                            <div className={`relative-pos w-90 margin-lr-auto`}>
                              <img
                                src={
                                  dt.alphabetImage != undefined
                                    ? dt.alphabetImage.original
                                    : require("../../../../assets/images/tubobject1.png")
                                }
                                className="br-10 inheritHeight"
                                alt="alphatub"
                              />
                              {game.activityDetail.ObjectType !== 'Reading_Program' ?
                                (game.activityDetail.ObjectType == "Card" || game.activityDetail.ObjectType == "Sheet" || game.activityDetail.ObjectType == "Assignment") ?
                                  <div className="row align-items-center no-gutters explore_std_detail">
                                    <img
                                      className="whbrbx-75"
                                      src={
                                        (game.activityDetail.studentDetail != undefined && game.activityDetail.studentDetail.profilePic.original != undefined && game.activityDetail.studentDetail.profilePic.original != '')
                                          ?
                                          game.activityDetail.studentDetail.profilePic.original
                                          :
                                          require("../../../../assets/images/noImage.png")
                                      }
                                      alt="alpatub"
                                    />

                                    <p className="mb-2 tub-content-4 color-white pl-2">
                                      {(game.activityDetail.studentDetail != undefined && game.activityDetail.studentDetail?.firstName != '') ? game.activityDetail.studentDetail.firstName : ''}
                                    </p>
                                  </div>
                                  :
                                  <div className="row align-items-center no-gutters explore_std_detail">
                                    <img
                                      className="whbrbx-75"
                                      src={
                                        (dt.profilePic != undefined && dt.profilePic.original != undefined && dt.profilePic.original != '')
                                          ?
                                          dt.profilePic.original
                                          :
                                          require("../../../../assets/images/noImage.png")
                                      }
                                      alt="alpatub"
                                    />
                                    <p className="mb-2 tub-content-4 color-white pl-2">
                                      {(dt.studentName != undefined && dt.studentName != '') ? dt.studentName : ''}
                                    </p>
                                  </div>
                                :
                                <></>
                              }
                            </div>
                          </div>
                        </div>
                        <div className="col-4">
                          <div className="row align-items-center justify-content-center h-100">
                            {game.activityDetail.ObjectType !== 'Reading_Program' ?
                              <div className="p-2 w_70 position_relative">
                                <img
                                  src={
                                    (dt.stencilImage != undefined && dt.stencilImage.original != undefined && dt.stencilImage.original != "")
                                      ? dt.stencilImage.original
                                      : require("../../../../assets/images/tubobject1.png")
                                  }
                                  className="br-10 inheritHeight "
                                  alt="alpatub"
                                />
                                <div className="explore_stencil_container">
                                  <img
                                    src={require(`assets/images/stencils/${dt.alphabetName.toLowerCase()}.png`)}
                                    className="br-10 inheritHeight explore_stencil_image"
                                    alt="alpatub"
                                  />
                                </div>
                              </div>
                              :
                              <div className="p-2 w_70 position_relative">
                                  <img
                                    src={require(`assets/images/reading_program_stencils/${dt.alphabetName.toLowerCase()}.png`)}
                                    className="br-10 inheritHeight explore_stencil_image"
                                    alt="alpatub"
                                  />
                                <div className="explore_reading_pr_stencil_container">
                                  <img
                                    src={
                                      (dt.alphabetImage != undefined && dt.alphabetImage.original != undefined && dt.alphabetImage.original != "")
                                        ? dt.alphabetImage.original
                                        : require("../../../../assets/images/tubobject1.png")
                                    }
                                    className="br-10 inheritHeight "
                                    alt="alpatub"
                                  />
                                  </div>
                              </div>
                            }
                          </div>
                        </div>
                      </div>
                    ))}
                </Carousel>
              </div>
            </div>
            <div className="col-12">
              <div className="w-6 mx-auto">
                <p className="text_white text-center">
                  {state?.selectedItem + 1} / {game.activityDetail.data?.length}
                </p>
              </div>
            </div>
          </div>
        }
      </>
    );
  }
}

const mapStateToProps = ({ tubGallery, myProfile, loader, game, tubinbox }) => {
  return {
    tubGallery,
    myProfile,
    tubinbox,
    loader,
    game,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    gameAction: bindActionCreators(gameAction, dispatch),
  };
};

export const ExploreView = ClassPresentationWrapper(connect(
  mapStateToProps,
  mapDispatchToProps
)(ExploreViewClass));
