import { React, Component } from 'react';
import { connect } from 'react-redux'
import * as gameAction from '../../../../../../actions/game';
import { bindActionCreators } from "redux";
import {  main } from '../../../../../../service/actionType';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import staticImage from '../../../../../../assets/images/placeholder.jpg'
import { getGamePath } from '../../../../../../helpers/index'
import { SelectGroupPlayers } from './selectPlayers';
import { SetPlayersPerGroup } from './setPlayersPerGroup';
import findLastIndex from "lodash/findLastIndex"
import toast from '../../../../../../helpers/Toast';
import _ from 'lodash';
import ClassPresentationWrapper from '../../../../../../Hoc/wrapper'

class SelectGroupClass extends Component {
    constructor(props) {
        super(props);
        this.state = { 
           disableGroup: true,
           isBtnShown: false,
           startBtnDisabled: true,
           tab: 1,
           selectedGroupData: {
               grpNo: null
           },
           selectedPlayerList: [],
           groupPartitionData: [],
           groups: [],
           selectedGroupNo: null
        };
    }

    async componentDidMount() {
        let getProfileDetail = JSON.parse(sessionStorage.getItem('profileDetail'))
        let { game } = this.props
        if(!_.isEmpty(getProfileDetail)){
            game.playersList.unshift(getProfileDetail)
            this.props.dispatch({ type: main.PLAYERS_LIST, payload:  game.playersList})
        }

    }
    selectedObject = (id) => {
        let { game } = this.props
        game.playersList.map((pl, index) => {
            if(pl._id === id){
                pl.isSelected = !pl.isSelected
            } 
        })
        this.props.dispatch({ type: main.PLAYERS_LIST, payload:  game.playersList})
    }
    selectAll = () => {
        let { game } = this.props
        game.playersList.map(pl => pl.isSelected = true )
        this.props.dispatch({type: main.PLAYERS_LIST, payload: game.playersList})
    }
    autoSelectPlayers = () => {
        let {selectedPlayerList,groupPartitionData, selectedGroupNo} = this.state
        let player = 0
        let shuffledPlayers = _.shuffle(selectedPlayerList)
        for(let i = 0; i < groupPartitionData?.length; i++){
            for(let j = 0; j < groupPartitionData[i].data?.length; j++){
                shuffledPlayers[player]["groupNo"] = groupPartitionData[i].groupNo
                shuffledPlayers[player]["isSelected"] = true
                groupPartitionData[i].data[j]._id = shuffledPlayers[player]._id
                groupPartitionData[i].data[j].isAdded = true
                groupPartitionData[i].data[j].playerName = shuffledPlayers[player].label
                groupPartitionData[i].data[j].playerImage = shuffledPlayers[player].profilePic?.original || ''
                player++
            }
        }
        console.log('groupPartitionData', groupPartitionData)
        this.setState({
            selectedPlayerList: shuffledPlayers,
            groupPartitionData
        })
    }
    isPowerOfTwo = (num) => {
        let groups = []
        for(let i = 2; i <= num; i++){
            if (num % i === 0){
                groups.push(i)
            } 
        }
        groups.pop()
        return groups
    }
    renderTab = (tab, data) => {
        if(tab === 2) {
            let selectedPlLength = data?.length
            if(selectedPlLength % 2 === 0 && selectedPlLength > 2){
                if(selectedPlLength >= 2){
                    let groups = this.isPowerOfTwo(selectedPlLength)
                    let selectedList = data.map(dt => ({...dt, isSelected: false}))
                    this.setState({
                        tab: tab,
                        groups: groups,
                        selectedPlayerList: selectedList,
                        disableGroup: true,
                        isBtnShown: true
                    })
                } else {
                    toast('Atleast two players are required to play', 'error')
                }
            } else {
                toast('Please choose divisible number of players', 'error')
            }
        }
        
    }
    selectedGroup = (groupNo) => {
        let {selectedPlayerList} = this.state
        let partitionData = []
        selectedPlayerList = selectedPlayerList.map(dt => ({...dt, isSelected: false}))
        let noOfPartitions = parseInt(selectedPlayerList?.length) / parseInt(groupNo)
        for(let i = 1; i <= noOfPartitions; i++){
            let data = []
            for(let j = 1; j <= groupNo; j++){
                data.push({
                    staticImage: staticImage,
                    isAdded: false,
                    playerName: null,
                    playerImage: null,
                    id: null
                })
            }
            partitionData.push({
                groupName: `Group ${i}`,
                groupNo: i,
                data: data
            })
        }
        this.setState({
            groupPartitionData: partitionData,
            selectedGroupData: {
                ...this.state.selectedGroupData,
                grpNo: 1
            },
            selectedPlayerList: selectedPlayerList,
            selectedGroupNo: groupNo,
            disableGroup: false,
            isBtnShown: false
        })
    }
    autoFillStudents = () => {
        let {selectedPlayerList,groupPartitionData, selectedGroupNo} = this.state
        let remainingUnSelectedPlayers = selectedPlayerList.filter(sp => !sp.isSelected)
        let remainingStudentsCount = 0
        if(remainingUnSelectedPlayers?.length == selectedGroupNo){
            for(let i = 0; i < groupPartitionData.length; i++){
                for(let j = 0; j < groupPartitionData[i]?.data?.length; j++){
                    if(!groupPartitionData[i].data[j].isAdded){
                        groupPartitionData[i].data[j]._id = remainingUnSelectedPlayers[remainingStudentsCount]._id
                        groupPartitionData[i].data[j].isAdded = true
                        groupPartitionData[i].data[j].playerName = remainingUnSelectedPlayers[remainingStudentsCount].label
                        groupPartitionData[i].data[j].playerImage = remainingUnSelectedPlayers[remainingStudentsCount].profilePic?.original || ''
                        remainingStudentsCount++
                    }
                }
            }
            selectedPlayerList.map(sp => {
                if(!sp.isSelected){
                    sp["groupNo"] = groupPartitionData[groupPartitionData?.length -1]["groupNo"]
                    sp.isSelected = true
                }
            })
            this.setState({
                selectedPlayerList,
                groupPartitionData
            })
        }
        
    }
    addPlayersToGroup = (selectedPlayer, index) => {
        let { groupPartitionData, selectedPlayerList, selectedGroupNo} = this.state
        selectedPlayerList[index].isSelected = !selectedPlayerList[index].isSelected
        let isFound = false
        for(let i = 0; i < groupPartitionData?.length; i++){
            let isPlayerAddedIndex = groupPartitionData[i].data.findIndex(dt => dt._id === selectedPlayer._id)
            if(isPlayerAddedIndex !== -1){
                isFound = true
                groupPartitionData[i].data[isPlayerAddedIndex]._id = null
                groupPartitionData[i].data[isPlayerAddedIndex].isAdded = false
                groupPartitionData[i].data[isPlayerAddedIndex].playerName = null
                groupPartitionData[i].data[isPlayerAddedIndex].playerImage = null
                break
            }
        }
        if(!isFound){
            for(let i = 0; i < groupPartitionData?.length; i++){
                let lastAdded = findLastIndex(groupPartitionData[i].data, dt => dt.isAdded === true)
                selectedPlayerList[index]["groupNo"] = groupPartitionData[i].groupNo
                let lengthOfGroupData = groupPartitionData[i]?.data?.length - 1
                    if(lastAdded !== lengthOfGroupData){
                        groupPartitionData[i].data[lastAdded + 1]._id = selectedPlayer._id
                        groupPartitionData[i].data[lastAdded + 1].isAdded = true
                        groupPartitionData[i].data[lastAdded + 1].playerName = selectedPlayer.label
                        groupPartitionData[i].data[lastAdded + 1].playerImage = selectedPlayer.profilePic?.original || ''
                        
                        let checkForGroupChange = lengthOfGroupData - lastAdded
                        if(checkForGroupChange == 1 && groupPartitionData[i + 1] != undefined){
                            this.setState({selectedGroupData: {
                                ...this.state.selectedGroupData,
                                grpNo: this.state.selectedGroupData.grpNo + 1
                            }})
                        }
                        
                        // check for auto fil of players at last 
                        this.autoFillStudents()
                        
                        break
                    } else {
                        let emptyIndex = groupPartitionData[i].data.findIndex(dt => dt.isAdded === false)
                        if(emptyIndex === -1){
                            continue
                        } else {
                            selectedPlayerList[index]["groupNo"] = groupPartitionData[i].groupNo
                            groupPartitionData[i].data[emptyIndex]._id = selectedPlayer._id
                            groupPartitionData[i].data[emptyIndex].isAdded = true
                            groupPartitionData[i].data[emptyIndex].playerName = selectedPlayer.label
                            groupPartitionData[i].data[emptyIndex].playerImage = selectedPlayer.profilePic?.original || ''
                            break
                        }
                        
                    }
                
            }
        }
        this.setState({
            groupPartitionData,
            selectedPlayerList
        })
    }
    start = async () => {
        let { groupPartitionData, selectedGroupNo } = this.state
            let selectedGame = sessionStorage.getItem('selectedGame')
            let path = getGamePath(parseInt(selectedGame))
            let data = {
                groupCount: groupPartitionData?.length,
                perGroupStudentCount: selectedGroupNo,
                group_details: groupPartitionData
            }
            await this.props.gameAction.storeGameSettings(path, data)
        
    }
    render() {
        let state = this.state
        let {game} = this.props
        let nextBtnDisabled = false
        let startBtnDisabled = false
        let plList, allTrue;
        if(game.playersList?.length > 0){
            
            plList = game.playersList.filter(pl => pl.isSelected === true)
            if(plList?.length > 2) {
                nextBtnDisabled = false
            } else {
                nextBtnDisabled = true
            }
        } else {
            nextBtnDisabled = true
        }

        if(state.selectedPlayerList?.length > 0){
            allTrue = state.selectedPlayerList.every(pl => pl.isSelected === true)
            if(allTrue) {
                startBtnDisabled = false
            } else {
                startBtnDisabled = true
            }
        } else {
            startBtnDisabled = true
        }
        return (
            <>
                {
                state.tab === 1 && 
                    <SelectGroupPlayers 
                        {...this.state}
                        {...this.props}
                        nextBtnDisabled={nextBtnDisabled}
                        selectAll={this.selectAll}
                        selectedObject={this.selectedObject}
                        next={() => this.renderTab(2, plList)}
                    />
                }
                {
                state.tab === 2 && 
                    <SetPlayersPerGroup
                        {...this.state} 
                        {...this.props}
                        selectedPlayersList={state.selectedPlayerList}
                        nextBtnDisabled={nextBtnDisabled}
                        startBtnDisabled={startBtnDisabled}
                        selectAll={this.autoSelectPlayers}
                        next={() => this.start()}
                        selectedGroup={this.selectedGroup}
                        addPlayersToGroup={this.addPlayersToGroup}
                    />
                }
            </>
        )
    }
}

const mapStateToProps = ({ tubGallery, myProfile, loader, session, tubinbox, game }) => {
    return {
        tubGallery,
        myProfile,
        tubinbox,
        loader,
        session,
        game
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        gameAction : bindActionCreators(gameAction, dispatch),
        dispatch
    }
};

export const SelectGroup = ClassPresentationWrapper(connect(mapStateToProps, mapDispatchToProps)(SelectGroupClass))

