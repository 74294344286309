import React, { useState } from 'react'
import { loaders } from '../../service/actionType'
import FormInput from '../inputs/FormInput'
import { API } from 'aws-amplify';
import { connect } from 'react-redux'
import { ENVIRONMENT } from '../../common/constants';
import { bindActionCreators } from "redux";
import { accessHeaders } from '../../helpers';

const SearchSuggestion = (props) => {
    const [search, onChangeSearch] = useState(null)
    const [showSuggessionPopup, setSuggestionPopup] = useState(false)
    const [suggestionsList, setSuggestionList] = useState([])

    const handleSearch = (value) => {
        onChangeSearch(value)
        if(value == ''){
            setSuggestionPopup(false)
            props.onSuggestionSelect('')
        } else {
            setSuggestionPopup(true)
        }
        getSuggestion(value)
    }
    const getSuggestion = async (search) => {
        try {
            let selectedClass = sessionStorage.getItem('selectedClass')
            let {dataToPersist} = props
            let url = "gallery/suggestions";
            let myInit = {
                headers: accessHeaders(),
                queryStringParameters: {
                    classId: selectedClass ? selectedClass : dataToPersist.selectedClass.value,
                    schoolId: sessionStorage.getItem('schoolId'),
                    search: search
                }
            }
            const response = await API.get(ENVIRONMENT, url, myInit);
            if(response.status === 200){
                setSuggestionList(response.data)
            } else {
                setSuggestionList([])
            }
        } catch (error) {
            console.log("error", error);
        }
    }
    const onSuggestionSelect = (label) => {
        setSuggestionPopup(false)
        onChangeSearch(label)
        props.onSuggestionSelect(label)
    }
    return (
        <>
                <FormInput
                    id="search"
                    name="search"
                    type="search"
                    placeholder="search..."
                    onChange={(name, value) => handleSearch(value)}
                    value={search}
                    className="m-0"
                    groupclassName='search-type-1'
                />
                {
                showSuggessionPopup && 
                <div className='suggestion_container'>
                    {suggestionsList?.length > 0 
                        ?
                        suggestionsList.map(match => (
                            <div>
                                <p className='suggestion' onClick={() => onSuggestionSelect(match.label)}>{match.label}</p>
                            </div>
                        ))
                        :
                        <div>
                            <p className='no_suggestion'>No suggestion found</p>
                        </div>
                    }
                </div>
            }
            
        </>
    )
}


const mapStateToProps = ({ loader,dataToPersist }) => {
    return {
        loader,
        dataToPersist
    };
};

let mapDispatchToProps = (dispatch) => {
    return {
        dispatch
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchSuggestion)