import { React, Component } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import ButtonType1 from '../../../../../../components/inputs/ButtonType1';

class SelectGroupPlayersClass extends Component {
    constructor(props) {
        super(props);
        this.state = { 
        };
    }

    async componentDidMount() {

    }
    render() {
        let {game} = this.props
        return (
            <div className='clpContainer container'>
                <div className="row">
                    <div className="col-12">
                        <div className="mt-2">
                            <p className="clp_heading1 text-center">Select a Player for Group Play</p>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="row no-gutters justify-content-center">
                            <div className="col-12">
                                <div className="row align-items-center justify-content-center mt-5">
                                    {game.playersList && game.playersList.map((dt, i) => (
                                        <div className='relative-pos' key={i}>
                                            <div className='cursor-pointer game_player_img w-85 mt-3 text-center mlr-auto' onClick={() => this.props.selectedObject(dt._id)}>
                                                <div>
                                                    <img
                                                        src={(dt.profilePic != undefined && dt.profilePic.original != undefined && dt.profilePic.original != "") ? dt.profilePic.original : require("../../../../../../assets/images/noImage.png")}
                                                        className="whbr-70"
                                                        alt="alphatub"
                                                    />
                                                </div>
                                                <div className="mt-2">
                                                    <p className='clpSelectPlayer text-center'>{dt.label ? dt.label : '-'}</p>
                                                </div>
                                            </div>
                                            {dt.isSelected &&
                                                <div className="selectedPlayer">
                                                    <img
                                                        src={
                                                            require("../../../../../../assets/images/selectedPlayer.png")
                                                        }
                                                        className=""
                                                        alt="alphatub"
                                                    />
                                                </div>
                                            }
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 fixedGameButtons">
                        <div className='row justify-flex-end'>
                            <div className="col-2">
                                <ButtonType1
                                    btnName="Select All"
                                    className='buttonType2'
                                    onClick={() => this.props.selectAll()}
                                    disabled={false}
                                />
                            </div>
                            <div className="col-2">
                                <ButtonType1
                                    btnName="Next"
                                    className='buttonType2'
                                    onClick={() => this.props.next()}
                                    disabledClassName="l-lh-42"
                                    disabled={this.props.nextBtnDisabled}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export const SelectGroupPlayers = SelectGroupPlayersClass

