import { loaders, main } from '../service/actionType';
// import { API } from '../service/api';
import { API } from 'aws-amplify';
import { API_BOOK } from '../service/endpoints';
import { Auth } from 'aws-amplify';
import toast from '../helpers/Toast';
import { accessHeaders, history } from '../helpers';
import _, { assign } from 'lodash';
import { ENVIRONMENT } from '../common/constants';
import moment from 'moment';
import teacher_choice_add from "../assets/images/teacher_add_choice.svg"

let defaultTeacherChoice = { 
    teacher_Choice: [{
        alphabetImage: { original : teacher_choice_add },
        isDraft: true
    }],
    program_Choice: [],
    isToCreate: true, 
    start_Date: null,
    end_Date: moment().add(1, 'd').format('YYYY-MM-DDT[00:00:00.000Z]'),
    assignment_Name: ''
}

export const getAssignmentGalleryList = () => async (dispatch, getState) => {
    try {
        dispatch({ type: loaders.ASSIGNMENT_TABLE_LIST_FETCHING, payload: true })
        let url = "user/v3.7/tubclass/getGalleryList?type=1";
        let myInit = {
            headers: accessHeaders()
        }
        const response = await API.get(ENVIRONMENT, url, myInit);
        if(response.status === 200){
            dispatch({ type: main.ASSIGNMENT_GALLERY_LIST, payload: response.data })
            dispatch({ type: loaders.ASSIGNMENT_TABLE_LIST_FETCHING, payload: false })
        } else {
            dispatch({ type: main.ASSIGNMENT_GALLERY_LIST, payload: [] })
            dispatch({ type: loaders.ASSIGNMENT_TABLE_LIST_FETCHING, payload: false })
        }
    } catch (error) {
        console.log("error", error);
        dispatch({ type: loaders.ASSIGNMENT_TABLE_LIST_FETCHING, payload: false })
    }
}


export const getAssignedList = (isDisabledPastAssignmentBtns) => async (dispatch, getState) => {
    try {
        dispatch({ type: loaders.IS_ASSIGNMENT_LOADING, payload: true })
        let { assignedData, getAssignmentQuery} = getState().curriculum
        assignedData = {}
        dispatch({type: main.ASSIGNED_LIST, payload: {}})
        let url = "assignment/v3.7/getAssingmentData";
        let myInit = {
            headers: accessHeaders(),
            queryStringParameters: getAssignmentQuery
        }
        const response = await API.get(ENVIRONMENT, url, myInit);
        if(response.status == 200){
            let result = response.data
            if(!isDisabledPastAssignmentBtns ){
                if(!_.isEmpty(result) ){
                    // result["end_Date"] = moment(result.start_Date).add(1, 'd').format('YYYY-MM-DDT[00:00:00.000Z]')
                    result["isFromApi"] = true
                    if(!result.isAssignmentSent){
                        if(
                            !_.isEmpty(result["program_Choice"]) && 
                            !_.isEmpty(result["teacher_Choice"]) && 
                            ((parseInt(result["teacher_Choice"]?.length) + parseInt(result["program_Choice"]?.length )) < 9 )
                        ) {
                            result["teacher_Choice"].push(defaultTeacherChoice.teacher_Choice[0])
                        } else if ( 
                            _.isEmpty(result["program_Choice"]) && 
                            !_.isEmpty(result["teacher_Choice"]) &&
                            result["teacher_Choice"]?.length < 9
                        ) {
                            result["teacher_Choice"].push(defaultTeacherChoice.teacher_Choice[0])
                        } else if ( 
                            !_.isEmpty(result["program_Choice"]) && 
                            _.isEmpty(result["teacher_Choice"]) &&
                            parseInt(result["program_Choice"]?.length) < 9
                        ) {
                            result["teacher_Choice"].push(defaultTeacherChoice.teacher_Choice[0])
                        } else if( 
                            _.isEmpty(result["program_Choice"]) && 
                            _.isEmpty(result["teacher_Choice"])
                        ) {
                            result["teacher_Choice"].push(defaultTeacherChoice.teacher_Choice[0])
                        }
                    }
                } else {
                    result = { 
                        teacher_Choice: [{
                            alphabetImage: { original : teacher_choice_add },
                            isDraft: true
                        }],
                        program_Choice: [],
                        isToCreate: true, 
                        start_Date: null,
                        end_Date: moment(getAssignmentQuery.date).add(1, 'd').format('YYYY-MM-DDT[00:00:00.000Z]'),
                        assignment_Name: '',
                        _id: ''
                    }
                }
            }
            if(_.isEmpty(result)){
                dispatch({type: main.CREATE_DEFAULT_ASSIGNED_LIST})
            } else {
                dispatch({type: main.CREATE_ASSIGNED_LIST, payload: result})
            }

            if(!_.isEmpty(result._id)) {
                if(result.isAssignmentSent){
                    await getAssignmentTableList()(dispatch, getState)
                } else {
                    await getAssignmentGalleryList()(dispatch, getState)
                }
            } else {
                await getAssignmentGalleryList()(dispatch, getState)
            }

            dispatch({ type: loaders.IS_ASSIGNMENT_LOADING, payload: false })
        } else {
            dispatch({type: main.CREATE_ASSIGNED_LIST, payload: {}})
            dispatch({ type: loaders.IS_ASSIGNMENT_LOADING, payload: false })
        }
    } catch (error) {
        dispatch({type: main.CREATE_ASSIGNED_LIST, payload: {}})
        dispatch({ type: loaders.IS_ASSIGNMENT_LOADING, payload: false })
        toast(error?.response?.data?.message, 'error')
    }
} 
export const getCurriculumList = () => async (dispatch, getState) => {
    try {
        const username = localStorage.getItem("username")
        if(username){
            dispatch({ type: loaders.IS_APP_FETCHING, payload: true });
            const url = 'user?username=' + username;
            const myInit = {
                headers: accessHeaders()
            }
            const response = await API.get(ENVIRONMENT, url, myInit);
            
        }
        
    } catch (error) {
        console.log("error", error);
        dispatch({ type: loaders.IS_APP_FETCHING, payload: false })
    }
}

export const getReadingProgramsList = (search = null, Session_Date = null) => async (dispatch, getState) => {
    try {
        const { curriculumAndProgramNames } = getState().curriculum
        if(curriculumAndProgramNames != null){
            let { dataToPersist } = getState()
            let selectedClass = sessionStorage.getItem('selectedClass')

            let url = "admin_panel/v3.7/curriculum/getReadingProgramData";
            let myInit = {
                headers: accessHeaders(),
                queryStringParameters: {
                    reading_Program_Id: curriculumAndProgramNames?.reading_Program_Id,
                    classId: selectedClass ? selectedClass :  dataToPersist.selectedClass?.value,
                    schoolId: sessionStorage.getItem('schoolId'),
                    search: search,
                    Session_Date: Session_Date
                }
            }
            const response = await API.get(ENVIRONMENT, url, myInit);
            if(response.status === 200){
                dispatch({ type: main.READING_PROGRAM_LISTS, payload: response.data })
            } else {
                dispatch({ type: main.READING_PROGRAM_LISTS, payload: [] })
            }
        } else {
            dispatch({ type: main.READING_PROGRAM_LISTS, payload: [] })
        }
    } catch (error) {
        console.log("error", error);
    }
}

export const getReadingProgramData = (id, classId) => async (dispatch, getState) => {
    try {

        let url = "admin_panel/v3.7/curriculum/getReadingProgramData";
        let myInit = {
            headers: accessHeaders(),
            queryStringParameters: {
                reading_Program_Id: id,
                classId: classId,
                schoolId: sessionStorage.getItem('schoolId'),
            }
        }
        const response = await API.get(ENVIRONMENT, url, myInit);
        if(response.status === 200){
            dispatch({ type: main.READING_PROGRAM_LISTS, payload: response.data })
        } else {
            dispatch({ type: main.READING_PROGRAM_LISTS, payload: [] })
        }
    } catch (error) {
        console.log("error", error);
    }
}

export const getCurriculumAndProgramName = () => async (dispatch, getState) => {
    try {
        let url = "assignment/v3.8/programDetails";
        let { dataToPersist } = getState()
        let selectedClass = sessionStorage.getItem('selectedClass')
        let myInit = {
            headers: accessHeaders(),
            queryStringParameters: {
                classId: selectedClass ? selectedClass :  dataToPersist.selectedClass?.value,
                schoolId : sessionStorage.getItem('schoolId')
            }
        }
        const response = await API.get(ENVIRONMENT, url, myInit);
        if(response.status === 200){
            dispatch({ type: main.CURRICULUM_AND_READING_PROGRAM, payload: response.data })
        } else {
            dispatch({ type: main.CURRICULUM_AND_READING_PROGRAM, payload: [] })
        }
    } catch (error) {
        console.log("error", error);
    }
}


export const getAssignmentDropdownList = () => async (dispatch, getState) => {
    try {
        let url = "assignment/v3.7/getAssignmentList";
        let { dataToPersist } = getState()
        let selectedClass = sessionStorage.getItem('selectedClass')
        let myInit = {
            headers: accessHeaders(),
            queryStringParameters: {
                classId: selectedClass ? selectedClass :  dataToPersist.selectedClass?.value,
                schoolId : sessionStorage.getItem('schoolId')
            }
        }
        const response = await API.get(ENVIRONMENT, url, myInit);
        if(response.status === 200 && !_.isEmpty(response.data)){
            let res = response.data
            let newres = res.map(rs => ({label: rs.assignment_Number, value: rs.assignmentId}))
            dispatch({ type: main.ASSIGNMENTS_DROPDOWN_LIST, payload: newres })
        } else {
            dispatch({ type: main.ASSIGNMENTS_DROPDOWN_LIST, payload: [] })
        }
    } catch (error) {
        console.log("error", error);
    }
}

export const changeAssignmentDropdownList = (id) => async (dispatch, getState) => {
    try {
        dispatch({ type: loaders.INNER_POPUP_LOADING, payload: true})
        let url = "assignment/v3.8/changeAssignmentDropdownResult";
        let myInit = {
            headers: accessHeaders(),
            queryStringParameters: {
                reading_Program_Id: id
            }
        }
        const response = await API.get(ENVIRONMENT, url, myInit);
        if(response.status === 200 && !_.isEmpty(response.data)){
            let dropdonwdata = response.data && response.data.map(dt => ({label: dt.label, value: dt?.value, data: {data: dt.data, title: dt.title}}))
            dispatch({ type: main.CHANGE_ASSIGNMENTS_DROPDOWN_LIST, payload: dropdonwdata })
            dispatch({ type: loaders.INNER_POPUP_LOADING, payload: false})
        } else {
            dispatch({ type: main.CHANGE_ASSIGNMENTS_DROPDOWN_LIST, payload: [] })
            dispatch({ type: loaders.INNER_POPUP_LOADING, payload: false})
            toast(response?.data?.message,'error')
        }
    } catch (error) {
        dispatch({ type: main.CHANGE_ASSIGNMENTS_DROPDOWN_LIST, payload: [] })
        dispatch({ type: loaders.INNER_POPUP_LOADING, payload: false})
        console.log("error", error);
        toast(error?.message,'error')
    }
}

export const getAssingmentCalendarDatesByMonth = (queryPayload) => async (dispatch, getState) => {
    try {
        dispatch({ type: loaders.ASSIGNMENT_DATES_FETCHING, payload: true })
        let url = "assignment/v3.7/getAssingmentDataByMonth";
        let myInit = {
            headers: accessHeaders(),
            queryStringParameters: {
                ...queryPayload,
                userName: localStorage.getItem("username")
            }
        }
        const response = await API.get(ENVIRONMENT, url, myInit);
        if(response.status === 200){
            let formattedList = [], assignmentDates = [], unAssignmentDates = []
            if(response.data.calendarDatesList?.length > 0){
                response.data.calendarDatesList.map(dt => dt.dates.map(date => {
                    if (date.isHoliday) {
                        formattedList.push(new Date(date.holiday_Dates))
                    }
                }))
            }
            if(response.data.AssignmentData?.length > 0){
                response.data.AssignmentData.map(dt => {
                    assignmentDates.push(new Date(dt.start_Date))
                })
            }

            if(response.data.AssignmentUnSendData?.length > 0){
                response.data.AssignmentUnSendData.map(dt => {
                    unAssignmentDates.push(new Date(dt.start_Date))
                })
            }

            dispatch({ 
                type: main.ASSIGNMENT_CALENDAR_DATES_LIST, 
                payload: {
                    sessionDisableDatesList: formattedList, 
                    assignmentDates: assignmentDates,
                    unAssignmentDates: unAssignmentDates,
                    academicYear: response.data.schoolAcademicYear
                } 
            })
        }
        dispatch({ type: loaders.ASSIGNMENT_DATES_FETCHING, payload: false })
    } catch (error) {
        console.log("error", error);
        toast(error.message, 'error');
        dispatch({ type: loaders.ASSIGNMENT_DATES_FETCHING, payload: false })
    }

}

// assignment screen - table list api
export const getAssignmentTableList = () => async (dispatch, getState) => {
    try {
        dispatch({ type: loaders.ASSIGNMENT_TABLE_LIST_FETCHING, payload: true })
        let url = "assignment/v3.7/getAssingmentSubmissionResults";
        let { curriculum } = getState()
        if(!_.isEmpty(curriculum.assignedData?._id)){
            let myInit = {
                headers: accessHeaders(),
                queryStringParameters: {
                    _id: curriculum?.assignedData?._id
                }
            }
            const response = await API.get(ENVIRONMENT, url, myInit);
            if(response.status === 200 && !_.isEmpty(response.data)){
                let res = response.data
                dispatch({ type: main.ASSIGNMENT_TABLE_LIST, payload: res })
                dispatch({ type: loaders.ASSIGNMENT_TABLE_LIST_FETCHING, payload: false })
            } else {
                dispatch({ type: main.ASSIGNMENT_TABLE_LIST, payload: [] })
                dispatch({ type: loaders.ASSIGNMENT_TABLE_LIST_FETCHING, payload: false })
            }
        } else {
            console.log('here')
            dispatch({ type: main.ASSIGNMENT_TABLE_LIST, payload: [] })
            dispatch({ type: loaders.ASSIGNMENT_TABLE_LIST_FETCHING, payload: false })
        }
    } catch (error) {
        console.log("error", error);
    }
}

// assignment track screen - table list api
export const trackAssignmentsList = () => async (dispatch, getState) => {
    try {
        let { dataToPersist } = getState()
        let selectedClass = sessionStorage.getItem('selectedClass')
        let url = "assignment/v3.7/getAllAssignments";
        let myInit = {
            headers: accessHeaders(),
            queryStringParameters: {
                classId: selectedClass ? selectedClass :  dataToPersist.selectedClass?.value,
                schoolId : sessionStorage.getItem('schoolId')
            }
        }
        const response = await API.get(ENVIRONMENT, url, myInit);
        if(response.status === 200 && !_.isEmpty(response.data)){
            let res = response.data
            dispatch({ type: main.TRACK_ASSIGNMENT_TABLE_LIST, payload: res })
            dispatch({ type: main.COPYOF_TRACK_ASSIGNMENT_TABLE_LIST, payload: res })
        } else {
            dispatch({ type: main.TRACK_ASSIGNMENT_TABLE_LIST, payload: [] })
            dispatch({ type: main.COPYOF_TRACK_ASSIGNMENT_TABLE_LIST, payload: [] })
        }
        
    } catch (error) {
        console.log("error", error);
    }
}

export const sendAssignmentNotification = (query) => async (dispatch, getState) => {
    try {
        let url = "assignment/v3.7/sendContentToParents";
        let myInit = {
            headers: accessHeaders(),
            queryStringParameters: {
                ...query
            }
        }
        const response = await API.get(ENVIRONMENT, url, myInit);
        if(response.status === 200){
            toast('success', 'success')
        }
    } catch (error) {
        console.log("error", error);
    }
}

export const notifyAssignment = (query) => async (dispatch, getState) => {
    try {
        let url = "assignment/v3.7/notifyToParents";
        let myInit = {
            headers: accessHeaders(),
            queryStringParameters: {
                ...query
            }
        }
        const response = await API.get(ENVIRONMENT, url, myInit);
        if(response.status === 200){
            toast('success', 'success')
        }
    } catch (error) {
        console.log("error", error);
    }
}


export const notifyToAll = (query) => async (dispatch, getState) => {
    try {
        let url = "assignment/v3.7/notifyToAllStudentParents";
        let myInit = {
            headers: accessHeaders(),
            queryStringParameters: {
                ...query
            }
        }
        const response = await API.get(ENVIRONMENT, url, myInit);
        if(response.status === 200){
            toast('success', 'success')
        }
    } catch (error) {
        console.log("error", error);
    }
}