import { React, useEffect, useState } from "react";
import { AssignmentScreenContent, ChangeAssignmentScreenContent, TeacherAssignmentContent } from 'components/tubClass/assigmentContent'
import ButtonType1 from "components/inputs/ButtonType1";
import _ from "lodash";
import FormInput from "components/inputs/FormInput";
import { CreateModel } from "components/Model/createModel";
import { CommonDatePopup } from "components/tubClass/commonDatePopup";
import { formatDate, formatDateWithText, history } from "helpers";
import { InnerAppLoader } from "components/loader/appLoader";
import moment from "moment";
import { SendLaterDatePopup } from "components/tubClass/sendLaterDatePopup";
import { ReadingProgramView } from "components/ReadingPrograms/readingProgramView";

export const AssignmentView = (props) => {
    const [isShowChange, toggleChangeProgram] = useState(false)
    const [isShowRemove, toggleRemoveContent] = useState(false)
    const [assignmentData, setAssignmentData] = useState({})
    const [isShowDatePopup, toggleDatePopup] = useState(false)
    const [isShowSendLaterDatePopup, toggleSendLaterDatePopup] = useState(false)
    const [isShowNamePopup, toggleNamePopup] = useState(false)
    const [assignment_Name, setassignment_Name] = useState(null)
    const [popupData, setPopupData] = useState({
        assignment_Name: '',
        label: '',
        reading_Program_Hierarachy: null,
        data: []
    })
    const [isNewReadingProgram, setIsNewReadingProgram] = useState(false)

    useEffect(() => {
        setAssignmentData(props.curriculum.assignedData)
    }, [props])

    const onChangePopupFields = (name, value) => {
        let { curriculum } = props
        let extraData = curriculum.changeAssignmentDropdowns.find(dr => dr.value == value)
        let ppData = {
            ...popupData,
            [name]: value,
            label: extraData?.label,
            assignment_Name: extraData?.data?.title,
            data: extraData.data?.data
        }
        setPopupData(ppData)
    }

    const saveChangeProgram = async () => {
        if (!_.isEmpty(popupData.data)) {
            assignmentData.teacher_Choice.pop()
            let assignData = {
                program_Choice: popupData.data,
                reading_Program_Hierarachy: popupData.label,
                assignment_Name: popupData.assignment_Name,
                end_Date: assignmentData?.end_Date,
                curriculum_Id: props.curriculum?.curriculumAndProgramNames?.curriculum_Details?._id,
                start_Date: props.curriculum.getAssignmentQuery?.date,
                classId: props.curriculum.getAssignmentQuery.classId,
                schoolId: props.curriculum.getAssignmentQuery.schoolId
            }
            if (!isNewReadingProgram) {
                assignData = {
                    ...assignmentData,
                    ...assignData
                }
            }
            await props.updateAssignment(assignData)
            setPopupData({ ...popupData, reading_Program_Hierarachy: '' })
            toggleChangeProgram(false)
        }
    }

    const onConfirmDate = async (selectedDate) => {
        let newDate = moment(selectedDate).format('YYYY-MM-DDT[00:00:00.000Z]')
        await props.updateDeadline(newDate)
        toggleDatePopup(false)
    }

    const onConfirmSendLater = async (selectedDate) => {
        await props.changeDate(selectedDate)
        toggleSendLaterDatePopup(false)
    }

    const onSelectChangeProgram = (isNew) => {
        let id = props.curriculum.curriculumAndProgramNames?.reading_Program_Id
        props.curriculumAction.changeAssignmentDropdownList(id)
        setIsNewReadingProgram(isNew)
        setPopupData({ ...popupData, reading_Program_Hierarachy: '', data: [] })
        toggleChangeProgram(true)
    }

    const getReadingPrItems = (rd) => {
        if (!_.isEmpty(rd?.reading_Program_Hierarachy) && rd?.program_Choice?.length > 0) {
            return (
                <div className="row no-gutters">
                    <div className="col-7">
                        <div className='d-flex'>
                            <p className='curriculum_nm_f_1 fs-12'>{rd?.reading_Program_Hierarachy}</p>
                        </div>
                    </div>
                </div>
            )
        } else {
            return (
                <>
                    <div className="d-flex justify-space-between">
                        <div className="col-3 p-0">
                            {rd?.isAssignmentSent || props.isDisabledPastAssignmentBtns ?
                                <p
                                    className="alp_text_success_1"
                                >
                                    {!_.isEmpty(rd?.assignment_Name) ? rd.assignment_Name : ''}
                                </p>
                                :
                                <p
                                    className="alp_text_success_1 cursor-pointer"
                                    onClick={() => toggleNamePopup(true)}
                                >
                                    {!_.isEmpty(rd?.assignment_Name) ? rd.assignment_Name : 'Add Name'}
                                </p>
                            }
                        </div>
                        {rd?.isAssignmentSent || props.isDisabledPastAssignmentBtns || rd?.program_Choice?.length != 0 ?
                            <></>
                            :
                            <div className="col-6 p-0">
                                <p className="assignment_text_1 cursor-pointer" onClick={() => redirectTo(`/dashboard/tubclass/readingprogram?date=${formatDate(props?.contentSelectedDate)}`)}>Add Program Choice Assignment</p>
                            </div>
                        }
                    </div>
                </>

            )
        }
    }

    const onSelectRemoveContent = (data) => {
        setPopupData({ ...popupData, ...data })
        toggleRemoveContent(true)
    }

    const removeAssignment = async () => {
        await props.removeAssignment(
            {
                _id: assignmentData._id,
                isDeleted: true
            }
        )
        toggleRemoveContent(false)
    }

    const saveAssignmentName = () => {
        props.setAssignmentName(assignment_Name)
        toggleNamePopup(false)
    }

    const isSendDisable = () => {
        if (
            (Array.isArray(assignmentData?.program_Choice) && assignmentData?.program_Choice?.length > 0) ||
            (Array.isArray(assignmentData?.teacher_Choice) && assignmentData?.teacher_Choice?.length > 1)
        ) {
            return false
        } else return true
    }

    const redirectTo = (to) => {
        history.push(to)
    }

    return (
        <>

            <div className="col-md-8">
                <div className="tub-card-1 assignment_program_container">
                    {props.loader.assignmentLoading ?
                        <>
                            <InnerAppLoader />
                        </>
                        :
                        <>
                            {(props.isDisabledPastAssignmentBtns &&
                                _.isEmpty(assignmentData))
                                ?
                                <div className="h_inherit d-flex justify-content-center align-items-center">
                                    <div>
                                        <p className="empty_data_text">No Assignment Found on this date</p>
                                        <div className="mt-4 empty_data_container">
                                            <ButtonType1
                                                disabled={false}
                                                btnName="Track All Assignments"
                                                className='buttonType7'
                                                onClick={() => redirectTo('/dashboard/tubclass/trackassignment')}
                                            />
                                        </div>
                                    </div>
                                </div>
                                :
                                <div>
                                    {
                                        getReadingPrItems(assignmentData)
                                    }
                                    {!_.isEmpty(assignmentData) && !_.isEmpty(assignmentData?.program_Choice) &&
                                        <div className="mt-3 col-12 p-0">
                                            <div className="d-flex justify-content-between">
                                                <p className="f-18 fw-600 alphatub-card-bold-p text-center">
                                                    Program Choice Assignment
                                                </p>
                                                {assignmentData?.isAssignmentSent || props.isDisabledPastAssignmentBtns ?
                                                    <></>
                                                    :
                                                    <div className="d-flex">
                                                        <p className="alp_text_success_1 cursor-pointer" onClick={() => redirectTo(`/dashboard/tubclass/readingprogram?date=${formatDate(props?.contentSelectedDate)}&_id=${assignmentData?._id}`)}>Change</p>
                                                        <p className="alp_text_failure_1 ml-3 cursor-pointer" onClick={() => onSelectRemoveContent(assignmentData)}>Remove</p>
                                                    </div>
                                                }
                                            </div>
                                            <div className="mt-3">
                                                <AssignmentScreenContent
                                                    assignmentContent={assignmentData?.program_Choice}
                                                />
                                            </div>
                                        </div>
                                    }
                                    <div>
                                        <>
                                            <div className="mt-3 d-flex justify-content-between">
                                                {
                                                    // ?
                                                    (!_.isEmpty(assignmentData?.teacher_Choice) &&
                                                        assignmentData?.teacher_Choice?.length > 0 &&
                                                        <p className="f-18 fw-600 alphatub-card-bold-p">
                                                            Teacher's Choice
                                                        </p>
                                                    )
                                                    // :
                                                    // <p className="f-18 fw-600 alphatub-card-bold-p">

                                                    // </p>
                                                }
                                                {
                                                    !props.isDisabledPastAssignmentBtns &&
                                                    !_.isEmpty(assignmentData?.teacher_Choice) &&
                                                    _.isEmpty(assignmentData?.program_Choice) &&
                                                    assignmentData?.teacher_Choice?.length > 1 &&
                                                    assignmentData?.isFromApi &&
                                                    !assignmentData?.isAssignmentSent &&
                                                    <div>
                                                        <p className="alp_text_failure_1 ml-3 cursor-pointer" onClick={() => onSelectRemoveContent(assignmentData)}>Remove</p>
                                                    </div>
                                                }
                                            </div>
                                            <div className="mt-3">
                                                <TeacherAssignmentContent
                                                    assignmentContent={assignmentData}
                                                    removeTeacherContent={props.removeTeacherContent}
                                                    addWordToTeacherChoice={props.addWordToTeacherChoice}
                                                />
                                            </div>
                                        </>

                                    </div>
                                </div>
                            }
                            <div className="mt-3">
                                {!_.isEmpty(assignmentData) &&
                                    <div className="row no-gutters">
                                        <div className="col-6">
                                            <div className="d-flex align-items-center">
                                                <p className="f-15 alphatub-card-bold-p">
                                                    Deadline :
                                                </p>
                                                {assignmentData?.isAssignmentSent ?
                                                    <span className="d-flex align-items-center ml-2">
                                                        <p className="deadline_date ml-2 mr-2">{formatDateWithText(assignmentData['end_Date'])}</p>
                                                    </span>
                                                    :
                                                    <span className="d-flex align-items-center ml-2">
                                                        <div className="left-arrow cursor-pointer" onClick={() => toggleDatePopup(true)}></div>
                                                        <p className="deadline_date ml-2 mr-2">{formatDateWithText(assignmentData['end_Date'])}</p>
                                                        <div className="right-arrow cursor-pointer" onClick={() => toggleDatePopup(true)}></div>
                                                    </span>
                                                }
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="d-flex justify-content-end">
                                                {!assignmentData?.isAssignmentSent &&
                                                    <div className="w_130px ml-3">
                                                        <ButtonType1
                                                            btnName="Send"
                                                            disabled={isSendDisable()}
                                                            disabledClassName="p-0 h-lh-25"
                                                            className="btn-bg1 p-0 buttonType1"
                                                            onClick={() => props.sendAssignment()}
                                                        />
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </>
                    }
                </div>
            </div>
            {/* change popup */}
            <CreateModel
                isOpen={isShowChange}
                toggle={() => toggleChangeProgram(!isShowChange)}
                title={'Change Program Choice Assignment'}
                className="mx-500"
            >
                <div className="container">
                    <div className="row mt-4">
                        <div className="col-8">
                            <FormInput
                                type="select"
                                label="Choose Reading Program"
                                name="reading_Program_Hierarachy"
                                groupclassName = "mb-3"
                                options={props?.curriculum?.changeAssignmentDropdowns}
                                placeholder="Reading Program Hierarchy"
                                onChange={(...params) => onChangePopupFields(...params)}
                                value={popupData["reading_Program_Hierarachy"]}
                            />
                        </div>
                    </div>
                    {popupData?.data?.length > 0 &&
                        <ReadingProgramView
                            btnName={null}
                            readingPr={popupData}
                            // setReadingPr={saveChangeProgram}
                        />
                    }
                    <div className="d-flex align-items-center mt-4 content-end">
                        <div className="col-4 p-0">
                            <ButtonType1
                                btnName="Confirm"
                                disabled={_.isEmpty(popupData.data) ? true : false}
                                className='buttonType1'
                                onClick={() => saveChangeProgram()}
                            />
                        </div>
                    </div>
                </div>
            </CreateModel>

            {/* remove popup */}
            <CreateModel
                isOpen={isShowRemove}
                toggle={() => toggleRemoveContent(!isShowRemove)}
                title={"Delete Assignment"}
            >
                <div className="alphatub-card-border"></div>
                <div className="row pt-3 pb-5">
                    <div className="col-12">
                        <p className="f-16px alphatub-card-normal-p">
                            Do you want to remove this Assignment ?
                        </p>
                    </div>
                </div>
                <div className="alphatub-card-border"></div>
                <div className="row align-items-center mt-3 content-end">
                    <div className="col-4">
                        <ButtonType1
                            btnName="Close"
                            className='popup-close-btn'
                            onClick={() => toggleRemoveContent(!isShowRemove)}
                        />
                    </div>
                    <div className="col-4">
                        <ButtonType1
                            btnName="Confirm"
                            className='bg-tub c-white buttonType1 '
                            onClick={() => removeAssignment()}
                        />
                    </div>
                </div>
            </CreateModel>

            {/* deadline date popup */}
            <CommonDatePopup
                showDatePopup={isShowDatePopup}
                triggerPopup={toggleDatePopup}
                onClick={(selectedDate) => onConfirmDate(selectedDate)}
                session={props.session}
                mindate={moment(props.curriculum.getAssignmentQuery?.date).add(1, 'd').format('YYYY-MM-DD')}
                title={'Change Deadline'}
            />
            {/* send later */}
            <SendLaterDatePopup
                mindate={moment(props.curriculum.getAssignmentQuery?.date).add(1, 'd').format('YYYY-MM-DD')}
                maxdate={assignmentData?.end_Date}
                showDatePopup={isShowSendLaterDatePopup}
                triggerPopup={toggleSendLaterDatePopup}
                onClick={(selectedDate) => onConfirmSendLater(selectedDate)}
                session={props.session}
                title={'Choose Date To send Assignment Later'}
            />
            {/* add assignment name */}
            <CreateModel
                isOpen={isShowNamePopup}
                toggle={() => toggleNamePopup(!isShowNamePopup)}
                title={'Add Name'}
                className="mx-500"
            >
                <div className="row mt-3">
                    <div className="col-12">
                        <FormInput
                            type="text"
                            label="Please name the teacher choice assignment"
                            name="assignment_Name"
                            placeholder=""
                            onChange={(name, value) => setassignment_Name(value)}
                            value={assignment_Name}
                        />
                    </div>
                </div>
                <div className="row align-items-center mt-3 content-end">
                    <div className="col-4">
                        <ButtonType1
                            btnName="Close"
                            className='popup-close-btn'
                            onClick={() => toggleNamePopup(!isShowNamePopup)}
                        />
                    </div>
                    <div className="col-4">
                        <ButtonType1
                            btnName="Save Changes"
                            className='bg-tub c-white buttonType1 '
                            onClick={() => saveAssignmentName()}
                        />
                    </div>
                </div>
            </CreateModel>
        </>
    )
}