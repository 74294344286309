import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import { AppLoader } from '../components/loader/appLoader';
import * as gameAction from '../actions/game';
import * as classAction from '../actions/class';
import * as myProfileAction from '../actions/myProfile';
import * as sessionAction from '../actions/session';
import * as subscriptionAction from '../actions/subscriptionTeam';
import * as galleryAction from '../actions/tubgallery';
import * as tubInboxAction from '../actions/tubInbox';
import * as curriculumAction from '../actions/curriculum';
import * as analyticsAction from '../actions/analytics';

import moment from 'moment';
import { main } from '../service/actionType';



function ClassPresentationWrapper(WrappedComponent) {

  
    return connect(mapStateToProps, mapDispatchToProps)(class extends React.Component {

        componentDidMount() {
            let pathname = window.location.pathname
            let component = pathname.split('/')
            if (component.includes('presentation')) {
                this.props.gameAction.getReviewTubClass()
            } else if(component.includes('tubclass')){
                this.props.dispatch({ type: main.IS_SELECTED_ACTIVITY_TAB, payload: false})
            }
            this.getApisBasedOnScreen()
        }

        getTubInboxList = async (tubstatus) => {
            let dataToPersist = this.props.dataToPersist
            let selectedClass = sessionStorage.getItem('selectedClass')
            let queryPayload = {
                SchoolID: sessionStorage.getItem('schoolId'),
                ClassID: selectedClass ? selectedClass : dataToPersist.selectedClass?.value,
                TubObjectStatus: tubstatus
            }
            await this.props.tubInboxAction.getTubInboxList(queryPayload)
        }

        getApisBasedOnScreen = async () => {
            let pathname = window.location.pathname

            let dataToPersist = this.props.dataToPersist
            let selectedClass = sessionStorage.getItem('selectedClass')

            switch (pathname) {
                case '/dashboard/settings/profile':
                    await this.props.profileAction.getCountriesList()
                    await this.props.profileAction.getProfileInformations();
                    break;
                case '/dashboard/settings/class':
                    let classQueryPayload = {
                        status: {
                            label: "Active",
                            value: "Active"
                        }
                    }
                    await this.props.classAction.getClassTableList(classQueryPayload)
                    await this.props.subscriptionAction.getSubscriptionData()
                    break;
                case '/dashboard/settings/subscription':
                    await this.props.subscriptionAction.getRolesDropdownList();
                    await this.props.subscriptionAction.getTeamTableData()
                    break;
                case '/dashboard/settings/curriculum': 
                    await this.props.curriculumAction.getCurriculumAndProgramName()
                    break;
                case '/dashboard/tubclass/assignment':
                    const searchParams = new URLSearchParams(this.props.location.search);
                    const date = searchParams.get('date');
                
                    let assignQuery = {
                        classId: selectedClass != null ? selectedClass : dataToPersist.selectedClass?.value,
                        schoolId: sessionStorage.getItem('schoolId'),
                        date: moment(new Date()).startOf('day').format('YYYY-MM-DDT[00:00:00.000Z]')
                    }
                    let sessionDatesqueryPayload = {
                        schoolId: sessionStorage.getItem('schoolId'),
                        month: parseInt(moment(new Date()).format("M")),
                        classId: selectedClass ? selectedClass : dataToPersist.selectedClass?.value,
                    }

                    if(date){
                        assignQuery = {
                            ...assignQuery,
                            date: moment(date).startOf('day').format('YYYY-MM-DDT[00:00:00.000Z]')
                        }
                        sessionDatesqueryPayload = {
                            ...sessionDatesqueryPayload,
                            month: parseInt(moment(date).format("M")),
                        }
                    }
                    
                    await this.props.dispatch({type:main.ASSIGNMENT_QUERY, payload: assignQuery})
                    this.props.curriculumAction.getAssingmentCalendarDatesByMonth(sessionDatesqueryPayload)
                    await this.props.curriculumAction.getCurriculumAndProgramName()
                    await this.props.curriculumAction.getAssignedList(false)
                    break;
                case '/dashboard/tubclass/trackassignment':
                    await this.props.curriculumAction.trackAssignmentsList()
                    break;
                case '/dashboard/tubclass/tubinbox/pending':
                    await this.getTubInboxList("Pending")
                    break;
                case '/dashboard/tubclass/tubinbox/approved':
                    await this.getTubInboxList("Approved")
                    break;
                case '/dashboard/tubclass/tubinbox/rejected':
                    await this.getTubInboxList("Rejected")
                    break;
                case '/dashboard/tubclass/tubinbox/deleted':
                    await this.getTubInboxList("Deleted")
                    break;
                case '/dashboard/tubclass/tubinbox/disabled':
                    await this.getTubInboxList("Disabled")
                    break;
                case '/dashboard/tubclass/tubgallery':
                    let queryPayload = {
                        schoolId: sessionStorage.getItem('schoolId'),
                        month: parseInt(moment(new Date()).format("M")),
                        classId: selectedClass ? selectedClass : dataToPersist.selectedClass?.value,
                    }
                    await this.props.sessionAction.getSessionDatesList(queryPayload)
                    let galleryqueryPayload = {
                        schoolId: sessionStorage.getItem('schoolId'),
                        classId: selectedClass ? selectedClass : dataToPersist.selectedClass?.value,
                    }
                    this.props.curriculumAction.getAssignmentDropdownList()
                    await this.props.galleryAction.getGalleryList(galleryqueryPayload)
                    break;
                case '/dashboard/tubclass/session':
                    // let sessionDatesqueryPayload = {
                    //     schoolId: sessionStorage.getItem('schoolId'),
                    //     month: parseInt(moment(new Date()).format("M")),
                    //     classId: selectedClass ? selectedClass : dataToPersist.selectedClass?.value,
                    // }
                    // this.props.sessionAction.getSessionDatesList(sessionDatesqueryPayload)
                    // this.props.curriculumAction.getAssignmentDropdownList()
                    break;

                // analytics
                case '/dashboard/analytics/overall':
                    await this.props.analyticsAction.resetGraphs()
                    this.props.analyticsAction.fetchGraphData(0, 'director/curriculumProgress', null, null, null)
                    this.props.analyticsAction.fetchGraphData(1, 'director/activityLevelOfTeacher', '30', null, 1)
                    this.props.analyticsAction.fetchGraphData(2, 'director/assinmentCompletion', '30', null, 1)
                    this.props.analyticsAction.fetchGraphData(3, 'director/avgScoreByStudentForHomeSchoolOverall', '30', null, 1)
                    this.props.analyticsAction.fetchGraphData(4, 'director/activityLevelOfParents', '30', null, 1)
                    this.props.analyticsAction.fetchGraphData(5, 'director/parentEngagementMeterForHomeSchoolOverall', '30', null, 1)
                    // this.props.analyticsAction.fetchGraphData(6, 'alphbaticExposureForOverAll', "30", null, 1)
                    this.props.analyticsAction.fetchGraphData(6, 'director/totalStudentandTotalParents', "30", null, 1)
                    this.props.analyticsAction.fetchGraphData(7, 'director/userWiseContributionByOverAll', "30", null, 1)
                    this.props.analyticsAction.fetchGraphData(8, 'director/voacabluaryReportByStudent', '30', null, 1)
                    this.props.analyticsAction.fetchGraphData(9, 'director/socialAwernesReportForHomeSchoolOverall', '30', null, 1)
                    break;
                case '/dashboard/analytics/curriculum':
                    await this.props.analyticsAction.resetGraphs()
                    this.props.analyticsAction.fetchGraphData(0, 'engagementMeterForHomeSchoolOverall', "7", null, 2)
                    this.props.analyticsAction.fetchGraphData(1, 'learningProgressForHomeSchoolOverall', "7", null, 2)
                    this.props.analyticsAction.fetchGraphData(2, 'topPerformersForHomeSchoolOverall', "7", null, 2)
                    this.props.analyticsAction.fetchGraphData(3, 'activeWordsCountForHomeSchoolOverall', "7", null, 2)
                    this.props.analyticsAction.fetchGraphData(4, 'activeTagsCountForHomeSchoolOverall', "7", null, 2)
                    this.props.analyticsAction.fetchGraphData(5, 'recentActivePlayersForHomeSchoolOverall', "7", null, 2)
                    break;
                case '/dashboard/analytics/personalized':
                    await this.props.analyticsAction.resetGraphs()
                    this.props.analyticsAction.fetchGraphData(0, 'engagementMeterForHomeSchoolOverall', "7", null, 3)
                    this.props.analyticsAction.fetchGraphData(1, 'learningProgressForHomeSchoolOverall', "7", null, 3)
                    this.props.analyticsAction.fetchGraphData(2, 'topPerformersForHomeSchoolOverall', "7", null, 3)
                    this.props.analyticsAction.fetchGraphData(3, 'activeWordsCountForHomeSchoolOverall', "7", null, 3)
                    this.props.analyticsAction.fetchGraphData(4, 'activeTagsCountForHomeSchoolOverall', "7", null, 3)
                    this.props.analyticsAction.fetchGraphData(5, 'recentActivePlayersForHomeSchoolOverall', "7", null, 3)
                    break;
                case '/dashboard/analytics/studentreport/students':
                    await this.props.galleryAction.getStudentsList();
                    break;
                case "/class/presentation/home":
                    this.props.gameAction.getActivityList()
                    break;
                case "/class/presentation/detail/view":
                    this.props.gameAction.getDetailActivity()
                    break;
                case "/class/presentation/explore":
                    this.props.gameAction.getDetailActivity()
                    break;
                case "/class/presentation/attendance":
                    this.props.gameAction.getClassAttandanceList()
                    break;
                case "/class/presentation/collage":
                    this.props.gameAction.getCollagePlayersList()
                    break;
                case "/class/presentation/select/game":
                    await this.props.gameAction.getGameTypesAndModes()
                    break;
                case "/class/presentation/select/individual/players":
                    await this.props.gameAction.getPlayersList()
                    break;
                case "/class/presentation/select/random/players":
                    await this.props.gameAction.getPlayersList()
                    break;
                case "/class/presentation/select/group/players":
                    await this.props.gameAction.getPlayersList()
                    break;
                case "/class/presentation/play/matchtheobject":
                    await this.props.gameAction.getMatchTheObjectQuestions()
                    break;
                case "/class/presentation/play/missingletter":
                    await this.props.gameAction.getType2GameOptions()
                    break;
                case "/class/presentation/play/matchthegoodword":
                    await this.props.gameAction.getType3GameOptions()
                    break;
                default:
                    break;
            }
        }

        render() {
            const { loader } = this.props
            return (
                <Fragment>
                    {
                        loader.isAppFetching ?
                            <AppLoader />
                            :
                            <WrappedComponent {...this.props} />
                    }
                </Fragment>

            )
        }
    })
}

const mapStateToProps = ({ loader, session }) => {
    return {
        loader: loader,
        session: session
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        profileAction: bindActionCreators(myProfileAction, dispatch),
        classAction: bindActionCreators(classAction, dispatch),
        subscriptionAction: bindActionCreators(subscriptionAction, dispatch),
        sessionAction: bindActionCreators(sessionAction, dispatch),
        gameAction: bindActionCreators(gameAction, dispatch),
        galleryAction: bindActionCreators(galleryAction, dispatch),
        tubInboxAction: bindActionCreators(tubInboxAction, dispatch),
        analyticsAction: bindActionCreators(analyticsAction, dispatch),
        curriculumAction: bindActionCreators(curriculumAction, dispatch),
        dispatch: dispatch
    }
};

export default ClassPresentationWrapper;