import { React, Component, useState, useEffect } from "react";
import { connect } from "react-redux";
import Calender from "react-calendar";
import moment from 'moment'

const MainPageClass = (props) => {
  const [maxDate] = useState()
  const [minDate] = useState()

  const onChangeDate = (value) => {
    props.onChangeDate(value)
  }
  
  return (
      <Calender
        onChange={(value) => onChangeDate(value)}
        onActiveStartDateChange={(value) => props.changeMonth(value)}
        activeStartDate = {props.activeStartDate}
        value={props.contentSelectedDate}
        selectRange={false}
        minDate={minDate}
        className="assignment_calendar"
        maxDate={maxDate}
        tileClassName={props.tileContent}
        tileDisabled={props.tileDisabled}
      />
  );
}

const AssignmentCalendar = connect(null, null)(MainPageClass);

export default AssignmentCalendar;
