import { combineReducers } from 'redux';
import loader from './loader'
import subscriptionTeam from './subscriptionTeam';
import myProfile from './myProfile';
import classes from './classes';
import tubGallery from './tubGallery';
import invitation from './invitation';
import session from './session';
import tubinbox from './tubinbox';
import analytics from './analytics'
import game from './game';
import dataToPersist from './dataToPersist';
import curriculum from './curriculum'
import auth from './auth';

const reducersList =  combineReducers({
    loader,
    subscriptionTeam,
    myProfile,
    classes,
    invitation,
    tubGallery,
    session,
    tubinbox,
    game,
    analytics,
    curriculum,
    dataToPersist,
    auth,
})

export const reducers = (state, action) => {
    if (action.type === "LOGOUT") {
      state = undefined;
    }
  
    return reducersList(state, action)
}