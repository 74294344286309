import errorMsgs from "../utils/errorMsgs";
import { loaders, main } from '../service/actionType';
// import { API } from '../service/api';
import { API } from 'aws-amplify';
import { API_BOOK } from '../service/endpoints';
import { Auth } from 'aws-amplify';
import toast from '../helpers/Toast';
import { fetchAllCountries, getCititesList, getStatesList, accessHeaders, history } from '../helpers';
import _ from 'lodash';
import { ENVIRONMENT } from '../common/constants';
import { getStudentsList } from "./tubgallery";


export const getCountriesList = () => async (dispatch, getState) => {
    let countriesList = await fetchAllCountries()
    dispatch({ type: main.COUNTRY_LIST, payload: countriesList })
}

export const getStates = (val) => async (dispatch, getState) => {
    let states = await getStatesList(val)
    dispatch({ type: main.STATES_LIST, payload: states })
}

export const districtsList = (val) => async (dispatch, getState) => {
    let citites = await getCititesList(val)
    dispatch({ type: main.CITIES_LIST, payload: citites })
}
export const createOrUpdateProfile = (payload) => async (dispatch, getState) => {
    try {
        let url = "user/save";
        const myInit = {
            body: payload,
            headers: accessHeaders()
        };
        const response = await API.post(ENVIRONMENT, url, myInit)
        if (response.status === 200 && !_.isEmpty(response.data)) {
            await getProfileInformations()(dispatch, getState)
            toast(response.message, 'success');
        }
        else {
            toast(response.message, 'error');
        }
    } catch (error) {
        console.log("error", error);
        dispatch({ type: loaders.IS_APP_FETCHING, payload: false })
    }
}

export const getProfileInformations = () => async (dispatch, getState) => {
    try {
        const username = localStorage.getItem("username")
        if(username){
            dispatch({ type: loaders.IS_APP_FETCHING, payload: true });
            const url = 'user?username=' + username;
            const myInit = {
                headers: accessHeaders()
            }
            const response = await API.get(ENVIRONMENT, url, myInit);


            if (!_.isEmpty(response)) { 
                sessionStorage.setItem('profileDetail', JSON.stringify({
                        label: response.User.First_name, 
                        profilePic : response.User.Profile_img,
                        value: response.User._id, 
                        _id: response.User._id, 
                        isSelected: false,
                        Email: response.User.Email,
                        RoleID: response.User.RoleID
                }))
                localStorage.setItem('userid', response.User._id);
                const data = response;
                let profileDetails = {};
                if (data && data.School && data.User) {
                    let schoolInfo = data.School
                    profileDetails = {
                        ...schoolInfo,
                        ...data.User,
                        Mobile: data.User.Tel_Country_Code + data.User.Mobile,
                        isObjectUrlImage : data.User.Profile_img.original === '' ? false : true,
                        AlternativeMobile : data.User?.Alt_Tel_Country_Code + data.User.AlternativeMobile,
                    }
                    if (data.User.DOB) profileDetails.DOB = new Date(data.User.DOB);
                    localStorage.setItem('schoolInfo', JSON.stringify(schoolInfo));
                    if(!_.isEmpty(schoolInfo.Country)){
                        let statesList = await getStatesList(schoolInfo.Country)
                        dispatch({ type: main.STATES_LIST, payload: statesList })
                    }
                    if(!_.isEmpty(schoolInfo.Country) && !_.isEmpty(schoolInfo.State)){
                        let cCode = {
                            value: schoolInfo.State.value,
                            countryCode: schoolInfo.Country.value
                        }
                        let cities = getCititesList(cCode);
                        dispatch({ type: main.CITIES_LIST, payload: cities })
                    }
                } else if(data && data.User){
                    profileDetails = {
                        ...data.User,
                        Mobile: data.User.Tel_Country_Code + data.User.Mobile,
                        isObjectUrlImage : data.User.Profile_img.original === '' ? false : true,
                        AlternativeMobile : data.User?.Alt_Tel_Country_Code + data.User.AlternativeMobile,
                    }
                    if (data.User.DOB) profileDetails.DOB = new Date(data.User.DOB);
                }
                if(Array.isArray(data.classList) && data.classList?.length > 0){
                    profileDetails["classList"] = data.classList
                }
                if(Array.isArray(data.curriculumList) && data.curriculumList?.length != 0){
                    profileDetails["curriculumList"] = data.curriculumList.map(cp => ({label: cp.curriculum_Name, value: cp._id}))
                }
                if(profileDetails.curriculum_Id != undefined){
                    profileDetails['curriculum_Id'] = {
                        label: profileDetails.curriculum_Id?.curriculum_Name,
                        value: profileDetails.curriculum_Id?._id
                    }
                    delete profileDetails["curriculum_Id"]["curriculum_Name"]
                    delete profileDetails["curriculum_Id"]["_id"]
                }
                localStorage.setItem('userid', data.User._id);
                dispatch({ type: loaders.IS_APP_FETCHING, payload: false })
                dispatch({ type: main.PROFILE_INFO, payload: profileDetails })
                toast(response.message, 'success');
                return profileDetails;
            } 
            else {
                dispatch({ type: loaders.IS_APP_FETCHING, payload: false })
            }
        }
        
    } catch (error) {
        console.log("error", error);
        dispatch({ type: loaders.IS_APP_FETCHING, payload: false })
    }
}


export const getSchoolBasedClassDropdown = () => async (dispatch, getState) => {
    try {
        // dispatch({ type: loaders.IS_AUTH_FETCHING, payload: true })
        let url = "user/school/getClassDropdown";
        let myInit = {
            headers: accessHeaders(),
            queryStringParameters: {
                schoolId: sessionStorage.getItem('schoolId'),
                username: localStorage.getItem("username")
            }
        }
        const response = await API.get(ENVIRONMENT, url, myInit);
        if(response.status === 200){ 
            sessionStorage.setItem('selectedClass', response.data.classList[0].value)
            dispatch({ type: main.USER_CLASS_DROPDOWN_LIST, payload: response.data.classList })
            dispatch({ type: main.SELECTED_CLASS, payload: response.data.classList[0]})
            getStudentsList()(dispatch, getState)
        }
        // dispatch({ type: loaders.IS_AUTH_FETCHING, payload: false })
    } catch (error) {
        console.log("error", error);
        // dispatch({ type: loaders.IS_AUTH_FETCHING, payload: false })
    }

}