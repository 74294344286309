
export const ContentStudentDetail = ({src, className, studentName}) => {
    return (
        <>
            <img
                src={src}
                className={className}
                alt="alphatub"
            />
            <p className="object_student_name pl-2">{studentName}</p>
        </>
    )
}