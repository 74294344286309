import { React, Component } from 'react';
import { connect } from 'react-redux'
import * as gameAction from '../../../actions/game';
import { bindActionCreators } from "redux";
import ClassPresentationWrapper from '../../../Hoc/wrapper'
import moment from 'moment'
import Carousel from 'react-grid-carousel'
import { history, timeSince } from '../../../helpers/index'
import _ from 'lodash'
import RightArrow from '../../../assets/images/rightarrow.svg'
import LeftArrow from '../../../assets/images/leftarrow.svg'
import { ObjectCount } from '../../../components/tubClass/ObjectCount';
class ClassPresentationHomeClass extends Component {
    constructor(props) {
        super(props);
        this.state = { };
    }
    getDetailView = async (data) => {
        sessionStorage.setItem('selectedTubActivity', JSON.stringify({_id: data.alphatub_content_result._id, studentId: data.studentDetail._id}) )
        history.push({
            pathname: '/class/presentation/detail/view',
        })
    }
   
    render() {
        let isReviewTubClass = sessionStorage.getItem('reviewTubClass')
        let { game } = this.props
        return (
            <div className='clp-col'>
                {game.activityList?.length > 0 &&
                    <>
                        <div className="row">
                            <div className="pt-3 col-12">
                                <div className='plr-35'>
                                    <p className="tub-content-2">
                                        All Activities
                                    </p>
                                </div>
                                <div className="pt-3 pb-4">
                                    <Carousel 
                                        cols={4} 
                                        rows={1} 
                                        gap={15} 
                                        showStatus={false}
                                        emulateTouch={false}
                                        showIndicators={false}
                                        arrowRight={(
                                            <div className='clp_arrow_right arrowRightStyles'>
                                                <img 
                                                    src={RightArrow}
                                                    className="clp_arrow"
                                                    
                                                />
                                            </div>
                                        )}
                                        arrowLeft={(
                                            <div className='clp_arrow_left arrowLeftStyles'>
                                                <img 
                                                    src={LeftArrow}
                                                    className="clp_arrow"
                                                />
                                            </div>
                                        )}
                                        responsiveLayout={[
                                            {
                                              breakpoint: 1299,
                                              cols: 2
                                            },
                                            {
                                              breakpoint: 1399,
                                              cols: 3
                                            },
                                            {
                                              breakpoint: 1400,
                                              cols: 4
                                            }
                                        ]}
                                    >
                                        {game.activityList.map(activity => activity.data.map(dt => (
                                            <Carousel.Item>
                                                <div 
                                                        className={`tub-bg-type-1 p-2 cursor-pointer ${(dt.Session_Type === "Letter" || dt.Session_Type === "Word" || dt.Session_Type === "Tag" || dt.Session_Type === "Mix" || dt.Session_Type === 'Reading_Program') && 'home_type2_container'}`}
                                                        onClick={() => this.getDetailView(dt)}
                                                    >
                                                        <div  
                                                            // className="pt-30"
                                                            className={`${(dt.Session_Type === "Card" || dt.Session_Type === "Sheet") ? '' : 'h_inherit'} pt-12`}
                                                        >
                                                        <div className='d-flex justify-flex-end'>
                                                            <span className='tub-day cl_object_type'>( {
                                                                dt.Session_Type === 'Card' ? 'TUB Cards' : 
                                                                dt.Session_Type === 'Sheet' ? 'TUB Sheet' : 
                                                                dt.Session_Type === 'Mix' ? 'TUB Mix' :
                                                                dt.Session_Type === 'Word' ? 'Word Collection' :
                                                                dt.Session_Type === 'Tag' ? 'Tag Collection' :
                                                                dt.Session_Type === 'Letter' ? 'Letter Collection' :
                                                                dt.Session_Type === 'Assignment' ? 'Assignment' : 
                                                                dt.Session_Type === 'Reading_Program' ? 'Reading Program' : ''
                                                            } )</span>
                                                        </div>
                                                        <div className={`relative-pos ${(dt.Session_Type === "Letter" || dt.Session_Type === "Word" || dt.Session_Type === "Tag" || dt.Session_Type === "Mix" || dt.Session_Type === 'Reading_Program') ? 'h_inherit' : ''}`}>
                                                            <div className={`clp_content_obj ${(dt.Session_Type === 'Sheet') ? 'addFilter p-1 ' : '' }`}>
                                                                <div className="h_inherit">
                                                                    {(dt.Session_Type === 'Word' || dt.Session_Type === 'Letter' || dt.Session_Type === 'Tag' || dt.Session_Type === "Mix" || dt.Session_Type === 'Reading_Program') ?
                                                                        <div className="position_relative h_inherit">
                                                                            {dt.alphatub_content_result.data.map((item, i) => (
                                                                            <div className={`typeTwoImage`}>
                                                                                <img
                                                                                    src={item.alphabetImage.original}
                                                                                    alt="alphatub"
                                                                                />
                                                                                {i === 1 && 
                                                                                    <ObjectCount 
                                                                                        data = {dt.alphatub_content_result.data}
                                                                                        className="clp_home_count"
                                                                                    />
                                                                                }
                                                                            </div>
                                                                            ))}
                                                                                
                                                                        </div>
                                                                        : 
                                                                        <div>
                                                                            <img
                                                                                src={dt.alphatub_content_result.ObjectImage.original}
                                                                                alt="alphatub"
                                                                                className={`${(dt.Session_Type === 'Card' || dt.Session_Type === 'Assignment') ? 'card_img' : ''}`}
                                                                            />
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </div>
                                                            {(dt.Session_Type === "Card" || dt.Session_Type === "Sheet" || dt.Session_Type === "Assignment") &&
                                                            <div className="row align-items-center no-gutters inner-content-spnImg">
                                                                <img
                                                                    className="whbr-40"
                                                                    src={
                                                                        dt?.studentDetail?.profilePic?.original ? dt.studentDetail.profilePic.original : require("../../../assets/images/noImage.png")
                                                                    }
                                                                    alt="alphatub"
                                                                />
                                                                <p className="object_student_name ml-2">{dt.studentDetail.firstName}</p>
                                                            </div>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="row no-gutters mt-1 justify-content-between align-items-center">
                                                        <p className="tub-session-category">{dt.Session_Type === 'Letter' ? 'Letter' : '' } {dt.alphatub_content_result.ObjectName}</p>
                                                        <p className="tub-day">{timeSince(dt.Session_Date, true)}</p>
                                                    </div>
                                                </div>
                                            </Carousel.Item>
                                        )))}
                                    </Carousel>
                                </div>
                            </div>
                        </div>
                        {game.activityList.map(activity => (
                            <div className="row">
                                <div className="pt-3 col-12">
                                    <div className='plr-35'>
                                        <p className="tub-content-2">
                                            {
                                                activity.sessionType === "Card" ? "TUB Card" : 
                                                activity.sessionType === "Mix" ? "TUB Mix" :
                                                activity.sessionType === "Sheet" ? "TUB Sheet": 
                                                activity.sessionType === "Word" ? "Words" : 
                                                activity.sessionType === "Tag" ? "Tags" : 
                                                activity.sessionType === 'Assignment' ? 'Assignment' :  
                                                activity.sessionType === 'Letter' ? 'Letter' :  
                                                activity.sessionType === 'Reading_Program' ? 'Reading Program' : ''
                                            }
                                        </p>
                                    </div>
                                    <div className="pt-3 pb-4">
                                        <Carousel 
                                            cols={4} 
                                            rows={1} 
                                            gap={15} 
                                            responsiveLayout={[
                                                {
                                                    breakpoint: 1299,
                                                    cols: 2
                                                },
                                                {
                                                    breakpoint: 1399,
                                                    cols: 3
                                                },
                                                {
                                                    breakpoint: 1400,
                                                    cols: 4
                                                }
                                            ]}
                                            showStatus={false}
                                            emulateTouch={false}
                                            showIndicators={false}
                                            arrowRight={(
                                                <div className='clp_arrow_right arrowRightStyles'>
                                                    <img 
                                                        src={RightArrow}
                                                        className="clp_arrow"
                                                        
                                                    />
                                                </div>
                                            )}
                                            arrowLeft={(
                                                <div className='clp_arrow_left arrowLeftStyles'>
                                                    <img 
                                                        src={LeftArrow}
                                                        className="clp_arrow"
                                                    />
                                                </div>
                                            )}
                                        >
                                            {activity.data.map(dt => (
                                                <Carousel.Item>
                                                    <div 
                                                        className={`tub-bg-type-1 p-2 cursor-pointer`}
                                                        onClick={() => this.getDetailView(dt)}
                                                    >
                                                        <div  
                                                            // className="pt-30"
                                                            className={`${(dt.Session_Type === "Card" || dt.Session_Type === "Sheet") ? 'pt-12' : 'pt10'}`}
                                                        >
                                                            <div className='d-flex justify-flex-end'>
                                                                <span className='tub-day cl_object_type'>( {
                                                                    dt.Session_Type === 'Card' ? 'TUB Cards' : 
                                                                    dt.Session_Type === 'Sheet' ? 'TUB Sheet' : 
                                                                    dt.Session_Type === 'Mix' ? 'TUB Mix' :
                                                                    dt.Session_Type === 'Word' ? 'Word Collection' :
                                                                    dt.Session_Type === 'Tag' ? 'Tag Collection' :
                                                                    dt.Session_Type === 'Letter' ? 'Letter Collection' : 
                                                                    dt.Session_Type === 'Assignment' ? 'Assignment' :
                                                                    dt.Session_Type === 'Reading_Program' ? 'Reading Program' : ''
                                                                } )</span>
                                                            </div>
                                                            <div className="relative-pos">
                                                                <div className={`tub-session-card ${(dt.Session_Type === 'Sheet') && 'addFilter p-1'}`}>
                                                                    <div className="row flex-wrap no-gutters">
                                                                        {(dt.Session_Type === 'Word' || dt.Session_Type === 'Letter' || dt.Session_Type === 'Tag' || dt.Session_Type === "Mix" || dt.Session_Type === "Reading_Program") ?
                                                                            <div className="col-12 position_relative">
                                                                                {dt.alphatub_content_result.data.map((item, i) => (
                                                                                <div className={`typeTwoImage`}>
                                                                                    <img
                                                                                        src={item.alphabetImage.original}
                                                                                        alt="alphatub"
                                                                                    />
                                                                                    {i === 1 && 
                                                                                        <ObjectCount 
                                                                                            data = {dt.alphatub_content_result.data}
                                                                                            className="clp_home_count"
                                                                                        />
                                                                                    }
                                                                                </div>
                                                                                ))}
                                                                            </div>
                                                                            : 
                                                                            <div className="col-12">
                                                                                <img
                                                                                    src={dt.alphatub_content_result.ObjectImage.original}
                                                                                    alt="alphatub"
                                                                                    className={`${(dt.Session_Type === 'Card' || dt.Session_Type === 'Assignment') && 'card_img'}`}
                                                                                />
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                </div>
                                                                {(dt.Session_Type === "Card" || dt.Session_Type === "Sheet" || dt.Session_Type === "Assignment") &&
                                                                <div className="row align-items-center no-gutters inner-content-spnImg">
                                                                    <img
                                                                        className="whbr-40"
                                                                        src={
                                                                            dt?.studentDetail?.profilePic?.original ? dt.studentDetail.profilePic.original : require("../../../assets/images/noImage.png")
                                                                        }
                                                                        alt="alphatub"
                                                                    />
                                                                    <p className="object_student_name ml-2">{dt.studentDetail.firstName}</p>
                                                                </div>
                                                                }
                                                                
                                                            </div>
                                                        </div>
                                                        <div className="row no-gutters mt-2 justify-content-between align-items-center">
                                                            <p className="tub-session-category">{dt.Session_Type === 'Letter' && 'Letter' } {dt.alphatub_content_result.ObjectName}</p>
                                                            {/* {(dt.Session_Type == 'Sheet' || dt.Session_Type == 'Card') &&  */}
                                                                <p className="tub-day">{timeSince(dt.Session_Date, true)}</p>
                                                            {/* } */}
                                                        </div>
                                                    </div>
                                                </Carousel.Item>
                                            ))}
                                        </Carousel>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </>
                }
            </div>
        )
    }
}

const mapStateToProps = ({ tubGallery, myProfile, loader, game }) => {
    return {
        tubGallery,
        myProfile,
        loader,
        game
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        gameAction : bindActionCreators(gameAction, dispatch)
    }
};

export const ClassPresentationHome = ClassPresentationWrapper(connect(mapStateToProps, mapDispatchToProps)(ClassPresentationHomeClass))

