import { React, Component } from 'react';
import { connect } from 'react-redux'
import * as gameAction from '../../../../actions/game';
import { bindActionCreators } from "redux";
import ClassPresentationWrapper from '../../../../Hoc/wrapper'
import ButtonType1 from "../../../../components/inputs/ButtonType1"
import { main } from '../../../../service/actionType';
import { history } from '../../../../helpers';

const ClassAttendanceView = (props) => {

    const selectedObject = (id) => {
        props.game.attendanceList.map((pl, index) => {
            if(pl._id === id){
                pl.isSelected = !pl.isSelected
            } 
        })
        props.dispatch({ type: main.ATTENDANCE_LIST, payload:  props.game.attendanceList})
    }
    const saveAttendance = async () => {
        await props.gameAction.saveStudentAttendance()
        history.push('/class/presentation/home')
    }
    return (
        <div className='clpContainer container'>
            <div className="row">
                <div className="col-12">
                    <div className="mt-2">
                        <p className="clp_heading1 text-center">Whom are we missing today ?</p>
                    </div>
                </div>
                <div className="col-12">
                    <div className="row no-gutters justify-content-center mt-5">
                        {props.game.attendanceList && props.game.attendanceList.map((dt, i) => (
                            <div className='relative-pos' key={i}>
                                <div 
                                    className={`${dt?.isSelected ? 'disabled_student_in_attendance' : ''} cursor-pointer w-150 game_player_img  mt-3 text-center `}
                                    onClick={() => selectedObject(dt._id)}
                                >
                                    <div className='wh-120 mlr-auto'>
                                        <img
                                            src={(dt.profilePic != undefined && dt.profilePic.original != undefined && dt.profilePic.original != "") ? dt.profilePic.original : require("../../../../assets/images/noImage.png")}
                                            alt="alphatub"
                                            className={`${dt.isSelected ? 'selected_class_att_player' : ''}`}
                                        />
                                    </div>
                                    <div className="mt-3">
                                        <p className={`${dt.isSelected ? 'selected_class_atd_player' : '' } class_atd_player `}>{dt.label ? dt.label : '-'}</p>
                                    </div>
                                </div>
                                
                                {/* {dt.isSelected &&
                                    <div className="selectedPlayer">
                                        <img
                                            src={
                                                require("../../../../assets/images/selectedPlayer.png")
                                                .default
                                            }
                                            className=""
                                            alt="alphatub"
                                        />
                                    </div>
                                } */}
                            </div>
                        ))}
                    </div>
                </div>
                <div className="col-12 fixedGameButtons">
                    <div className='row justify-flex-end'>
                        <div className="col-2">
                            <ButtonType1
                                btnName="Save"
                                className='buttonType2'
                                onClick={() => saveAttendance()}
                                disabled={false}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = ({ tubGallery, myProfile, loader, game }) => {
    return {
        tubGallery,
        myProfile,
        loader,
        game
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        gameAction : bindActionCreators(gameAction, dispatch),
        dispatch: dispatch
    }
};

export const ClassAttendance = ClassPresentationWrapper(connect(mapStateToProps, mapDispatchToProps)(ClassAttendanceView))