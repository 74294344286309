import { React, Component } from 'react';
import { connect } from 'react-redux';
import FormInput from '../inputs/FormInput';
import { CreateModel } from '../Model/createModel'
import StripeCheckout from 'react-stripe-checkout';
import { API } from 'aws-amplify';
import { ENVIRONMENT } from '../../common/constants';
import { loaders } from '../../service/actionType';
import toast from '../../helpers/Toast';
import { accessHeaders } from '../../helpers';
import ButtonType1 from 'components/inputs/ButtonType1';
import MyComponent from 'pages/app/Dashboard/sample';
class UpgradeSubscriptionClass extends Component {
    constructor(props) {
        super(props);
        this.state = {
            subscriptionDetails: null,
            perMonthSeatAmount: 41.66,
            noOfSeats: 0,
            finalAmount: 0,
            htmlResponse: ''
        };
    }
    // componentDidMount () {
    //     console.log(Intl.DateTimeFormat().resolvedOptions().timeZone)

    // }
    onChange = (name, value) => {
        let { perMonthSeatAmount } = this.state
        this.setState({ [name]: value, finalAmount: perMonthSeatAmount * value })
    }
    makePayment = async (token) => {
        let { finalAmount, noOfSeats } = this.state
        try {
            this.props.dispatch({ type: loaders.IS_APP_FETCHING, payload: true })
            let url = "subscriptions/initiate/payment";
            const myInit = {
                headers: accessHeaders(),
                body: {
                    token: {
                        id: token.id,
                        email: token.email
                    },
                    total: finalAmount,
                    // seatsPurchased: parseInt(this.props.subscriptionTeam.subscriptionInfo.SeatsPurchased) + parseInt(noOfSeats),
                    seatsPurchased: parseInt(noOfSeats),
                    InvAmt: finalAmount,
                    AWS_UserName: localStorage.getItem("username"),
                    subscriptionId: this.props.subscriptionTeam.subscriptionInfo._id,
                    payment_status: "Active"
                }
            };
            const response = await API.post(ENVIRONMENT, url, myInit)
            if (response.status === 200) {
                toast('Payment successfull', 'success')
                if (this.props.page === 'class') {
                    await this.props.subscription.getSubscriptionData()
                } else {
                    await this.props.getSubscriptionData()
                }
                this.setState({
                    finalAmount: 0,
                    noOfSeats: 0
                }, this.props.triggerPopup("showBuyMoreSeatsPopup"))
            }
            this.props.dispatch({ type: loaders.IS_APP_FETCHING, payload: false })
        } catch (error) {
            this.props.dispatch({ type: loaders.IS_APP_FETCHING, payload: false })
        }
    }

    getOrderHtmlFromApi = async () => {
        try {
            let url = "v1/payment/initiatePayment";
            let myInit = {
                headers: accessHeaders()
            }
            const response = await API.post(ENVIRONMENT, url, myInit)
            if(response.status === 200){
                this.setState({
                    htmlResponse: response.data
                })
            }
           
        } catch (error) {
            console.log('err', error)
        }
    }

    render() {
        let { showBuyMoreSeatsPopup } = this.props
        let { perMonthSeatAmount, noOfSeats, finalAmount, htmlResponse } = this.state
        return (
            <>
                <CreateModel
                    isOpen={showBuyMoreSeatsPopup}
                    toggle={() => this.props.triggerPopup("showBuyMoreSeatsPopup")}
                    title="Buy More Seats"
                >
                    <div className="row mt-4">
                        <div className="col-6">
                            <h5 className="alphatub-card-bold-p f-16px">Buy Additional Seats For 12 Months</h5>
                        </div>
                        <div className="col-6">
                            <div className="row no-gutters">
                                <div className="col-6">
                                    <p className="text-right alphatub-card-normal-p f-16px mt-1">$ {perMonthSeatAmount} x</p>
                                </div>
                                <div className="col-5 ml-2 w-100px">
                                    <FormInput
                                        type="text"
                                        name="noOfSeats"
                                        placeholder=""
                                        className="w-50px "
                                        groupclassName="text-center"
                                        onChange={(...params) => this.onChange(...params)}
                                        value={noOfSeats}
                                    // error={errors["Last_name"]}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="my-3 top-bottom-borders py-12">
                        <div className="col-8 ml-auto">
                            <div className="row justify-content-center align-items-center">
                                <h5 className="alphatub-card-bold-p f-16px">Total Price:</h5>
                                <p className="pl-3 f-16px alphatub-card-normal-p">${parseFloat(finalAmount).toFixed(2)}</p>
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center mt-5">
                        <div className="col-4 mx-auto">
                            {/* <StripeCheckout
                            token={(token) => this.makePayment(token)}
                            shippingAddress
                            name="alphaTUB" 
                            description="Subscription Plan" 
                            image={'https://alphatubbucket.s3.ap-south-1.amazonaws.com/Alogo-1649042849545.png'}
                            ComponentClass="div"
                            panelLabel="Submit" // prepended to the amount in the bottom pay button
                            amount={finalAmount * 100}
                            currency="INR"
                            stripeKey="pk_test_51JxnmJSEus7ATkkGBlCK8IKBI79agsJ3xTvxygeMj68hBJukJW7FUdTKgV1wQttJcdlVG33D6UcoldOYl8pEV2QZ00TiHZc0ee"
                            locale="in"
                        > */}

                            {/* </StripeCheckout> */}
                            <ButtonType1
                                btnName="Proceed"
                                className='buttonType1'
                                onClick={() => this.getOrderHtmlFromApi()}
                                disabled={false}
                            />
                        </div>

                    </div>
                </CreateModel>
                <div>
                    {htmlResponse != "" ?
                        <MyComponent data = {htmlResponse}/>
                        :
                        <></>
                    }
                </div>
            </>
        )
    }
}

const mapStateToProps = ({ loader }) => {
    return {
        loader
    };
};

// let mapDispatchToProps = (dispatch) => {
//     return {
//         dispatch
//     }
// }

const UpgradeSubscriptionPopup = connect(mapStateToProps, null)(UpgradeSubscriptionClass)

export default UpgradeSubscriptionPopup