import errorMsgs from "../utils/errorMsgs";
import { loaders, main } from '../service/actionType'
import { Auth } from 'aws-amplify';
import { API } from 'aws-amplify';
import toast from '../helpers/Toast';
import { accessHeaders, history } from '../helpers';
import _ from 'lodash'
import { ENVIRONMENT } from '../common/constants';
import { acceptInvitation } from './subscriptionTeam'
import { cognitoidentityserviceprovider } from '../common/constants' 
import moment from 'moment';
import { getProfileInformations } from "./myProfile";

export const login = (payload, errorState) => async (dispatch, getState) => {
    try {
        dispatch({ type: loaders.IS_AUTH_FETCHING, payload: true })
        const user = await Auth.signIn(payload.username, payload.password);
        if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
            dispatch({
              type: user.challengeName,
              payload: user,
            });
            history.push("/resetpassword");
            dispatch({ type: loaders.IS_AUTH_FETCHING, payload: false });
            return;
        }
        if (user) {
            localStorage.setItem('accessToken', user?.signInUserSession?.idToken?.jwtToken)
            localStorage.setItem('username', user?.username);
            sessionStorage.setItem('email', user?.attributes.email);
            let userData = {};
            userData.middle_name = user?.attributes.middle_name;
            userData.name = user?.attributes.name;
            userData.email = user?.attributes.email;
            let formattedNumber
            if(user.attributes["custom:Tel_Country_Code"]){
                formattedNumber = user.attributes.phone_number.slice(user.attributes["custom:Tel_Country_Code"]?.length + 1)
            } else {
                formattedNumber = user.attributes.phone_number
            }
            userData.phone_number = formattedNumber
            if (user.attributes.gender) {
                userData.gender = user.attributes.gender;
            }
            else {
                userData.gender = '';
            }
            userData.Tel_Country_Code = user.attributes["custom:Tel_Country_Code"]
            userData.username = user.username;
            let registrationResult = await registerInDB(userData)(dispatch, getState);
            dispatch({ type: loaders.IS_AUTH_FETCHING, payload: false })
            const profileDetails =  await getProfileInformations()(dispatch, getState);
            if (profileDetails?.RoleID !== 'R08') {
                throw new Error(`Sorry buddy, your role is not a director, currently your role is a ${profileDetails.RoleName}`);
            }
            if(registrationResult.status){
                history.push('/dashboard/analytics/overall');
            } else {
                toast(registrationResult?.error?.response?.data?.message, 'error')
            }
        }
    } catch (error) {
        if (error.message && error.message === "Incorrect username or password.") {
            const index = parseInt(errorState);
            switch (index) {
                case 1:
                    toast(errorMsgs.TCW006[0], 'error');
                    break;
                case 2:
                    toast(errorMsgs.TCW006[1], 'error');
                    break;
                case 3:
                    toast(errorMsgs.TCW006[2], 'error');
                    break;
                case 4:
                    toast(errorMsgs.TCW006[3], 'error');
                    break;
                default:
                    break;
            }
        }
        else {
            if(error?.message === "Password attempts exceeded"){
                toast(errorMsgs.TCW037[0], 'error');
            }else if(error?.message == "User is not confirmed."){
                var getUserparams = {
                    UserPoolId: "ap-south-1_SAoRK634X",
                    AttributesToGet: [
                        'email', 'email_verified'
                    ],
                    Filter:  "email = \"" + payload.username + "\"",
                    Limit: 1,
                };
                cognitoidentityserviceprovider.listUsers(getUserparams, function (err, data) {
                    if (err) console.log(err, err.stack); // an error occurred
                    else {
                        let userNotConfirmedHour = moment(data.Users[0].UserCreateDate)
                        let currentHour = moment(new Date())
                        if(currentHour.diff(userNotConfirmedHour, 'hours') > 24){
                            var params = {
                                // ClientId: "3fnmbbnld77e4uj8ehhga3k390",
                                Username: data.Users[0].Username
                            };
                            cognitoidentityserviceprovider.resendConfirmationCode(params, function (err, result) {
                                if (err) toast(err.message, 'error') ;
                                else {
                                    toast("We have sent a new confirmation mail. Please verify it and try to login", 'success');
                                }
                            });
                        } else {
                            toast("Please confirm your mail and try to login", 'success');
                        }
                    }
                })
                toast("Please confirm your mail and try to login", 'success');

            } else {
                toast(error?.message, 'error')
            }
        }
        dispatch({ type: loaders.IS_AUTH_FETCHING, payload: false })
    }
}

export const register =  (payload, errorState) => async (dispatch, getState) => {
    try {
        dispatch({ type: loaders.IS_AUTH_FETCHING, payload: true });
        let username = payload["Email"];
        let password = payload["password"];
        let lastname = payload["lastname"];
        let firstname = payload["firstname"];
        const { user } = await Auth.signUp({
            username,
            password,
            attributes: {
                email: username,
                name: firstname,
                middle_name: lastname,
                gender: payload["gender"],
                "custom:Tel_Country_Code": payload["Tel_Country_Code"],
                phone_number: "+" + payload["phone_number"]
            }
        });
        if (user) {
            await acceptInvitation(username)(dispatch, getState)
            toast("Registered successfully, and an email has been sent for verification.", "success");
            dispatch({ type: loaders.IS_AUTH_FETCHING, payload: false });
            history.push('/');
        }
    } catch (error) {
        if (error.message && error.message === "Incorrect username or password.") {
            const index = parseInt(errorState);
            if (index && index < 4) {
                toast(errorMsgs.TCW006[index], 'error');
            } else {
                toast(errorMsgs.TCW006[3], 'error');
            }
        }
        else {
            toast(error.message, 'error');
        }
        dispatch({ type: loaders.IS_AUTH_FETCHING, payload: false })
    }
}

export const getInvitationDetails = (id) => async (dispatch, getState) => {
    try {
        dispatch({ type: loaders.IS_APP_FETCHING, payload: true });
        let url = "user/getInvitationDetails?id=" + id;
       
        const response = await API.get(ENVIRONMENT, url);
        if (response.status === 200 && !_.isEmpty(response.data)) {
            dispatch({ type: main.INVITATION_INFO, payload: response.data });
            dispatch({ type: loaders.IS_APP_FETCHING, payload: false });
        }
    } catch (error) {
        console.log(error);
        dispatch({ type: loaders.IS_APP_FETCHING, payload: false });
    }
}

export const registerInDB = (payload) => async (dispatch, getState) => {
    try {
        let url = "user/register";
        const myInit = {
            body: payload,
            headers: accessHeaders(),
        };
        const response = await API.post(ENVIRONMENT, url, myInit);
        if (response.status === 200 && !_.isEmpty(response.data)) {
            localStorage.setItem("role", response.data.RoleID);
            sessionStorage.setItem("schoolId", response.data.schoolId);
            dispatch({ type: loaders.IS_AUTH_FETCHING, payload: false })
            return { status: true}
        }
    } catch (error) {
        dispatch({ type: loaders.IS_AUTH_FETCHING, payload: false });
        return { status: false, error: error}
    }
}

export const getUserRole = () => async (dispatch, getState) => {
    try {
        let url = "user/role/detail";
        const myInit = {
            headers: accessHeaders(),
            queryStringParameters: {
                userName: localStorage.getItem("username")
            },
        };
        const response = await API.get(ENVIRONMENT, url, myInit)
        if (response.status === 200 && !_.isEmpty(response.data)) {
            localStorage.setItem("role", response.data.roleData.RoleID)
            sessionStorage.setItem("schoolId", response.data.schoolId)
        }
    } catch (error) {
        toast(error.message, 'error');
        dispatch({ type: loaders.IS_AUTH_FETCHING, payload: false });
    }

}