import React, { useState } from "react";
import {
  isEmptyOrUndefine,
  isValidEmailAddress,
  passwordMatchCondition,
} from "../../../utils/common.utils";
import { Auth, API } from "aws-amplify";
import "../../../assets/css/main.scss";
import { connect, useDispatch } from "react-redux";
import errorMsgs from "../../../utils/errorMsgs";
import toast from "../../../utils/Toast";
import { loaders } from "../../../service/actionType";
import FormInput from "../../../components/inputs/FormInput";
import Header from "../../../components/Header/header";
import AuthBg from "../../../assets/images/loginpageimage.png";
import { history } from "../../../helpers/index";
import { useSelector } from "react-redux";

const ResetPasswordFn = () => {
  const cognitoUser = useSelector((state) => state.auth.cognitoUser);
  const [emptyBtnClicks, setEmptyBtnClicks] = useState(0);
  const [emailValidBtnClicks, setEmailValidBtnClicks] = useState(0);
  const [passwordCondBtnClicks, setPasswordCondBtnClicks] = useState(0);
  const [username, setUsername] = useState("");
  const [vcode, setVcode] = useState(null);
  const [verifyCode, setVerifyCode] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [gender, setGender] = useState("");
  const [verificationCodeMessage, setVerificationCodeMessage] = useState("");
  const [passwordErrorMessage, setPasswordErrorMessage] = useState("");
  const [usernameErrorMessage, setUsernameErrorMessage] = useState("");
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const genderOptions = [
    {
      label: "Male",
      value: "Male",
    },
    {
      label: "Female",
      value: "Female",
    },
    {
      label: "Others",
      value: "Others",
    },
  ];
  const dispatch = useDispatch();

  const showToast = (btnClicks, type) => {
    let errMsg = "";
    switch (type) {
      case 1:
        errMsg = errorMsgs.TCW001[btnClicks - 1] || errorMsgs.TCW001[0];
        break;
      case 2:
        errMsg = errorMsgs.TCW031[btnClicks - 1] || errorMsgs.TCW031[0];
        break;
      case 3:
        errMsg = errorMsgs.TCW032[btnClicks - 1] || errorMsgs.TCW032[0];
        break;
      default:
        errMsg = errorMsgs.TCW001[0];
        break;
    }
    toast(errMsg, "error");
  };

  const checkRequiredFields = (type) => {
    if (type === 1) {
      if (isEmptyOrUndefine(username)) {
        const newBtnClicks = emptyBtnClicks === 4 ? 1 : emptyBtnClicks + 1;
        setEmptyBtnClicks(newBtnClicks);
        showToast(newBtnClicks, 1);
        return false;
      } else if (!isValidEmailAddress(username)) {
        const newBtnClicks =
          emailValidBtnClicks === 4 ? 1 : emailValidBtnClicks + 1;
        setEmailValidBtnClicks(newBtnClicks);
        showToast(newBtnClicks, 2);
        return false;
      }
    } else if (type === 2) {
      if (isEmptyOrUndefine(vcode) || isEmptyOrUndefine(password)) {
        const newBtnClicks = emptyBtnClicks === 4 ? 1 : emptyBtnClicks + 1;
        setEmptyBtnClicks(newBtnClicks);
        showToast(newBtnClicks, 1);
        return false;
      } else if (!passwordMatchCondition(password)) {
        const newBtnClicks =
          passwordCondBtnClicks === 4 ? 1 : passwordCondBtnClicks + 1;
        setPasswordCondBtnClicks(newBtnClicks);
        showToast(newBtnClicks, 3);
        return false;
      }
    }
    return true;
  };

  const completeNewPassword = async () => {
    try {
      const data = await Auth.completeNewPassword(cognitoUser, password, {
        gender: "M",
        middle_name: ".",
      });
      if (data) {
        history.push({
          pathname: "/login",
        });
      }
    } catch (error) {
      if (error && error.message) {
        if (
          error.message ===
          "Attempt limit exceeded, please try after some time."
        ) {
          toast(errorMsgs.TCW037[0], "error");
        } else {
          toast(error.message, "error");
        }
      }
      dispatch({ type: loaders.IS_AUTH_FETCHING, payload: false });
      setVerifyCode(false);
      dispatch({ type: loaders.IS_AUTH_FETCHING, payload: false });
    }
  };

  const togglePassword = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  const handleChange = (name, value) => {
    switch (name) {
      case "username":
        setUsername(value);
        break;
      case "vcode":
        setVcode(value);
        break;
      case "password":
        setPassword(value);
        break;
      case "confirmPassword":
        setConfirmPassword(value);
        break;
      case "gender":
        setGender(value);
      default:
        break;
    }
  };

  const navigate = (route) => {
    history.push(route);
  };

  return (
    <>
      <Header />
      <div className="maincontainer auth-background">
        <div className="container-fluid">
          <div className="row no-gutter justify-content-center">
            <div className="col-md-7 d-none d-lg-flex auth-bg">
              <div className="">
                <img src={AuthBg} className="auth-bgImage" alt="alphatub" />
              </div>
            </div>
            <div className="col-md-5">
              <div className="h-100 d-flex align-items-center py-4">
                <div className="col-12">
                  <div className="col-lg-10 col-xl-8 mx-auto">
                    <>
                      <h3 className="login-text-login">Reset Password</h3>

                      <div className="form-group mb-3">
                        <h3 className="mt-login">New Password</h3>
                        <FormInput
                          name="password"
                          value={password}
                          className="m-0"
                          type="password"
                          labelClassName="mt-login"
                          onChange={handleChange}
                          isPasswordVisible={isPasswordVisible}
                          togglePassword={togglePassword}
                        />
                        <span className="error-msg">
                          {usernameErrorMessage}
                        </span>
                      </div>
                      <div className="form-group mb-3">
                        <h3 className="mt-login">Confirm Password</h3>
                        <FormInput
                          name="confirmPassword"
                          value={confirmPassword}
                          className="m-0"
                          type="password"
                          labelClassName="mt-login"
                          onChange={handleChange}
                          isPasswordVisible={isPasswordVisible}
                          togglePassword={togglePassword}
                        />
                        <span className="error-msg">
                          {usernameErrorMessage}
                        </span>
                      </div>

                      <div className="form-group proceed-button mtb-30">
                        <button
                          type="button"
                          className="w130-px btn btn-primary"
                          onClick={completeNewPassword}
                        >
                          Proceed
                        </button>
                      </div>
                    </>
                  </div>
                  <div className="col-lg-10 col-xl-8 mx-auto">
                    <div className="form-group mt-20">
                      <div className="row">
                        <div className="col-md-6 col-sm-6 col-6 alignself-flex-end">
                          <p
                            className="auth-toggle-link"
                            onClick={() => navigate("/login")}
                          >
                            Login
                          </p>
                        </div>
                        <div className="col-md-6 col-sm-6 col-6 text-right">
                          <h6 className="new_to">New to TUB Class?</h6>
                          <a
                            className="auth-toggle-link"
                            href="https://alphatub.com/offerings/tub-class/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Signup Instead
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export const ResetPassword = connect(null, null)(ResetPasswordFn);
