import React from "react";
import ReactApexChart from "react-apexcharts";

const Apaexlinecolumn = (props) => {
  const series = [
    {
      name: props.name,
      data: props.y_axis,
    },
    {
      name: props.name_2,
      data: props.y_axis_2,
    },
    {
      name: props.name_3,
      data: props.y_axis_3,
    }
  ];
  const options = {
    chart: {
      toolbar: {
        show: props.showToolBar,
      },
      "animations": {
        "enabled": true,
        "easing": "swing"
      },
      "background": "#FFFFFF",
      "dropShadow": {
        "enabled": true,
        "top": 1,
        "left": 1,
        "blur": 4,
        "color": "#000",
        "opacity": 0.1
      },
    },
    plotOptions: {
      bar: {
        horizontal: props.horizontal,
        borderRadius: 5,
        borderRadiusApplication: 'end',
        backgroundBarColors: '#000000',
        "colors": {
          "backgroundBarColors": [
              "#f1f2f3"
          ],
          "backgroundBarOpacity": 0
        },
        dataLabels: {
          position: "top",
        },
      },
      endingShape: "rounded",
    },
    dataLabels: {
      enabled: true,
      offsetY: props.dataEnabledOffsetY,
      offsetX: props.dataEnabledOffsetX,
      style: {
        colors: ["#000"],
        fontSize: '10px',
        fontFamily: 'Poppins'
      }
    },
    noData: {
      text: "No data Found",
      align: "center",
      verticalAlign: "middle",
      style: {
        color: "#7f7575",
        fontSize: '14px',
        fontFamily: 'Poppins'
      }
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },

    colors: props.colors,
    xaxis: {
      sorted: true,
      type: 'category',
      categories: props.x_axis,
      "title": {
        "text": props.x_axis_title,
        "style": {
            cssClass: 'x_axis_label'
        },
        offsetY: props.offsetY
      },
      labels: {
        trim: true,
        formatter: (val) => {
          return val
        },
        style: {
          color: '#000000',
          cssClass: 'x_axis_content'
        },
        "offsetY": -2
      },
      tooltip: {
        enabled: true,
        offsetY: 100,
      },
      axisTicks: {
        show: true,
        borderType: 'solid',
        color: '#78909C',
        width: 6,
        offsetX: 0,
        offsetY: 0
      },
      axisBorder: {
        show: true,
        color: '#78909C',
        offsetX: 0,
        offsetY: -1
      }
    },
    yaxis: {
      title: {
        text: props.y_axis_title,
        style: {
          color: '#000000',
          cssClass: 'y_axis_label'
        }
      },
      axisBorder: {
        show: true,
        color: '#78909C',
        offsetX: 0,
        offsetY: -2
      },
      axisTicks: {
        show: true,
        borderType: 'solid',
        color: '#78909C',
        width: 6,
        offsetX: 0,
        offsetY: 0.5
      },
    },
    grid: {
      show: true,
      borderColor: "#E0E0E0",
      strokeDashArray: 3,
      padding: {
        left: 13,
        bottom: 0
      }
    },
    fill: {
      opacity: 1,
      type: 'solid'
    },
    legend: {
      show: true,
      showForSingleSeries: true,
      position: props.legendPosition,
      horizontalAlign: props.horizontalAlign,
      offsetX: 0,
      offsetY: 0,
      showForNullSeries: false,
      showForZeroSeries: false
    },
  };

  return (
    <ReactApexChart options={options} series={series} type="bar" height={450} />
  );
};

export default Apaexlinecolumn;
