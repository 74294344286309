import React from "react"
import ReactApexChart from "react-apexcharts"

const Barchart = (props) => {
  const series = [
    {
      name: props.name,
      data: props.y_axis,
    },
  ]
  const options = {
    miscellaneousValues: props?.miscellaneousValues || [],
    chart: {
      toolbar: {
        show: props.showToolBar,
      },
      "animations": {
        "enabled": true,
        "easing": "swing"
      },
      "background": "#FFFFFF",
      "dropShadow": {
        "enabled": true,
        "top": 1,
        "left": 1,
        "blur": 4,
        "color": "#000",
        "opacity": 0.1
      },
    },
    plotOptions: {
      bar: {
        borderRadius: 5,
        borderRadiusApplication: 'end',
        backgroundBarColors: '#000000',
        "colors": {
          "backgroundBarColors": [
              "#f1f2f3"
          ],
          "backgroundBarOpacity": 0
        },
        dataLabels: {
          position: "top"
        },
      }
    },
    xaxis: {
      sorted: true,
      type: 'category',
      categories: props.x_axis,
      "title": {
        "text": props.x_axis_title,
        "style": {
            cssClass: 'x_axis_label'
        },
        offsetY: 90
      },
      labels: {
        trim: true,
        show: true,
        formatter: (val) => {
          return val
        },
        style: {
          color: '#000000',
          cssClass: 'x_axis_content'
        },
        "trim": true,
        "offsetY": -2
      },
      tooltip: {
        enabled: true,
        offsetY: 100,
      },
      axisTicks: {
        show: true,
        borderType: 'solid',
        color: '#78909C',
        width: 6,
        offsetX: 0,
        offsetY: 0
      },
      axisBorder: {
        show: true,
        color: '#78909C',
        offsetX: 0,
        offsetY: -1
      }
    },
    yaxis: {
      title: {
        text: props.y_axis_title,
        style: {
          color: '#000000',
          cssClass: 'y_axis_label'
        }
      },
      axisBorder: {
        show: true,
        color: '#78909C',
        offsetX: 0,
        offsetY: -2
      },
      axisTicks: {
        show: true,
        borderType: 'solid',
        color: '#78909C',
        width: 6,
        offsetX: 0,
        offsetY: 0.5
      },
    },
    dataLabels: {
      enabled: true,
      offsetY: -14,
      style: {
        colors: ["#000"],
        fontSize: '10px',
        fontFamily: 'Poppins'
      }
    },
    noData: {
      text: "No data Found",
      align: "center",
      verticalAlign: "middle",
      style: {
        color: "#7f7575",
        fontSize: '14px',
        fontFamily: 'Poppins'
      }
    },
    colors: props.colors,
    grid: {
      show: true,
      borderColor: "#E0E0E0",
      strokeDashArray: 3,
      padding: {
        left: 13,
        bottom: 0
      }
    },
    fill: {
      opacity: 1,
      type: 'solid'
    },
    legend: {
      show: true,
      showForSingleSeries: true,
      position: "top",
      horizontalAlign: "right",
      offsetX: 0,
      offsetY: 25,
      showForNullSeries: false,
      showForZeroSeries: false
    },
  }
  if (props.dataLabels) {
    options.dataLabels = props.dataLabels;
  }
  return (
    <ReactApexChart options={options} series={series} type="bar" height="400" />
  )
}

export default Barchart
