import React, { useState, useEffect } from 'react'
import * as ProfileActions from '../actions/myProfile'
import * as galleryAction from '../actions/tubgallery'
import * as sessionAction from '../actions/session'
import * as curriculumAction from '../actions/curriculum';
import * as gameAction from '../actions/game'
import { connect } from 'react-redux'
import { getActiveTab, history, toggleSidebars } from '../helpers'
import { bindActionCreators } from 'redux'
import AuthLoader from '../components/loader/authloader'
import AdminLeftSidebar from '../components/Sidebar/AdminLeftSidebar'
import AdminRightSidebar from '../components/Sidebar/AdminRightSidebar'
import AdminHeader from '../components/Header/adminheader'
import BottomNavBar from '../components/Header/bottomNavBar'
import BasicTab from '../components/Tabs/BasicTab'
import { ANALYTICS_TABS, SETTINGS_TABS, TUB_CLASS_TABS } from '../helpers/variables'
import { cloneDeep } from 'lodash';

const { innerWidth: width, innerHeight: height } = window;

const AdminLayoutFunc = (props) => {
    const [isLeftSidebarOpen, setIsLeftSidebarOpen] = useState(true)
    const [isRightSidebarOpen, setIsRightSidebarOpen] = useState(true)
    const [activeTab, setActiveTab] = useState(1)
    const [isScroll, setIsScroll] = useState(false)
    const [tabs, setBasicTabs] = useState([])

    useEffect(() => {
        if(props.location.pathname){
            setTabs()
            authGaurd()
            setStickyTabs()
            setSidebars()
            doNotRedirectBack()

            getClassDropdown()
            triggerApis()
        }
    }, [props.location])

    const setTabs = () => {
        let {location} = props
        console.log(typeof location.pathname, location.pathname);
        if (location.pathname?.endsWith('/settings/profile') || location.pathname?.endsWith('/dashboard/settings/class')) {
            const tabs = cloneDeep(SETTINGS_TABS);
            setBasicTabs(() => [...tabs])
        } else {
            setBasicTabs(() => []);
        }
    }

    const triggerApis = async () => {
        await props.galleryAction.getStudentsList()
        props.sessionAction.checkLauchTubEnabled()
        // props.curriculumAction.getCurriculumAndProgramName()
    }

    const getClassDropdown = async () => {
        if(props.dataToPersist.selectedClass == null){
            await props.profileAction.getSchoolBasedClassDropdown()
        }
    }
    const authGaurd = () => {
        let username = localStorage.getItem('username');
        let role = localStorage.getItem('role');
        
        if(!username && !role) {
            history.push({pathname: "/"});
        }
    }

    const doNotRedirectBack = () => {
        window.history.pushState(null, "", window.location.href);
        window.onpopstate = function () {
            window.history.pushState(null, "", window.location.href);
        };
    }
    
    const setStickyTabs = () => {
        let isScroll = false
        window.addEventListener('scroll', () => {
            if(window.scrollY > 10){
                isScroll = true;
            } else isScroll = false
            setIsScroll(isScroll);
        });
    }

    const setSidebars = () => {
        if(width <= 991){
            setIsLeftSidebarOpen(false)
            setIsRightSidebarOpen(false)
        }
    }
    const selectedBottomTab = (tab) => {
        if(tab == 1){
            toggleSidebars('leftsidebar', tab)
        } else toggleSidebars('rightsidebar', tab)
    }

    const onChange = (value,route) => {
        setActiveTab(value)
        redirect(route)
    };

    const redirect = (route) => {
        history.push(route)
    }

    let role = localStorage.getItem('role')
    return (
        <>
            { props.loader.isAuthFetching && 
                <AuthLoader />
            }
            <div className='dashboard_layout'>
                <AdminHeader {...props}/>
                <div className={`staff-dashboard-container`}>
                    <AdminLeftSidebar {...props} />
                    <div className="dashboard-col">
                        <div className={`sticky-tab ${isScroll ? 'sticky_bx_shadow' : ''}`}>
                            <BasicTab
                                role={role}
                                tabs={tabs}
                                name="activeTab"
                                onChange={(value, i, index, route) => onChange(value, i)}
                                activeTab={activeTab}
                            />
                        </div>
                        { props.children }
                    </div>
                    <AdminRightSidebar {...props} />
                </div>
                <BottomNavBar {...props} selectedBottomTab={selectedBottomTab}/>
            </div>
        </>
    )
}

let mapStateToProps = ({myProfile,loader, tubGallery,dataToPersist}) => {
    return {
        myProfile,
        tubGallery,
        loader,
        dataToPersist
    }
}

let mapDispatchToProps = (dispatch) => {
    return {
        profileAction: bindActionCreators(ProfileActions,dispatch),
        galleryAction: bindActionCreators(galleryAction,dispatch),
        sessionAction: bindActionCreators(sessionAction,dispatch),
        curriculumAction: bindActionCreators(curriculumAction, dispatch),
        dispatch,
        gameAction:  bindActionCreators(gameAction,dispatch)
    }
}

let AdminLayout = connect(mapStateToProps,mapDispatchToProps)(AdminLayoutFunc)

export { AdminLayout }
