import { React, Component } from 'react';
import { connect } from 'react-redux'
import * as tubInboxAction from '../../../../actions/tubInbox'
import { bindActionCreators } from "redux";
import { API } from 'aws-amplify';
import { ENVIRONMENT } from '../../../../common/constants';
import ButtonType1 from '../../../../components/inputs/ButtonType1';
import { CreateModel } from '../../../../components/Model/createModel';
import moment from 'moment';
import toast from '../../../../helpers/Toast';
import _ from 'lodash'
import { accessHeaders } from '../../../../helpers';
import { PendingInboxFilter } from '../../../../components/tubClass/PendingInboxFilter';
import { InboxPendingCard } from '../../../../components/tubClass/inboxPendingCard';
import Wrapper from '../../../../Hoc/wrapper';

class InboxClass extends Component {
    constructor(props) {
        super(props);
        this.state = {
            init: true,
            inboxStatus: null,
            isRejectPopupOpen: false,
            rejectAlphabets: [],
            rejectedReason: null,
            selectedTubObject: null,
            selectedTubInbox: null,
        };
    }
   
    triggerPopup = (val, data) => {
        if(val === "isRejectPopupOpen"){
            let rejectedAlphabets = []
            if(data != null){
                data.TubObjectId.data.map(dt => {
                    rejectedAlphabets.push({
                        alphabet: dt.alphabetName,
                        imageTitle: dt.imageTitle,
                        id: dt._id,
                        isSelected: dt["isSelected"] ? true : false
                    })
                })
                this.setState({ rejectAlphabets: rejectedAlphabets, selectedTubObject: data})
            }
        } else {
            if(data != null){
                this.setState({selectedTubInbox: data})
            }
        }
        this.setState({
            [val]: !this.state[val]
        })
    }
    selectRejectReason = (reason) => {
        this.setState({ rejectedReason: reason})
    }
    updateTubObject = async (status,data) => {
        let { rejectAlphabets, rejectedReason } = this.state
        let payload = {}
        const username = localStorage.getItem('userid');
        if(status === 'approved'){
            payload.TubObjectStatus = "Approved"
            payload.TubObjectSubmittedBy = username
            payload.IsRejected = false
            payload.TubObjectApprovedDt = moment(new Date()).format('YYYY-MM-DD hh:mm:ss')
        } else {
            payload.TubObjectStatus = 'Rejected'
            payload.TubObjectRejectReason = rejectedReason
            payload.TubObjectSubmittedBy = username
            payload.TubObject_LastRejectedDt = moment(new Date()).format('YYYY-MM-DD hh:mm:ss')
            payload.tubObjectDataList = rejectAlphabets.filter(ra => ra.isSelected === true)
        }
        // this.props.dispatch({ type: loaders.IS_APP_FETCHING, payload: true })
        let url = `tubinbox/updateTubObjectStatus/${data._id}`;
        let myInit = {
            headers: accessHeaders(),
            body: payload
        }
        const response = await API.post(ENVIRONMENT, url, myInit);
        if(response.status === 200){
            toast(response.message, 'success')
            if(status === 'rejected'){
                this.setState({isRejectPopupOpen: false})
            }
            this.getInboxPendingList()
            // this.props.dispatch({ type: loaders.IS_APP_FETCHING, payload: false })
            
        } else {
            // this.props.dispatch({ type: loaders.IS_APP_FETCHING, payload: false })
        }
    }
    getInboxPendingList = async (newPayload = null) => {
        let selectedClass = sessionStorage.getItem('selectedClass')
        let queryPayload = {
            SchoolID : sessionStorage.getItem('schoolId'),
            ClassID: selectedClass ? selectedClass : this.props.dataToPersist.selectedClass.value,
            TubObjectStatus: 'Pending'
        }
        if(newPayload != null){
            queryPayload = {...queryPayload, ...newPayload}
        }
        await this.props.tubInboxAction.getTubInboxList(queryPayload)
    }
    selectAlphabet = (obj, index) => {
        let { rejectAlphabets } = this.state
        rejectAlphabets[index]['isSelected'] = !rejectAlphabets[index]['isSelected']
        this.setState({rejectAlphabets:rejectAlphabets})
    }
    
    getApprovedCount = (data) => {
        let filteredContent = data?.filter(dt => !dt.isApprovedBySchool)
        return filteredContent?.length
    }
    
    render() {
        let state = this.state
        let { tubinbox } = this.props
        return (
            <>
            <div className='col-12 p-0'>
                <PendingInboxFilter 
                    getInboxPendingList={this.getInboxPendingList}
                />
            </div>
            { tubinbox?.tubInboxList?.length > 0
                ?
                    <InboxPendingCard 
                        tubInboxList = {tubinbox?.tubInboxList}
                        triggerPopup = {this.triggerPopup}
                        updateTubObject = {this.updateTubObject}
                        type={1}
                        {...this.props}
                        {...this.state}
                    />
                :
                <div className="row no-gutters align-items-center center-content w-100 h-400px">
                    <p className='tub-content-4 text-center'>No Pending Approvals</p>
                </div>
            }
                {/* Delete Tub Object */}
            {
              state.isRejectPopupOpen &&
              <CreateModel
                  isOpen={state.isRejectPopupOpen}
                  toggle={() => this.triggerPopup('isRejectPopupOpen', null)}
                  title={"Reason For Rejection"}
              >
                  <div className="col-12 p-0">
                        <p className="f-16px reject-card-p1">
                            Recheck Objects
                        </p>
                        {/* <div className="row no-gutters pendingAlphabets mt-2 mb-2">
                            {state.rejectAlphabets.map((obj, i) => (
                                <p className={`${(obj.isSelected) && 'selectedAlphabet'} rejectAlphabet`} onClick={() => this.selectAlphabet(obj, i)}>{obj.alphabet}</p>
                            ))}
                        </div> */}
                        <div className='mt-2 mb-2'>
                            <ButtonType1
                              btnName="Improve Image Quality"
                              className={`${state.rejectedReason === 'Improve Image Quality' && 'btn_selected'} buttonType5 pt-2 pb-2 mt-3`}
                              onClick={() => this.selectRejectReason('Improve Image Quality')}
                            />
                            <ButtonType1
                              btnName="Wrong Name / Tag"
                              className={`${state.rejectedReason === 'Wrong Name / Tag' && 'btn_selected'} buttonType5 pt-2 pb-2 mt-3`}
                              onClick={() => this.selectRejectReason('Wrong Name / Tag')}
                            />
                            <ButtonType1
                              btnName="Other"
                              className={`${state.rejectedReason === 'Other' && 'btn_selected'} buttonType5 pt-2 pb-2 mt-3`}
                              onClick={() => this.selectRejectReason("Other")}
                            />
                        </div>
                    </div>
                      
                  <div className="row align-items-center mt-3 content-end">
                      <div className="col-4">
                          <ButtonType1
                              btnName="Close"
                              className='popup-close-btn'
                              onClick={() => this.triggerPopup('isRejectPopupOpen', null)}
                          />
                      </div>
                      <div className="col-4">
                          <ButtonType1
                              btnName="Confirm"
                              className='bg-tub c-white buttonType1 '
                              onClick={() => this.updateTubObject('rejected', state.selectedTubObject)}
                          />
                      </div>
                  </div>
              </CreateModel>
            }
            
            </>
        )
    }
}


const mapStateToProps = ({ tubinbox, loader,dataToPersist }) => {
    return {
        tubinbox,
        loader,
        dataToPersist
    };
};

let mapDispatchToProps = (dispatch) => {
    return {
        tubInboxAction: bindActionCreators(tubInboxAction, dispatch),
        dispatch
    }
}

const  Pending  = Wrapper(connect(mapStateToProps, mapDispatchToProps)(InboxClass))

export { Pending }