const AWS = require("aws-sdk");

const ROLES = {
  ADMIN: 1,
  MANAGER: 2,
  TEACHER: 3,
  TRAINEE: 4,
};

// const ENVIRONMENT = 'prod';
// // const API_URL =  'https://api.alphatub.com/api/';
// const API_URL = 'https://api.dev.alphatub.com/api/';

// // const AWS_URL =  'http://localhost:3000/dev/api/';
// const cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider({
//     accessKeyId: "AKIAUQMKNTPOI2EJFS4H",
//     secretAccessKey:"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",
//     region:'ap-south-1'
// });

const ENVIRONMENT = "qa";
const API_URL = process.env.REACT_APP_API_URL;
const cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider({
  IdentityPoolId: process.env.REACT_APP_AWS_COGNITO_IDENTITY_ID,
});

module.exports = {
  ROLES,
  ENVIRONMENT,
  API_URL,
  cognitoidentityserviceprovider,
};

// const AWS  = require("aws-sdk");

// const ROLES = {
//     "ADMIN": 1,
//     "MANAGER": 2,
//     "TEACHER": 3,
//     "TRAINEE": 4
// }

// const ENVIRONMENT = 'qa';
// const API_URL =  'https://api.qa.alphatub.com/api/';
// // const AWS_URL =  'http://localhost:3000/dev/api/';
// const cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider({
//     accessKeyId: "AKIAVQRISFHG73VLHCOY",
//     secretAccessKey:"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",
//     region:'ap-south-1'
// });
// module.exports = {
//     ROLES,
//     ENVIRONMENT,
//     API_URL,
//     cognitoidentityserviceprovider
// }
