import { createStore, applyMiddleware, compose } from "redux";
import { reducers } from "../reducer";
import thunk from "redux-thunk";
import { API, API_BOOK } from "../service";
import toast from "./Toast";
import moment from "moment";
import { Country, State, City } from "country-state-city";

export const store = createStore(
  reducers,
  compose(
    applyMiddleware(
      thunk.withExtraArgument({
        API,
        API_BOOK,
        toast,
      })
    ),
    window.devToolsExtension ? window.devToolsExtension() : (f) => f
  )
);

export const history = require("history").createBrowserHistory();

export const ThemeContext = require("react").createContext();

export const checkIsPrivacyAccepted = () => {
  const isPrivacyAccepted = localStorage.getItem("isPrivacyPolicyAccepted");
  if (isPrivacyAccepted === "true") {
    return true;
  } else {
    return false;
  }
};

export const toggleRootClassName = (name) => {
  document.body.style.overflow = name;
  // var element = document.getElementsByTagName('body')[0];
  // element.classList.toggle("net-disconnected");
};

export const toggleSidebars = (name, tab) => {
  var element = document.getElementsByClassName(name)[0];
  element.classList.toggle(tab === 1 ? "showLeftSidebar" : "showRightSidebar");
  // element.classList.toggle("net-disconnected");
};
export const formatDate = (value) => {
  return moment(value).format("YYYY-MM-DD");
};

export const formatDateWithText = (value) => {
  return moment(value).format("DD MMM YYYY");
};

export function validateUploadSize(file) {
  if (!file) return false;
  let FileSize = file.files[0].size / 1024 / 1024; // in MB
  return FileSize <= 5;
}

export const getDaysArray = (start, end, toggleHoliday, filteredIndexes) => {
  for (
    var arr = [], dt = new Date(start);
    dt <= end;
    dt.setDate(dt.getDate() + 1)
  ) {
    let getDayIndex = moment(dt).format("d");
    if (!filteredIndexes.includes(parseInt(getDayIndex))) {
      arr.push({
        date: moment(dt).format("YYYY-MM-DD"),
        month: parseInt(moment(dt).format("MM")),
        isHoliday: toggleHoliday,
      });
    }
  }
  return arr;
};

export const getInbetweenDates = (start, end, filteredIndexes) => {
  for (
    var arr = [], dt = new Date(start);
    dt <= end;
    dt.setDate(dt.getDate() + 1)
  ) {
    let getDayIndex = moment(dt).format("d");
    if (!filteredIndexes.includes(parseInt(getDayIndex)))
      arr.push(moment(dt).format("YYYY-MM-DD"));
  }
  return arr;
};

export const getRoleFromRoleId = (roleId) => {
  let roles = [
    {
      label: "Admin",
      value: "R01",
    },
    {
      label: "Manager",
      value: "R02",
    },
    {
      label: "Teacher",
      value: "R03",
    },
    {
      label: "Trainee",
      value: "R04",
    },
    {
      label: "Volunteer",
      value: "R07",
    },
  ];
  return roles.find((rol) => rol.value === roleId);
};

export const getGamePath = (type) => {
  let path = "";
  switch (type) {
    case 1:
      path = "/class/presentation/play/matchtheobject";
      break;
    case 2:
      path = "/class/presentation/play/missingletter";
      break;
    case 3:
      path = "/class/presentation/play/matchthegoodword";
      break;
    case 4:
      path = "/class/presentation/play/etub";
      break;
  }
  return path;
};
export const accessHeaders = () => {
  const accessToken = localStorage.getItem("accessToken");
  return {
    Authorization: `${accessToken}`,
  };
};

export const ALPHABETS = [
  { alphabet: "A", id: 1 },
  { alphabet: "B", id: 2 },
  { alphabet: "C", id: 3 },
  { alphabet: "D", id: 4 },
  { alphabet: "E", id: 5 },
  { alphabet: "F", id: 6 },
  { alphabet: "G", id: 7 },
  { alphabet: "H", id: 8 },
  { alphabet: "I", id: 9 },
  { alphabet: "J", id: 10 },
  { alphabet: "K", id: 11 },
  { alphabet: "L", id: 12 },
  { alphabet: "M", id: 13 },
  { alphabet: "N", id: 14 },
  { alphabet: "O", id: 15 },
  { alphabet: "P", id: 16 },
  { alphabet: "Q", id: 17 },
  { alphabet: "R", id: 18 },
  { alphabet: "S", id: 19 },
  { alphabet: "T", id: 20 },
  { alphabet: "U", id: 21 },
  { alphabet: "V", id: 22 },
  { alphabet: "W", id: 23 },
  { alphabet: "X", id: 24 },
  { alphabet: "Y", id: 25 },
  { alphabet: "Z", id: 26 },
];

export const timeSince = (date, isToAddTime) => {
  let time = new Date(date).getTime();
  if (isToAddTime) time = time - 19800000;
  var seconds = Math.floor(Math.abs((new Date() - time) / 1000));
  // var interval = seconds / 31536000;
  // if (interval > 1) {
  //   return Math.floor(interval) + " years";
  // }
  // interval = seconds / 2592000;
  // if (interval > 1) {
  //   return Math.floor(interval) + " months";
  // }
  let interval = seconds / 86400;
  if (interval > 1) {
    return interval > 1
      ? moment(date).format("DD MMM YYYY")
      : Math.floor(interval) + " days ago";
  }

  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval) + " hours ago";
  }

  interval = seconds / 60;
  if (interval > 1) {
    return (
      Math.floor(interval) + (interval == 1 ? " minute ago" : " minutes ago")
    );
  }
  return Math.floor(seconds) + " seconds ago";
};

export const fetchAllCountries = async () => {
  let countryList = [];
  let countries = Country.getAllCountries();
  countries &&
    countries.map((country) => {
      countryList.push({
        label: country.name,
        value: country.isoCode,
      });
    });
  return countryList;
};
export const getStatesList = async (country) => {
  let statesList = [];
  if (country != null) {
    let states = State.getStatesOfCountry(country.value);
    states.map((state) => {
      statesList.push({
        label: state.name,
        value: state.isoCode,
        countryCode: state.countryCode,
      });
    });
    return statesList;
  }
};

export const getCititesList = (state) => {
  let citiesList = [];
  let cities = City.getCitiesOfState(state.countryCode, state.value);
  cities.map((city) => {
    citiesList.push({
      label: city.name,
      value: city.stateCode,
      countryCode: city.countryCode,
    });
  });
  return citiesList;
};

export const getPlayResult = (options) => {
  // 2 - wrong anser
  // 3 - right answer
  let optionChoosed = 2;
  let getOptionChoosed = options.find(
    (op) => op.isCorrectAnswer && op.isSelected
  );
  if (getOptionChoosed) optionChoosed = 3;

  return optionChoosed;
};

export const getPlayerIdWhoAnswered = (playerList, gameMode, index) => {
  if (gameMode == 2) {
    return playerList[index].playerId;
  } else if (gameMode == 1) {
    return playerList[0]._id;
  }
};

export const getTotalRightOrWrong = (answers) => {
  let totalRight = 0,
    totalWrong = 0;
  answers.map((ans) => {
    let opt = ans.options.find((op) => op.isCorrectAnswer && op.isSelected);
    if (opt) totalRight++;
    else totalWrong++;
  });
  return {
    totalRight: totalRight,
    totalWrong: totalWrong,
  };
};

export const getLayoutOfCollage = (items) => {
  let length = items?.length;
  let layout;
  switch (length) {
    case 1:
      layout = [1, 1];
      break;
    case 2:
      layout = [2, 1];
      break;
    case 3:
      layout = [2, 1];
      break;
    case 4:
      layout = [2, 2];
      break;
    case 5:
      layout = [2, 3];
      break;
    case 6:
      layout = [3, 3];
      break;
    case 7:
      layout = [3, 4];
      break;
    case 8:
      layout = [4, 4];
      break;
    case 9:
      layout = [4, 5];
      break;
    case 10:
      layout = [5, 5];
      break;
    case 11:
      layout = [6, 5];
      break;
    case 12:
      layout = [6, 6];
      break;
    case 13:
      layout = [7, 6];
      break;
    case 14:
      layout = [7, 7];
      break;
    case 15:
      layout = [8, 7];
      break;
    case 16:
      layout = [8, 8];
      break;
    case 17:
      layout = [9, 8];
      break;
    case 18:
      layout = [9, 9];
      break;
    case 19:
      layout = [10, 9];
      break;
    case 20:
      layout = [10, 10];
      break;
    case 21:
      layout = [11, 10];
      break;
    case 22:
      layout = [11, 11];
      break;
    case 23:
      layout = [12, 11];
      break;
    case 24:
      layout = [12, 12];
      break;
    case 25:
      layout = [13, 12];
      break;
    case 26:
      layout = [13, 13];
      break;
    case 27:
      layout = [14, 13];
      break;
    case 28:
      layout = [14, 14];
      break;
    case 29:
      layout = [15, 14];
      break;
    case 30:
      layout = [15, 15];
      break;
    case 31:
      layout = [15, 16];
      break;
    case 32:
      layout = [16, 16];
      break;
    case 33:
      layout = [17, 16];
      break;
    case 34:
      layout = [17, 17];
      break;
    case 35:
      layout = [18, 17];
      break;
    case 36:
      layout = [18, 18];
      break;
    default:
      layout = [5, 4];
  }
  return layout;
};

export const getActiveTab = (module, routeSegments) => {
  let getIndexOfModule = routeSegments.findIndex((d) => d == module);
  let pageComponent = routeSegments[getIndexOfModule + 1];
  let tab = null;
  if (
    pageComponent == "profile" ||
    pageComponent == "assignment" ||
    pageComponent == "overall"
  ) {
    tab = 1;
  } else if (
    pageComponent == "class" ||
    pageComponent == "pending" ||
    pageComponent == "tubinbox"
  ) {
    tab = 2;
  } else if (
    pageComponent == "subscription" ||
    pageComponent == "personalized"
  ) {
    tab = 3;
  } else if (
    pageComponent == "curriculum" ||
    pageComponent == "studentreport"
  ) {
    tab = 4;
  } else if (pageComponent == "tubgallery") {
    tab = 7;
  } else if (pageComponent == "session") {
    tab = 8;
  }
  return tab;
};
