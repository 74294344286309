import {  main } from '../service/actionType'

let sampleData =  [
    {
        loading: false,
        graphData: {
            percentage: 0,
            Total_Curriculum_Words: 0,
            Total_Explored: 0
        },
        gameFilter: null,
        contentFilter: null
    },
    {
        loading: false,
        graphData: {
            x_axis : [],
            y_axis_1: [],
            y_axis_2: [],
            y_axis_3: []
        },
        gameFilter: null,
        contentFilter: null
    },
    {
        loading: false,
        graphData: {
            x_axis : [],
            y_axis: []
        },
        gameFilter: null,
        contentFilter: null
    },
    {
        loading: false,
        graphData: {
            x_axis : [],
            y_axis: []
        },
        gameFilter: null,
        contentFilter: null
    },
    {
        loading: false,
        graphData: {
            x_axis : [],
            y_axis: []
        },
        gameFilter: null,
        contentFilter: null
    },
    {
        loading: false,
        graphData: {
            x_axis : [],
            y_axis: [],
            data: []
        },
        gameFilter: null,
        contentFilter: null
    },
    {
        loading: false,
        graphData: {
            x_axis : [],
            y_axis: [],
            y_axis_2: [],
        },
        gameFilter: null,
        contentFilter: null
    },
    {
        loading: false,
        graphData: {
            x_axis : [],
            y_axis: []
        },
        gameFilter: null,
        contentFilter: null
    },
    {
        loading: false,
        graphData: {
            x_axis : [],
            y_axis: []
        },
        gameFilter: null,
        contentFilter: null
    },
    {
        loading: false,
        graphData: {
            x_axis : [],
            y_axis: []
        },
        gameFilter: null,
        contentFilter: null
    }
]
const initialState = {
    allGrpahsList: [
        {
            loading: true,
            graphData: {
                percentage: 0,
                Total_Curriculum_Words: 0,
                Total_Explored: 0
            },
            gameFilter: null,
            contentFilter: null
        },
        {
            loading: true,
            graphData: {
                x_axis : [],
                y_axis: []
            },
            gameFilter: null,
            contentFilter: null
        },
        {
            loading: true,
            graphData: {
                x_axis : [],
                y_axis: []
            },
            gameFilter: null,
            contentFilter: null
        },
        {
            loading: true,
            graphData: {
                x_axis : [],
                y_axis: []
            },
            gameFilter: null,
            contentFilter: null
        },
        {
            loading: true,
            graphData: {
                x_axis : [],
                y_axis: []
            },
            gameFilter: null,
            contentFilter: null
        },
        {
            loading: true,
            graphData: {
                x_axis : [],
                y_axis: [],
                data: []
            },
            gameFilter: null,
            contentFilter: null
        },
        {
            loading: true,
            graphData: {
                x_axis : [],
                y_axis: [],
                y_axis_2: [],
            },
            gameFilter: null,
            contentFilter: null
        },
        {
            loading: false,
            graphData: {
                x_axis : [],
                y_axis: []
            },
            gameFilter: null,
            contentFilter: null
        },
        {
            loading: false,
            graphData: {
                x_axis : [],
                y_axis: []
            },
            gameFilter: null,
            contentFilter: null
        },
        {
            loading: false,
            graphData: {
                x_axis : [],
                y_axis: []
            },
            gameFilter: null,
            contentFilter: null
        }
    ],
    studentsGrpahsList: [
        {
            loading: true,
            graphData: {
                x_axis: [],
                y_axis: []
            }
        },
        {
            loading: true,
            graphData: {
                percentage: null
            }
        },
        {
            loading: true,
            graphData: {
                x_axis: [],
                y_axis: []
            }
        },
        {
            loading: true,
            graphData: {
                x_axis: [],
                y_axis: []
            }
        },
        {
            loading: true,
            graphData: {
                x_axis: [],
                y_axis: []
            }
        },
        {
            loading: true,
            graphData: {
                x_axis: [],
                x_axis_1: [],
                y_axis: []
            }
        },
        {
            loading: true,
            graphData: {
                x_axis: [],
                x_axis_1: [],
                y_axis: []
            }
        },
        {
            loading: true,
            graphData: {
                x_axis: [],
                y_axis: []
            }
        },
        {
            loading: true,
            graphData: {
                x_axis: [],
                y_axis: []
            }
        },
        {
            loading: true,
            graphData: {
                x_axis: [],
                y_axis: []
            }
        }
    ],
}
const analytics =  (state = Object.assign({}, initialState), { type, payload }) => {
    switch (type) {
        case main.ANALYTICS_GRAPHS_LIST:
            return {
                ...state,
                allGrpahsList: payload
            }
        case main.STUDENT_ANALYTICS_GRAPHS_LIST:
            return {
                ...state,
                studentsGrpahsList: payload
            }
        case main.RESET_GRAPHS:
            return {
                ...state,
                allGrpahsList: {
                    ...state.allGrpahsList,
                    ...sampleData
                }
            }
        default:
            return state
    }

}

export default analytics;