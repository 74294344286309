
import React, { useRef } from "react";
import { Col } from "reactstrap";
import { toggleRootClassName, history } from '../../../helpers'
import { getLinksBasedOnRoleId } from '../../../helpers/roleHandler'
import { connect } from 'react-redux'
import "../../../assets/css/main.scss";
import { Auth } from 'aws-amplify';
import * as session from '../../../actions/session';
import { bindActionCreators } from "redux";
import toast from '../../../helpers/Toast';
import { main } from "../../../service/actionType";

let prefix = "/dashboard"

const Sidebar = ( props) => {
  const { innerWidth: width } = window;
  const sideBarRef = useRef(null)
  const redirectTo = (pathName) => {

    if(width <= 1199) {
      toggleRootClassName("open-sidebar")
    }
    console.log('pathName', pathName)
    if(pathName === "tubclass/assignment"){
      props.sessionAction.selectedLeftSidebar(1)
      if(props.dataToPersist?.classDropdownList?.length > 0){
        props.history.push(prefix + pathName)
      } else {
        toast('You are not assigned to any class. Please contact your School Administrator', 'error')
      }
    } else {
      props.history.push(prefix + pathName)
    }
  }

  let filteredLinks = getLinksBasedOnRoleId(1)

  const handleLinkClick = async (data) => {
    if(data.key === "logout"){
      await Auth.signOut();
      localStorage.clear();
      sessionStorage.clear();
      props.dispatch({type: main.RESET_PERSIST})
      props.dispatch({type: main.LOGOUT})
      history.replace("/");
    } else {
      await props.dispatch({ type: main.IS_SELECTED_ACTIVITY_TAB, payload: false})
      redirectTo(data.routeName)
    }
  }
  const getHeightOfRightStudentsList = () => {
    // console.log("sideBarRef.current?.clientHeight", sideBarRef.current?.clientHeight )
      return sideBarRef.current?.clientHeight
  }
  return (
    <>
      <Col md="auto" className="side-nav p-0 leftsidebar" >
        <aside 
          className="w-100 d-flex flex-column leftScrollBar"
          ref={sideBarRef}
          style={{ height: getHeightOfRightStudentsList(), overflowY: "auto"}}
        >
          <ul 
            className="w-100 list-unstyled staff-sidebar custom-scrollbar flex-grow-1 mt10"
            
          >
            {filteredLinks.map((data, i) => (
              <li
                id={data.togglerName}
                className={`nav-list-menu text-center ${
                  props.location.pathname.includes(prefix + data?.pathname) && "active"
                  } `}
              >
                {!data.disabled ?
                <div
                    onClick={() => handleLinkClick(data)}
                    className={"br-0 cursor-pointer"}
                  >
                    <div className="nav-view-icon">
                      <img 
                        src={props.location.pathname.includes(prefix + data?.pathname) ? data.selectedImage : data.image} 
                        alt="alphatub"
                      />
                    </div>
                    <div className="nav-view-content">
                      <h5 className="name">{data.name}</h5>
                    </div>
                </div>
                :
                <div
                    className={"disabled_links br-0"}
                  >
                    <div className="nav-view-icon">
                      <img 
                        src={props.location.pathname.includes(prefix + data?.pathname) ? data.selectedImage : data.image} 
                        alt="alphatub"  
                      />
                    </div>
                    <div className="nav-view-content">
                      <h5 className="name">{data.name}</h5>
                    </div>
                </div>
              }
              </li>
            ))}
          </ul>
        </aside>
      </Col>
    </>
  );
}

const mapStateToProps = ({ loader, profile }) => {
  return ({
    loader,
    profile
  })
}

let mapDispatchToProps = (dispatch) => {
  return {
    sessionAction: bindActionCreators(session, dispatch),
    dispatch
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar)