import { React } from 'react';
import { Card, CardBody } from 'reactstrap';
import { AppLoader } from '../loader/appLoader';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

export const ChartBaseComponent = ({ report_number, title, loading, children, customText }) => {
    return (
        <div className='report row no-gutters'>
            <div className='col-12'>
                <Card>
                    <CardBody>
                        <div className='report_heading'>
                            <p>{title}</p>
                        </div>
                        <div className='custom_description'>
                            <p>{customText}</p>
                        </div>
                        {loading ?
                            <Skeleton
                                style={{marginTop: 15}}
                                height="350px"
                                width="100%"
                            />
                            :
                            <div className='main_graph'>
                                {children}
                            </div>
                        }


                    </CardBody>
                </Card>
            </div>
        </div>
    )
}

// <AppLoader 
                //     className="w-100"
                // />