import vector1Red from '../assets/images/vector-1-red.svg'
import vector2Red from '../assets/images/vector-2-red.svg'
import vector3Red from '../assets/images/vector-3-red.svg'
import vector4Red from '../assets/images/vector-4-red.svg'
import vector5Red from '../assets/images/vector-5-red.svg'
import vector6Red from '../assets/images/vector-6-red.svg'
import vector1Blue from '../assets/images/vector-1-blue.svg'
import vector2Blue from '../assets/images/vector-2-blue.svg'
import vector3Blue from '../assets/images/vector-3-blue.svg'
import TubPage from '../assets/images/TubPage.svg'
import TubPages from '../assets/images/TubPages.svg'
import vector4Blue from '../assets/images/vector-4-blue.svg'
import vector5Blue from '../assets/images/vector-5-blue.svg'
import vector6Blue from '../assets/images/vector-6-blue.svg'

let roleBasedAllLinks = {
    1 : [
      {
        name: "Dashboard",
        image: vector2Red,
        key: 'analytics',
        selectedImage: vector2Blue,
        routeName: "/analytics/overall",
        pathname: '/analytics',
        disabled: false
      },
      {
        name: "Tub Page",
        image: TubPages,
        key: 'tubpage',
        selectedImage: TubPage,
        routeName: "/tubpage",
        pathname: '/tubpage',
        disabled:  false
      },
      {
        name: "Settings",
        image: vector4Red,
        key: 'settings',
        selectedImage: vector4Blue,
        routeName: "/settings/profile",
        pathname: '/settings',
        disabled: false
      },
      {
        name: "Logout",
        image: vector5Red,
        key: 'logout',
        selectedImage: vector5Blue,
        routeName: "logout",
        pathname: '/login',
        disabled: false
      }
    ],
    get : function(roleId) {
      if(this[roleId])
          return this[roleId]
      else return []
    }
}

if (process.env.REACT_APP_ENVIRONMENT !== 'prod') {
  roleBasedAllLinks[1].splice(1, 0,  {
      name: "Hierarchy",
      image: vector6Red,
      key: 'hierarchy',
      selectedImage: vector6Blue,
      routeName: "/hierarchy",
      pathname: '/hierarchy',
      disabled:  false
  })
}


export const getLinksBasedOnRoleId = (roleId=1) => {
  // let path = history.location.pathname.split('/').pop()
    let filteredLinks
    if(roleBasedAllLinks.get(roleId)?.length){
          filteredLinks = roleBasedAllLinks.get(roleId)
          // let checkPathInLinks = filteredLinks.findIndex(data => data.pathname === path)
         
    }
    return filteredLinks
}
