
import React, { useEffect, useState, useRef } from "react";
import {  Col } from "reactstrap";
import { toggleRootClassName, history, accessHeaders } from '../../../helpers'
import { connect } from 'react-redux'
import "../../../assets/css/main.scss";
import vectorLaunchclass from '../../../assets/images/Vector.png'
import noImage from '../../../assets/images/noImage.png'
import { useCustomEventListener } from 'react-custom-events';
import moment from 'moment';
import { main } from "../../../service/actionType";
import { API } from "aws-amplify";
import { ENVIRONMENT } from "../../../common/constants";

const Sidebar = (props) => {
  const [currentSchoolId, setCurrentSchool] = useState(null)
  // const [ profileDetail, setProfileDetail] = useState({label: null , profilePic: {original: null}})
  const { innerWidth: width } = window;
  // const [height, setHeight] = useState(0)
  const rightScrollBar = useRef(null)
  const sideBar = useRef(null)
  const launchTubRef = useRef(null)
  const [schools, setSchools] = useState([]);


  const getSchoools  = async () => {
      const { data } = await API.get(ENVIRONMENT, "director/schoolListing" ,{
        headers: accessHeaders(),
      });
      setSchools(() => [...data.items]);
  }

  useEffect(() => {
    getSchoools();

  }, [])

  return (
    <>
      <Col md="auto" className="right-side-nav p-0 rightsidebar" id="rightsidenav">
        <aside className="w-100 d-flex flex-column">
          <ul className="w-100 list-unstyled rightbarinner flex-grow-1 " ref={sideBar}>
            <div ref={launchTubRef}>
              <div className="text-center">
                <h5 className="right-tubtext">TUB Schools</h5>
              </div>
            </div>
            {schools.length > 0 &&
            <div 
              className="rightScrollBar mt10"
              ref={rightScrollBar}
            >
                {/* <div className="righttub-members">
                  <img 
                    src={(profileDetail != null && profileDetail.Profile_img != undefined && profileDetail.Profile_img != null && profileDetail.Profile_img.original != undefined && profileDetail.Profile_img.original != '' && profileDetail.Profile_img.original != null)  ? profileDetail.Profile_img.original : noImage} 
                    className="studentProfileImg"
                    alt="alphatub"
                  />
                  <h5>{profileDetail?.label}</h5>
                </div> */}
              {schools.map(school => (
                  <div className="righttub-members mt-2 cursor-pointer">
                    <img 
                      src={school?.userDetails?.Profile_img.original ? school?.userDetails?.Profile_img.original : noImage} 
                      className={`${(currentSchoolId && currentSchoolId._id === school._id) ? "active_student_image" : ""} right_sidebar_student_image `}
                      alt="alphatub"
                    />
                    <h5>{school?.SchoolName ? school?.SchoolName?.split(' ').slice(0, 2).join(' ') : '-'}</h5>
                  </div>))}
            </div>
            } 
          </ul>
        </aside>
      </Col>
    </>
  );
}

const mapStateToProps = ({ loader, profile , session}) => {
  return ({
    loader,
    profile,
    session
  })
}

export default connect(mapStateToProps, null)(Sidebar)
