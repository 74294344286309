import { loaders, main } from '../service/actionType';
import { API } from 'aws-amplify';
import toast from '../helpers/Toast';
import { ENVIRONMENT } from '../common/constants';
import { accessHeaders } from '../helpers';
import _ from 'lodash';


function generateColorFromString(str) {
    // Simple hash function
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 4) - hash);
    }
    
    // Convert hash to a color
    let color = '#';
    for (let i = 0; i < 3; i++) {
        let value = (hash >> (i * 2)) & 0xFF;
        color += ('00' + value.toString(16)).substr(-2);
    }
    
    return color;
}

export const fetchGraphData = (index, apiname, noOfDays, filterPayload, tabFilter) => async (dispatch, getState) => {
    try {
        let { allGrpahsList } = getState().analytics
        let { dataToPersist, tubGallery } = getState()
        let selectedClass = sessionStorage.getItem('selectedClass')
        let classId = selectedClass ? selectedClass : dataToPersist?.selectedClass?.value
        let params = {
            classId: classId,
            schoolId: sessionStorage.getItem('schoolId'),
        }
        if (filterPayload !== null && filterPayload.gameFilter !== undefined) {
            params['gameFilter'] = filterPayload.gameFilter
        } else if (filterPayload !== null && filterPayload.contentFilter !== undefined) {
            params['contentFilter'] = filterPayload.contentFilter
        }

        if (!_.isEmpty(noOfDays)) {
            params['noOfDays'] = noOfDays
        }

        if (!_.isEmpty(noOfDays)) {
            params['filter'] = tabFilter
        }

        allGrpahsList[index]['loading'] = true

        dispatch({ type: main.ANALYTICS_GRAPHS_LIST, payload: allGrpahsList })
        let url = `${apiname}`;
        let myInit = {
            headers: accessHeaders(),
            queryStringParameters: params,
        }
        const response = await API.get(ENVIRONMENT, url, myInit)
        if (response.status === 200) {
            let axis = {
                x_axis: [],
                y_axis: []
            }
            let series = {
                y_axis: [],
            };
            allGrpahsList[index]['loading'] = false

            if (index == 0) {
                // allGrpahsList[index]['graphData'] = response.data.result
                if (!_.isEmpty(response.data.result)) {
                    response.data?.result.map(rs => {
                        allGrpahsList[index]['graphData'].push({
                            percentage: rs.percentage != null ? rs.percentage : 0,
                            Total_Curriculum_Words: rs.Total_Curriculum_Words,
                            Total_Explored: rs.Total_Explored,
                            class_Name: rs.class?.class_Name,
                            SchoolName: rs.school?.SchoolName
                        })
                    })
                }
                // if(!_.isEmpty(response.data.result)){
                //     response.data.result.map(rs => {
                //         axis.push(rs.percentage != null ? response.percentage : 0)
                //         axis.push(parseInt(rs.Total_Curriculum_Words))
                //         axis.push(parseInt(rs.Total_Explored))
                //     })
                //     allGrpahsList[index]['graphData'] = axis
                // }
            } else if (index == 1) {
              if (!_.isEmpty(response.data)) {
                    response.data.map(rs => {
                        axis.x_axis.push(parseInt(rs?.sum))
                        axis.y_axis.push(rs?.school?.SchoolName)
                    })
                    allGrpahsList[index]['graphData'] = axis
                }
            } else if (index == 2) {
                if (!_.isEmpty(response.data)) {
                    response.data.map(rs => {
                        axis.x_axis.push(rs?.school?.SchoolName)
                        axis.y_axis.push(parseInt(rs?.count))
                    })
                    allGrpahsList[index]['graphData'] = axis
                }
            } else if (index == 3) {
                if (!_.isEmpty(response.data)) {
                    axis.miscellaneousValues = [];
                    response.data.map(rs => {
                        axis.x_axis.push(rs?.school?.SchoolName)
                        // axis.y_axis.push({
                        //     average: rs?.average,
                        //     totalParticipations: rs?.totalParticipations
                        // });

                        axis.y_axis.push(parseInt(rs?.average || 0));
                        axis.miscellaneousValues.push(parseInt(rs?.totalParticipations || 0));
                    })
                    allGrpahsList[index]['graphData'] = axis
                }
            } else if (index == 4) {
                if (!_.isEmpty(response.data)) {
                    response.data.map(rs => {
                        axis.x_axis.push(parseInt(rs?.data))
                        axis.y_axis.push(rs?.school?.SchoolName)
                    })
                    allGrpahsList[index]['graphData'] = axis
                }
            } else if (index == 5) {
                if (!_.isEmpty(response.data)) {
                    axis["data"] = []
                    response.data.map(rs => {
                        axis.x_axis.push(rs._id)
                        axis.y_axis.push(parseInt(rs?.count))
                        axis["data"].push({
                            name: rs._id,
                            count: rs.count
                        })
                    })
                    allGrpahsList[index]['graphData'] = axis
                }
            } else if (index == 6) {
                if (!_.isEmpty(response.data)) {
                    let sortedList = _.sortBy(response.data, 'firstName');
                    axis["data"] = []
                    axis["y_axis_2"] = []
                    axis["y_axis_3"] = []
                    sortedList.map(rs => {
                        axis.x_axis.push(rs.school?.SchoolName)
                        axis.y_axis_3.push(parseInt(rs.data?.parent_count))
                        axis["y_axis_2"].push(parseInt(rs.data?.student_count))
                        axis['y_axis'].push(parseInt(rs?.data?.total_seats))
                    })

                    // const studentsLeft = tubGallery.rightSidebarStudentsList.filter(({ firstName: id1 }) => !response.data.some(({ firstName: id2 }) => id2 === id1));
                    // console.log("abcdde", studentsLeft)
                    // if(studentsLeft.length > 0){
                    //     studentsLeft.map(rs => {
                    //         axis.x_axis.push(rs.school.SchoolName)
                    //         axis.y_axis.push(0)
                    //         axis["y_axis_2"].push(0)
                    //     })
                    // }
                    allGrpahsList[index]['graphData'] = axis
                }
            } else if (index == 7) {
                if (!_.isEmpty(response.data)) {
                    let sortedList = _.sortBy(response.data, 'firstName');
                    sortedList.map(rs => {
                        axis.x_axis.push(rs.school.SchoolName)
                        let totalCount = _.sumBy(rs.data, 'withUserPlayedCount');
                        axis.y_axis.push(totalCount);
                        // axis.y_axis.push(parseInt(rs.data.count))
                    })
                    // let totalCount = _.sumBy(sortedList, 'data.count');
                    // axis.x_axis.push("Total");
                    // axis.y_axis.push(totalCount);

                    allGrpahsList[index]['graphData'] = axis
                }
            }
            else if (index == 8) {
                if (!_.isEmpty(response.data)) {
                    // axis.x_axis.push(parseInt(response?.data?.curriculum_Choice), parseInt(response?.data?.parent_Choice), parseInt(response?.data?.total_Words))

                    // response?.data.map((item) => {
                    //   axis.x_axis.push([
                    //     item.curriculum_Choice,
                    //     item.parent_Choice,
                    //     item.total_Words,
                    //   ]);
                    //   //   axis.x_axis.push(item.parent_Choice);
                    //   //   axis.x_axis.push(item.total_Words);
                    // });
                    let arrOne = [];
                    let arrTwo = [];
                    let arrThree = [];
                    let y_axis = [];
                    // for (let i = 0; i < response?.data?.length; i++) {
                    //   let element = response.data[i];
                    //   arrOne.push(element.curriculum_Choice);
                    //   arrTwo.push(element.total_Words);
                    //   arrThree.push(element.parent_Choice);
                    //   series.y_axis.push(element.school.SchoolName);
                    // }
                    response?.data?.forEach((item) => {
                        arrOne.push(item?.parent_Choice);
                        // arrTwo.push(item.total_Words);
                        arrTwo.push(item?.curriculum_Choice);
                        arrThree.push(item?.teacher_Choice);
                        series?.y_axis.push(item.school?.SchoolName);
                    });
                    let output = [
                        {
                            name: "Parent Choice",
                            data: arrOne,
                        },
                        // {
                        //     name: "Total Words",
                        //     data: arrTwo,
                        // },
                        {
                            name: "Curriculum Choice",
                            data: arrTwo,
                        },
                        {
                            name: 'Teacher Choice',
                            data: arrThree,
                        }
                    ];
                    // series.y_axis.push("Curriculum Choice", "Parent Choice", "Words");
                    let graphdata = [output, series];
                    allGrpahsList[index]["graphData"] = graphdata;
                }
            }
            else if (index == 9) {
                if (!_.isEmpty(response.data)) {
                    let arr = [];
                    const ids = new Set((response?.data || []).map((item) => (item?.data || []).map(d => d?._id)).flatMap(d => d));

                    arr = [...ids].map(id => ({
                        name: id,
                        data: [],
                    }));
                    
                    const colors = arr.map(arr => generateColorFromString(arr.name));


                    response?.data?.forEach((item) => {
                         arr.forEach((a, index) => {
                            const valueExists = item?.data?.find(d => d?._id === a.name);
                            arr[index].data.push(valueExists?.count || 0);
                         });
                         series?.y_axis.push(item?.school?.SchoolName);
                    })

                    let graphdata = [arr, series];
                    allGrpahsList[index]["graphData"] = graphdata;
                    allGrpahsList[index].colors = colors;
                }
            }
            // else if (index == 8) {
            //     if (!_.isEmpty(response.data)) {
            //         axis.x_axis.push(parseInt(response?.data?.curriculum_Choice), parseInt(response?.data?.parent_Choice), parseInt(response?.data?.total_Words))
            //         axis.y_axis.push('Curriculum Choice', 'Parent Choice', 'Words')
            //         allGrpahsList[index]['graphData'] = axis
            //     }
            // } 
            // else if (index == 9) {
            //     if (!_.isEmpty(response.data)) {
            //         response.data.map(rs => {
            //             axis.y_axis.push(rs.relation)
            //             axis.x_axis.push(parseInt(rs.withUserPlayedCount))
            //         })
            //         allGrpahsList[index]['graphData'] = axis
            //     }
            // }
            dispatch({ type: main?.ANALYTICS_GRAPHS_LIST, payload: allGrpahsList })
        }
    } catch (error) {
        // console.log("error", error);
        dispatch({ type: main?.RESET_GRAPHS })
    }

}
export const fetchStudentGraphData = (index, apiname, noOfDays, filterPayload, tabFilter, studentId) => async (dispatch, getState) => {
    try {
        let { studentsGrpahsList } = getState().analytics
        let { dataToPersist, tubGallery } = getState()
        let selectedClass = sessionStorage.getItem('selectedClass')
        let classId = selectedClass ? selectedClass : dataToPersist?.selectedClass?.value
        let params = {
            classId: classId,
            schoolId: sessionStorage.getItem('schoolId'),
            studentId: studentId
        }
        if (filterPayload !== null && filterPayload.gameFilter !== undefined) {
            params['gameFilter'] = filterPayload.gameFilter
        } else if (filterPayload !== null && filterPayload.contentFilter !== undefined) {
            params['contentFilter'] = filterPayload.contentFilter
        }

        if (!_.isEmpty(noOfDays)) {
            params['noOfDays'] = noOfDays
        }

        if (!_.isEmpty(noOfDays)) {
            params['filter'] = tabFilter
        }

        studentsGrpahsList[index]['loading'] = true

        dispatch({ type: main.STUDENT_ANALYTICS_GRAPHS_LIST, payload: studentsGrpahsList })
        let url = `${apiname}`;
        let myInit = {
            headers: accessHeaders(),
            queryStringParameters: params,
        }
        const response = await API.get(ENVIRONMENT, url, myInit)
        if (response.status === 200) {
            studentsGrpahsList[index]['loading'] = false
            let axis = {
                x_axis: [],
                y_axis: []
            }
            let res = response.data
            if (index == 0) {
                axis.x_axis = ["Completed", 'Pending']
                axis.y_axis = [res.percentage, 100 - res.percentage]
                studentsGrpahsList[index]['graphData'] = axis
            } else if (index == 1) {
                studentsGrpahsList[index]['graphData']['percentage'] = parseInt(res.percentage)
            } else if (index == 2) {
                if (!_.isEmpty(res)) {
                    res.map(rs => {
                        axis.x_axis.push(rs.date)
                        axis.y_axis.push(parseInt(rs.avgScore))
                    })
                    studentsGrpahsList[index]['graphData'] = axis
                }
            } else if (index == 3) {
                if (!_.isEmpty(res.userData)) {
                    res.userData.map(rs => {
                        axis.x_axis.push(parseInt(rs.withUserCreatedCount))
                        axis.y_axis.push(rs.relation)
                    })
                    studentsGrpahsList[index]['graphData'] = axis
                }
            } else if (index == 4) {
                if (!_.isEmpty(res.userData)) {
                    res.userData.map(rs => {
                        axis.x_axis.push(parseInt(rs.withUserPlayedCount))
                        axis.y_axis.push(rs.relation)
                    })
                    studentsGrpahsList[index]['graphData'] = axis
                }
            } else if (index == 5) {
                if (!_.isEmpty(res)) {
                    axis["x_axis_1"] = []
                    res.map(rs => {
                        axis.x_axis.push(parseInt(rs.total_Explored))
                        axis["x_axis_1"].push(parseInt(rs.totalRight))
                        axis.y_axis.push(rs.wordName)
                    })
                    studentsGrpahsList[index]['graphData'] = axis
                }
            } else if (index == 6) {
                if (!_.isEmpty(res)) {
                    axis["x_axis_1"] = []
                    res.map(rs => {
                        axis.x_axis.push(parseInt(rs.total_Explored))
                        axis["x_axis_1"].push(parseInt(rs.totalRight))
                        axis.y_axis.push(rs._id)
                    })
                    studentsGrpahsList[index]['graphData'] = axis
                }
            } else if (index == 7) {
                if (!_.isEmpty(res)) {
                    axis.x_axis.push(parseInt(res?.curriculum_Choice), parseInt(res?.parent_Choice), parseInt(res?.total_Words))
                    axis.y_axis.push('Curriculum Choice', 'Parent Choice', 'Words')
                    studentsGrpahsList[index]['graphData'] = axis
                }
            }
            // else if (index == 8) {
            //     if (!_.isEmpty(res)) {
            //         axis.x_axis.push(parseInt(res?.curriculum_Choice), parseInt(res?.parent_Choice), parseInt(res?.total_Words))

            //         studentsGrpahsList[index]['graphData'] = axis
            //     }
            // } else if (index == 9) {
            //     if (!_.isEmpty(res)) {
            //         axis.x_axis.push(parseInt(res?.curriculum_Choice), parseInt(res?.parent_Choice), parseInt(res?.total_Words))

            //         studentsGrpahsList[index]['graphData'] = axis
            //     }
            // } 
            dispatch({ type: main.STUDENT_ANALYTICS_GRAPHS_LIST, payload: studentsGrpahsList })

        }
    } catch (error) {
        console.log("error", error);
        dispatch({ type: main.RESET_GRAPHS })
    }

}

export const resetGraphs = () => async (dispatch, getState) => {
    let allGrpahsList = [
        {
            loading: false,
            graphData: [],
            gameFilter: null,
            contentFilter: null
        },
        {
            loading: false,
            graphData: {
                x_axis: [],
                y_axis: [],
                y_axis_1: [],
                y_axis_2: [],
                y_axis_3: []
            },
            gameFilter: null,
            contentFilter: null
        },
        {
            loading: false,
            graphData: {
                x_axis: [],
                y_axis: []
            },
            gameFilter: null,
            contentFilter: null
        },
        {
            loading: false,
            graphData: {
                x_axis: [],
                y_axis: []
            },
            gameFilter: null,
            contentFilter: null
        },
        {
            loading: false,
            graphData: {
                x_axis: [],
                y_axis: []
            },
            gameFilter: null,
            contentFilter: null
        },
        {
            loading: false,
            graphData: {
                x_axis: [],
                y_axis: [],
                data: []
            },
            gameFilter: null,
            contentFilter: null
        },
        {
            loading: false,
            graphData: {
                x_axis: [],
                y_axis: [],
                y_axis_2: [],
                data: []
            },
            gameFilter: null,
            contentFilter: null
        },
        {
            loading: false,
            graphData: {
                x_axis: [],
                y_axis: []
            },
            gameFilter: null,
            contentFilter: null
        },
        {
            loading: false,
            graphData: {
                x_axis: [],
                y_axis: []
            },
            gameFilter: null,
            contentFilter: null
        },
        {
            loading: false,
            graphData: {
                x_axis: [],
                y_axis: []
            },
            gameFilter: null,
            contentFilter: null
        }
    ]
    dispatch({ type: main.ANALYTICS_GRAPHS_LIST, payload: allGrpahsList })
}
