import { React, Component } from "react";
import ButtonType1 from "../../../../../components/inputs/ButtonType1";
import { CustomBootstrapTable } from "../../../../../components/Table/CustomBootstrapTable";
import { StudentsTableColumns } from "../../../../../helpers/tableDataMapper";
import ReactSelect from "../../../../../components/inputs/ReactSelect";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import toast from "../../../../../helpers/Toast";
import _ from "lodash";
const EnrolledStudents = ({
  studentFilterDropdown,
  studentQueryPayload,
  disabled,
  isEnrolledStudentsBtnsDisabled,
  resendStudentRequest,
  onNavigate,
  studentTableList,
  onChangeStatusFilter,
}) => {
  const resendRequest = async (row) => {
    await resendStudentRequest(row);
  };
  const getTableColumns = (disabled) => {
    if (disabled) {
      let action = {
        text: "Action",
        dataField: "action",
        headerClasses: "columnHeader text-center",
        formatter: (cellContent, row, rowIndex) => {
          return (
            <>
              <div className="row align-items-center justify-content-center">
                <div
                  className=""
                  // className={row.status === 'Active' ? 'roundBlue mr-2' : 'roundRed mr-2'}
                >
                  {/* {row.status === 'Active' &&  row.parentAcceptance === 1 && !_.isEmpty(row.playerId) &&
                                        <p onClick={() => this.resendRequest(row)} className="resendRequest mr-2">Resend Request</p>
                                    } */}
                </div>
                <div className="ml-1">
                  <img
                    src={require("../../../../../assets/images/edit.png")}
                    className="cursor-pointer studentEditIcon"
                    onClick={() => onNavigate(4, row)}
                    alt="alphatub"
                  />
                </div>
              </div>
            </>
          );
        },
      };
      return [...StudentsTableColumns, action];
    } else {
      return [...StudentsTableColumns];
    }
  };
  const exportData = () => {
    if (studentTableList?.length > 0) {
      let newArray = [];
      studentTableList.map((data) => {
        newArray.push({
          firstName: data.firstName,
          middleName: data.middleName,
          lastName: data.lastName,
          dob: data.dob,
          firstContactNo: data.firstContactNo,
          secondContactNo: data.secondContactNo,
          email: data.email,
        });
      });
      const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const fileName = "Student details";
      const fileExtension = ".xlsx";
      const ws = XLSX.utils.json_to_sheet(newArray);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);
    } else {
      toast("Please create student to export", "error");
    }
  };

  return (
    <div className="col-md-12 pl-3 pr-3 classTable ">
      {/* <div className="col-md-12 mb-3 mt-1">
                    <div className="row no-gutters">
                        <img src={require('../../../../../assets/images/left-arrow.svg').default} className="back-btn" onClick={() => this.props.onNavigate(5, null)} alt="alphatub" />
                        <p className="global_color privacy-content cursor-pointer ml-3" onClick={() => this.props.onNavigate(5, null)} >Back To Classes</p>
                    </div>
                </div> */}
      <div className="row no-gutters">
        <div className="col-10">
          <h3 className="login-text-login text-left">Enrolled Students</h3>
        </div>
        <div className="col-2">
          <img
            src={require("../../../../../assets/images/left-arrow.svg").default}
            className="back-btn"
            onClick={() => onNavigate(5, null)}
            alt="alphatub"
          />
          <span
            className="global_color privacy-content cursor-pointer ml-3"
            onClick={() => onNavigate(5, null)}
          >
            Back To Classes
          </span>
        </div>
        {/* {!disabled &&
                        <div className="col-3">
                            <p className="classes-seats-link text-right global_red_color" onClick={() => this.props.createStudent(7, "Delete Class", null, 'student')}>Delete Class</p>
                        </div>
                    } */}
      </div>
      <div className="row no-gutters mt-3">
        <div className="col-3">
          <div className="row no-gutters">
            <div className="col-12 custom-reselect1">
              <ReactSelect
                name="status"
                options={studentFilterDropdown}
                isClearable={false}
                onChange={(...params) => onChangeStatusFilter(...params, 2)}
                value={studentQueryPayload["status"]}
              />
            </div>
          </div>
        </div>
        {!isEnrolledStudentsBtnsDisabled && (
          <div className="col-9">
            <div className="row no-gutters justify-end">
              <div className="col-10">
                <div className="row">
                  {/* <div className="col-4">
                                            <ButtonType1 
                                                btnName="Add Student"
                                                className='buttonType1'
                                                onClick={() => this.props.createStudent(1, "Add New Student", null, 'student')}
                                            />
                                        </div> */}

                  <div className="col-4">
                    {/*  <ButtonType1 
                                                btnName="Import Student"
                                                className='buttonType1'
                                                onClick={() => this.props.createStudent(2, "Import Students", null, 'import')}
                                            />*/}
                  </div>
                  <div className="col-4">
                    {/* <input type="search" placeholder='Search...' className="buttonType1"/> */}
                  </div>
                  <div className="col-4">
                    <ButtonType1
                      btnName="Export"
                      className="buttonType1"
                      onClick={() => exportData()}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="row no-gutters">
        <div className="col-12">
          <CustomBootstrapTable
            rows={studentTableList}
            columns={getTableColumns(disabled)}
            // isLoading={loader.isTableDetailsFetching}
            keyField="shopId"
            tableClass="custom-row-class"
            tableMainClass="student-table"
          />
        </div>
      </div>
    </div>
  );
};

// const mapStateToProps = ({ subscriptionTeam, loader }) => {
//     return {
//         subscriptionTeam,
//         loader
//     };
// };

// const mapDispatchToProps = (dispatch) => {
//     return bindActionCreators(subscriptionTeam, dispatch);
// };

export default EnrolledStudents;
