import { React, Component } from 'react';
import MainPage from './mainpage';
import Tubtypes from './tubactivities';
import { connect } from 'react-redux'
import * as tubgallery from '../../../../actions/tubgallery';
import * as sessionAction from '../../../../actions/session';
import * as curriculumAction from '../../../../actions/curriculum';
import { bindActionCreators } from "redux";
import { FILTER_OPTIONS, SORT_OPTIONS } from '../../../../helpers/variables';
import moment from 'moment'
import { differenceInCalendarDays } from 'date-fns';
import { API } from 'aws-amplify';
import { ENVIRONMENT } from '../../../../common/constants';
import { loaders, main } from '../../../../service/actionType';
import toast from '../../../../helpers/Toast';
import { CreateModel } from '../../../../components/Model/createModel';
import ButtonType1 from '../../../../components/inputs/ButtonType1';
import * as htmlToImage from 'html-to-image';
import html2canvas from 'html2canvas'
import FormInput from '../../../../components/inputs/FormInput';
import _ from 'lodash';
import CreateSession from './createSession';
import { AppLoader } from '../../../../components/loader/appLoader';
import { accessHeaders, ALPHABETS } from '../../../../helpers';
import { DateRangeFilterPopup } from '../../../../components/tubClass/dateRangeFilterPopup';
import Wrapper from '../../../../Hoc/wrapper';

class CreateSessionClass extends Component {
    defaultQueryPayload = {
        search: '',
        startDate: '',
        endDate: '',
        sort: '7',
        tubObjectType: null,
        classId: sessionStorage.getItem('selectedClass'),
        schoolId: null,
        studentId: null,
        activityType: null
    }
    constructor(props) {
        super(props);
        this.state = {
            tab: 1,
            tubMixName: null,
            selectedActivity: null,
            queryPayload: {
                search: null,
                startDate: null,
                endDate: null,
                sort: '7',
                tubObjectType: null,
                classId: null,
                schoolId: null,
                studentId: null,
                activityType: null,
                filterByDate: null,
                assignmentId: null
            },
            sortOptions: SORT_OPTIONS,
            filterOptions: FILTER_OPTIONS,
            init: true,
            sessionDisabledDates: [],
            createdSessionList: [],
            contentSelectedDate: null,
            selectedContents: [],
            suggestionsList: [],
            searchedValue: null,
            closeSuggessionPopup: false,
            showAddToSessionPopup: false,
            showActivityInfoPopup: false,
            workingDaysOptions: [
                {
                    label: "S",
                    value: "Sun",
                    checked: false,
                    index: 0
                },
                {
                    label: "M",
                    value: "Mon",
                    checked: false,
                    index: 1
                },
                {
                    label: "T",
                    value: "Tue",
                    checked: false,
                    index: 2
                },
                {
                    label: "W",
                    value: "Wed",
                    checked: false,
                    index: 3
                },
                {
                    label: "T",
                    value: "Thu",
                    checked: false,
                    index: 4
                },
                {
                    label: "F",
                    value: "Fri",
                    checked: false,
                    index: 5
                },
                {
                    label: "S",
                    value: "Sat",
                    checked: false,
                    index: 6
                },

            ],
            selectedWordOrTag: [],
            sessionSelectedDate: null,
            loading: true,
            showFilterDatePopup: false,
            isEditContent: false,
            selectedLetterIndexOnEdit: null,
            isShowEraseContentPopup: false,
            isSelectedOnlyAssignment: false
        };
    }
    static getDerivedStateFromProps(nextProps, prevState) {
        if (prevState.sessionDisabledDates?.length !== nextProps.session.sessionDisableDatesList?.length) {
            return {
                sessionDisabledDates: nextProps.session.sessionDisableDatesList,
                init: false
            }
        }
        return null
    }
    async componentDidMount() {
        let date = moment(new Date()).startOf('day').format('YYYY-MM-DD')
        const month =  moment(new Date()).startOf('day').format('MM')

        this.setState({ loading: true })
        Promise.all([
            this.getSessionDates(month),
            this.getCreatedSessionList(date),
            this.getTodayAndUpcomingSessionList()
        ]).then(() => {
            this.setState({ loading: false })
        })
            .catch(err => {
                console.log('err', err)
                this.setState({ loading: false })
            })
    }
    getSessionDates = async (month) => {
        const dataToPersist = this.props.dataToPersist
        const selectedClass = sessionStorage.getItem('selectedClass')
        const queryPayload = {
            schoolId: sessionStorage.getItem('schoolId'),
            classId: selectedClass != null ? selectedClass : dataToPersist.selectedClass?.value,
            date: moment(new Date()).startOf('day').format('YYYY-MM-DD'),
            month: month
        }
        await this.props.sessionAction.getSessionAssignedDatesList(queryPayload)
    }

    getTodayAndUpcomingSessionList = async () => {
        let dataToPersist = this.props.dataToPersist
        let selectedClass = sessionStorage.getItem('selectedClass')
        let queryPayload = {
            schoolId: sessionStorage.getItem('schoolId'),
            classId: selectedClass != null ? selectedClass : dataToPersist.selectedClass?.value,
            date: moment(new Date()).startOf('day').format('YYYY-MM-DD')
        }
        await this.props.sessionAction.getTodayAndUpcomingSessionList(queryPayload)

    }
    getCreatedSessionList = async (date) => {
        await this.props.sessionAction.getCreatedSessionList(date)
    }
    onSuggestionSelect = (value) => {
        let { queryPayload, } = this.state
        queryPayload['search'] = value
        this.setState({ queryPayload, closeSuggessionPopup: false }, this.getSelectedActivityList)
    }

    getSelectedActivityList = async () => {
        let { queryPayload, selectedActivity, isSelectedOnlyAssignment, contentSelectedDate } = this.state
        if (selectedActivity.key === 'Tag' && _.isEmpty(queryPayload['search'])) {

        } else {
            await this.props.dispatch({ type: main.ACTIVITY_LIST_QUERY_PAYLOAD, payload: queryPayload })
            let dataToPersist = this.props.dataToPersist
            let selectedClass = sessionStorage.getItem('selectedClass')
            let newqueryPayload = {
                ...queryPayload,
                schoolId: sessionStorage.getItem('schoolId'),
                classId: selectedClass ? selectedClass : dataToPersist.selectedClass.value,
                tubObjectType: selectedActivity.key != null ? selectedActivity.key : null,
                type: selectedActivity.type != null ? selectedActivity.type : null,
                activityType: selectedActivity != null ? selectedActivity.id === 3 ? 5 : selectedActivity.id : selectedActivity.id === 8 ? 2 : null,
                onlyAssignment: false,
                Session_Date: contentSelectedDate ?  moment(contentSelectedDate).format('YYYY-MM-DD') :  moment(new Date()).format('YYYY-MM-DD')
            }

            if (isSelectedOnlyAssignment) {
                newqueryPayload.onlyAssignment = true
            }

            try {
                this.props.dispatch({ type: loaders.INNER_APP_FETCHING, payload: true })
                let url = "contentselection/createsession/activity/list";
                let myInit = {
                    headers: accessHeaders(),
                    queryStringParameters: {
                        ...newqueryPayload,
                        userName: localStorage.getItem("username")
                    }
                }
                const response = await API.get(ENVIRONMENT, url, myInit);
                if (response.status === 200) {
                    this.checkForContentsAdded(response.data)
                } else {
                    this.props.dispatch({ type: main.TUB_ACTIVITY_LIST, payload: [] })
                }
                this.setState({ loading: false })
                this.props.dispatch({ type: loaders.INNER_APP_FETCHING, payload: false })
            } catch (error) {
                console.log("error", error);
                this.props.dispatch({ type: loaders.INNER_APP_FETCHING, payload: false })
            }
        }
    }
    checkForContentsAdded = async (response) => {
        let { selectedContents } = this.state
        let contentLength = selectedContents?.length
        if (contentLength > 0) {
            let i = 0
            while (i < contentLength) {
                if (response.length > 0) {
                    let checkInResIndex = response.findIndex(res => res._id == selectedContents[i]._id)
                    if (checkInResIndex != -1) {
                        response[checkInResIndex]["isSelected"] = true
                    }
                }
                i++
            }
        }
        this.props.dispatch({ type: main.TUB_ACTIVITY_LIST, payload: response })

    }
    getReadingPrograms = async () => {
        let { contentSelectedDate } = this.state
        contentSelectedDate = contentSelectedDate ?  moment(contentSelectedDate).format('YYYY-MM-DD') :  moment(new Date()).format('YYYY-MM-DD')
        await this.props.curriculumAction.getCurriculumAndProgramName()
        await this.props.curriculumAction.getReadingProgramsList(null, contentSelectedDate)
        this.setState({ loading: false })
    }
    selectActivity = async (activity, date) => {
        let { showActivityInfoPopup, queryPayload } = this.state
        await this.props.dispatch({ type: main.IS_SELECTED_ACTIVITY_TAB, payload: true })
        await this.props.dispatch({ type: main.TUB_ACTIVITY_LIST, payload: [] })

        if (activity.id === 1 || activity.id === 2 || activity.id === 3) {
            this.setState({ loading: true, selectedActivity: activity, contentSelectedDate: date, tab: 2 }, this.getSelectedActivityList)
        }
        if (activity.id === 4 || activity.id === 6 || activity.id === 5) {
            showActivityInfoPopup = true
            this.setState({
                showActivityInfoPopup,
                selectedActivity: activity,
                contentSelectedDate: date,
                tab: 2
            })
        }
        if (activity.id === 7) {
            this.setState({ loading: true, selectedActivity: activity, contentSelectedDate: date, tab: 2 }, this.getReadingPrograms)
        }
        if (activity.id === 8) {
            this.setState({ loading: true, selectedActivity: activity, contentSelectedDate: date, tab: 2, isSelectedOnlyAssignment: true }, this.getSelectedActivityList)
        }

    }

    navigate = async (tab, date) => {
        let { contentSelectedDate } = this.state
        if (tab === 1) {
            contentSelectedDate = moment(new Date()).startOf('day').format('YYYY-MM-DD')
            let dataToPersist = this.props.dataToPersist
            let selectedClass = sessionStorage.getItem('selectedClass')
            let queryPayload = {
                schoolId: sessionStorage.getItem('schoolId'),
                classId: selectedClass != null ? selectedClass : dataToPersist.selectedClass?.value,
                date: contentSelectedDate
            }
            this.props.sessionAction.toggleReviewTubClass(false)
            this.props.dispatch({ type: main.IS_SELECTED_ACTIVITY_TAB, payload: false })
            this.setState({ loading: true })
            await this.props.sessionAction.getTodayAndUpcomingSessionList(queryPayload)
            await this.getCreatedSessionList(contentSelectedDate)
            this.setState({
                tab: 1,
                selectedActivity: null,
                suggestionsList: [],
                loading: false,
                selectedContents: [],
                isSelectedOnlyAssignment: false,
                queryPayload: { ...this.defaultQueryPayload }
            })
            // contentSelectedDate = contentSelectedDate === null ? moment(new Date()).startOf('day').format('YYYY-MM-DD') : moment(contentSelectedDate).startOf('day').format('YYYY-MM-DD')

        } else if (tab === 3) {
            let sDate = moment(date).startOf('day').format('YYYY-MM-DD')
            this.setState({
                sessionSelectedDate: date,
                tab: 3
            }, () => this.navigateCallback(sDate))
        }
    }
    navigateCallback = async (date) => {
        this.setState({ loading: true })
        await this.getCreatedSessionList(date)
        this.setState({ loading: false })
    }

    onChangeCustomOptions = (name, value) => {
        let { queryPayload, filterOptions, isEditContent } = this.state
        if (name === "filterByDate") {
            let getdates = filterOptions.find(op => op.value === value)
            if (getdates && getdates.id === 7) {
                queryPayload["filterByDate"] = value
                this.setState({
                    showFilterDatePopup: true,
                    queryPayload
                })
            } else {
                if (getdates) {
                    queryPayload["startDate"] = getdates.startDate
                    queryPayload["endDate"] = getdates.endDate
                    queryPayload["filterByDate"] = value
                } else {
                    queryPayload["startDate"] = ''
                    queryPayload["endDate"] = ''
                    queryPayload["filterByDate"] = ''
                }
            }
            this.setState({ queryPayload }, () => this.checkforApiTrigger(name, value))
        } else if (name == "letter") {
            if (isEditContent) {
                this.setState({
                    isShowEraseContentPopup: true
                })
            } else {
                queryPayload["search"] = value
                this.setState({ queryPayload }, () => this.checkforApiTrigger("search", value))
            }
        } else {
            queryPayload[name] = value
            this.setState({ queryPayload }, () => this.checkforApiTrigger(name, value))
        }
    }
    checkforApiTrigger = async (name, value, index) => {
        this.setState({}, this.getSelectedActivityList)
    }
    onSearchTubActivities = async (name, value) => {
        let { selectedActivity, queryPayload, closeSuggessionPopup, isEditContent } = this.state
        let { dataToPersist } = this.props
        if (isEditContent) {
            this.setState({
                isShowEraseContentPopup: true
            })
        } else {
            let selectedClass = sessionStorage.getItem('selectedClass')
            let newqueryPayload = {
                ...queryPayload,
                schoolId: sessionStorage.getItem('schoolId'),
                classId: selectedClass ? selectedClass : dataToPersist.selectedClass.value,
                tubObjectType: selectedActivity.key != null ? selectedActivity.key : null,
                type: selectedActivity.type != null ? selectedActivity.type : null,
                activityType: selectedActivity != null ? selectedActivity.id === 3 ? 5 : selectedActivity.id : null,
                search: value
            }
            try {
                queryPayload['search'] = value
                if (value === '') {
                    closeSuggessionPopup = false
                    if (selectedActivity.key == 'Card' || selectedActivity.key == 'Sheet' || selectedActivity.key == 'Mix') {
                        this.getSelectedActivityList()
                    } else {
                        this.props.dispatch({ type: main.TUB_ACTIVITY_LIST, payload: [] })
                        this.setState({
                            selectedContents: []
                        })
                    }
                } else {
                    this.getSuggestion(newqueryPayload)
                    closeSuggessionPopup = true
                }
                this.setState({ queryPayload, closeSuggessionPopup })
            } catch (error) {
                console.log("error", error);
            }
        }
    }
    getSuggestion = async (payload) => {
        try {
            this.props.dispatch({ type: loaders.INNER_APP_FETCHING, payload: true })
            let url = "contentselection/createsession/suggestion/list";
            let myInit = {
                headers: accessHeaders(),
                queryStringParameters: {
                    ...payload,
                    userName: localStorage.getItem("username")
                }
            }
            const response = await API.get(ENVIRONMENT, url, myInit);
            if (response.status === 200) {
                this.setState({
                    suggestionsList: response.data
                })
            } else {
                this.setState({
                    suggestionsList: []
                })
            }
            this.props.dispatch({ type: loaders.INNER_APP_FETCHING, payload: false })
        } catch (error) {
            console.log("error", error);
            this.props.dispatch({ type: loaders.INNER_APP_FETCHING, payload: false })
        }
    }
    isSameDay = (disDate, date) => {
        return differenceInCalendarDays(disDate, date) === 0;
    }
    tileDisabled = ({ date, view }) => {
        let { session } = this.props
        let { workingDaysOptions } = this.state
        let getDisableDates = [], filteredDays
        if (session.sessionDisableDatesList?.length > 0) {
            getDisableDates = session.sessionDisableDatesList.find(dDate => this.isSameDay(dDate, date))
        }
        if (session.schoolAcademicYear != null) {
            let filteredIndexes = workingDaysOptions.filter(fl => !session.schoolAcademicYear.days.includes(fl.value)).map(f => f.index)
            if (filteredIndexes?.length > 0) {
                filteredDays = filteredIndexes.includes(date.getDay());
            }
        }
        if (session.sessionDisableDatesList?.length > 0) {
            return filteredDays || getDisableDates
        } else {
            return filteredDays
        }
    }
    deleteTubObject = async (payload, date) => {
        let response = await this.props.sessionAction.deleteSelectedTubObject(payload)
        this.setState({
            contentSelectedDate: date
        })
        if (response) {
            let newdate = moment(date).startOf('day').format('YYYY-MM-DD')
            await this.getCreatedSessionList(newdate)
        }
    }
    addToSelectedContents = (data, index) => {
        let { selectedContents } = this.state
        let session = this.props.session
        if ((selectedContents?.length) < 50) {
            let checkIsAdded = selectedContents.find(s => s._id == data._id)
            if (!checkIsAdded) {
                selectedContents.push({
                    alphabetImage: data.alphabetImage,
                    alphabetName: data.alphabetName,
                    imageTitle: data.imageTitle,
                    originalImageId: data.originalImageId,
                    stencilImage: data.stencilImage,
                    tags: data.tags,
                    studentId: data?.studentId,
                    profilePic: data.profilePic,
                    _id: data._id,
                    isAdded: true
                })
                session.activityList[index].isSelected = true
                this.props.dispatch({ type: main.TUB_ACTIVITY_LIST, payload: session.activityList })
                this.setState({ selectedContents: selectedContents })
            }

        } else {
            toast('Only 50 images can be selected', 'error')
        }
    }
    checkForDate = (contentSelectedDate) => {
        let err = '', sessionToBeCreated = true, today = new Date()
        contentSelectedDate = new Date(contentSelectedDate)

        if (contentSelectedDate.setHours(0, 0, 0, 0) < today.setHours(0, 0, 0, 0)) {
            err = "cannot create session for past date"
            sessionToBeCreated = false
        }
        return {
            err: err,
            sessionToBeCreated: sessionToBeCreated
        }
    }
    createReadingProgram = async (payload) => {
        let { contentSelectedDate } = this.state
        let { dataToPersist } = this.props
        try {
            this.props.dispatch({ type: loaders.INNER_APP_FETCHING, payload: true })
            let selectedClass = sessionStorage.getItem('selectedClass')
            const time = contentSelectedDate.toLocaleTimeString('it-IT')

            let url = "contentselection/createsession/createSessionForReadingProgram";
            let myInit = {
                headers: accessHeaders(),
                body: {
                    Class_Id: selectedClass ? selectedClass : dataToPersist.selectedClass.value,
                    School_Id: sessionStorage.getItem('schoolId'),
                    Session_Date: moment(contentSelectedDate).format(`YYYY-MM-DD[T${time}.000Z]`),
                    createdBy: '',
                    readingProgramId: payload?.readingProgramId,
                    readingProgramDataId: payload?.readingProgramDataId,
                    TubObjectId: payload?.TubObjectId,
                    Session_Type: "Reading_Program",
                    Session_Name: payload?.Session_Name,
                    content_Recevied_Date: payload?.content_Recevied_Date
                }
            }
            const response = await API.post(ENVIRONMENT, url, myInit);
            if (response.status === 200) {
                toast(response?.data?.message, 'success')
            } else {
                toast(response?.data?.message, 'error')
            }
        } catch (error) {
            toast(error?.response?.data?.message, 'error')
            this.props.dispatch({ type: loaders.INNER_APP_FETCHING, payload: false })
        }
    }
    addToCreateSession = async (data, index) => {
        let { selectedContents, selectedActivity, contentSelectedDate, queryPayload } = this.state
        if (selectedActivity.id === 4 || selectedActivity.id === 5) {
            if (selectedContents?.length > 0) {
                let checkForEqualLetter = selectedContents.every(sc => sc.alphabetName.charCodeAt(0) === data.alphabetName.charCodeAt(0))
                if (!checkForEqualLetter) {
                    toast(`Please choose same ${selectedActivity.title} image`, 'error')
                } else {
                    this.addToSelectedContents(data, index)
                }
            } else {
                this.addToSelectedContents(data, index)
            }

        } else if (selectedActivity.id === 6) {
            if (data.tags.includes(queryPayload.search)) {
                this.addToSelectedContents(data, index)
            } else {
                toast(`Please choose same tag`, 'error')
            }
        } else if (selectedActivity.id == 3) {
            this.addToSelectedContents(data, index)
        } else if (selectedActivity.id == 7) {
            let createSessionMasterPayload = {
                userid: localStorage.getItem('userid'),
                AWS_UserName: localStorage.getItem('username'),
                ObjectType: selectedActivity.key,
                data: data.data,
                ObjectImage: {
                    original: '',
                    thumbnail: '',
                },
                ObjectName: data.title,
                Session_Name: data?.title,
                content_Recevied_Date: data?.createdAt,
                createdBy: data?.createdBy,
                readingProgramId: data?.reading_Program_Id,
                readingProgramDataId: data?._id,
                Session_Type: selectedActivity.key
            }
            this.createSessionMaster(createSessionMasterPayload)
            

        } else {
            let sDate = contentSelectedDate != null ? contentSelectedDate : new Date()
            let checkDate = this.checkForDate(contentSelectedDate)
            if (checkDate.sessionToBeCreated) {
                let time = contentSelectedDate.toLocaleTimeString('it-IT')
                let payload = {
                    Class_Id: data.ClassID,
                    Session_Name: data.TubObjectName,
                    School_Id: data.SchoolID,
                    Session_Type: data.TubObjectType,
                    TubObjectId: data.TubObjectId,
                    studentId: data.StudentId,
                    Session_Date: moment(sDate).format(`YYYY-MM-DD[T${time}.000Z]`)
                }
                await this.props.tubGallery.createSession(payload)
                await this.getSelectedActivityList()
                this.props.sessionAction.getCreatedSessionList(moment(new Date()).format('YYYY-MM-DD'))
                await this.props.sessionAction.checkLauchTubEnabled()
                await this.getSessionDates(moment(new Date()).format('MM'))
            } else {
                toast(checkDate.err, 'error')
            }
        }
    }
    unSelectActivity = (data, index) => {
        let { selectedContents } = this.state
        let session = this.props.session
        let updatedSelectedContents = selectedContents.filter(dt => !(dt._id === data._id))
        session.activityList.map(dt => { if (dt._id === data._id) { dt.isSelected = false } })
        this.props.dispatch({ type: main.TUB_ACTIVITY_LIST, payload: session.activityList })
        this.setState({ selectedContents: updatedSelectedContents })
    }
    base64ToArrayBuffer = (base64) => {
        var binary_string = window.atob(base64);
        var len = binary_string?.length;
        var bytes = new Uint8Array(len);
        for (var i = 0; i < len; i++) {
            bytes[i] = binary_string.charCodeAt(i);
        }
        return bytes.buffer;
    }
    convertHtmlToImage = async () => {
        html2canvas(document.getElementById("selectedContents"), {
            onrendered: function (canvas) {
                var _canvas = document.createElement("canvas");
                _canvas.setAttribute('width', 1080);
                _canvas.setAttribute('height', 1920);
                var ctx = _canvas.getContext('2d');
                ctx.drawImage(canvas, 0, 0, canvas.width, canvas.height, 0, 0, 1080, 1920);
                var dataURL = _canvas.toDataURL();
                document.getElementById("canvasWrapper").appendChild(_canvas);
                var image = _canvas.toDataURL("image/png");
                document.getElementById("downloadLink").href = image;
            },
            useCORS: true
        });
        // var node = document.getElementById('selectedContents');
        // console.log("node", node)
        // htmlToImage.toPng(node, {width: 1382, height: 975})
        //     .then(async function (dataUrl) {
        // const link = document.createElement('a');
        // link.download = "html-to-img.png";
        // link.href = dataUrl;
        // link.click();
        // console.log("dataUrl", dataUrl)
        // let bufferI = this.base64ToArrayBuffer(dataUrl)
        // console.log("bufferI", bufferI)
        // document.body.appendChild(img);

        //     let url = "upload/image";
        //     const myInit = {
        //         body: dataUrl,
        //         headers: {
        //             "Authorization": localStorage.getItem("username")
        //         },
        //     };
        //     const response = await API.post(ENVIRONMENT, url, myInit)
        //     console.log("response.data.imageUrl", response.data.imageUrl)
        // })
        // .catch(function (error) {
        //     console.error('oops, something went wrong!', error);
        // });
    }
    createAlphatubContentMaster = async () => {
        let { selectedContents, selectedActivity, contentSelectedDate, queryPayload, tubMixName } = this.state
        let checkForEqualLetter = null
        let payload = {
            userid: localStorage.getItem('userid'),
            AWS_UserName: localStorage.getItem('username'),
            ObjectType: selectedActivity.key,
            data: selectedContents,
            ObjectImage: {
                original: '',
                thumbnail: '',
            },
        }
        if (selectedActivity.id == 5) {
            payload["ObjectName"] = queryPayload["search"] != undefined ? queryPayload["search"] : ''
            this.createSessionMaster(payload)
        } else if (selectedActivity.id == 4) {
            checkForEqualLetter = selectedContents.every(sc => sc.alphabetName === selectedContents[0].alphabetName)
            if (checkForEqualLetter) {
                payload["ObjectName"] = selectedContents[0].alphabetName
            }
            this.createSessionMaster(payload)
        } else if (selectedActivity.id == 6) {
            payload["ObjectName"] = queryPayload["search"] != undefined ? queryPayload["search"] : ''
            this.createSessionMaster(payload)
        } else if (selectedActivity.id == 3) {
            if (!_.isEmpty(tubMixName)) {
                payload["ObjectName"] = tubMixName
                this.createSessionMaster(payload)
            } else {
                toast('please enter a name', 'error')
            }
        }

    }
    createSessionMaster = async (payload) => {
        let { selectedActivity, contentSelectedDate } = this.state
        let { dataToPersist, session } = this.props
        let selectedClass = sessionStorage.getItem('selectedClass')
        contentSelectedDate = contentSelectedDate != null ? contentSelectedDate : new Date()
        let checkDate = this.checkForDate(contentSelectedDate)
        if (checkDate.sessionToBeCreated) {
            let time = contentSelectedDate.toLocaleTimeString('it-IT')
            try {
                // this.props.dispatch({ type: loaders.INNER_APP_FETCHING, payload: true })
                let url = "user/createAlphatubContent";
                let myInit = {
                    headers: accessHeaders(),
                    body: payload
                }
                const response = await API.post(ENVIRONMENT, url, myInit);
                if (response.status === 200) {
                    if(selectedActivity.key === 'Reading_Program'){
                        const rpPayload = {
                            ...payload,
                            TubObjectId: response.data._id,
                        }
                        await this.createReadingProgram(rpPayload)
                        await this.getReadingPrograms()
                    } else {
                        let createSessionPayload = {
                            Class_Id: selectedClass ? selectedClass : dataToPersist.selectedClass.value,
                            School_Id: sessionStorage.getItem('schoolId'),
                            Session_Type: selectedActivity.key,
                            TubObjectId: response.data._id,
                            Session_Date: moment(contentSelectedDate).format(`YYYY-MM-DD[T${time}.000Z]`)
                        }
                        await this.props.tubGallery.createSession(createSessionPayload)
                    }
                    session.activityList.map(dt => dt.isSelected = false)
                    this.props.dispatch({ type: main.TUB_ACTIVITY_LIST, payload: session.activityList })
                    this.props.dispatch({ type: main.IS_SELECTED_ACTIVITY_TAB, payload: false })
                    this.setState({
                        selectedContents: [],
                        queryPayload: { ...this.defaultQueryPayload },
                        // tab: 1,
                        showAddToSessionPopup: false,
                    })
                    
                    this.props.sessionAction.getCreatedSessionList(moment(new Date()).format(`YYYY-MM-DD`))
                    let upcomingSesssionPayload = {
                        schoolId: sessionStorage.getItem('schoolId'),
                        classId: selectedClass != null ? selectedClass : dataToPersist.selectedClass?.value,
                        date: moment(new Date()).format('YYYY-MM-DD')
                    }
                    await this.props.sessionAction.getTodayAndUpcomingSessionList(upcomingSesssionPayload)
                    await this.props.sessionAction.checkLauchTubEnabled()
                    await this.getSessionDates(moment(new Date()).format('MM'))

                } else {
                    // this.setState({
                    //     activityList: []
                    // })
                }
                // this.props.dispatch({ type: loaders.INNER_APP_FETCHING, payload: false })
                return response
            } catch (error) {
                // this.props.dispatch({ type: loaders.INNER_APP_FETCHING, payload: false })
            }
        } else {
            toast(checkDate.err, 'error')
        }
    }
    togglePopup = (popup) => {
        this.setState({
            [popup]: !this.state[popup]
        })
    }
    createSessionForType2 = () => {
        let { selectedActivity } = this.state
        if (selectedActivity.id == 3) {
            this.setState({
                showAddToSessionPopup: true
            })
        } else {
            this.createAlphatubContentMaster()
        }
    }
    handleNameChange = (name, value) => {
        this.setState({ [name]: value })
    }
    unselectSelectedValue = (type) => {
        let { queryPayload } = this.state
        if (type == "filterByDate") {
            queryPayload["startDate"] = ''
            queryPayload['endDate'] = ''
            queryPayload['filterByDate'] = ''
        } else {
            queryPayload[type] = ''
        }
        this.setState({ queryPayload }, this.getSelectedActivityList)
    }
    onHideImage = async (data) => {
        await this.props.sessionAction.onHideContent(data)
        this.getSelectedActivityList()
    }
    onChangeFilterDate = (ranges) => {
        let { queryPayload } = this.state
        queryPayload = {
            ...queryPayload,
            ...ranges
        }
        // queryPayload["filterByDate"] = `Custom Dates ${ranges}` 
        this.setState({
            queryPayload,
            showFilterDatePopup: false
        }, this.getSelectedActivityList)
    }
    getContentListOnEdit = (item) => {
        let { queryPayload } = this.state
        if (item?.Session_Type == 'Letter') {
            let selectedLetter = item?.alphatub_content_result?.ObjectName
            let selectedLetterIndex = ALPHABETS.findIndex(al => al.alphabet == selectedLetter)
            queryPayload["search"] = selectedLetter
            this.setState({
                tab: 2,
                selectedLetterIndexOnEdit: selectedLetterIndex
            }, this.getSelectedActivityList)
        } else if (item?.Session_Type == 'Word' || item?.Session_Type == 'Tag') {
            let selectedLetter = item?.alphatub_content_result?.ObjectName
            queryPayload["search"] = selectedLetter
            this.setState({
                tab: 2,
            }, this.getSelectedActivityList)
        } else {
            this.setState({
                tab: 2,
            }, this.getSelectedActivityList)
        }
    }
    getActivityContent = (item) => {
        switch (item?.Session_Type) {
            case 'Mix':
                return {
                    key: 'Mix',
                    id: 3,
                    type: 2,
                }
            case 'Letter':
                return {
                    id: 4,
                    type: 2,
                    key: 'Letter',
                }
            case 'Word':
                return {
                    id: 5,
                    type: 2,
                    title: 'Word',
                    key: 'Word',
                }
            case 'Tag':
                return {
                    id: 6,
                    type: 2,
                    title: 'Tag',
                    key: 'Tag',
                }
        }
    }
    setSelectedContents = (contents, item) => {
        let getActivity = this.getActivityContent(item)
        this.setState({
            isEditContent: true,
            selectedContents: contents,
            selectedActivity: getActivity
        }, () => this.getContentListOnEdit(item))
    }
    eraseEditContent = () => {
        let { selectedContents } = this.state
        let { activityList } = this.props.session
        let contentLength = selectedContents?.length
        if (contentLength > 0) {
            let i = 0
            while (i < contentLength) {
                if (activityList?.length > 0) {
                    let checkInResIndex = activityList.findIndex(res => res._id == selectedContents[i]._id)
                    if (checkInResIndex != -1) {
                        activityList[checkInResIndex]["isSelected"] = false
                    }
                }
                i++
            }
            this.props.dispatch({ type: main.TUB_ACTIVITY_LIST, payload: activityList })
        }
        this.setState({
            selectedContents: [],
            selectedLetterIndexOnEdit: null,
            isShowEraseContentPopup: false,
            isEditContent: false
        })
    }

    showOnlyAssignment = () => {
        this.setState({
            isSelectedOnlyAssignment: !this.state.isSelectedOnlyAssignment
        }, this.getSelectedActivityList)
    }
    tileContent = ({ date, view }) =>  {
        let { session } = this.props
        if (view === 'month') {
            let getContent = session.sessionAssignedDates.sessionPastDates.find(dDate => this.isSameDay(dDate, date))
            if(getContent){
                return 'program_date';
            }

            let getUnAssignedContent = session.sessionAssignedDates.sessionFutureDates.find(dDate => this.isSameDay(dDate, date))
            if(getUnAssignedContent){
                return 'assignment_present_date';
            }
            
        }
    }
    changeSessionCalendarMonth = async (value) => {
        const month =  moment(new Date(value)).format('MM')
        await this.getSessionDates(month)
    }
    render() {
        let state = this.state
        return (
            <>
                {state.loading ?
                    <AppLoader />
                    :
                    <div className="row no-gutters">
                        {
                            state.tab === 1 &&
                            <MainPage
                                {...this.props}
                                {...state}
                                getCreatedSessionList={this.getCreatedSessionList}
                                selectActivity={this.selectActivity}
                                tileDisabled={this.tileDisabled}
                                tileClassName={this.tileContent}
                                onChangeSessionCalendarMonth = {this.changeSessionCalendarMonth}
                                deleteTubObject={this.deleteTubObject}
                                onNavigate={this.navigate}
                            />
                        }
                        {
                            state.tab === 2 &&
                            <Tubtypes
                                {...this.props}
                                {...state}
                                onHideImage={this.onHideImage}
                                onNavigate={this.navigate}
                                onChangeCustomOptions={this.onChangeCustomOptions}
                                onSearchTubActivities={this.onSearchTubActivities}
                                addToCreateSession={this.addToCreateSession}
                                unSelectActivity={this.unSelectActivity}
                                onSuggestionSelect={this.onSuggestionSelect}
                                createSessionForType2={this.createSessionForType2}
                                unselectSelectedValue={this.unselectSelectedValue}
                                showOnlyAssignment={this.showOnlyAssignment}
                            />
                        }
                        {
                            state.tab === 3 &&
                            <CreateSession
                                {...this.props}
                                {...state}
                                setSelectedContents={this.setSelectedContents}
                                onNavigate={this.navigate}
                                selectActivity={this.selectActivity}
                                getCreatedSessionList={this.getCreatedSessionList}
                                deleteTubObject={this.deleteTubObject}
                                unSelectActivity={this.unSelectActivity}
                                createSessionForType2={this.createSessionForType2}
                                tileDisabled={this.tileDisabled}
                                tileClassName={this.tileContent}
                                onChangeSessionCalendarMonth = {this.changeSessionCalendarMonth}
                            />
                        }

                        {/* <div className="tub-session-card">
                                <div className="p-2">
                                    <div className="row flex-wrap p-1 no-gutters">
                                            <div className="col-11 position_relative" id="selectedContents">
                                            {state.selectedContents && state.selectedContents.map((dt, i) => (
                                                <div className={`typeTwoImage`}>
                                                <img
                                                    src={dt.alphabetImage.original}
                                                    alt="alphatub"
                                                />
                                                </div>
                                            ))}
                                            </div>
                                        
                                    </div>
                                </div>
                            </div> */}
                    </div>
                }
                {/* Erase Edit Content Confirmation popup */}
                {
                    state.isShowEraseContentPopup &&
                    <CreateModel
                        isOpen={state.isShowEraseContentPopup}
                        toggle={() => this.togglePopup('isShowEraseContentPopup')}
                        title={""}
                    >
                        <div className="alphatub-card-border"></div>
                        <div className="row pt-3 pb-5">
                            <div className="col-12">
                                <p className="f-16px alphatub-card-normal-p">
                                    Upon search, selected contents will be erased ?
                                </p>
                            </div>
                        </div>
                        <div className="alphatub-card-border"></div>
                        <div className="row align-items-center mt-3 content-end">
                            <div className="col-4">
                                <ButtonType1
                                    btnName="OK"
                                    className='bg-tub c-white buttonType1 '
                                    onClick={() => this.eraseEditContent()}
                                />
                            </div>
                        </div>
                    </CreateModel>
                }
                {/* Information popup */}
                {
                    state.showActivityInfoPopup &&
                    <CreateModel
                        isOpen={state.showActivityInfoPopup}
                        toggle={() => this.togglePopup('showActivityInfoPopup')}
                        title={""}
                    >
                        <div className="alphatub-card-border"></div>
                        <div className="row pt-3 pb-5">
                            <div className="col-12">
                                <p className="f-16px alphatub-card-normal-p">
                                    Please choose a {state.selectedActivity.title} to add a content to the collection
                                </p>
                            </div>
                        </div>
                        <div className="alphatub-card-border"></div>
                        <div className="row align-items-center mt-3 content-end">
                            <div className="col-4">
                                <ButtonType1
                                    btnName="OK"
                                    className='bg-tub c-white buttonType1 '
                                    onClick={() => this.togglePopup('showActivityInfoPopup')}
                                />
                            </div>
                        </div>
                    </CreateModel>
                }
                {/* confirmation popup */}
                {
                    state.showAddToSessionPopup &&
                    <CreateModel
                        isOpen={state.showAddToSessionPopup}
                        toggle={() => this.togglePopup('showAddToSessionPopup')}
                        title={"Name The TUB Mix"}
                    >
                        <div className="alphatub-card-border"></div>
                        <div className="row pt-4 pb-2">
                            <div className="col-12">
                                <FormInput
                                    type="text"
                                    label="Enter the name for TUB Mix Collection"
                                    name="tubMixName"
                                    maxLength={70}
                                    placeholder="Enter a Name"
                                    onChange={(...params) => this.handleNameChange(...params)}
                                    value={state.tubMixName}
                                />
                            </div>
                        </div>
                        <div className="alphatub-card-border"></div>
                        <div className="row align-items-center mt-3 content-end">
                            <div className="col-4">
                                <ButtonType1
                                    btnName="Cancel"
                                    className='popup-close-btn'
                                    onClick={() => this.togglePopup('showAddToSessionPopup')}
                                />
                            </div>
                            <div className="col-4">
                                <ButtonType1
                                    btnName="Save"
                                    className='bg-tub c-white buttonType1 '
                                    onClick={() => this.createAlphatubContentMaster()}
                                />
                            </div>
                        </div>
                    </CreateModel>
                }
                {/* Filter date popup*/}
                <DateRangeFilterPopup
                    showFilterDatePopup={state.showFilterDatePopup}
                    triggerPopup={() => this.togglePopup('showFilterDatePopup')}
                    onChangeFilterDate={this.onChangeFilterDate}
                />
            </>
        )
    }
}

const mapStateToProps = ({ tubGallery, myProfile, loader, session, dataToPersist, curriculum }) => {
    return {
        tubGallery,
        myProfile,
        dataToPersist,
        curriculum,
        loader,
        session
    };
};

let mapDispatchToProps = (dispatch) => {
    return {
        tubGallery: bindActionCreators(tubgallery, dispatch),
        sessionAction: bindActionCreators(sessionAction, dispatch),
        curriculumAction: bindActionCreators(curriculumAction, dispatch),
        dispatch
    }
}

const Session = Wrapper(connect(mapStateToProps, mapDispatchToProps)(CreateSessionClass))

export { Session }