import { React, useEffect, useState } from "react";
import ButtonType1 from "../../../../../components/inputs/ButtonType1";
import { CustomBootstrapTable } from "../../../../../components/Table/CustomBootstrapTable";
import { SchoolTableColumns } from "../../../../../helpers/tableDataMapper";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import toast from "../../../../../helpers/Toast";
import _ from "lodash";
import { accessHeaders } from "helpers";
import { ENVIRONMENT } from "common/constants";
import { API } from "aws-amplify";
import Select from "react-select";
const EnrolledSchools = (props) => {
  const { disabled, isEnrolledSchoolsBtnsDisabled, onNavigate } =
    props;
  const schoolFilterDropdown = [
    {
      label: "All",
      value: "All",
    },
    {
      label: "Active",
      value: "Active",
    },
    {
      label: "InActive",
      value: "InActive",
    },
  ];
  const [schoolTableList, setSchoolData] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(1000000);
  const [totalItems, setTotalItems] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [selectedOption, setSelectedOption] = useState(schoolFilterDropdown[1]);
  const [loader, setloader] = useState(false);
  const getTableColumns = (disabled) => {
    if (!disabled) {
      let action = {
        text: "Action",
        dataField: "action",
        headerClasses: "columnHeader text-center",
        formatter: (cellContent, row, rowIndex) => {
          return (
            <>
              <div className="row align-items-center justify-content-center">
                <div
                  className=""
                  // className={row.status === 'Active' ? 'roundBlue mr-2' : 'roundRed mr-2'}
                >
                  {/* {row.status === 'Active' &&  row.parentAcceptance === 1 && !_.isEmpty(row.playerId) &&
                                        <p onClick={() => resendRequest(row)} className="resendRequest mr-2">Resend Request</p>
                                    } */}
                </div>
                <div className="ml-1">
                  <img
                    src={require("../../../../../assets/images/rightAction.png")}
                    className="cursor-pointer studentEditIcon"
                    onClick={() => onNavigate(5, row)}
                    alt="alphatub"
                  />
                </div>
              </div>
            </>
          );
        },
      };
      return [...SchoolTableColumns, action];
    } else {
      return [...SchoolTableColumns];
    }
  };

  const exportData = () => {
    // let { schoolTableList } = props;
    if (schoolTableList?.length > 0) {
      console.log("schoolTableListschoolTableList", schoolTableList);
      let newArray = [];
      schoolTableList.map((data) => {
        newArray.push({
          SchoolName: data.SchoolName,
          Admin: data.PrincipalFirstName,
          Email: data.SchoolEmailID,
          AllocatedSeats: data.latestSubscription?.SeatsPurchased,
          status: data.Status,
        });
      });
      const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const fileName = "School details";
      const fileExtension = ".xlsx";
      const ws = XLSX.utils.json_to_sheet(newArray);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);
    } else {
      toast("Please create student to export", "error");
    }
  };

  const fetchData = async (selectedOption) => {
    console.log(selectedOption, "selectedOption");
    let url = "director/schoolListing";
    const queryStringParameters = {
      page: page.toString(),
      limit: limit.toString(),
      search: searchInput,
    };
    if (selectedOption?.value && selectedOption?.value !== 'All') {
      queryStringParameters.status = selectedOption.value;
    }
    let myInit = {
      headers: accessHeaders(),
      queryStringParameters,
    };
    setloader(true);
    try {
      const response = await API.get(ENVIRONMENT, url, myInit);

      if (response.status === 200) {
        const { items, totalItems } = response.data;
        setSchoolData(
          items.map((item, index) => ({ ...item, serialNumber: index + 1 }))
        );

        setTotalItems(totalItems);
        setloader(false);
        // Disable "Next" button if there is no next page
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (newLimit) => {
    setLimit(newLimit);
  };
  const handleSearch = () => {
    fetchData(selectedOption);
  };

  // Event handler for input change
  const handleInputChange = (event) => {
    setSearchInput(event.target.value);
  };

  // Event handler for Enter key press
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };
  const handlechange = (event) => {
    setSelectedOption(event);
  };
  useEffect(() => {
    fetchData(selectedOption);
  }, [page, limit, selectedOption]);

  return (
    <div className="col-md-12 pl-3 pr-3 classTable ">
      {/* <div className="col-md-12 mb-3 mt-1">
                    <div className="row no-gutters">
                        <img src={require('../../../../../assets/images/left-arrow.svg').default} className="back-btn" onClick={() => props.onNavigate(1, null)} alt="alphatub" />
                        <p className="global_color privacy-content cursor-pointer ml-3" onClick={() => props.onNavigate(1, null)} >Back To Classes</p>
                    </div>
                </div> */}
      <div className="row no-gutters">
        <div className="col-9">
          <h3 className="login-text-login text-left">Your Schools</h3>
        </div>
        {/* {!disabled &&
                        <div className="col-3">
                            <p className="classes-seats-link text-right global_red_color" onClick={() => props.createStudent(7, "Delete Class", null, 'student')}>Delete Class</p>
                        </div>
                    } */}
      </div>
      <div className="row no-gutters mt-3 mb-3">
        <div className="col-3">
          <div className="row no-gutters">
            <div className="col-12 custom-reselect1">
              <Select
                name="status"
                options={schoolFilterDropdown}
                isClearable={false}
                value={selectedOption}
                onChange={handlechange}
              />
            </div>
          </div>
        </div>
        {!isEnrolledSchoolsBtnsDisabled && (
          <div className="col-9">
            <div className="row no-gutters justify-end">
              <div className="col-10">
                <div className="row">
                  <div className="col-4">
                  </div>
                  <div className="col-4">
                    <input
                      type="search"
                      placeholder="Search..."
                      className="buttonTypes2"
                      value={searchInput}
                      onChange={handleInputChange}
                      onKeyPress={handleKeyPress}
                    />
                  </div>
                  <div className="col-4">
                    <ButtonType1
                      btnName="Export"
                      className="buttonType1"
                      onClick={() => exportData()}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="row no-gutters">
        <div className="col-12">
          <CustomBootstrapTable
            totalItems={totalItems}
            page={page}
            setPage={setPage}
            rows={schoolTableList}
            columns={getTableColumns(disabled)}
            isLoading={loader}
            keyField="shopId"
            tableClass="custom-row-class"
            tableMainClass="student-table"
            onPageChange={handlePageChange}
            onLimitChange={handleLimitChange}
          />
        </div>
      </div>
    </div>
  );
};

export default EnrolledSchools;
